import { call, put, takeEvery } from 'redux-saga/effects'

// Crypto Redux States
import {
  GET_BANNERS,
  CREATE_BANNER,
  GET_BANNER_DETAILS,
  DELETE_BANNER,
  UPDATE_BANNER,
} from './actionTypes'
import {
  getBannersSuccess,
  getBannersFail,
  getBannerDetailsSuccess,
  getBannerDetailsFail,
  createBannerFail,
  createBannerSuccess,
  updateBannerSuccess,
  updateBannerFail,
  deleteBannerSuccess,
  deleteBannerFail,
} from './actions'

import { post, get, ApiPut, del } from '../../helpers/api_helper'
import { Notification } from '../../components/Common/Notification'

function bannerApi() {
  return get('/banner/admin/all')
}

const getBannerDetailsAPi = bannerId => {
  return get(`/banner/admin/${bannerId}`)
}

function createBannerApi({ banner }) {
  return post('/banner/admin/new', banner)
}

function updateBannerApi({ banner, bannerId }) {
  return ApiPut(`/banner/admin/${bannerId}`, banner)
}

function deleteBannerApi(bannerId) {
  return del(`/banner/admin/${bannerId}`)
}

function* fetchBanners() {
  try {
    const response = yield call(bannerApi)
    yield put(getBannersSuccess(response))
  } catch (error) {
    yield put(getBannersFail(error))
  }
}

function* fetchBannerDetails({ payload: zestonProductId }) {
  try {
    const response = yield call(getBannerDetailsAPi, zestonProductId)
    yield put(getBannerDetailsSuccess(response))
  } catch (error) {
    yield put(getBannerDetailsFail(error))
  }
}

function* onCreateBanner({ payload }) {
  try {
    const response = yield call(createBannerApi, payload)
    Notification({
      type: 'success',
      message: 'Banner Created Successfully!',
      title: '',
    })
    yield put(createBannerSuccess(response))
    payload.history.push('/banners')
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: 'error',
        message: error.response?.data?.message,
        title: '',
      })
    }
    yield put(createBannerFail(error))
  }
}

function* onUpdateBanner({ payload }) {
  try {
    const response = yield call(updateBannerApi, payload)
    Notification({
      type: 'success',
      message: 'Banner Updated Successfully!',
      title: '',
    })
    yield put(updateBannerSuccess(response))
    payload.history.push('/banners')
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: 'error',
        message: error.response?.data?.message,
        title: '',
      })
    }
    yield put(updateBannerFail(error))
  }
}

function* onDeleteBanner({ bannerId }) {
  try {
    const response = yield call(deleteBannerApi, bannerId)
    Notification({
      type: 'success',
      message: 'Banner Deleted Successfully!',
      title: '',
    })
    yield put(deleteBannerSuccess(response))
  } catch (error) {
    yield put(deleteBannerFail(error))
  }
}

function* bannerSaga() {
  yield takeEvery(GET_BANNERS, fetchBanners)
  yield takeEvery(GET_BANNER_DETAILS, fetchBannerDetails)
  yield takeEvery(CREATE_BANNER, onCreateBanner)
  yield takeEvery(UPDATE_BANNER, onUpdateBanner)
  yield takeEvery(DELETE_BANNER, onDeleteBanner)
}

export default bannerSaga
