import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Spinner,
  FormGroup,
} from 'reactstrap'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'

// data-table related plugins
import BootstrapTable from 'react-bootstrap-table-next'
import { debounce, map, range } from 'lodash'

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'

//actions
import { deleteReview, getAllProducts, getAllReview } from 'store/actions'

import '../../assets/scss/data-tables.scss'

import MyPagination from 'components/Common/MyPagination'
import DeleteModal from 'components/Common/DeleteModal'
import ReviewModal from './Crud/Modal'

const Reviews = () => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [reviewModal, setReviewModal] = useState({ status: false, data: {} })

  const { loading, reviews, prodLoading, products } = useSelector(state => ({
    loading: state.Products.loading,
    reviews: state.Products.reviews,
    prodLoading: state.Products.loading,
    products: state.Products.products,
  }))

  const totalPages = Math.ceil(reviews?.total / 10)
  const pages = range(1, totalPages + 1)

  const [productSelect, setProductSelect] = useState({})
  const [productSearch, setProductSearch] = useState()

  useEffect(() => {
    dispatch(getAllReview(page, 10, '', productSelect?.id))
  }, [dispatch, page, '', productSelect?.id])

  useEffect(() => {
    dispatch(getAllProducts(1, 'all', '', productSearch))
  }, [dispatch, productSearch])

  const [isOpen, setIsOpen] = useState({ status: false, id: '' })

  const handleDelete = id => {
    setIsOpen({ status: true, id: id })
  }

  const handleDeleteEvent = () => {
    setIsOpen({ ...isOpen, status: false })
    dispatch(deleteReview(isOpen?.id))
  }

  const columns = [
    {
      dataField: 'user',
      text: 'User Name',
      sort: true,
    },
    {
      dataField: 'product',
      text: 'Product',
      sort: true,
    },
    {
      dataField: 'rating',
      text: 'Rating',
      sort: true,
    },
    {
      dataField: 'message',
      text: 'Message',
      sort: true,
    },
    {
      dataField: 'action',
      text: 'Action',
    },
  ]

  const tableData = map(reviews?.reviews, (item, index) => ({
    ...item,
    user: <span>{item?.user?.name || item?.phone}</span>,
    product: <p>{item?.product?.primaryLang?.name}</p>,
    message: (
      <div style={{ whiteSpace: 'break-spaces' }}>
        {item?.message?.slice(0, 110)}
        {item?.message?.length > 110 ? '...' : ''}
      </div>
    ),
    action: (
      <div>
        <Link
          to={`#`}
          className="btn-md"
          onClick={() => setReviewModal({ status: true, data: item })}
        >
          <i className="bx bx-edit me-2 " style={{ fontSize: '1.3rem' }} />
        </Link>
        <Button
          id={`Delete${index}`}
          type="button"
          color="white"
          className="ms-1 btn "
          onClick={() => {
            handleDelete(item._id)
          }}
        >
          <i
            className="bx bx-trash me-2"
            style={{ color: 'red', marginBottom: '10px', fontSize: '1.3rem' }}
          />
        </Button>
      </div>
    ),
  }))

  const { SearchBar } = Search

  //product dropdown
  const debounceProductSearch = debounce(value => setProductSearch(value), 600)

  const handleProductEnters = textEntered => {
    debounceProductSearch(textEntered)
  }

  function handlerProductFinalValue(event) {
    const product = {
      ...productSelect,
      title: event.value !== 'loading' && event.label,
      id: event.value,
    }
    setProductSelect(product)
  }

  const productOptions = (products?.length >= 1 ? products : [])?.map(
    (result, index) => ({
      key: index,
      label: result?.primaryLang?.name,
      value: result?._id,
    }),
  )

  const modifiedOption = [
    {
      key: 1234,
      label: 'All',
      value: '',
    },
    ...productOptions,
  ]

  return (
    <React.Fragment>
      <Row>
        <DeleteModal
          show={isOpen?.status}
          onCloseClick={() => setIsOpen({ ...isOpen, status: false })}
          onDeleteClick={handleDeleteEvent}
        />
        <ReviewModal
          onCloseClick={() => setReviewModal({ status: false, data: {} })}
          show={reviewModal?.status}
          updateData={reviewModal?.data}
        />
        <Col className="col-12">
          <Card>
            <CardBody>
              <ToolkitProvider
                keyField="_id"
                key={'id'}
                columns={columns}
                data={tableData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col sm="4">
                        <div className="search-box ms-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <SearchBar {...toolkitProps.searchProps} />
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>
                      </Col>
                      <Col lg={4}>
                        {' '}
                        <FormGroup className="mb-3">
                          <div className="ajax-select mt-lg-0 select2-container">
                            <Select
                              onInputChange={handleProductEnters}
                              value={productSelect?.title}
                              placeholder={productSelect?.title}
                              onChange={handlerProductFinalValue}
                              options={modifiedOption}
                              classNamePrefix="select2-selection"
                              isLoading={prodLoading}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="8 " lg={4}>
                        <div className="text-sm-end">
                          <Button
                            type="button"
                            color="success"
                            className="btn-rounded  mb-2 me-2"
                            onClick={() => setReviewModal({ status: true })}
                          >
                            <i className="mdi mdi-plus me-1" />
                            Create Review
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        {reviews?.total >= 1 ? (
                          <>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={'id'}
                                    key={'id'}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    // selectRow={selectRow}
                                    classes={'table align-middle table-nowrap'}
                                    headerWrapperClasses={'thead-light'}
                                    {...toolkitProps.baseProps}
                                    // onTableChange={handleTableChange}
                                    // {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <MyPagination
                              totalPages={pages}
                              page={page}
                              setPage={setPage}
                            />
                          </>
                        ) : (
                          <p className="my-4 mx-3 ">No Reviews !</p>
                        )}
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Reviews
