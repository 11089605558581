import { AvForm } from 'availity-reactstrap-validation'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { Card, CardBody, CardTitle, Col, Container, Row } from 'reactstrap'

//actions
import { getPromoterDetails, updatePromoter } from 'store/actions'
import MetaTag from 'components/Common/Meta-tag'
import Form from './Form'
import BreadcrumbPremium from 'components/Common/Breadcrumb2'

const UpdatePromoter = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams()
  const [isImaUser, setIsImaUser] = useState(false)

  const { promoterDetails, loading } = useSelector(state => ({
    promoterDetails: state?.Users?.promoterDetails,
    loading: state.Users.promoterLoad,
  }))

  function handleValidSubmit(values) {
    const finalPromoterData = {
      ...values,
      promoterPercentage: parseInt(values?.promoterPercentage),
    }
    dispatch(updatePromoter(finalPromoterData, promoterDetails?._id, history))
  }

  useEffect(() => {
    dispatch(getPromoterDetails(params?.id))
  }, [dispatch])

  return (
    <>
      <MetaTag title={'Update Promoter'} />

      <div className="page-content">
        <BreadcrumbPremium
          root={[
            '/promoter-dashboard',
            '/promoters',
            promoterDetails?.name,
            'Edit Details',
          ]}
        />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col lg={8} md={8}>
                <Card>
                  <CardBody>
                    <CardTitle className="h4 mb-4">
                      {/* Fill this form */}
                    </CardTitle>
                    <AvForm
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(v)
                      }}
                    >
                      <Form
                        updateData={promoterDetails}
                        isUpdate={true}
                        isImaUser={isImaUser}
                        setIsImaUser={setIsImaUser}
                      />

                      <Row>
                        <Col>
                          <div className="text-end">
                            <button
                              type="submit"
                              className="btn btn-success save-customer"
                            >
                              {loading && (
                                <>
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                </>
                              )}
                              Update
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default UpdatePromoter
