import {
  GET_COURSEFORMS,
  GET_COURSEFORMS_SUCCESS,
  GET_COURSEFORMS_FAIL,
  GET_COURSEFORM_DETAILS,
  GET_COURSEFORM_DETAILS_SUCCESS,
  GET_COURSEFORM_DETAILS_FAIL,
  CREATE_COURSEFORM,
  CREATE_COURSEFORM_SUCCESS,
  CREATE_COURSEFORM_FAIL,
  UPDATE_COURSEFORM,
  UPDATE_COURSEFORM_SUCCESS,
  UPDATE_COURSEFORM_FAIL,
  DELETE_COURSEFORM_SUCCESS,
  DELETE_COURSEFORM_FAIL,
  DELETE_COURSEFORM,
} from './actionTypes'

// CourseForm - This line cannot be edited or removed
export const getCourseForms = (page, sort, limit, searchText) => ({
  type: GET_COURSEFORMS,
  payload: { page, sort, limit, searchText },
})

export const getCourseFormsSuccess = courseforms => ({
  type: GET_COURSEFORMS_SUCCESS,
  payload: courseforms,
})

export const getCourseFormsFail = error => ({
  type: GET_COURSEFORMS_FAIL,
  payload: error,
})

export const getCourseFormDetails = courseformId => ({
  type: GET_COURSEFORM_DETAILS,
  payload: courseformId,
})

export const getCourseFormDetailsSuccess = courseformDetails => ({
  type: GET_COURSEFORM_DETAILS_SUCCESS,
  payload: courseformDetails,
})

export const getCourseFormDetailsFail = error => ({
  type: GET_COURSEFORM_DETAILS_FAIL,
  payload: error,
})

export const createCourseForm = (courseform, history) => ({
  type: CREATE_COURSEFORM,
  payload: { courseform, history },
})

export const createCourseFormSuccess = courseform => ({
  type: CREATE_COURSEFORM_SUCCESS,
  payload: courseform,
})

export const createCourseFormFail = error => ({
  type: CREATE_COURSEFORM_FAIL,
  payload: error,
})

export const getCourseFormDetail = courseformId => ({
  type: GET_COURSEFORM_DETAILS,
  payload: courseformId,
})

export const updateCourseForm = (courseform, courseformId, history) => ({
  type: UPDATE_COURSEFORM,
  payload: { courseform, courseformId, history },
})

export const updateCourseFormSuccess = courseform => ({
  type: UPDATE_COURSEFORM_SUCCESS,
  payload: courseform,
})

export const updateCourseFormFail = error => ({
  type: UPDATE_COURSEFORM_FAIL,
  payload: error,
})

export const deleteCourseForm = (courseformId, history) => ({
  type: DELETE_COURSEFORM,
  payload: { courseformId, history },
})

export const deleteCourseFormSuccess = courseform => ({
  type: DELETE_COURSEFORM_SUCCESS,
  payload: courseform,
})

export const deleteCourseFormFail = error => ({
  type: DELETE_COURSEFORM_FAIL,
  payload: error,
})
