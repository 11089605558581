import React from 'react'
import PropTypes from 'prop-types'
import { Col, Modal, ModalBody, Row } from 'reactstrap'
import Spinner from 'components/Common/Spinner'
import { useDispatch, useSelector } from 'react-redux'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import { map } from 'lodash'
import { addUserAddress } from 'store/actions'

const AddressModal = ({ onCloseClick, show, userId }) => {
  const dispatch = useDispatch()
  const { shippingInputs, loading } = useSelector(state => ({
    shippingInputs: state.Settings.settings?.setup?.shippingInputs,
    loading: state.Users.addressLoad,
  }))

  const handleLabel = key => {
    switch (key) {
      case 'contactNumber':
        return 'Contact Number'
      case 'pinCode':
        return 'Pin Code'
      default:
        return key
    }
  }
  const handleInputCol = key => {
    switch (key) {
      case 'address':
        return 'col-12'
      default:
        return 'col-6'
    }
  }

  function HandleInputType(input) {
    switch (input) {
      case 'contactNumber':
      case 'pinCode':
        return 'number'
      case 'email':
        return 'email'
      case 'address':
        return 'textarea'
      default:
        return 'text'
    }
  }

  const modifiedShippingInputs = shippingInputs?.map(item => ({
    ...item,
    label: handleLabel(item?.name),
    col: handleInputCol(item?.name),
  }))

  const handleValidSubmit = value => {
    dispatch(addUserAddress({ shippingInfo: value }, userId, onCloseClick))
  }

  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      centered={true}
      style={deleteModalStyle}
      className="delete-modal"
    >
      <ModalBody style={deleteModalBodyStyle} className="p-0">
        <div
          style={{
            backgroundColor: '#f8f8f8',
            borderRadius: '10px',
            padding: '1.4rem',
          }}
          className="h-100"
        >
          <AvForm
            className="form-horizontal h-100 d-flex justify-content-between flex-column"
            onValidSubmit={(e, v) => {
              handleValidSubmit(v)
            }}
          >
            <Row>
              {map(modifiedShippingInputs, (input, key) => (
                <Col className={'mb-3 ' + input?.col} key={key}>
                  <AvField
                    name={input?.name}
                    label={input?.label}
                    className="form-control"
                    placeholder={input?.label}
                    type={HandleInputType(input?.name)}
                    required={input.required === 'Required' ? true : false}
                  />
                </Col>
              ))}
            </Row>
            <div className="hstack gap-2 mt-3">
              <button
                className="btn btn-outline-dark w-50"
                style={{ borderRadius: 10 }}
                onClick={onCloseClick}
              >
                Cancel
              </button>
              <button
                type="submit"
                style={{ borderRadius: 10 }}
                className="btn btn-success w-100"
              >
                Add Address
                {loading && <Spinner />}
              </button>
            </div>
          </AvForm>
        </div>
      </ModalBody>
    </Modal>
  )
}

AddressModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.bool,
  userId: PropTypes.string,
  // data: PropTypes.object,
}

export default AddressModal

const deleteModalStyle = {
  width: 600,
}
const deleteModalBodyStyle = {
  minHeight: 300,
}
