import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { Container } from 'reactstrap'
import { Row } from 'reactstrap'

//actions
import { getLuckyDrawDetails } from 'store/actions'
import MetaTag from 'components/Common/Meta-tag'
import CardUser from './elements/CardUser'
import Winner from './elements/Winner'
import BreadcrumbPremium from 'components/Common/Breadcrumb2'

const LuckyDrawDetailsDetails = () => {
  const dispatch = useDispatch()
  const params = useParams()

  const { luckyDrawDetails } = useSelector(state => ({
    luckyDrawDetails: state.SupplyChain.luckyDrawsDetails,
  }))

  useEffect(() => {
    dispatch(getLuckyDrawDetails(params?.id))
  }, [dispatch])

  return (
    <>
      <MetaTag title={'LuckyDraw Details'} />

      <div className="page-content">
        <BreadcrumbPremium
          root={['/promoter-dashboard', '/lucky-draw', 'LuckyDraw Details']}
        />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <CardUser luckyDrawDetails={luckyDrawDetails} />
              <Winner luckyDrawDetails={luckyDrawDetails} />
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default LuckyDrawDetailsDetails
