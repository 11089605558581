import { AvField, AvForm } from 'availity-reactstrap-validation'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Badge, Col, Modal, ModalBody, Row } from 'reactstrap'
import { createWithdrawalRequest, updateWithdrawalRequest } from 'store/actions'
import WithdrawalRequestForm from './Form'
import { CurrencySwitch } from 'hooks/Currency'

const WithdrawalModal = ({
  show,
  onCloseClick,
  updateData,
  isPromoterDashboard,
}) => {
  const dispatch = useDispatch()
  const currency = CurrencySwitch()

  const { loading, errorMsg } = useSelector(state => ({
    loading: state.SupplyChain.loading,
    errorMsg: state.SupplyChain.error,
  }))

  const role = sessionStorage.getItem('role')

  const [promoterId, setPromoterId] = useState('')
  const [select, setSelect] = useState('')

  function handleValidSubmit(values) {
    const WithdrawalData = {
      ...values,
      promoter: promoterId,
      amount: parseInt(values?.amount),
    }
    const UpdateData = {
      status: select,
      ...values,
    }

    if (isPromoterDashboard) {
      dispatch(
        createWithdrawalRequest(
          { amount: parseInt(values?.amount) },
          onCloseClick,
        ),
      )
    } else if (updateData !== null) {
      dispatch(
        updateWithdrawalRequest(UpdateData, updateData?._id, onCloseClick),
      )
    } else {
      dispatch(createWithdrawalRequest(WithdrawalData, onCloseClick))
    }
  }

  console.log(updateData)
  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      centered={true}
      style={{ width: role === 'admin' ? 600 : 300 }}
    >
      <ModalBody style={deleteModalBodyStyle} className="p-4">
        {errorMsg?.length >= 1 && <Alert color="danger">{errorMsg}</Alert>}

        <AvForm
          onValidSubmit={(e, v) => {
            handleValidSubmit(v)
          }}
        >
          <Row xl="12">
            <div className="d-flex align-items-start justify-content-between mb-2">
              <div>
                <p className="mb-0 pb-0">Name : {updateData?.promoter?.name}</p>
                <p>Phone : {updateData?.promoter?.phone}</p>
                <p>
                  Amount : {currency} {updateData?.amount}
                </p>
              </div>
              <Badge
                className={'font-size-12 me-2 p-2 badge-soft-info mt-2'}
                pill
              >
                Wallet Balance : {updateData?.promoter?.walletBalance}
              </Badge>
            </div>
            <Col xl="12">
              <hr />
              {isPromoterDashboard ? (
                <AvField
                  name="amount"
                  label="Amount"
                  className="mb-3"
                  type="number"
                  value={updateData?.amount}
                />
              ) : (
                <WithdrawalRequestForm
                  PromoterId={setPromoterId}
                  updateData={updateData}
                  setSelect={setSelect}
                  select={select}
                />
              )}
              <button
                type="submit"
                className="col-12 mt-3 btn btn-success save-customer"
              >
                {loading && (
                  <>
                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                  </>
                )}
                {updateData ? 'save' : 'Request'}
              </button>
            </Col>
          </Row>
        </AvForm>
      </ModalBody>
    </Modal>
  )
}

WithdrawalModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
  updateData: PropTypes.any,
  isPromoterDashboard: PropTypes.bool,
}

export default WithdrawalModal

const deleteModalBodyStyle = {
  minHeight: 100,
}
