import React from 'react'

import GrossRevenues from './Details'

function GrossRevenue() {
  return (
    <>
      <GrossRevenues />
    </>
  )
}

export default GrossRevenue
