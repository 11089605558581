import {
  GET_COUPONS,
  GET_COUPONS_FAIL,
  GET_COUPONS_SUCCESS,
  GET_COUPON_DETAILS,
  GET_COUPON_DETAILS_SUCCESS,
  GET_COUPON_DETAILS_FAIL,
  CREATE_COUPON_SUCCESS,
  CREATE_COUPON_FAIL,
  UPDATE_COUPON,
  UPDATE_COUPON_SUCCESS,
  UPDATE_COUPON_FAIL,
  DELETE_COUPON_SUCCESS,
  DELETE_COUPON_FAIL,
  CREATE_COUPON,
} from './actionTypes'

const INIT_STATE = {
  coupons: [],
  couponDetails: {},
  error: {},
  loading: false,
  tableLoad: false,
}

const Coupon = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COUPON_DETAILS:
    case UPDATE_COUPON:
    case CREATE_COUPON:
      return {
        ...state,
        loading: true,
      }
    case GET_COUPONS:
      return {
        ...state,
        tableLoad: true,
      }
    case GET_COUPONS_SUCCESS:
      return {
        ...state,
        coupons: action.payload,
        tableLoad: false,
        error: {},
      }

    case DELETE_COUPON_FAIL:
    case UPDATE_COUPON_FAIL:
    case CREATE_COUPON_FAIL:
    case GET_COUPON_DETAILS_FAIL:
    case GET_COUPONS_FAIL:
      return {
        ...state,
        error: action.payload,
        tableLoad: false,
      }

    case GET_COUPON_DETAILS_SUCCESS:
      return {
        ...state,
        couponDetails: action.payload,
        loading: false,
        error: {},
      }

    case CREATE_COUPON_SUCCESS:
      return {
        ...state,
        coupons: [...state.coupons, action.payload],
        loading: false,
        error: {},
      }

    case UPDATE_COUPON_SUCCESS:
      return {
        ...state,
        coupons: [...state.coupons].map(coupon =>
          coupon._id.toString() === action.payload._id.toString()
            ? { coupon, ...action.payload }
            : coupon,
        ),
        loading: false,
        error: {},
      }

    case DELETE_COUPON_SUCCESS:
      return {
        ...state,
        coupons: state.coupons.filter(
          coupon => coupon._id.toString() !== action.payload._id.toString(),
        ),
        loading: false,
        error: {},
      }

    default:
      return state
  }
}

export default Coupon
