import { AvForm } from 'availity-reactstrap-validation'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { Card, CardBody, CardTitle, Col, Container, Row } from 'reactstrap'

import Breadcrumbs from '../../../components/Common/Breadcrumb'

//actions
import { getBannerDetails, updateBanner } from 'store/actions'
import MetaTag from 'components/Common/Meta-tag'
import Form from './Form'

const UpdateBanner = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams()

  const { bannerDetails, loading } = useSelector(state => ({
    bannerDetails: state.Banner.bannerDetails,
    loading: state.Banner.loading,
  }))

  const [bannerImagesPreview, setBannerImagesPreview] = useState([])

  function handleValidSubmit(values) {
    const updateBannerData = {
      tag: values.tag,
      priority: parseInt(values.priority),
      images: bannerImagesPreview,
      type: values.type,
      link: values.link,
    }
    dispatch(updateBanner(updateBannerData, bannerDetails?._id, history))
  }

  useEffect(() => {
    dispatch(getBannerDetails(params?.id))
  }, [dispatch])

  useEffect(() => {
    setBannerImagesPreview(bannerDetails?.images)
  }, [bannerDetails?.images])

  const formOption = {
    bannerImagesPreview,
    setBannerImagesPreview,
  }

  return (
    <>
      <MetaTag title={'Update Banners'} />

      <div className="page-content">
        <Breadcrumbs title="Banners" breadcrumbItem="Update Banner" />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col lg={8} md={8}>
                <Card>
                  <CardBody>
                    <CardTitle className="h4 mb-4">
                      {/* Fill this form */}
                    </CardTitle>
                    <AvForm
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(v)
                      }}
                    >
                      <Form
                        formOption={formOption}
                        updateData={bannerDetails}
                      />

                      <Row>
                        <Col>
                          <div className="text-end">
                            <button
                              type="submit"
                              className="btn btn-success save-customer"
                            >
                              {loading && (
                                <>
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                </>
                              )}
                              Update
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default UpdateBanner
