import React, { useEffect, useState } from 'react'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import { Row, Col, Card, CardBody, Badge } from 'reactstrap'
import { Button, Spinner } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'

//  related plugins
import BootstrapTable from 'react-bootstrap-table-next'
import { map, range } from 'lodash'

import ToolkitProvider from 'react-bootstrap-table2-toolkit'
import MyPagination from '../../components/Common/MyPagination'

//actions
import { LanguageSwitch } from 'hooks/LanguageSwitch'

import '../../assets/scss/data-tables.scss'

import DeleteModal from 'components/Common/DeleteModal'
import { deleteArea_list, getArea_lists, updateArea_list } from 'store/actions'
import AreaModal from './Crud/Modal'

const Areas = () => {
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState('')
  const [limit] = useState('10')
  const [isOpen, setIsOpen] = useState({ status: false, id: '' })
  const [isUpdate, setIsUpdate] = useState(false)

  //pagination
  const [page, setPage] = useState(1)
  const totalPages = Math.ceil(total / limit)
  const pages = range(1, totalPages + 1)

  const { area, loading } = useSelector(state => ({
    area: state.Area.areas,
    loading: state.Area.loading,
  }))

  useEffect(() => {
    dispatch(getArea_lists(page, limit, searchText))
  }, [dispatch, page, searchText])

  const total = area?.length

  const columns = [
    {
      dataField: 'name',
      text: 'Name',
      sort: true,
    },
    {
      dataField: 'pinCode',
      text: 'Pin Codes',
      sort: true,
    },
    {
      dataField: 'action',
      text: 'Actions',
    },
  ]

  const handleValidSubmit = (e, v, item) => {
    const updateData = {
      name: item?.name,
      pinCodes: [...item?.pinCodes, v.pinCode],
    }
    dispatch(updateArea_list(updateData, item?._id))
  }

  const handleDeletePinCode = (pin, item) => {
    const newPin = item?.pinCodes?.filter(i => i !== pin)

    const updateData = {
      name: item?.name,
      pinCodes: newPin,
    }
    dispatch(updateArea_list(updateData, item?._id))
  }

  const handleValidSubmitName = (e, v, item) => {
    const updateData = {
      name: v?.name,
      pinCodes: item?.pinCodes,
    }

    dispatch(updateArea_list(updateData, item?._id))
  }

  const areaData = map(area, (item, index) => ({
    ...item,
    name: (
      <>
        {isUpdate === item?._id ? (
          <AvForm
            onValidSubmit={(e, v) => {
              handleValidSubmitName(e, v, item)
            }}
          >
            <div className="d-flex">
              <AvField
                label="Area"
                name="name"
                type="text"
                placeholder="Enter Area Name"
                value={item?.name}
              />
            </div>
          </AvForm>
        ) : (
          item?.name
        )}
      </>
    ),
    pinCode: (
      <>
        {isUpdate === item?._id ? (
          <>
            <AvForm
              onValidSubmit={(e, v) => {
                handleValidSubmit(e, v, item)
              }}
            >
              <div className="d-flex">
                <AvField
                  name="pinCode"
                  type="text"
                  placeholder="Enter Pincode"
                />
              </div>
            </AvForm>
          </>
        ) : (
          ''
        )}
        <div className="areaBadge">
          {map(item?.pinCodes, (pin, key) => (
            <Badge
              key={key}
              className={
                'font-size-12 me-2 p-2 badge-soft-info mt-2 d-flex align-items-center justify-content-center '
              }
              style={{
                width: 'fit-content',
              }}
              pill
            >
              {pin}
              <i
                className="bx bx-x mx-2 bx-tada-hover"
                style={{
                  fontSize: '1.2rem',
                  cursor: 'pointer',
                  color: '#ff0000ab',
                }}
                onClick={() => handleDeletePinCode(pin, item)}
              ></i>
            </Badge>
          ))}
        </div>
      </>
    ),
    action: (
      <div>
        {isUpdate === item?._id ? (
          <Button
            id={`view${index}`}
            type="button"
            color="white"
            className="ms-1 btn"
            onClick={() => setIsUpdate()}
          >
            <i
              className="bx bx-x me-2"
              style={{ color: 'green', fontSize: '22px' }}
            />
          </Button>
        ) : (
          <Button
            id={`view${index}`}
            type="button"
            color="white"
            className="ms-1 btn"
            onClick={() => setIsUpdate(item._id)}
          >
            <i
              className="bx bx-pen me-2"
              style={{ color: 'green', fontSize: '22px' }}
            />
          </Button>
        )}
        <Button
          id={`Delete${index}`}
          type="button"
          color="white"
          className="ms-1 btn "
          onClick={() => {
            handleDelete(item._id)
          }}
        >
          <i
            className="bx bx-trash me-2"
            style={{ color: 'red', fontSize: '20px' }}
          />
        </Button>
      </div>
    ),
  }))

  const handleDeleteEvent = () => {
    setIsOpen({ ...isOpen, status: false })
    dispatch(deleteArea_list(isOpen?.id))
  }

  const handleDelete = id => {
    setIsOpen({ status: true, id: id })
  }

  const isMult = LanguageSwitch()?.isMult

  const [openModal, setOpenModal] = useState(false)

  return (
    <React.Fragment>
      <DeleteModal
        show={isOpen?.status}
        onCloseClick={() => setIsOpen({ ...isOpen, status: false })}
        onDeleteClick={handleDeleteEvent}
      />
      <AreaModal show={openModal} onCloseClick={() => setOpenModal(false)} />
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <div>
                <ToolkitProvider
                  keyField="_id"
                  columns={
                    isMult
                      ? columns
                      : columns?.filter(
                          i => i.dataField !== 'secondaryLang.title',
                        )
                  }
                  data={areaData}
                  search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <Row className="mb-2">
                        <Col sm="4">
                          <div className="search-box ms-2 mb-2 d-inline-block">
                            <div className="position-relative">
                              <AvForm>
                                <AvField
                                  name="searchText"
                                  placeholder="Search"
                                  type="text"
                                  onChange={e => setSearchText(e.target.value)}
                                />
                              </AvForm>
                              <i className="bx bx-search-alt search-icon" />
                            </div>
                          </div>
                        </Col>
                        <Col sm="8">
                          <div className="text-sm-end">
                            <Button
                              type="button"
                              color="success"
                              className="btn-rounded  mb-2 me-2"
                              onClick={() => setOpenModal(true)}
                            >
                              <i className="mdi mdi-plus me-1" />
                              Create Area
                            </Button>
                          </div>
                        </Col>
                      </Row>
                      {loading ? (
                        <Spinner color="secondary" className="d-block m-auto" />
                      ) : (
                        <>
                          {!loading && area && area?.length > 0 ? (
                            <Row>
                              <Col xl="12">
                                <div
                                  className="table-responsive"
                                  id="area_table"
                                >
                                  <BootstrapTable
                                    keyField={'id'}
                                    key={'id'}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    // selectRow={selectRow}
                                    classes={'table align-middle table-nowrap'}
                                    headerWrapperClasses={'thead-light'}
                                    {...toolkitProps.baseProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          ) : (
                            <p className="my-4 mx-3 ">No Areas!</p>
                          )}
                          <MyPagination
                            totalPages={pages}
                            page={page}
                            setPage={setPage}
                          />
                        </>
                      )}
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Areas
