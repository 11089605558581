import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAIL,
  GET_NOTIFICATION_DETAILS,
  GET_NOTIFICATION_DETAILS_SUCCESS,
  GET_NOTIFICATION_DETAILS_FAIL,
  CREATE_NOTIFICATION,
  CREATE_NOTIFICATION_SUCCESS,
  CREATE_NOTIFICATION_FAIL,
  UPDATE_NOTIFICATION,
  UPDATE_NOTIFICATION_SUCCESS,
  UPDATE_NOTIFICATION_FAIL,
  DELETE_NOTIFICATION,
  DELETE_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION_FAIL,
} from './actionTypes'

const INIT_STATE = {
  notifications: {
    notifications: [],
    total: '',
    page: '',
  },
  notificationDetails: {},
  error: {},
  loading: false,
}

const Notification = (state = INIT_STATE, action) => {
  switch (action.type) {
    // Notification - This line cannot be edited or removed
    case GET_NOTIFICATIONS:
    case GET_NOTIFICATION_DETAILS:
    case CREATE_NOTIFICATION:
    case UPDATE_NOTIFICATION:
    case DELETE_NOTIFICATION:
      return {
        ...state,
        loading: true,
      }

    case DELETE_NOTIFICATION_FAIL:
    case GET_NOTIFICATIONS_FAIL:
    case GET_NOTIFICATION_DETAILS_FAIL:
    case UPDATE_NOTIFICATION_FAIL:
    case CREATE_NOTIFICATION_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: action.payload,
        error: {},
        loading: false,
      }

    case GET_NOTIFICATION_DETAILS_SUCCESS:
      return {
        ...state,
        notificationDetails: action.payload,
        error: {},
        loading: false,
      }

    case CREATE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          notifications: [action.payload, ...state.notifications.notifications],
          total: state.notifications.total + 1,
        },

        notificationDetails: action.payload,
        error: {},
        loading: false,
      }

    case UPDATE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          notifications: state.notifications.notifications.map(notification =>
            notification._id === action.payload._id
              ? { ...notification, ...action.payload }
              : notification,
          ),
        },
        notificationDetails: action.payload,
        loading: false,
        error: {},
      }

    case DELETE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          notifications: state.notifications.notifications.filter(
            notification => notification._id !== action.payload._id,
          ),
        },
        error: {},
        loading: false,
      }
    default:
      return state
  }
}

export default Notification
