import React from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CardBody } from 'reactstrap'
import { Col, Row } from 'reactstrap'
import ToolkitProvider from 'react-bootstrap-table2-toolkit'
import BootstrapTable from 'react-bootstrap-table-next'
import PropTypes from 'prop-types'

var xlsx = require('xlsx')

// Form Editor
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import 'assets/css/style.css'

//actions

import { map } from 'lodash'
import { updateBulkRequest } from 'store/actions'

function BulkRequestsUpload({ onCloseClick }) {
  const dispatch = useDispatch()

  const { updateBulkRequestLoading } = useSelector(state => ({
    updateBulkRequestLoading: state.SupplyChain.loading,
  }))

  const [uploadedExcelDataAsJson, setUploadedExcelDataAsJson] = useState()

  const readUploadFile = e => {
    e.preventDefault()
    if (e.target.files) {
      const reader = new FileReader()
      reader.onload = e => {
        const data = e.target.result
        const workbook = xlsx.read(data, { type: 'array' })
        const sheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[sheetName]
        const json = xlsx.utils.sheet_to_json(worksheet)
        setUploadedExcelDataAsJson(json)
      }
      reader.readAsArrayBuffer(e.target.files[0])
    }
  }

  const columns = [
    {
      dataField: '_id',
      filterText: '_id',
      text: 'ID',
      sort: true,
    },
    {
      dataField: 'Request ID',
      filterText: 'Request ID',
      text: 'Request ID',
      sort: true,
    },
    {
      dataField: 'Name',
      filterText: 'Name',
      text: 'Name',
      sort: true,
    },
    {
      dataField: 'Ref. ID',
      filterText: 'Ref. ID',
      text: 'Ref. ID',
      sort: true,
    },
    {
      dataField: 'Phone',
      filterText: 'Phone',
      text: 'Phone',
      sort: true,
    },
    {
      dataField: 'Amount',
      filterText: 'Amount',
      text: 'Amount',
      sort: true,
    },
    {
      dataField: 'Account Number',
      filterText: 'Account Number',
      text: 'Account Number',
      sort: true,
    },
    {
      dataField: 'IFSC',
      filterText: 'IFSC',
      text: 'IFSC',
      sort: true,
    },
    {
      dataField: 'status',
      filterText: 'status',
      text: 'status',
      sort: true,
    },
  ]
  console.log(uploadedExcelDataAsJson)

  const allExcelData = map(uploadedExcelDataAsJson, (item, index) => ({
    ...item,
    key: index,
  }))

  const handleUploadExcelData = () => {
    dispatch(
      updateBulkRequest(
        { withdrawalRequests: uploadedExcelDataAsJson },
        onCloseClick,
      ),
    )
  }

  return (
    <CardBody className="py-3">
      <div>
        <ToolkitProvider
          keyField="key"
          columns={columns}
          data={allExcelData}
          search
        >
          {toolkitProps => (
            <React.Fragment>
              <Row className="mb-2">
                <Col className="d-flex justify-content-space-between">
                  <div className="search-box mb-2 d-inline-block">
                    <div className="position-relative">
                      <div className="mb-3 ">
                        <form>
                          <label className="" htmlFor="upload">
                            Upload Excel File:
                          </label>
                          <br />
                          <input
                            type="file"
                            name="upload"
                            id="upload"
                            onChange={readUploadFile}
                          />
                        </form>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              {allExcelData?.length >= 1 ? (
                <Row>
                  <p>Total Request Data :&nbsp;{allExcelData?.length} </p>
                  <Col xl="12">
                    <div className="table-responsive">
                      <BootstrapTable
                        keyField={'id'}
                        key={'id'}
                        responsive
                        bordered={false}
                        striped={false}
                        classes={'table align-middle table-nowrap'}
                        headerWrapperClasses={'thead-light'}
                        {...toolkitProps.baseProps}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="d-flex align-items-center justify-content-end">
                      <button
                        className="mt-5 w-25 btn btn-success bg-success save-customer"
                        style={{ maxWidth: '150px' }}
                        onClick={handleUploadExcelData}
                      >
                        {updateBulkRequestLoading && (
                          <>
                            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                          </>
                        )}
                        Upload Data
                      </button>
                    </div>
                  </Col>
                </Row>
              ) : (
                <></>
              )}
            </React.Fragment>
          )}
        </ToolkitProvider>
      </div>
    </CardBody>
  )
}

export default BulkRequestsUpload

BulkRequestsUpload.propTypes = {
  onCloseClick: PropTypes.func,
}
