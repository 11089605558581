/* blog */
export const GET_BLOGS = 'GET_BLOGS'
export const GET_BLOGS_SUCCESS = 'GET_BLOGS_SUCCESS'
export const GET_BLOGS_FAIL = 'GET_BLOGS_FAIL'

export const GET_BLOG_DETAILS = 'GET_BLOG_DETAILS'
export const GET_BLOG_DETAILS_SUCCESS = 'GET_BLOG_DETAILS_SUCCESS'
export const GET_BLOG_DETAILS_FAIL = 'GET_BLOG_DETAILS_FAIL'

export const CREATE_BLOG = 'CREATE_BLOG'
export const CREATE_BLOG_SUCCESS = 'CREATE_BLOG_SUCCESS'
export const CREATE_BLOG_FAIL = 'CREATE_BLOG_FAIL'

export const UPDATE_BLOG = 'UPDATE_BLOG'
export const UPDATE_BLOG_SUCCESS = 'UPDATE_BLOG_SUCCESS'
export const UPDATE_BLOG_FAIL = 'UPDATE_BLOG_FAIL'

export const DELETE_BLOG = 'DELETE_BLOG'
export const DELETE_BLOG_SUCCESS = 'DELETE_BLOG_SUCCESS'
export const DELETE_BLOG_FAIL = 'DELETE_BLOG_FAIL'
