import {
  GET_ALL_CLASSIFICATIONS,
  GET_ALL_CLASSIFICATIONS_FAIL,
  GET_ALL_CLASSIFICATIONS_SUCCESS,
} from './actionTypes'

//all classifications
export const getAllClassifications = (page, sort, limit, searchText) => ({
  type: GET_ALL_CLASSIFICATIONS,
  payload: { page, sort, limit, searchText },
})

export const getAllClassificationsSuccess = classifications => ({
  type: GET_ALL_CLASSIFICATIONS_SUCCESS,
  payload: classifications,
})

export const getAllClassificationsFail = error => ({
  type: GET_ALL_CLASSIFICATIONS_FAIL,
  payload: error,
})
