import React, { useEffect, useState } from 'react'
import { Col, FormGroup, Row } from 'reactstrap'
import Select from 'react-select'
import { debounce } from 'lodash'
import PropTypes from 'prop-types'
import { AvField } from 'availity-reactstrap-validation'
import { useDispatch, useSelector } from 'react-redux'
import { getAllProducts } from 'store/actions'

function Form({ productSelect, setProductSelect, updateData }) {
  const dispatch = useDispatch()

  const { products, proLoading } = useSelector(state => ({
    products: state.Products.products,
    proLoading: state.Products.loading,
  }))
  //product dropdown
  const [productSearch, setProductSearch] = useState()
  const [distributionBonus, setDistributionBonus] = useState()
  const [levelLimit, setLevelLimit] = useState()

  useEffect(() => {
    dispatch(getAllProducts(1, 'all', '', productSelect?.searchText))
  }, [dispatch, productSelect?.searchText])

  const debounceProductSearch = debounce(value => setProductSearch(value), 600)

  const handleProductEnters = textEntered => {
    debounceProductSearch(textEntered)
  }
  const handleDistributionBonus = () => {
    const finalValue = distributionBonus / levelLimit
    const roundedValue = parseFloat(finalValue)
    // const roundedValue = parseFloat(finalValue.toFixed(3))
    return roundedValue
  }

  function handlerProductFinalValue(event) {
    const product = {
      ...productSelect,
      title: event.value !== 'loading' && event.label,
      id: event.value,
      image: event.image,
    }
    setProductSelect(product)
  }

  const productOptions = (products?.length >= 1 ? products : [])?.map(
    (result, index) => ({
      key: index,
      label: result?.primaryLang?.name,
      value: result?._id,
      image: result?.images[0]?.url,
    }),
  )

  useEffect(() => {
    setDistributionBonus(updateData?.distributionBonus)
    setLevelLimit(updateData?.levelLimit)
  }, [updateData])

  useEffect(() => {
    dispatch(getAllProducts(1, 'all', '', productSearch))
  }, [dispatch, productSearch])

  //end of product dropdown

  return (
    <>
      <Row>
        <Col md="6">
          <p className="p text-muted mb-2">Product</p>
          <FormGroup className="mb-3">
            <div className="ajax-selectmt-lg-0 select2-container">
              <Select
                onInputChange={handleProductEnters}
                value={productSelect?.title}
                placeholder={productSelect?.title}
                onChange={handlerProductFinalValue}
                options={productOptions}
                classNamePrefix="select2-selection"
                isLoading={proLoading}
              />
            </div>
          </FormGroup>
        </Col>
        <Col md="6">
          <AvField
            name="chainId"
            label="Chain Id"
            type="text"
            value={updateData?.chainId || ''}
          />
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <AvField
            name="joiningBonus"
            label="Lead Bonus"
            className="mb-3"
            type="number"
            value={updateData?.joiningBonus || ''}
          />
        </Col>
        <Col md="4">
          <AvField
            name="totalDistributionAmount"
            label="Total Distribution Amount"
            className="mb-3"
            type="number"
            value={updateData?.totalDistributionAmount || ''}
            onChange={e => setDistributionBonus(e.target.value)}
          />
        </Col>
        <Col md="4">
          <AvField
            name="levelLimit"
            label="Level Limit"
            className="mb-3"
            type="number"
            value={updateData?.levelLimit || ''}
            onChange={e => setLevelLimit(e.target.value)}
          />
        </Col>
        <Col md="4">
          <AvField
            name="distributionBonus"
            label="Growth Reward"
            className="mb-3"
            type="number"
            readOnly
            validate={{
              required: { value: true },
            }}
            value={handleDistributionBonus()}
          />
        </Col>
      </Row>
    </>
  )
}

export default Form

Form.propTypes = {
  productSelect: PropTypes.any,
  setProductSelect: PropTypes.func,
  updateData: PropTypes.any,
}
