import { AvForm } from 'availity-reactstrap-validation'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Card, CardBody, CardTitle, Col, Container, Row } from 'reactstrap'

//actions
import MetaTag from 'components/Common/Meta-tag'
import { createPromoters } from 'store/actions'
import PromoterForm from './Form'
import BreadcrumbPremium from 'components/Common/Breadcrumb2'

const CreatePromoter = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { loading } = useSelector(state => ({
    loading: state.Users.loading,
  }))
  const [isImaUser, setIsImaUser] = useState(false)

  function handleValidSubmit(values) {
    const finalData = {
      ...values,
      promoterPercentage: parseInt(values?.promoterPercentage),
      // accountDetails: {
      //   benificiaryName: values?.benificiaryName,
      //   bankName: values?.bankName,
      //   branchName: values?.branchName,
      //   accountNumber: values?.accountNumber,
      //   ifsc: values?.ifsc,
      //   panNumber: values?.panNumber,
      // },
      isImaUser: isImaUser,
    }
    dispatch(createPromoters(finalData, history))
  }

  return (
    <>
      <MetaTag title={'Create Promoter'} />

      <div className="page-content">
        <BreadcrumbPremium
          root={['/promoter-dashboard', '/promoters', 'Create Promoter']}
        />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col lg={8} md={8}>
                <Card>
                  <CardBody>
                    <CardTitle className="h4 mb-4"></CardTitle>
                    <AvForm
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(v)
                      }}
                    >
                      <PromoterForm
                        isImaUser={isImaUser}
                        setIsImaUser={setIsImaUser}
                      />
                      <Row>
                        <Col>
                          <div className="m-3 text-end">
                            <button
                              type="submit"
                              className="btn btn-success save-customer"
                            >
                              {loading && (
                                <>
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                </>
                              )}
                              Create
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default CreatePromoter
