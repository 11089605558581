import {
  GET_COURSE_REGISTRATIONS,
  GET_COURSE_REGISTRATIONS_SUCCESS,
  GET_COURSE_REGISTRATIONS_FAIL,
  GET_COURSE_REGISTRATION_DETAILS,
  GET_COURSE_REGISTRATION_DETAILS_SUCCESS,
  GET_COURSE_REGISTRATION_DETAILS_FAIL,
  UPDATE_COURSE_REGISTRATION,
  UPDATE_COURSE_REGISTRATION_SUCCESS,
  UPDATE_COURSE_REGISTRATION_FAIL,
  GET_COURSE_PROVIDER_COURSES,
  GET_COURSE_PROVIDER_COURSES_SUCCESS,
  GET_COURSE_PROVIDER_COURSES_FAIL,
} from './actionTypes'

// CourseRegistration - This line cannot be edited or removed
export const getCourseRegistrations = (
  page,
  sort,
  limit,
  searchText,
  sortByDate,
  courseProvider,
  order,
  product,
) => ({
  type: GET_COURSE_REGISTRATIONS,
  payload: {
    page,
    sort,
    limit,
    searchText,
    sortByDate,
    courseProvider,
    order,
    product,
  },
})

export const getCourseRegistrationsSuccess = courseRegistrations => ({
  type: GET_COURSE_REGISTRATIONS_SUCCESS,
  payload: courseRegistrations,
})

export const getCourseRegistrationsFail = error => ({
  type: GET_COURSE_REGISTRATIONS_FAIL,
  payload: error,
})

export const getCourseRegistrationDetails = courseRegistrationId => ({
  type: GET_COURSE_REGISTRATION_DETAILS,
  payload: { courseRegistrationId },
})

export const getCourseRegistrationDetailsSuccess =
  courseRegistrationDetails => ({
    type: GET_COURSE_REGISTRATION_DETAILS_SUCCESS,
    payload: courseRegistrationDetails,
  })

export const getCourseRegistrationDetailsFail = error => ({
  type: GET_COURSE_REGISTRATION_DETAILS_FAIL,
  payload: error,
})

export const updateCourseRegistration = (
  courseRegistration,
  courseRegistrationId,
) => ({
  type: UPDATE_COURSE_REGISTRATION,
  payload: { courseRegistration, courseRegistrationId },
})

export const updateCourseRegistrationSuccess = courseRegistration => ({
  type: UPDATE_COURSE_REGISTRATION_SUCCESS,
  payload: courseRegistration,
})

export const updateCourseRegistrationFail = error => ({
  type: UPDATE_COURSE_REGISTRATION_FAIL,
  payload: error,
})

export const getCourseProviderCourses = (page, sort, limit, searchText) => ({
  type: GET_COURSE_PROVIDER_COURSES,
  payload: { page, sort, limit, searchText },
})

export const getCourseProviderCoursesSuccess = courseProviderCourses => ({
  type: GET_COURSE_PROVIDER_COURSES_SUCCESS,
  payload: courseProviderCourses,
})

export const getCourseProviderCoursesFail = error => ({
  type: GET_COURSE_PROVIDER_COURSES_FAIL,
  payload: error,
})
