import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'
import { Alert, CardSubtitle, Modal, ModalBody } from 'reactstrap'
import BulkRequestsUpload from './BulkRequestsUpload'

const BulkRequestModal = ({ show, onCloseClick }) => {
  // const dispatch = useDispatch()

  const { errorMsg } = useSelector(state => ({
    loading: state.SupplyChain.loading,
  }))

  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      centered={true}
      style={deleteModalStyle}
    >
      <ModalBody style={deleteModalBodyStyle} className="p-4">
        <CardSubtitle className="mb-2">
          Upload Withdrawal Requests{' '}
        </CardSubtitle>
        {errorMsg?.length >= 1 && <Alert color="danger">{errorMsg}</Alert>}

        <BulkRequestsUpload onCloseClick={onCloseClick} />
      </ModalBody>
    </Modal>
  )
}

BulkRequestModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
  updateData: PropTypes.any,
}

export default BulkRequestModal

const deleteModalBodyStyle = {
  minHeight: 100,
  width: 700,
}

const deleteModalStyle = {
  width: 700,
  maxWidth: 700,
}
