import { DELETE_CATEGORY_SUCCESS } from 'store/category/actionTypes'
import {
  GET_ALL_CLASSIFICATIONS,
  GET_ALL_CLASSIFICATIONS_FAIL,
  GET_ALL_CLASSIFICATIONS_SUCCESS,
} from './actionTypes'

const INIT_STATE = {
  classifications: [],
  error: {},
  loading: false,
}

const Category = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_CLASSIFICATIONS:
      return {
        ...state,
        loading: true,
      }
    case GET_ALL_CLASSIFICATIONS_SUCCESS:
      return {
        ...state,
        classifications: action.payload,
        total: action.payload.total,
        error: {},
        loading: false,
      }

    case GET_ALL_CLASSIFICATIONS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    //category
    case DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        classifications: state.classifications.filter(
          category => category._id.toString() !== action.payload._id.toString(),
        ),
        error: {},
        loading: false,
      }

    default:
      return state
  }
}

export default Category
