import { useSelector } from 'react-redux'

export const useSiteVersion = () => {
  const { settings } = useSelector(state => ({
    settings: state.Settings.settings,
  }))
  const version = {
    isBasic: false,
    isPremium: true,
    isAdvanced: false,
    currentVersion: 'premium',
  }

  if (settings?.setup?.version === 'basic') {
    return {
      isBasic: true,
      currentVersion: 'basic',
    }
  } else if (settings?.setup?.version === 'premium') {
    return {
      isPremium: true,
      currentVersion: 'premium',
    }
  } else if (settings?.setup?.version === 'advanced') {
    return {
      // isAdvanced: true,
      // currentVersion: "advanced",
      isPremium: true,
      currentVersion: 'premium',
    }
  }
  return version
}
