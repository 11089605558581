/* ORDERS */
export const GET_ORDERS = 'GET_ORDERS'
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS'
export const GET_ORDERS_FAIL = 'GET_ORDERS_FAIL'

/* ORDER_DETAILS */
export const GET_ORDER_DETAILS = 'GET_ORDER_DETAILS'
export const GET_ORDER_DETAILS_SUCCESS = 'GET_ORDER_DETAILS_SUCCESS'
export const GET_ORDER_DETAILS_FAIL = 'GET_ORDER_DETAILS_FAIL'

/* USER_ORDER_DETAILS */
export const GET_USER_ORDER_DETAILS = 'GET_USER_ORDER_DETAILS'
export const GET_USER_ORDER_DETAILS_SUCCESS = 'GET_USER_ORDER_DETAILS_SUCCESS'
export const GET_USER_ORDER_DETAILS_FAIL = 'GET_USER_ORDER_DETAILS_FAIL'

/* Edit ORDER ITEM*/
export const UPDATE_ORDER_ITEM = 'UPDATE_ORDER_ITEM'
export const UPDATE_ORDER_ITEM_SUCCESS = 'UPDATE_ORDER_ITEM_SUCCESS'
export const UPDATE_ORDER_ITEM_FAIL = 'UPDATE_ORDER_ITEM_FAIL'

export const UPDATE_ORDER = 'UPDATE_ORDER'
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS'
export const UPDATE_ORDER_FAIL = 'UPDATE_ORDER_FAIL'

//awb number update
export const UPDATE_ORDER_AWB_NUMBER = 'UPDATE_ORDER_AWB_NUMBER'
export const UPDATE_ORDER_AWB_NUMBER_SUCCESS = 'UPDATE_ORDER_AWB_NUMBER_SUCCESS'
export const UPDATE_ORDER_AWB_NUMBER_FAIL = 'UPDATE_ORDER_AWB_NUMBER_FAIL'

//create shiprocket order
export const CREATE_SHIPROCKET_ORDER = 'CREATE_SHIPROCKET_ORDER'
export const CREATE_SHIPROCKET_ORDER_SUCCESS = 'CREATE_SHIPROCKET_ORDER_SUCCESS'
export const CREATE_SHIPROCKET_ORDER_FAIL = 'CREATE_SHIPROCKET_ORDER_FAIL'

/* STARRED_ORDERS */
export const GET_STARRED_ORDERS = 'GET_STARRED_ORDERS'
export const GET_STARRED_ORDERS_SUCCESS = 'GET_STARRED_ORDERS_SUCCESS'
export const GET_STARRED_ORDERS_FAIL = 'GET_STARRED_ORDERS_FAIL'

/* CANCELLED_ORDERS */
export const GET_CANCELLED_ORDERS = 'GET_CANCELLED_ORDERS'
export const GET_CANCELLED_ORDERS_SUCCESS = 'GET_CANCELLED_ORDERS_SUCCESS'
export const GET_CANCELLED_ORDERS_FAIL = 'GET_CANCELLED_ORDERS_FAIL'

/* RETURNED_ORDERS */
export const GET_RETURNED_ORDERS = 'GET_RETURNED_ORDERS'
export const GET_RETURNED_ORDERS_SUCCESS = 'GET_RETURNED_ORDERS_SUCCESS'
export const GET_RETURNED_ORDERS_FAIL = 'GET_RETURNED_ORDERS_FAIL'

// update payment
export const UPDATE_PAYMENT = 'UPDATE_PAYMENT'
export const UPDATE_PAYMENT_SUCCESS = 'UPDATE_PAYMENT_SUCCESS'
export const UPDATE_PAYMENT_FAIL = 'UPDATE_PAYMENT_FAIL'

export const CREATE_USER_ORDER = 'CREATE_USER_ORDER'
export const CREATE_USER_ORDER_SUCCESS = 'CREATE_USER_ORDER_SUCCESS'
export const CREATE_USER_ORDER_FAIL = 'CREATE_USER_ORDER_FAIL'

export const CLEAR_ORDER = 'CLEAR_ORDER'

export const WHATSAPP_ORDER_ONLINE_MSG = 'WHATSAPP_ORDER_ONLINE_MSG'
export const WHATSAPP_ORDER_ONLINE_MSG_SUCCESS =
  'WHATSAPP_ORDER_ONLINE_MSG_SUCCESS'
export const WHATSAPP_ORDER_ONLINE_MSG_FAIL = 'WHATSAPP_ORDER_ONLINE_MSG_FAIL'

export const GET_TOTAL_ORDER_COUNTS = 'GET_TOTAL_ORDER_COUNTS'
export const GET_TOTAL_ORDER_COUNTS_SUCCESS = 'GET_TOTAL_ORDER_COUNTS_SUCCESS'
export const GET_TOTAL_ORDER_COUNTS_FAIL = 'GET_TOTAL_ORDER_COUNTS_FAIL'

export const GET_SHIPROCKET_LABEL = 'GET_SHIPROCKET_LABEL'
export const GET_SHIPROCKET_LABEL_SUCCESS = 'GET_SHIPROCKET_LABEL_SUCCESS'
export const GET_SHIPROCKET_LABEL_FAIL = 'GET_SHIPROCKET_LABEL_FAIL'

export const GET_SHIPROCKET_INVOICE = 'GET_SHIPROCKET_INVOICE'
export const GET_SHIPROCKET_INVOICE_SUCCESS = 'GET_SHIPROCKET_INVOICE_SUCCESS'
export const GET_SHIPROCKET_INVOICE_FAIL = 'GET_SHIPROCKET_INVOICE_FAIL'

export const ORDER_AUTO_STATUS_UPDATE = 'ORDER_AUTO_STATUS_UPDATE'
export const ORDER_AUTO_STATUS_UPDATE_SUCCESS =
  'ORDER_AUTO_STATUS_UPDATE_SUCCESS'
export const ORDER_AUTO_STATUS_UPDATE_FAIL = 'ORDER_AUTO_STATUS_UPDATE_FAIL'

export const CLEAR_AUTO_STATUS_UPDATE = 'CLEAR_AUTO_STATUS_UPDATE'

/* order edit */
export const CREATE_ORDER_EDIT = 'CREATE_ORDER_EDIT'
export const CREATE_ORDER_EDIT_SUCCESS = 'CREATE_ORDER_EDIT_SUCCESS'
export const CREATE_ORDER_EDIT_FAIL = 'CREATE_ORDER_EDIT_FAIL'
