import React, { Fragment, useEffect, useState } from 'react'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import { Row, Col, Card, CardBody, Button, Badge, Spinner } from 'reactstrap'
import { UncontrolledTooltip } from 'reactstrap'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// data-table related plugins
import BootstrapTable from 'react-bootstrap-table-next'
import { debounce, map, range } from 'lodash'
import Select from 'react-select'

import ToolkitProvider from 'react-bootstrap-table2-toolkit'
import MyPagination from '../../components/Common/MyPagination'

//actions
import {
  getAllProducts,
  createDuplicateProduct,
  deleteProduct,
  hardDeleteProduct,
  restoreProduct,
  changeProductStatus,
  undoRefrash,
  getProductSortCount,
  getCourseProviders,
} from 'store/actions'

import '../../assets/scss/data-tables.scss'

import DeleteModal from 'components/Common/DeleteModal'
import { defaultImg } from 'assets/images'
import useCloudinary from 'hooks/useCloudinaryImage'
import { useWindowSize } from 'hooks/useWindowSize'

const Products = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const width = useWindowSize()

  const {
    count,
    productData,
    total,
    needRefresh,
    loading,
    courseProviders,
    providerLoading,
  } = useSelector(state => ({
    productData: state.Products,
    total: state.Products.total,
    needRefresh: state.Products.needRefrash,
    count: state?.Products?.productSortCount,
    loading: state.Products.loading,
    courseProviders: state.CourseProvider.courseProviders,
    providerLoading: state.CourseProvider.loading,
  }))

  const isMobile = width?.width <= 425

  const products = productData?.products
  const [totalItemLimit, setTotalItemLimit] = useState(10)
  const [page, setPage] = useState(1)
  // const [searchText, setSearchText] = useState("")
  // const [sort, setSort] = useState("all")

  // const [storeSearchText, setStoreSearchText] = useState("")
  // const [selectedStore, setSelectedStore] = useState("All Store")
  // const [finalStoreId, setFinalStoreId] = useState()

  const [searchText, setSearchText] = useState('')
  const [sort, setSort] = useState('all')

  const [courseProviderSelect, setCourseProviderSelect] = useState({
    id: '',
    name: 'Select course provider',
  })

  const [providerSearch, setProviderSearch] = useState('')

  const debounceProviderSearch = debounce(
    value => setProviderSearch(value),
    600,
  )
  const handleProviderEnters = textEntered => {
    debounceProviderSearch(textEntered)
  }

  function handlerProviderFinalValue(event) {
    const provider = {
      ...courseProviderSelect,
      name: event.label,
      id: event.value,
    }
    setCourseProviderSelect(provider)
  }

  const providerOptions = [
    {
      label: 'All Providers',
      value: '',
    },
    {
      options: courseProviders?.courseProviders?.map((result, index) => ({
        key: index,
        label: result?.name,
        value: result?._id,
      })),
    },
  ]

  useEffect(() => {
    dispatch(getCourseProviders(1, '', 10, providerSearch))
  }, [providerSearch])

  //delete
  const [isOpen, setIsOpen] = useState({ stauts: false, id: '' })
  const handleDeleteEvent = () => {
    setIsOpen({ ...isOpen, stauts: false })
    dispatch(deleteProduct(isOpen?.id, ''))
  }
  const handleDelete = id => {
    setIsOpen({ stauts: true, id: id })
  }

  const handleCopyProduct = id => {
    dispatch(createDuplicateProduct(id, ''))
  }

  const handleHardDelete = id => {
    dispatch(hardDeleteProduct(id, ''))
  }

  const handleRestore = id => {
    dispatch(restoreProduct(id, ''))
  }

  const handleSwitchChange = id => {
    dispatch(changeProductStatus(id, ''))
  }

  useEffect(() => {
    dispatch(
      getAllProducts(
        page,
        sort,
        totalItemLimit,
        searchText,
        '',
        courseProviderSelect?.id,
      ),
    )
    if (needRefresh === true) {
      dispatch(
        getAllProducts(
          page,
          sort,
          totalItemLimit,
          searchText,
          '',
          courseProviderSelect?.id,
        ),
      )
    }
  }, [
    dispatch,
    page,
    sort,
    searchText,
    totalItemLimit,
    '',
    needRefresh,
    courseProviderSelect,
  ])

  useEffect(() => {
    dispatch(getProductSortCount())
  }, [dispatch])

  //pagination
  const totalPages = Math.ceil(total / totalItemLimit)
  const pages = range(1, totalPages + 1)

  const columns = [
    {
      dataField: 'itemCode',
      filterText: 'itemCode',
      text: 'Item Code',
      sort: true,
    },
    {
      dataField: 'nameData',
      filterText: 'name',
      text: 'Name',
      sort: true,
    },
    {
      dataField: 'imageData',
      filterText: 'image',
      text: 'Image',
      sort: true,
    },
    {
      dataField: 'categoryData',
      filterText: 'categories',
      text: 'Categories',
      sort: true,
    },
    {
      dataField: 'price',
      text: 'Price',
      sort: true,
    },
    {
      dataField: 'stock',
      text: 'Stock',
      sort: true,
    },

    {
      dataField: 'activeData',
      filterText: 'status',
      text: 'Status',
      sort: true,
    },
    {
      dataField: 'action',
      text: 'Actions',
    },
  ]

  //all
  const allProductData = map(products, (item, index) => ({
    ...item,
    key: item._id,
    itemCode: <span>{item?.itemCode}</span>,
    stock: (
      <span className={item?.stock <= 0 ? 'text-danger' : ''}>
        {item?.stock
          ? item?.stock
          : item?.varients?.length > 0 && item?.varients[0]?.stock}
      </span>
    ),
    price: (
      <span>
        ₹{' '}
        {item?.offerPrice
          ? item.offerPrice
          : item?.price
          ? item?.price
          : item?.varients?.length > 0 && item?.varients[0]?.offerPrice
          ? item?.varients[0]?.offerPrice
          : item?.varients?.length > 0 && item?.varients[0]?.price}
      </span>
    ),
    nameData: (
      <Row>
        {item?.primaryLang?.name.length > 40
          ? item?.primaryLang?.name.substr(0, 40 - 1) + '...'
          : item?.primaryLang?.name}
      </Row>
    ),

    categoryData: (
      <>
        {item?.categories?.map((category, index) => {
          return (
            <Fragment key={index}>
              <Badge
                className={
                  'mx-1 font-size-12 badge-soft-info'
                  // `${colors[Math.floor(Math.random() * colors.length)]}`
                }
                color="black"
                pill
              >
                {category?.primaryLang?.name}
              </Badge>
            </Fragment>
          )
        })}
      </>
    ),

    imageData: (
      <>
        <img
          src={
            item?.images && item?.images[0]
              ? useCloudinary(item?.images[0]?.url, 'w_900')
              : defaultImg
          }
          width="50px"
        />
      </>
    ),

    colorData: (
      <Row
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
        }}
      >
        <Col
          md="2"
          className={'avatar-title rounded-circle bg-soft'}
          style={{
            backgroundColor: `${item?.color?.value}`,
            width: '30px',
            height: '30px',
            display: item?.color?.value == 'Not Specified' ? 'none' : '',
            boxShadow: '0px 0px 5px #888888',
          }}
        ></Col>
        <Col>{item?.color?.name}</Col>
      </Row>
    ),
    activeData: (
      <>
        <div className="form-check form-switch form-switch-md mb-3">
          <input
            type="checkbox"
            className="form-check-input"
            id="customSwitchsizemd"
            onChange={() => {
              handleSwitchChange(item?._id)
            }}
            checked={item?.isActive}
          />
          <label className="form-check-label" htmlFor="customSwitchsizemd">
            {item?.isActive ? `Active` : `Inactive`}
          </label>
        </div>
      </>
    ),
    action: (
      <div>
        <Link to={`/product/update/${item?._id}`}>
          <Button
            id={`view${index}`}
            type="button"
            color="white"
            className="ms-1 btn"
          >
            <i
              className="bx bx-edit me-2"
              style={{ color: 'blue', fontSize: '22px' }}
            />
          </Button>
        </Link>

        {sort == 'deleted' ? (
          <>
            <Button
              id={`restore${index}`}
              type="button"
              color="white"
              className="ms-1 btn"
              onClick={() => {
                handleRestore(item._id)
              }}
            >
              <i
                className="bx bx-revision me-2"
                style={{ color: 'green', fontSize: '20px' }}
              />
            </Button>
            <Button
              id={`hardDelete${index}`}
              type="button"
              color="white"
              className="ms-1 btn"
              onClick={() => {
                handleHardDelete(item._id)
              }}
            >
              <i
                className="bx bx-error-alt me-2"
                style={{ color: 'red', fontSize: '20px' }}
              />
            </Button>
          </>
        ) : (
          <>
            <Button
              id={`copy${index}`}
              type="button"
              color="white"
              className="ms-1 btn "
              onClick={() => {
                handleCopyProduct(item._id)
              }}
            >
              <i className="bx bx-copy me-2" style={{ fontSize: '20px' }} />
            </Button>
            <Button
              id={`Delete${index}`}
              type="button"
              color="white"
              className="ms-1 btn "
              onClick={() => {
                handleDelete(item._id)
              }}
            >
              <i
                className="bx bx-trash me-2"
                style={{ color: 'red', fontSize: '20px' }}
              />
            </Button>
          </>
        )}

        <UncontrolledTooltip placement="bottom" target={`view${index}`}>
          Edit
        </UncontrolledTooltip>

        {sort == 'deleted' ? (
          <>
            <UncontrolledTooltip placement="bottom" target={`restore${index}`}>
              Restore
            </UncontrolledTooltip>
            <UncontrolledTooltip
              placement="bottom"
              target={`hardDelete${index}`}
            >
              Hard Delete
            </UncontrolledTooltip>
          </>
        ) : (
          <>
            <UncontrolledTooltip placement="bottom" target={`copy${index}`}>
              Duplicate
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="bottom" target={`Delete${index}`}>
              Delete
            </UncontrolledTooltip>
          </>
        )}
      </div>
    ),
  }))

  const listChange = sortValue => {
    setSort(sortValue)
    history?.push({
      pathname: location.pathname,
      search: `p=${page}&s=${sortValue}`,
      state: {
        page: page,
        sort: sortValue,
      },
    })
    // dispatch(getAllProducts(page, sortValue, totalItemLimit, ""))
  }
  useEffect(() => {
    if (location?.state && location?.state?.sort) {
      setSort(location?.state?.sort)
    } else {
      // setSort("all")
    }
  }, [location?.state?.sort])

  useEffect(() => {
    const currentLimit = localStorage.getItem('productLimit')
    if (currentLimit) {
      setTotalItemLimit(currentLimit)
    }
  }, [])

  const handlePageLimit = e => {
    if (e.target.value >= 1) {
      setTotalItemLimit(parseInt(e.target.value))
      localStorage.setItem('productLimit', e.target.value)
    }
  }

  const tableTitles = [
    {
      title: 'All',
      value: 'all',
      count: count?.allProductsCount || 0,
    },

    {
      title: 'Active',
      value: 'active',
      count: count?.activeProductsCount || 0,
    },

    {
      title: 'Inactive',
      value: 'inactive',
      count: count?.inactiveProductsCount || 0,
    },

    {
      title: 'Featured',
      value: 'featured',
      count: count?.coursesCount || 0,
    },

    // {
    //   title: "Discounted",
    //   value: "discounted",
    //   count: count?.discounted || 0,
    // },

    {
      title: 'Deleted',
      value: 'deleted',
      count: count?.deletedProductsCount || 0,
    },

    {
      title: 'Out of Stock',
      value: 'out of stock',
      count: count?.outofstockProductsCount || 0,
    },
    {
      title: 'Courses',
      value: 'course',
      count: count?.coursesCount || 0,
    },
  ]

  // const debounceStoreSearch = debounce(value => setStoreSearchText(value), 600)

  // const handleEnters = textEntered => {
  //   debounceStoreSearch(textEntered)
  // }

  // function handlerProductFinalValue(event) {
  //   setSelectedStore(event.label)
  //   setFinalStoreId(event.id)
  // }

  // const storeOptions = [
  //   {
  //     label: "All Store",
  //     id: "",
  //   },
  //   {
  //     options: stores?.map((result, index) => ({
  //       key: index,
  //       label: result?.storeName,
  //       id: result?._id,
  //     })),
  //   },
  // ]

  const debounceProductSearch = debounce(value => setSearchText(value), 600)

  useEffect(() => {
    if (needRefresh === true) {
      setTimeout(() => {
        dispatch(undoRefrash())
      }, 2000)
    }
  }, [needRefresh])

  return (
    <React.Fragment>
      <DeleteModal
        show={isOpen?.stauts}
        onCloseClick={() => setIsOpen({ ...isOpen, stauts: false })}
        onDeleteClick={handleDeleteEvent}
      />
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <Row>
                <div
                  className={`${
                    !isMobile &&
                    'd-flex align-items-center justify-content-between'
                  } p-1`}
                >
                  <div
                    className={`${
                      isMobile ? 'btn btn-md d-flex flex-wrap' : 'btn-group'
                    } mt-2 mt-xl-0`}
                    role="group"
                    aria-label="Basic radio toggle button group"
                  >
                    {map(tableTitles, (head, key) => (
                      <Fragment key={key}>
                        <input
                          type="radio"
                          className="btn-check"
                          name="btnradio"
                          id={head.value + key}
                          autoComplete="off"
                          checked={sort === head.value}
                          onChange={() => listChange(head?.value || '')}
                        />
                        <label
                          className={`${
                            isMobile
                              ? 'justify-content-center me-2 btn btn-success d-flex align-items-center'
                              : 'btn btn-success d-flex align-items-center w-100'
                          } `}
                          htmlFor={head.value + key}
                          style={{
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {head?.title}

                          <Badge
                            className={`font-size-12 ms-2 ${
                              sort === head.value
                                ? 'badge-soft-warning'
                                : 'badge-soft-light'
                            }`}
                            pill
                          >
                            {head?.count}
                          </Badge>
                        </label>
                      </Fragment>
                    ))}
                  </div>

                  <div
                    className={`${
                      isMobile && 'd-flex justify-content-end'
                    } mt-1`}
                  >
                    {' '}
                    {/* Change the alignment class to 'text-sm-start' */}
                    <Link to="product/create/new">
                      <Button
                        type="button"
                        color="success"
                        className="btn-rounded  mb-2 me-2"
                      >
                        <i className="mdi mdi-plus me-1" />
                        Create Product
                      </Button>
                    </Link>
                  </div>
                </div>
              </Row>

              <div>
                <ToolkitProvider
                  keyField="_id"
                  columns={columns}
                  data={allProductData}
                  search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <Row className="mb-2">
                        <Col xl={4} lg={6} md={6} sm={6} className="d-flex ">
                          <div className="search-box ms-2 mb-2 d-inline-block">
                            <div className="position-relative">
                              <AvForm>
                                <AvField
                                  name="searchText"
                                  placeholder="Search"
                                  type="text"
                                  value={searchText}
                                  onChange={e =>
                                    debounceProductSearch(e.target.value)
                                  }
                                />
                              </AvForm>
                              <i className="bx bx-search-alt search-icon" />
                            </div>
                          </div>
                          <AvForm className="form-horizontal mx-3 d-flex align-items-center">
                            <div className="mb-3 mx-1">
                              <span className="">
                                Show
                                <i className="fas fa-sort-numeric-down-alt mx-1"></i>
                              </span>
                            </div>
                            <div className="mb-3" style={{ width: '50px' }}>
                              <AvField
                                name="pageLimit"
                                className="form-control"
                                value={totalItemLimit}
                                onChange={e => handlePageLimit(e)}
                                type="text"
                                required
                              />
                            </div>
                          </AvForm>
                        </Col>
                        {sort === 'courses' && (
                          <Col xl={4} lg={6} md={6} sm={6}>
                            <Select
                              onInputChange={handleProviderEnters}
                              value={courseProviderSelect?.name}
                              placeholder={courseProviderSelect?.name}
                              onChange={handlerProviderFinalValue}
                              options={providerOptions}
                              classNamePrefix="select2-selection"
                              isLoading={providerLoading}
                            />
                          </Col>
                        )}
                      </Row>
                      {loading ? (
                        <Spinner color="secondary" className="d-block m-auto" />
                      ) : (
                        <>
                          {products.length >= 1 ? (
                            <>
                              <Row>
                                <p>
                                  Total Products :&nbsp;{productData?.total}{' '}
                                </p>

                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={'id'}
                                      key={'id'}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      // defaultSorted={defaultSorted}
                                      // selectRow={selectRow}
                                      classes={
                                        'table align-middle table-nowrap'
                                      }
                                      headerWrapperClasses={'thead-light'}
                                      {...toolkitProps.baseProps}
                                      // onTableChange={handleTableChange}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <MyPagination
                                totalPages={pages}
                                page={page}
                                setPage={setPage}
                              />
                            </>
                          ) : (
                            <p className="my-4 mx-3 ">No {sort} Products !</p>
                          )}
                        </>
                      )}
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Products
