import {
  GET_COURSE_PROVIDERS,
  GET_COURSE_PROVIDERS_SUCCESS,
  GET_COURSE_PROVIDERS_FAIL,
  GET_COURSE_PROVIDER_DETAILS,
  GET_COURSE_PROVIDER_DETAILS_SUCCESS,
  GET_COURSE_PROVIDER_DETAILS_FAIL,
  CREATE_COURSE_PROVIDER,
  CREATE_COURSE_PROVIDER_SUCCESS,
  CREATE_COURSE_PROVIDER_FAIL,
  UPDATE_COURSE_PROVIDER,
  UPDATE_COURSE_PROVIDER_SUCCESS,
  UPDATE_COURSE_PROVIDER_FAIL,
  DELETE_COURSE_PROVIDER_SUCCESS,
  DELETE_COURSE_PROVIDER_FAIL,
  DELETE_COURSE_PROVIDER,
} from './actionTypes'

// CourseProvider - This line cannot be edited or removed
export const getCourseProviders = (page, sort, limit, searchText) => ({
  type: GET_COURSE_PROVIDERS,
  payload: { page, sort, limit, searchText },
})

export const getCourseProvidersSuccess = courseProviders => ({
  type: GET_COURSE_PROVIDERS_SUCCESS,
  payload: courseProviders,
})

export const getCourseProvidersFail = error => ({
  type: GET_COURSE_PROVIDERS_FAIL,
  payload: error,
})

export const getCourseProviderDetails = courseProviderId => ({
  type: GET_COURSE_PROVIDER_DETAILS,
  payload: courseProviderId,
})

export const getCourseProviderDetailsSuccess = courseProviderDetails => ({
  type: GET_COURSE_PROVIDER_DETAILS_SUCCESS,
  payload: courseProviderDetails,
})

export const getCourseProviderDetailsFail = error => ({
  type: GET_COURSE_PROVIDER_DETAILS_FAIL,
  payload: error,
})

export const createCourseProvider = (courseProvider, onCloseClick) => ({
  type: CREATE_COURSE_PROVIDER,
  payload: { courseProvider, onCloseClick },
})

export const createCourseProviderSuccess = courseProvider => ({
  type: CREATE_COURSE_PROVIDER_SUCCESS,
  payload: courseProvider,
})

export const createCourseProviderFail = error => ({
  type: CREATE_COURSE_PROVIDER_FAIL,
  payload: error,
})

export const getCourseProviderDetail = courseProviderId => ({
  type: GET_COURSE_PROVIDER_DETAILS,
  payload: courseProviderId,
})

export const updateCourseProvider = (
  courseProvider,
  courseProviderId,
  history,
) => ({
  type: UPDATE_COURSE_PROVIDER,
  payload: { courseProvider, courseProviderId, history },
})

export const updateCourseProviderSuccess = courseProvider => ({
  type: UPDATE_COURSE_PROVIDER_SUCCESS,
  payload: courseProvider,
})

export const updateCourseProviderFail = error => ({
  type: UPDATE_COURSE_PROVIDER_FAIL,
  payload: error,
})

export const deleteCourseProvider = courseProviderId => ({
  type: DELETE_COURSE_PROVIDER,
  payload: courseProviderId,
})

export const deleteCourseProviderSuccess = courseProvider => ({
  type: DELETE_COURSE_PROVIDER_SUCCESS,
  payload: courseProvider,
})

export const deleteCourseProviderFail = error => ({
  type: DELETE_COURSE_PROVIDER_FAIL,
  payload: error,
})

import {
  GET_COURSE_PROVIDER_PROFILE,
  GET_COURSE_PROVIDER_PROFILE_SUCCESS,
  GET_COURSE_PROVIDER_PROFILE_FAIL,
  UPDATE_COURSE_PROVIDER_PROFILE,
  UPDATE_COURSE_PROVIDER_PROFILE_SUCCESS,
  UPDATE_COURSE_PROVIDER_PROFILE_FAIL,
} from './actionTypes'

// CourseProviderProfile - This line cannot be edited or removed
export const getCourseProviderProfile = () => ({
  type: GET_COURSE_PROVIDER_PROFILE,
})

export const getCourseProviderProfileSuccess = courseProviderProfile => ({
  type: GET_COURSE_PROVIDER_PROFILE_SUCCESS,
  payload: courseProviderProfile,
})

export const getCourseProviderProfileFail = error => ({
  type: GET_COURSE_PROVIDER_PROFILE_FAIL,
  payload: error,
})

export const updateCourseProviderProfile = (
  courseProviderProfile,
  history,
) => ({
  type: UPDATE_COURSE_PROVIDER_PROFILE,
  payload: { courseProviderProfile, history },
})

export const updateCourseProviderProfileSuccess = courseProviderProfile => ({
  type: UPDATE_COURSE_PROVIDER_PROFILE_SUCCESS,
  payload: courseProviderProfile,
})

export const updateCourseProviderProfileFail = error => ({
  type: UPDATE_COURSE_PROVIDER_PROFILE_FAIL,
  payload: error,
})
