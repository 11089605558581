import React, { useEffect } from 'react'
import { Button, UncontrolledTooltip } from 'reactstrap'
import { CSVLink } from 'react-csv'

// Form Editor
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import { map } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from 'components/Common/Spinner'
import { downloadWithdrawalRequest } from 'store/actions'
import PropTypes from 'prop-types'
import { BsFillCloudDownloadFill } from 'react-icons/bs'

function DownloadRequest({ status }) {
  const dispatch = useDispatch()
  const { WithdrawalRequests, loading } = useSelector(state => ({
    WithdrawalRequests: state.SupplyChain.requestForDownload,
    loading: state.SupplyChain.downloadLoad,
  }))

  useEffect(() => {
    dispatch(downloadWithdrawalRequest(status))
  }, [dispatch, status])

  const headers = [
    { label: '_id', key: '_id' },
    { label: 'Name', key: 'name' },
    { label: 'Phone', key: 'phone' },
    { label: 'Is IMA User?', key: 'isImaUser' },
    { label: 'Ref. ID', key: 'refId' },
    { label: 'Benificiary Name', key: 'benificiaryName' },
    { label: 'Bank Name', key: 'bankName' },
    { label: 'Branch Name', key: 'branchName' },
    { label: 'Account Number', key: 'accountNumber' },
    { label: 'IFSC', key: 'ifsc' },
    { label: 'Request ID', key: 'requestId' },
    { label: 'Amount', key: 'amount' },
    { label: 'status', key: 'status' },
  ]

  const requestForDownload = WithdrawalRequests?.withdrawalPromoterData

  const excelDownloadProducts = map(requestForDownload, item => ({
    ...item,
    _id: item?._id,
    status: item?.status,
    name: item?.name,
    phone: item?.phone,
    isImaUser: item?.isImaUser,
    benificiaryName: item?.benificiaryName,
    bankName: item?.bankName,
    branchName: item?.branchName,
    refId: item?.ref_id,
    accountNumber: item?.accountNumber,
    ifsc: item?.ifsc,
    requestId: item?.requestId,
    amount: item?.amount,
  }))

  return (
    <>
      <Button
        data-tip
        id="downloadWithdrawalRequestId"
        data-for="downloadTip"
        style={{ border: 'none' }}
        color=""
        className="w-sm btn-sm btn-warning m-auto me-0 mt-0 d-flex align-items-center justify-content-center"
      >
        {requestForDownload && requestForDownload?.length > 0 && (
          <CSVLink
            data={excelDownloadProducts}
            headers={headers}
            filename={'requests.csv'}
            className="d-flex text-white align-items-center "
          >
            <BsFillCloudDownloadFill size={'14px'} className="mx-2" />
            {`${requestForDownload?.length} request`}
          </CSVLink>
        )}
        {loading && <Spinner />}
      </Button>
      <UncontrolledTooltip
        id="downloadTip"
        target={'downloadWithdrawalRequestId'}
      >
        Download Withdrawal Requests
      </UncontrolledTooltip>
    </>
  )
}

export default DownloadRequest
DownloadRequest.propTypes = {
  status: PropTypes.any,
}
