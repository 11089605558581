import React from 'react'
import PropType from 'prop-types'

const HTMLRendererChainTree = ({ treeData }) => {
  console.log(JSON?.stringify(treeData)?.length)

  const handleSize = () => {
    if (JSON?.stringify(treeData)?.length <= 1000) {
      return 2
    } else if (JSON?.stringify(treeData)?.length >= 5000) {
      return 10
    }
    return 5
  }

  if (treeData?.children?.length >= 1) {
    if (treeData?._id) {
      return (
        <iframe
          title="Embedded HTML"
          srcDoc={`
                <!DOCTYPE html>
                <html>
        
                <head>
                    <title>Tree</title>
                    <script src="https://d3js.org/d3.v7.min.js"></script>
                    <style>
                        /* Add your CSS styles here */
                        .node circle {
                            stroke: steelblue;
                            stroke-width: 3px;
                        }
        
                        .node text {
                            font: 12px sans-serif;
                        }
        
                        .link {
                            fill: none;
                            stroke: #ccc;
                            stroke-width: 2px;
                        }
                    </style>
                </head>
                <body>
                <script>
                console.log('Before treeData');
                const treeData =  ${JSON?.stringify(treeData)};
                console.log('After treeData', treeData);
        
                const margin = { top: 20, right: 120, bottom: 20, left: 120 };
                const width = (${
                  JSON?.stringify(treeData)?.length / handleSize()
                }) - margin.right - margin.left;
                const height = (${
                  JSON?.stringify(treeData)?.length / handleSize()
                }) - margin.top - margin.bottom;
        
                const svg = d3.select("body").append("svg")
                    .attr("width", width + margin.right + margin.left)
                    .attr("height", height + margin.top + margin.bottom)
                    .append("g")
                    .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
        
                const tree = d3.tree()
                    .size([height, width]);
        
                const root = d3.hierarchy(treeData);
                tree(root);
        
                const nodes = root.descendants();
                const links = root.links();
        
                const link = svg.selectAll(".link")
                    .data(links)
                    .enter().append("path")
                    .attr("class", "link")
                    .attr("d", d3.linkHorizontal()
                        .x(d => d.y)
                        .y(d => d.x));
        
                const node = svg.selectAll(".node")
                    .data(nodes)
                    .enter().append("g")
                    .attr("class", "node")
                    .attr("transform", d => "translate(" + d.y + "," + d.x + ")");
        
                node.append("circle")
                    .attr("r", 10)
                    .attr("fill", d => {
                        if (!d.data.chainPosition) {
                            return "steelblue";
                        } else if (d.data.isLevelLimitReached) {
                            return "#b22222";
                        } else {
                            return "#32CD32";
                        }
                    });
        
                node.append("text")
                    .attr("dy", ".35em")
                    .attr("x", d => d.children ? -13 : 13)
                    .attr("text-anchor", d => d.children ? "end" : "start")
                    .text(d => d.data.ref_id);
        
                node.append("text")
                    .attr("dy", "1.35em")
                    .attr("x", d => d.children ? -13 : 13)
                    .attr("text-anchor", d => d.children ? "end" : "start")
                    .text(d => d.data.chainPosition ? '(Pos: ' + d.data.chainPosition + ')' : '(Root)');
        
                const nodeDetails = d3.select("body").append("div")
                    .attr("id", "node-details")
                    .style("position", "absolute")
                    .style("display", "none")
        
                nodeDetails.append("div")
                    .attr("id", "arrow")
                    .style("width", "0")
                    .style("height", "0")
                    .style("border-left", "5px solid transparent")
                    .style("border-right", "5px solid transparent")
                    .style("border-bottom", "5px solid #333");
        
                nodeDetails.append("div")
                    .attr("id", "content")
                    .style("border", "1px solid #333")
                    .style("border-top-right-radius", "10px")
                    .style("border-bottom-right-radius", "10px")
                    .style("border-bottom-left-radius", "10px")
                    .style("background-color", "#fff")
                    .style("padding", "10px");
        
                // Add your code to make the tree interactive
                node.on("click", function (event, d) {
                    // Add your code to display more information about the promoter
        
                    const nodeDetails = document.getElementById("node-details");
                    nodeDetails.style.display = "block";
        
                    // Format the createdAt date
                    const createdAt = new Date(d.data.createdAt);
                    const formattedDate = createdAt.getDate() + "/" + (createdAt.getMonth() + 1) + "/" + createdAt.getFullYear();
        
        
                    const content = document.getElementById("content");
                    content.innerHTML = "Ref ID: " + d.data.ref_id + "<br>Position: " + d.data.chainPosition + "<br>Joined Date: " + formattedDate;
        
                    const svg = document.querySelector("svg");
                    const svgRect = svg.getBoundingClientRect();
                    const nodeX = d.y + svgRect.left + margin.left;
                    const nodeY = d.x + svgRect.top + margin.top + 20;
                    nodeDetails.style.left = nodeX + "px";
                    nodeDetails.style.top = nodeY + "px";
        
                    // Update the position of the node-details div
                });
        
        
            </script>
                </body>
        
                </html>
              `}
          style={{ width: '100%', height: '100vh' }}
          frameBorder="0"
        />
      )
    }
  } else {
    return (
      <div
        className="text-center d-flex align-items-center justify-content-center"
        style={{ height: '70vh' }}
      >
        <p> This supply chain does not have a chain visualization tree</p>
      </div>
    )
  }

  return ''
}

export default HTMLRendererChainTree

HTMLRendererChainTree.propTypes = {
  treeData: PropType.any,
}
