import {
  GET_BLOGS,
  GET_BLOGS_FAIL,
  GET_BLOGS_SUCCESS,
  GET_BLOG_DETAILS,
  GET_BLOG_DETAILS_SUCCESS,
  GET_BLOG_DETAILS_FAIL,
  CREATE_BLOG,
  CREATE_BLOG_SUCCESS,
  CREATE_BLOG_FAIL,
  UPDATE_BLOG,
  UPDATE_BLOG_SUCCESS,
  UPDATE_BLOG_FAIL,
  DELETE_BLOG,
  DELETE_BLOG_SUCCESS,
  DELETE_BLOG_FAIL,
} from './actionTypes'

//all categories
export const getBlogs = (page, limit, searchText, sort) => ({
  type: GET_BLOGS,
  payload: { page, limit, searchText, sort },
})

//all categories
export const getBlogsSuccess = res => ({
  type: GET_BLOGS_SUCCESS,
  payload: res,
})

export const getBlogsFail = error => ({
  type: GET_BLOGS_FAIL,
  payload: error,
})

export const getBlogDetails = blogId => ({
  type: GET_BLOG_DETAILS,
  payload: blogId,
})

export const getBlogDetailsSuccess = blogDetails => ({
  type: GET_BLOG_DETAILS_SUCCESS,
  payload: blogDetails,
})

export const getBlogDetailsFail = error => ({
  type: GET_BLOG_DETAILS_FAIL,
  payload: error,
})

export const createBlog = (blog, onCloseClick) => ({
  type: CREATE_BLOG,
  payload: { blog, onCloseClick },
})

export const createBlogSuccess = blog => ({
  type: CREATE_BLOG_SUCCESS,
  payload: blog,
})

export const createBlogFail = error => ({
  type: CREATE_BLOG_FAIL,
  payload: error,
})

export const updateBlog = (
  blog,
  blogId,
  onCloseClick,
  needReload,
  pagination,
) => ({
  type: UPDATE_BLOG,
  payload: { blog, blogId, onCloseClick, needReload, pagination },
})

export const updateBlogSuccess = blog => ({
  type: UPDATE_BLOG_SUCCESS,
  payload: blog,
})

export const updateBlogFail = error => ({
  type: UPDATE_BLOG_FAIL,
  payload: error,
})

export const deleteBlog = blogId => ({
  type: DELETE_BLOG,
  blogId,
})

export const deleteBlogSuccess = blog => ({
  type: DELETE_BLOG_SUCCESS,
  payload: blog,
})

export const deleteBlogFail = error => ({
  type: DELETE_BLOG_FAIL,
  payload: error,
})
