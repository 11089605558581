import { AvForm } from 'availity-reactstrap-validation'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Card, CardBody, Col } from 'reactstrap'
import { Row, Container } from 'reactstrap'

//actions
import { createSupplyChain } from 'store/actions'
import Form from './Form'
import BreadcrumbPremium from 'components/Common/Breadcrumb2'

const CreateSupplyChain = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const { loading } = useSelector(state => ({
    loading: state.SupplyChain.loading,
  }))

  const [productSelect, setProductSelect] = useState({})

  function handleValidSubmit(values) {
    const createSupplyChainData = {
      ...values,
      product: productSelect.id,
      joiningBonus: parseInt(values?.joiningBonus),
      distributionBonus: parseInt(values?.distributionBonus),
      levelLimit: parseInt(values?.levelLimit),
      totalDistributionAmount: parseInt(values?.totalDistributionAmount),
    }
    dispatch(createSupplyChain(createSupplyChainData, history))
  }

  return (
    <>
      <div className="page-content">
        <BreadcrumbPremium
          root={['/promoter-dashboard', '/supply-chain', 'Create Supply Chain']}
        />
        <Container fluid>
          <div className="container-fluid">
            <AvForm
              onValidSubmit={(e, v) => {
                handleValidSubmit(v)
              }}
            >
              <Row xl="12">
                <Col xl="8">
                  <Card>
                    <CardBody>
                      <Form
                        productSelect={productSelect}
                        setProductSelect={setProductSelect}
                      />
                      <button
                        type="submit"
                        className="col-12 mt-3 btn btn-success save-customer"
                      >
                        {loading && (
                          <>
                            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                          </>
                        )}
                        Create Supply Chain
                      </button>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </AvForm>
          </div>
        </Container>
      </div>
    </>
  )
}

export default CreateSupplyChain
