import React from 'react'
import { useSelector } from 'react-redux'
import AdminDashboard from './AdminDashboard'
import PromoterDashboard from './PromoterDashboard/index'
import CourseProviderDashboard from './CourseProviderDashboard'

const Dashboard = () => {
  const { user } = useSelector(state => ({
    user: state.Login.user,
  }))
  const role = sessionStorage.getItem('role')

  if (user?.role ? user?.role === 'admin' : role === 'admin') {
    return <AdminDashboard />
  } else if (user?.role ? user?.role === 'promoter' : role === 'promoter') {
    return <PromoterDashboard />
  } else return <CourseProviderDashboard />
}

export default Dashboard
