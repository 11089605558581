import React from 'react'
import { Container } from 'reactstrap'

import MetaTag from 'components/Common/Meta-tag'
import PromoterTransactions from './PromoterTransactions'
import BreadcrumbPremium from 'components/Common/Breadcrumb2'

function SupplyChainTransaction() {
  return (
    <>
      <MetaTag title={'All SupplyChainTransactions '} />
      <div className="page-content">
        <BreadcrumbPremium
          root={['/promoter-dashboard', '/promoter-transactions']}
        />
        <Container fluid>
          <div className="container-fluid">
            <PromoterTransactions />
          </div>
        </Container>
      </div>
    </>
  )
}

export default SupplyChainTransaction
