import { call, put, takeEvery } from 'redux-saga/effects'

// Crypto Redux States
import {
  CREATE_SUPPLY_CHAIN,
  GET_ALL_SUPPLY_CHAINS,
  UPDATE_SUPPLY_CHAIN,
  DELETE_SUPPLY_CHAIN,
  GET_SUPPLY_CHAINS_DETAILS,
  GET_ALL_CHAIN_PROMOTERS,
  GET_ALL_SUPPLY_CHAINS_TRANSACTION,
  GET_ALL_WALLETS,
  CREATE_LUCKY_DRAW,
  GET_ALL_LUCKY_DRAWS,
  UPDATE_LUCKY_DRAW,
  DELETE_LUCKY_DRAW,
  GET_LUCKY_DRAW_DETAILS,
  GET_PROMOTER_TRANSACTION,
  CREATE_WITHDRAWAL_REQUEST,
  GET_ALL_WITHDRAWAL_REQUESTS,
  UPDATE_WITHDRAWAL_REQUEST,
  APPROVE_WITHDRAWAL_REQUEST,
  GET_PROMOTER_SUMMERY,
  GET_INITIATE_LUCKY_DRAW,
  WINNER_APPROVE,
  LUCKY_DRAW_WON,
  LUCKY_DRAW_WINNINGS,
  GET_CHAIN_PROMOTER_DETAILS,
  DOWNLOAD_WITHDRAWAL_REQUEST,
  SUPPLY_CHAIN_BY_PRODUCT,
  GENERATE_SUPPLY_CHAIN,
  VISUALIZE_CHAIN_TREE,
  SYNC_CHAIN_TREE,
  UPDATE_BULKREQUEST,
} from './actionTypes'
import {
  createSupplyChainFail,
  createSupplyChainSuccess,
  getAllSupplyChainsSuccess,
  getAllSupplyChainsFail,
  updateSupplyChainSuccess,
  updateSupplyChainFail,
  deleteSupplyChainSuccess,
  deleteSupplyChainFail,
  getSupplyChainDetailsFail,
  getSupplyChainDetailsSuccess,
  getChainPromoterDetailsFail,
  getChainPromoterDetailsSuccess,
  getAllChainPromotersFail,
  getAllChainPromotersSuccess,
  getAllSupplyChainTransactionsSuccess,
  getAllSupplyChainTransactionsFail,
  getAllWalletsFail,
  getAllWalletsSuccess,
  createLuckyDrawSuccess,
  createLuckyDrawFail,
  getAllLuckyDrawsSuccess,
  getAllLuckyDrawsFail,
  updateLuckyDrawFail,
  updateLuckyDrawSuccess,
  deleteLuckyDrawFail,
  deleteLuckyDrawSuccess,
  getLuckyDrawDetailsSuccess,
  getLuckyDrawDetailsFail,
  getPromoterTransactionSuccess,
  getPromoterTransactionFail,
  createWithdrawalRequestSuccess,
  createWithdrawalRequestFail,
  getAllWithdrawalRequestsFail,
  getAllWithdrawalRequestsSuccess,
  updateWithdrawalRequestSuccess,
  updateWithdrawalRequestFail,
  ApproveWithdrawalRequestSuccess,
  ApproveWithdrawalRequestFail,
  getPromoterSummerySuccess,
  getPromoterSummeryFail,
  InitiateLuckyDrawFail,
  InitiateLuckyDrawSuccess,
  WinnerApproveFail,
  WinnerApproveSuccess,
  LuckyDrawWonFail,
  LuckyDrawWonSuccess,
  LuckyDrawWinningsSuccess,
  LuckyDrawWinningsFail,
  downloadWithdrawalRequestSuccess,
  downloadWithdrawalRequestFail,
  supplyChainByProductSuccess,
  supplyChainByProductFail,
  generateSupplyChainSuccess,
  generateSupplyChainFail,
  visualizeChainTreeSuccess,
  visualizeChainTreeFail,
  syncChainTreeSuccess,
  syncChainTreeFail,
  updateBulkRequestSuccess,
  updateBulkRequestFail,
} from './actions'
import { get, post, ApiPut, del } from 'helpers/api_helper'
import { Notification } from '../../components/Common/Notification'

/////////////////////supply chain///////////////////////////////////////
// create SupplyChain
const createSupplyChainApi = ({ supplyChain }) => {
  return post('/supplychain/admin/new', supplyChain)
}

// all SupplyChain
function getAllSupplyChainsAPi({ page, sort, limit, searchText }) {
  return get(
    `/supplychain/admin/all?sort=${sort ? sort : 'all'}&page=${
      page ? page : 1
    }&limit=${limit ? limit : 10}&search=${searchText ? searchText : ''}`,
  )
}

// update SupplyChain
const updateSupplyChainApi = ({ supplyChain, supplyChainId }) => {
  return post(`/supplychain/admin/status/${supplyChainId}`, supplyChain)
}

// delete SupplyChain
const deleteSupplyChainApi = supplyChainId => {
  return del(`/supplychain/admin/${supplyChainId}`)
}

// single view SupplyChain
const getSupplyChainDetailAPi = supplyChainId => {
  return get(`/supplychain/admin/${supplyChainId}`)
}

// ........................
// all chain promoter
function getAllChainPromotersAPi({
  page,
  sort,
  limit,
  searchText,
  promoter,
  supplyChain,
}) {
  return get(
    `/chainpromoter/admin/all/?sort=${sort ? sort : 'all'}&page=${
      page ? page : 1
    }&limit=${
      limit ? limit : 10
    }&search=${searchText}&supplyChain=${supplyChain}&promoter=${promoter}`,
  )
}
// single view chain promoter
const getChainPromoterDetailAPi = chainPromoterId => {
  return get(`/chainpromoter/admin/${chainPromoterId}`)
}
// ........................................................................................
// all  supplychain transactions
function getAllSupplyChainTransactionsAPi({
  page,
  category,
  limit,
  transactionType,
  promoter,
  chainPromoter,
  date,
}) {
  return get(
    `/promotertransaction/admin/all/?page=${page || 10}&limit=${
      limit || 10
    }&category=${category || ''}&transactionType=${
      transactionType || ''
    }&promoter=${promoter || ''}&chainPromoter=${
      chainPromoter || ''
    }&dateFrom=${date?.from || ''}&dateTo=${date?.to || ''}`,
  )
}

//...................................
// all  wallet
function getAllWalletsAPi({ page, sort, limit, searchText }) {
  return get(
    `/supplychain/admin/all/?sort=${sort ? sort : 'all'}&page=${
      page ? page : 1
    }&limit=${limit ? limit : 10}&search=${searchText}`,
  )
}
/////////////////////luckyDraws///////////////////////////////////////
// create LuckyDraw
const createLuckyDrawApi = ({ luckyDraw }) => {
  return post('luckydraw/admin/new', luckyDraw)
}
// all luckyDraws
function getAllLuckyDrawsAPi({ page, sort, limit, searchText, supplyChainId }) {
  // sort=${sort ? sort : ""}
  return get(
    `/luckydraw/admin/all?page=${page ? page : 1}&sort=${
      sort ? sort : ''
    }&limit=${
      limit ? limit : 10
    }&search=${searchText}&supplyChain=${supplyChainId}`,
  )
}
// update luckyDraws
const updateLuckyDrawApi = ({ luckyDraw, luckyDrawId }) => {
  return ApiPut(`/luckydraw/admin/${luckyDrawId}`, luckyDraw)
}

// delete luckyDraws
const deleteLuckyDrawApi = luckyDrawId => {
  return del(`/luckydraw/admin/${luckyDrawId}`)
}
// single view luckyDraws
const getLuckyDrawDetailAPi = luckyDrawId => {
  return get(`/luckydraw/admin/${luckyDrawId}`)
}

const getAllPromoterTransactionApi = promoterId => {
  return get(`/promotertransaction/admin/promoter/${promoterId}`)
}
// create withdrawalRequest
const createWithdrawalRequestApi = ({ withdrawalRequest }) => {
  return post('/withdrawal-request/admin/new', withdrawalRequest)
}

// all withdrawalRequest
function getAllWithdrawalRequestsAPi({
  page,
  sort,
  limit,
  searchText,
  date,
  promoterId,
}) {
  return get(
    `/withdrawal-request/admin/all/?status=${sort || ''}&page=${
      page ? page : 1
    }&limit=${limit ? limit : 10}&search=${searchText || ''}&promoter=${
      promoterId || ''
    }&dateFrom=${date?.from || ''}&dateTo=${date?.to || ''}`,
  )
}
// update withdrawalRequest
const onUpdateBulkRequestsApi = ({ uploadData }) => {
  return post(`/withdrawal-request/admin/update/bulk`, uploadData)
}
// update withdrawalRequest
const updateWithdrawalRequestsApi = ({
  withdrawalRequestId,
  withdrawalRequest,
}) => {
  return ApiPut(
    `/withdrawal-request/admin/${withdrawalRequestId}`,
    withdrawalRequest,
  )
}

// status approve
const approveWithdrawalRequestApi = ({ transactionId }) => {
  return get(`/withdrawal-request/admin/approve/${transactionId}`)
}

const getPromoterDashboardDataApi = () => {
  return get(`/static/admin/promoter/dashboard/data`)
}
//Initiate Lucky Draw
const initiateLuckyDrawApi = luckyDrawId => {
  return get(`/luckydraw/admin/initiate/${luckyDrawId}`)
}
//fetchSupplyChainByProductApi
const fetchSupplyChainByProductApi = ({ productId }) => {
  return get(`/supplychain/admin/product/${productId}`)
}
// winner approve
const winnerApproveApi = ({ winner, winnerId }) => {
  return post(`/luckydraw/admin/approve/${winnerId}`, winner)
}
// Lucky draw won
const luckyDrawWonApi = promoterId => {
  return get(`/luckydraw/admin/won/promoter/${promoterId}`)
}
const fetchLuckyDrawWinningsApi = () => {
  return get(`/luckydraw/promoter/won/all`)
}

const downloadWithdrawalRqApi = ({ status }) => {
  return get(`/withdrawal-request/admin/download/all?status=${status || ''}`)
}

const generateSupplyChainApi = ({ orderId }) => {
  return get(`/chainpromoter/admin/generate/order/${orderId}`)
}

const visualizeChainApi = ({ supplChainId, chainPromoterId }) => {
  return get(
    `/chainpromoter/visualize/tree/${supplChainId}?chainpromoter=${chainPromoterId}`,
  )
}

const syncChainApi = ({ supplChainId, chainPromoterId }) => {
  return get(
    `/chainpromoter/sync/tree/${supplChainId}?chainpromoter=${chainPromoterId}`,
  )
}

//...................................
// create SupplyChain
function* onCreateSupplyChain({ payload }) {
  try {
    const response = yield call(createSupplyChainApi, payload)
    if (response?.message) {
      Notification({
        type: 'error',
        message: response?.message,
        title: '',
      })
    } else if (response) {
      yield put(createSupplyChainSuccess(response))
      payload.history.push('/supply-chain')
      doneNotification('Supply Chain Created Successfully!')
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: 'error',
        message: error?.response?.data?.message,
        title: '',
      })
    }
    yield put(createSupplyChainFail(error))
  }
}

// all SupplyChain
function* fetchAllSupplyChains({ payload }) {
  try {
    const response = yield call(getAllSupplyChainsAPi, payload)
    yield put(getAllSupplyChainsSuccess(response))
  } catch (error) {
    yield put(getAllSupplyChainsFail(error))
  }
}
// update
function* onUpdateSupplyChain({ payload }) {
  try {
    const response = yield call(updateSupplyChainApi, payload)
    if (response) {
      Notification({
        type: 'success',
        message: 'Supply Chain Updated Successfully!',
        title: '',
      })
      yield put(updateSupplyChainSuccess(response))
      if (payload.history) {
        payload.history.push('/supply-chain')
      }
    }
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: 'error',
        message: error.response?.data?.message,
        title: '',
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: 'error',
        message: 'file size is Too Large',
        title: '',
      })
    }
    yield put(updateSupplyChainFail(error.response.data))
  }
}
// delete SupplyChain
function* onDeleteSupplyChain({ supplyChainId }) {
  try {
    const response = yield call(deleteSupplyChainApi, supplyChainId)
    yield put(deleteSupplyChainSuccess(response?._id))
    Notification({
      type: 'success',
      message: 'SupplyChain Deleted Successfully!',
      title: '',
    })
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: 'error',
        message: error?.response?.data?.message,
        title: '',
      })
    }
    yield put(deleteSupplyChainFail(error))
  }
}
// single view
function* fetchSupplyChainDetails({ payload: SupplyChainId }) {
  try {
    const response = yield call(getSupplyChainDetailAPi, SupplyChainId)
    yield put(getSupplyChainDetailsSuccess(response))
  } catch (error) {
    getSupplyChainDetailsFail
    yield put(getSupplyChainDetailsFail(error))
  }
}
// ...........................................................................................
// all chainPromoter
function* fetchAllChainPromoters({ payload }) {
  try {
    const response = yield call(getAllChainPromotersAPi, payload)
    yield put(getAllChainPromotersSuccess(response))
  } catch (error) {
    yield put(getAllChainPromotersFail(error))
  }
}
// single view chainPromoter
function* fetchChainPromoterDetails({ payload: ChainPromoterId }) {
  try {
    const response = yield call(getChainPromoterDetailAPi, ChainPromoterId)
    yield put(getChainPromoterDetailsSuccess(response))
  } catch (error) {
    yield put(getChainPromoterDetailsFail(error))
  }
}
// ..................
// all SupplyChainTransactions
function* fetchAllSupplyChainTransactions({ payload }) {
  try {
    const response = yield call(getAllSupplyChainTransactionsAPi, payload)
    yield put(getAllSupplyChainTransactionsSuccess(response))
  } catch (error) {
    yield put(getAllSupplyChainTransactionsFail(error))
  }
}
//..............................
// all wallet
function* fetchAllWallets({ payload }) {
  try {
    const response = yield call(getAllWalletsAPi, payload)
    yield put(getAllWalletsSuccess(response))
  } catch (error) {
    yield put(getAllWalletsFail(error))
  }
}
//..............................
// create LuckyDraws
function* onCreateLuckyDraw({ payload }) {
  try {
    const response = yield call(createLuckyDrawApi, payload)
    if (response?.message) {
      Notification({
        type: 'error',
        message: response?.message,
        title: '',
      })
    } else if (response) {
      yield put(createLuckyDrawSuccess(response))
      if (payload?.onCloseClick) {
        payload?.onCloseClick()
      }
      doneNotification('LuckyDraw Created Successfully!')
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: 'error',
        message: error?.response?.data?.message,
        title: '',
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: 'error',
        message: 'file size is Too Large',
        title: '',
      })
    }
    yield put(createLuckyDrawFail(error))
  }
}

// all LuckyDraws
function* fetchAllLuckyDraws({ payload }) {
  try {
    const response = yield call(getAllLuckyDrawsAPi, payload)
    yield put(getAllLuckyDrawsSuccess(response))
  } catch (error) {
    yield put(getAllLuckyDrawsFail(error))
  }
}
// update
function* onUpdateLuckyDraw({ payload }) {
  try {
    const response = yield call(updateLuckyDrawApi, payload)
    if (response) {
      Notification({
        type: 'success',
        message: 'LuckyDraw Updated Successfully!',
        title: '',
      })
      yield put(updateLuckyDrawSuccess(response))
      if (payload?.onCloseClick) {
        payload.onCloseClick()
      }
      if (payload?.history) {
        payload.history.push('/lucky-draw')
      }
    }
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: 'error',
        message: error.response?.data?.message,
        title: '',
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: 'error',
        message: 'file size is Too Large',
        title: '',
      })
    }
    yield put(updateLuckyDrawFail(error.response.data))
  }
}
// delete luckydraw
function* onDeleteLuckyDraw({ luckyDrawId }) {
  try {
    const response = yield call(deleteLuckyDrawApi, luckyDrawId)
    yield put(deleteLuckyDrawSuccess(response))
    Notification({
      type: 'success',
      message: 'LuckyDraw Deleted Successfully!',
      title: '',
    })
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: 'error',
        message: error?.response?.data?.message,
        title: '',
      })
    }
    yield put(deleteLuckyDrawFail(error))
  }
}

// single view
function* fetchLuckyDrawDetails({ payload: luckyDrawId }) {
  try {
    const response = yield call(getLuckyDrawDetailAPi, luckyDrawId)
    yield put(getLuckyDrawDetailsSuccess(response))
  } catch (error) {
    yield put(getLuckyDrawDetailsFail(error))
  }
}

function* getAllPromoterTransaction({ payload }) {
  try {
    const response = yield call(getAllPromoterTransactionApi, payload)
    yield put(getPromoterTransactionSuccess(response))
  } catch (error) {
    yield put(getPromoterTransactionFail(error))
  }
}
//..........................................
// create withdrawalRequest
function* onCreateWithdrawalRequest({ payload }) {
  try {
    const response = yield call(createWithdrawalRequestApi, payload)
    if (response?.message) {
      Notification({
        type: 'error',
        message: response?.message,
        title: '',
      })
    } else if (response) {
      yield put(createWithdrawalRequestSuccess(response))
      if (payload?.onCloseClick) {
        payload?.onCloseClick()
      }
      doneNotification('Withdrawal Request Successfully!')
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: 'error',
        message: error?.response?.data?.message,
        title: '',
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: 'error',
        message: 'file size is Too Large',
        title: '',
      })
    }
    yield put(createWithdrawalRequestFail(error.response.data.message))
  }
}

// all withdrawalRequest
function* fetchAllWithdrawalRequests({ payload }) {
  try {
    const response = yield call(getAllWithdrawalRequestsAPi, payload)
    yield put(getAllWithdrawalRequestsSuccess(response))
  } catch (error) {
    yield put(getAllWithdrawalRequestsFail(error))
  }
}
// update
function* onUpdateWithdrawalRequests({ payload }) {
  try {
    const response = yield call(updateWithdrawalRequestsApi, payload)
    if (response) {
      Notification({
        type: 'success',
        message: 'Withdrawal Requests Updated Successfully!',
        title: '',
      })
      yield put(updateWithdrawalRequestSuccess(response))
      if (payload?.onCloseClick) {
        payload.onCloseClick()
      }
    }
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: 'error',
        message: error.response?.data?.message,
        title: '',
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: 'error',
        message: 'file size is Too Large',
        title: '',
      })
    }
    yield put(updateWithdrawalRequestFail(error.response.data))
  }
}

function* fetchApproveWithdrawalRequest({ payload }) {
  try {
    const response = yield call(approveWithdrawalRequestApi, payload)
    if (response) {
      Notification({
        type: 'success',
        message: 'Withdrawal Requests Status Approve Updated Successfully!',
        title: '',
      })
      yield put(ApproveWithdrawalRequestSuccess(response))
      if (payload?.onCloseClick) {
        payload.onCloseClick()
      }
    }
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: 'error',
        message: error.response?.data?.message,
        title: '',
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: 'error',
        message: 'file size is Too Large',
        title: '',
      })
    }
    yield put(ApproveWithdrawalRequestFail(error))
  }
}
function* getPromoterDashboardData() {
  try {
    const response = yield call(getPromoterDashboardDataApi)
    yield put(getPromoterSummerySuccess(response))
  } catch (error) {
    yield put(getPromoterSummeryFail(error))
  }
}

function* fetchInitiateLuckyDraw({ payload }) {
  try {
    const response = yield call(initiateLuckyDrawApi, payload?.luckyDrawId)
    yield put(InitiateLuckyDrawSuccess(response))
    if (payload?.openModal) {
      payload?.openModal()
    }
  } catch (error) {
    yield put(InitiateLuckyDrawFail(error.response.data))
  }
}

function* fetchSupplyChainByProduct({ payload: productId }) {
  try {
    const response = yield call(fetchSupplyChainByProductApi, productId)
    yield put(supplyChainByProductSuccess(response))
  } catch (error) {
    yield put(supplyChainByProductFail(error.response.data))
  }
}

function* onWinnerApprove({ payload }) {
  try {
    const response = yield call(winnerApproveApi, payload)
    yield put(WinnerApproveSuccess(response))
  } catch (error) {
    yield put(WinnerApproveFail(error))
  }
}

function* fetchLuckyDrawWon({ payload: promoterId }) {
  try {
    const response = yield call(luckyDrawWonApi, promoterId)
    yield put(LuckyDrawWonSuccess(response))
  } catch (error) {
    yield put(LuckyDrawWonFail(error))
  }
}

function* fetchLuckyDrawWinnings() {
  try {
    const response = yield call(fetchLuckyDrawWinningsApi)
    yield put(LuckyDrawWinningsSuccess(response))
  } catch (error) {
    yield put(LuckyDrawWinningsFail(error))
  }
}

function* downloadWithdrawalRq({ payload }) {
  try {
    const response = yield call(downloadWithdrawalRqApi, payload)
    yield put(downloadWithdrawalRequestSuccess(response))
  } catch (error) {
    yield put(downloadWithdrawalRequestFail(error))
  }
}

function* generateSupplyChain({ payload }) {
  try {
    const response = yield call(generateSupplyChainApi, payload)

    yield put(generateSupplyChainSuccess(response))
  } catch (error) {
    yield put(generateSupplyChainFail(error))
  }
}
function* visualizeChain({ payload }) {
  console.log(payload)

  try {
    const response = yield call(visualizeChainApi, payload)
    yield put(visualizeChainTreeSuccess(response))
  } catch (error) {
    yield put(visualizeChainTreeFail(error))
  }
}
function* syncChain({ payload }) {
  try {
    const response = yield call(syncChainApi, payload)
    yield put(syncChainTreeSuccess(response))
    if (response) {
      Notification({
        type: 'success',
        message: 'everything upto date',
        title: '',
      })
    }
  } catch (error) {
    yield put(syncChainTreeFail(error))
  }
}

function* onUpdateBulkRequests({ payload }) {
  try {
    const response = yield call(onUpdateBulkRequestsApi, payload)
    if (response) {
      Notification({
        type: 'success',
        message: 'Withdrawal Requests Updated Successfully!',
        title: '',
      })
      yield put(updateBulkRequestSuccess(response))
      if (payload?.onCloseClick) {
        payload.onCloseClick()
      }
    }
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: 'error',
        message: error.response?.data?.message,
        title: '',
      })
    }
    yield put(updateBulkRequestFail(error.response.data))
  }
}

// ...........................................................................................
function* supplyChainSaga() {
  yield takeEvery(CREATE_SUPPLY_CHAIN, onCreateSupplyChain)
  yield takeEvery(GET_ALL_SUPPLY_CHAINS, fetchAllSupplyChains)
  yield takeEvery(UPDATE_SUPPLY_CHAIN, onUpdateSupplyChain)
  yield takeEvery(DELETE_SUPPLY_CHAIN, onDeleteSupplyChain)
  yield takeEvery(GET_SUPPLY_CHAINS_DETAILS, fetchSupplyChainDetails)
  yield takeEvery(GET_ALL_CHAIN_PROMOTERS, fetchAllChainPromoters)
  yield takeEvery(
    GET_ALL_SUPPLY_CHAINS_TRANSACTION,
    fetchAllSupplyChainTransactions,
  )
  yield takeEvery(GET_ALL_WALLETS, fetchAllWallets)
  yield takeEvery(CREATE_LUCKY_DRAW, onCreateLuckyDraw)
  yield takeEvery(GET_ALL_LUCKY_DRAWS, fetchAllLuckyDraws)
  yield takeEvery(UPDATE_LUCKY_DRAW, onUpdateLuckyDraw)
  yield takeEvery(DELETE_LUCKY_DRAW, onDeleteLuckyDraw)
  yield takeEvery(GET_LUCKY_DRAW_DETAILS, fetchLuckyDrawDetails)
  yield takeEvery(GET_PROMOTER_TRANSACTION, getAllPromoterTransaction)

  yield takeEvery(CREATE_WITHDRAWAL_REQUEST, onCreateWithdrawalRequest)
  yield takeEvery(GET_ALL_WITHDRAWAL_REQUESTS, fetchAllWithdrawalRequests)
  yield takeEvery(UPDATE_WITHDRAWAL_REQUEST, onUpdateWithdrawalRequests)
  yield takeEvery(GET_CHAIN_PROMOTER_DETAILS, fetchChainPromoterDetails)
  yield takeEvery(APPROVE_WITHDRAWAL_REQUEST, fetchApproveWithdrawalRequest)
  yield takeEvery(GET_PROMOTER_SUMMERY, getPromoterDashboardData)
  yield takeEvery(GET_INITIATE_LUCKY_DRAW, fetchInitiateLuckyDraw)
  yield takeEvery(SUPPLY_CHAIN_BY_PRODUCT, fetchSupplyChainByProduct)
  yield takeEvery(WINNER_APPROVE, onWinnerApprove)
  yield takeEvery(LUCKY_DRAW_WON, fetchLuckyDrawWon)
  yield takeEvery(LUCKY_DRAW_WINNINGS, fetchLuckyDrawWinnings)
  yield takeEvery(DOWNLOAD_WITHDRAWAL_REQUEST, downloadWithdrawalRq)
  yield takeEvery(GENERATE_SUPPLY_CHAIN, generateSupplyChain)
  yield takeEvery(VISUALIZE_CHAIN_TREE, visualizeChain)
  yield takeEvery(SYNC_CHAIN_TREE, syncChain)
  yield takeEvery(UPDATE_BULKREQUEST, onUpdateBulkRequests)
}

export default supplyChainSaga

function doneNotification(message) {
  Notification({
    type: 'success',
    message: message,
    title: '',
  })
}
