import {
  GET_COLLECTIONS,
  GET_COLLECTIONS_FAIL,
  GET_COLLECTIONS_SUCCESS,
  GET_COLLECTION_DETAILS,
  GET_COLLECTION_DETAILS_SUCCESS,
  GET_COLLECTION_DETAILS_FAIL,
  CREATE_COLLECTION,
  CREATE_COLLECTION_SUCCESS,
  CREATE_COLLECTION_FAIL,
  UPDATE_COLLECTION,
  UPDATE_COLLECTION_SUCCESS,
  UPDATE_COLLECTION_FAIL,
  DELETE_COLLECTION,
  DELETE_COLLECTION_SUCCESS,
  DELETE_COLLECTION_FAIL,
} from './actionTypes'

//all collections
export const getCollections = (page, limit, searchText, store) => ({
  type: GET_COLLECTIONS,
  payload: { page, limit, searchText, store },
})

export const getCollectionsSuccess = collections => ({
  type: GET_COLLECTIONS_SUCCESS,
  payload: collections,
})

export const getCollectionsFail = error => ({
  type: GET_COLLECTIONS_FAIL,
  payload: error,
})

//collections details
export const getCollectionsDetails = collectionId => ({
  type: GET_COLLECTION_DETAILS,
  payload: collectionId,
})

export const getCollectionsDtailsSuccess = collectionDetails => ({
  type: GET_COLLECTION_DETAILS_SUCCESS,
  payload: collectionDetails,
})

export const getCollectionsDtailsFail = error => ({
  type: GET_COLLECTION_DETAILS_FAIL,
  payload: error,
})

//create collection
export const createCollection = (collection, history) => ({
  type: CREATE_COLLECTION,
  payload: { collection, history },
})

export const createCollectionSuccess = collection => ({
  type: CREATE_COLLECTION_SUCCESS,
  payload: collection,
})

export const createCollectionFail = error => ({
  type: CREATE_COLLECTION_FAIL,
  payload: error,
})

//update collection
export const updateCollection = (collection, collectionId, history) => ({
  type: UPDATE_COLLECTION,
  payload: { collection, collectionId, history },
})

export const updateCollectionSuccess = collection => ({
  type: UPDATE_COLLECTION_SUCCESS,
  payload: collection,
})

export const updateCollectionFail = error => ({
  type: UPDATE_COLLECTION_FAIL,
  payload: error,
})

//delete collection
export const deleteCollection = (CollectionId, history) => ({
  type: DELETE_COLLECTION,
  CollectionId,
  history,
})

export const deleteCollectionSuccess = collection => ({
  type: DELETE_COLLECTION_SUCCESS,
  payload: collection,
})

export const deleteCollectionFail = error => ({
  type: DELETE_COLLECTION_FAIL,
  payload: error,
})
