import React from 'react'
import { Container } from 'reactstrap'

import Breadcrumbs from '../../components/Common/Breadcrumb'
import GrossRevenue from './GrossRevenue'
import MetaTag from 'components/Common/Meta-tag'

function Banner() {
  return (
    <>
      <MetaTag title={'Revenue'} />
      <div className="page-content position-relative">
        <Breadcrumbs title="Dashboard" breadcrumbItem="Revenue" />
        <Container fluid>
          <div className="container-fluid">
            <GrossRevenue />
          </div>
        </Container>
      </div>
    </>
  )
}

export default Banner
