import { useHistory } from 'react-router'
import { Col, Container, Row } from 'reactstrap'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, CardBody, CardTitle } from 'reactstrap'
import { AvForm } from 'availity-reactstrap-validation'
import { useParams } from 'react-router-dom'

import Breadcrumbs from 'components/Common/Breadcrumb'

//actions
import { getCouponsDetails, updateCoupon } from 'store/actions'

import MetaTag from 'components/Common/Meta-tag'
import Form from './Form'
import Users from './UserTable'

const UpdateCoupon = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams()

  const { loading, error, couponDetails, company } = useSelector(state => ({
    loading: state.Coupon.loading,
    error: state.Coupon.error,
    couponDetails: state.Coupon.couponDetails,
    company: state.Settings.settings.company,
  }))

  const defaultCurrencySymbol = company?.defaultCurrencySymbol

  const [couponDuration, setCouponDuration] = useState()
  const [selectedUsers, setSelectedUsers] = useState([])

  useEffect(() => {
    dispatch(getCouponsDetails(params?.id))
  }, [dispatch])

  function handleValidSubmit(values) {
    const updateCouponData = {
      ...values,
      durationType: couponDuration,
      whitelistUsersList: selectedUsers,
    }

    dispatch(updateCoupon(updateCouponData, params?.id, history))
  }

  const FormOption = {
    setSelectedUsers,
    couponDuration,
    setCouponDuration,
    isUpdate: true,
  }

  return (
    <>
      <MetaTag title="Coupon" />

      <div className="page-content">
        <Breadcrumbs title="Coupons" breadcrumbItem="Coupon" />
        <Container fluid>
          <div className="container-fluid">
            <AvForm
              onValidSubmit={(e, v) => {
                handleValidSubmit(v)
              }}
            >
              <Row xl="12">
                <Col xl="8" className="m-auto">
                  <Card
                    style={{
                      borderRadius: '20px',
                    }}
                  >
                    <CardBody
                      className="bg-green"
                      style={{
                        minHeight: '150px',
                        borderRadius: '20px',
                        padding: '0',
                      }}
                    >
                      <Row style={{}}>
                        <Col
                          md={8}
                          lg={8}
                          sm={8}
                          xs={8}
                          xl={8}
                          className="col-8 d-flex custom-dashed flex-wrap p-4 pl-5"
                          style={{
                            alignItems: 'flex-end',
                          }}
                        >
                          <div className="p-2 pb-0">
                            <h4>{couponDetails?.name}</h4>
                            <p>{couponDetails?.couponType}</p>

                            <span className="d-flex">
                              <h6 className="text-muted">Total user limit :</h6>{' '}
                              <p className="mx-2 mb-0">
                                {couponDetails?.totalUserLimit}
                              </p>
                            </span>
                            <span className="d-flex ">
                              <h6 className="mb-0 text-muted">
                                Per User Limit :{' '}
                              </h6>{' '}
                              <p className="mx-2 mb-0">
                                {couponDetails?.perUserLimit}
                              </p>
                            </span>
                          </div>
                          <div>
                            <ul className="mb-0">
                              <li className="my-2">
                                Coupon Redeemed: {couponDetails?.deductionCount}
                              </li>
                              <li className="my-2">
                                Redeemed Users:{' '}
                                {couponDetails?.deductedUsersList?.length
                                  ? couponDetails?.deductedUsersList?.length
                                  : 0}
                              </li>
                              <li className="mt-2">
                                Total Redeemed Amount: {defaultCurrencySymbol}
                                &nbsp;
                                {couponDetails?.totalDeductionAmount}
                              </li>
                            </ul>
                          </div>
                        </Col>
                        <Col
                          lg={4}
                          md={4}
                          sm={4}
                          xm={4}
                          xl={4}
                          className="p-4 bg-yellow"
                          style={{
                            // maxWidth: "255px",
                            borderRadius: ' 0 20px 20px 0',
                          }}
                        >
                          <h2>{couponDetails?.couponCode}</h2>
                          <h6>
                            Discount Percentage :{' '}
                            {couponDetails?.discountPercentage}%
                          </h6>
                          {couponDetails?.discountValue ? (
                            <h6>
                              Discount Value : {couponDetails?.discountValue}
                            </h6>
                          ) : (
                            ''
                          )}
                          {/* <h6>
                            Max Redeemable Value :{" "}
                            {couponDetails?.discountValue}
                          </h6> */}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Form formOption={FormOption} updateData={couponDetails} />

              <Row xl="12">
                <Col xl="12">
                  <Card>
                    <CardBody>
                      <CardTitle className="h4 mb-4 text_deals_green"></CardTitle>
                      <Row>
                        <Col>
                          <div className="text-end">
                            <button
                              type="submit"
                              className="col-12 btn btn-success save-customer"
                            >
                              {loading && (
                                <>
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                </>
                              )}
                              Save
                            </button>
                          </div>
                          {error?.message && (
                            <p
                              className="mt-2 mb-0 pb-0"
                              style={{ textAlign: 'end', color: '#ff0000a3' }}
                            >
                              {' '}
                              <span>!</span> {error?.message}
                            </p>
                          )}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Users />
            </AvForm>
          </div>
        </Container>
      </div>
    </>
  )
}

export default UpdateCoupon
