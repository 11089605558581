import React, { useEffect } from 'react'
import ReactApexChart from 'react-apexcharts'
import PropTypes from 'prop-types'
import { getPromoterTransactionGraph } from 'store/actions'
import { useDispatch, useSelector } from 'react-redux'

const SpineArea = () => {
  const dispatch = useDispatch()

  const { dashboardGraphLead } = useSelector(state => ({
    dashboardGraphLead:
      state?.Promoter?.promoterTransactionGraphLeadBonus?.data,
  }))

  useEffect(() => {
    dispatch(getPromoterTransactionGraph('leadbonus'))
  }, [dispatch])

  const myGraphData = Object?.values(
    dashboardGraphLead !== undefined ? dashboardGraphLead : '',
  )

  const series = [
    {
      name: 'Amount',
      data: myGraphData,
    },
  ]

  const options = {
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 3,
    },
    title: { text: 'Promoters Lead Bonus (Monthly)', align: 'left' },

    colors: ['#8bcaca'],
    xaxis: {
      title: '',
      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
    },
    grid: {
      borderColor: '#f1f1f1',
    },
    tooltip: {
      x: {
        format: 'dd/MM/yy HH:mm',
      },
    },
  }

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="area"
      height="350"
    />
  )
}

export default SpineArea

SpineArea.propTypes = {
  stateValue: PropTypes.object,
}
