import React, { Fragment, useEffect, useState } from 'react'
import { Row, Col, Card, CardBody, Button, Spinner, Badge } from 'reactstrap'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// data-table related plugins
import BootstrapTable from 'react-bootstrap-table-next'
import { debounce, map, range } from 'lodash'

import ToolkitProvider from 'react-bootstrap-table2-toolkit'

import '../../assets/scss/data-tables.scss'

import MyPagination from 'components/Common/MyPagination'
import { getPromoters } from 'store/actions'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import { CurrencySwitch } from 'hooks/Currency'

const Promoters = () => {
  const dispatch = useDispatch()
  const currency = CurrencySwitch()

  const { allPromoters, loading } = useSelector(state => ({
    allPromoters: state?.Users?.promotersList,
    loading: state.Users?.promoterLoad,
  }))
  console.log(allPromoters)
  const [page, setPage] = useState(1)
  const [searchText, setsearchText] = useState('')
  const [sort, setSort] = useState('all')
  useEffect(() => {
    dispatch(getPromoters(page, searchText, sort))
  }, [dispatch, page, searchText, sort])

  const totalPages = Math.ceil(allPromoters?.total / 10)
  const pages = range(1, totalPages + 1)

  const columns = [
    {
      dataField: 'ref_id',
      text: 'Referral Id',
      sort: true,
    },
    {
      dataField: 'phone',
      text: 'Phone',
      sort: true,
    },
    {
      dataField: 'name',
      text: 'Name',
      sort: true,
    },

    {
      dataField: 'walletBalance',
      text: 'Wallet Balance',
      sort: true,
    },
    // {
    //   dataField: "isActive",
    //   text: "Active",
    //   sort: true,
    // },
    // {
    //   dataField: "isVerified",
    //   text: "Verified",
    //   sort: true,
    // },
    // {
    //   dataField: "email",
    //   text: "Email",
    //   sort: true,
    // },
    // {
    //   dataField: "address",
    //   text: "Address",
    //   sort: true,
    // },
    {
      dataField: 'action',
      text: 'Action',
    },
  ]
  const headerTap = [
    {
      value: 'all',
      title: 'All',
    },

    {
      value: 'imauser',
      title: 'ImaUsers',
    },
  ]
  const listChange = sortValue => {
    setSort(sortValue)
  }

  const promoterData = map(allPromoters?.promoters, item => ({
    ...item,
    promoterPercentage: <span>{item?.promoterPercentage}%</span>,
    walletBalance: (
      <span>
        {currency} {item?.walletBalance}
      </span>
    ),
    phone: (
      <>
        {item?.phone}
        {item?.isImaUser && sort !== 'imauser' ? (
          <Badge className="badge-soft-success p-1 px-2 font-size-10 mar-gn">
            Ima User
          </Badge>
        ) : null}
      </>
    ),
    action: (
      <>
        <Link to={`promoter/${item?._id}`} className="text-info">
          <i className="fas fa-eye font-size-15" />
        </Link>
      </>
    ),
  }))
  const debounceOrderSearch = debounce(value => setsearchText(value), 600)

  // const { SearchBar } = Search

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <ToolkitProvider
                keyField="_id"
                key={'id'}
                columns={columns}
                data={promoterData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col lg={4} className="mb-3">
                        <div
                          className="btn-group mt-xl-0"
                          role="group"
                          aria-label="Basic radio toggle button group"
                        >
                          {headerTap?.map((tap, key) => (
                            <Fragment key={key}>
                              <input
                                type="radio"
                                className="btn-check"
                                name="btnRadio"
                                id={`btnRadio${tap?.value}`}
                                autoComplete="off"
                                checked={tap.value === sort ? true : false}
                                onChange={() => listChange(tap?.value)}
                              />
                              <label
                                className="btn btn-success"
                                htmlFor={`btnRadio${tap?.value}`}
                              >
                                {tap?.title}
                              </label>
                            </Fragment>
                          ))}
                        </div>
                      </Col>
                      <Col sm="4">
                        <div className="search-box ms-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <AvForm>
                              <AvField
                                name="searchText"
                                placeholder="Search"
                                type="text"
                                onChange={e =>
                                  debounceOrderSearch(e.target.value)
                                }
                              />
                            </AvForm>
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>
                      </Col>
                      <Col sm="4">
                        <div className="text-sm-end">
                          <Link to="/promoter/create/new">
                            <Button
                              type="button"
                              color="success"
                              className="btn-rounded  mb-2 me-2"
                            >
                              <i className="mdi mdi-plus me-1" />
                              Create Promoter
                            </Button>
                          </Link>
                        </div>
                      </Col>
                    </Row>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        {allPromoters?.promoters ? (
                          <>
                            <Row>
                              <p>Total :&nbsp;{allPromoters?.total} </p>

                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={'id'}
                                    key={'id'}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    // selectRow={selectRow}
                                    classes={
                                      'table align-middle table-nowrap floating-action'
                                    }
                                    headerWrapperClasses={'thead-light'}
                                    {...toolkitProps.baseProps}
                                    // onTableChange={handleTableChange}
                                    // {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <MyPagination
                              totalPages={pages}
                              page={page}
                              setPage={setPage}
                            />
                          </>
                        ) : (
                          <p className="my-4 mx-3 ">No Promoters !</p>
                        )}
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Promoters
