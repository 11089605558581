import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAIL,
  GET_NOTIFICATION_DETAILS,
  GET_NOTIFICATION_DETAILS_SUCCESS,
  GET_NOTIFICATION_DETAILS_FAIL,
  CREATE_NOTIFICATION,
  CREATE_NOTIFICATION_SUCCESS,
  CREATE_NOTIFICATION_FAIL,
  UPDATE_NOTIFICATION,
  UPDATE_NOTIFICATION_SUCCESS,
  UPDATE_NOTIFICATION_FAIL,
  DELETE_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION_FAIL,
  DELETE_NOTIFICATION,
} from './actionTypes'

// Notification - This line cannot be edited or removed
export const getNotifications = (page, limit, searchText, sort) => ({
  type: GET_NOTIFICATIONS,
  payload: { page, limit, searchText, sort },
})

export const getNotificationsSuccess = notifications => ({
  type: GET_NOTIFICATIONS_SUCCESS,
  payload: notifications,
})

export const getNotificationsFail = error => ({
  type: GET_NOTIFICATIONS_FAIL,
  payload: error,
})

export const getNotificationDetails = notificationId => ({
  type: GET_NOTIFICATION_DETAILS,
  payload: notificationId,
})

export const getNotificationDetailsSuccess = notificationDetails => ({
  type: GET_NOTIFICATION_DETAILS_SUCCESS,
  payload: notificationDetails,
})

export const getNotificationDetailsFail = error => ({
  type: GET_NOTIFICATION_DETAILS_FAIL,
  payload: error,
})

export const createNotification = (notification, onCloseClick) => ({
  type: CREATE_NOTIFICATION,
  payload: { notification, onCloseClick },
})

export const createNotificationSuccess = notification => ({
  type: CREATE_NOTIFICATION_SUCCESS,
  payload: notification,
})

export const createNotificationFail = error => ({
  type: CREATE_NOTIFICATION_FAIL,
  payload: error,
})

export const getNotificationDetail = notificationId => ({
  type: GET_NOTIFICATION_DETAILS,
  payload: notificationId,
})

export const updateNotification = (
  notification,
  notificationId,
  onCloseClick,
) => ({
  type: UPDATE_NOTIFICATION,
  payload: { notification, notificationId, onCloseClick },
})

export const updateNotificationSuccess = notification => ({
  type: UPDATE_NOTIFICATION_SUCCESS,
  payload: notification,
})

export const updateNotificationFail = error => ({
  type: UPDATE_NOTIFICATION_FAIL,
  payload: error,
})

export const deleteNotification = notificationId => ({
  type: DELETE_NOTIFICATION,
  payload: { notificationId },
})

export const deleteNotificationSuccess = notification => ({
  type: DELETE_NOTIFICATION_SUCCESS,
  payload: notification,
})

export const deleteNotificationFail = error => ({
  type: DELETE_NOTIFICATION_FAIL,
  payload: error,
})
