import { AvForm } from 'availity-reactstrap-validation'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { useKeyPress } from 'hooks/useKeyPress'

import { Badge, Button, Card, CardBody, Col, Container } from 'reactstrap'
import { Row } from 'reactstrap'

//actions
import { getSupplyChainDetails, updateSupplyChain } from 'store/actions'
import MetaTag from 'components/Common/Meta-tag'
import Form from './Form'
import ChainPromoters from 'pages/ChainPromoter/DatatableTables'
import BreadcrumbPremium from 'components/Common/Breadcrumb2'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { CurrencySwitch } from 'hooks/Currency'
import flowChart from 'assets/images/other/flowChart.png'
import { defaultImg } from 'assets/images'

const UpdateSupplyChain = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams()
  const currency = CurrencySwitch()

  const { loading, supplyChainsDetails } = useSelector(state => ({
    supplyChainsDetails: state.SupplyChain.supplyChainsDetails,
    loading: state.SupplyChain.loading,
  }))

  useEffect(() => {
    dispatch(getSupplyChainDetails(params?.id))
  }, [dispatch, params?.id])

  const [productSelect, setProductSelect] = useState({})

  function handleValidSubmit(values) {
    const updateSupplyChainData = {
      ...values,
      product: productSelect.id,
      joiningBonus: parseInt(values?.joiningBonus),
      distributionBonus: parseInt(values?.distributionBonus),
      levelLimit: parseInt(values?.levelLimit),
      totalDistributionAmount: parseInt(values?.totalDistributionAmount),
    }
    dispatch(
      updateSupplyChain(
        updateSupplyChainData,
        supplyChainsDetails?._id,
        history,
      ),
    )
  }

  useEffect(() => {
    setProductSelect({
      id: supplyChainsDetails?.product?._id,
      title: supplyChainsDetails?.product?.primaryLang?.name,
    })
  }, [supplyChainsDetails])

  const [form, setForm] = useState(false)
  const [myCombination, setMyCombination] = useState('')

  const onKeyPress = event => {
    setMyCombination(myCombination + event.key)
  }

  useKeyPress(['Control', 'Shift', 'o', 's', 'p', 'e', 'r', 'b'], onKeyPress)

  useEffect(() => {
    if (myCombination === 'ControlShiftosperb') {
      setForm(true)
      setMyCombination('')
    }
  }, [myCombination])

  return (
    <>
      <MetaTag title={'supplyChain'} />

      <div className="page-content">
        <BreadcrumbPremium
          root={[
            '/promoter-dashboard',
            '/supply-chain',
            supplyChainsDetails?.chainId,
          ]}
        />

        <Container fluid>
          <div className="container-fluid">
            {form === true && (
              <Row>
                <Col lg={12} md={12}>
                  <AvForm
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(v)
                    }}
                  >
                    <Card>
                      <CardBody>
                        <Form
                          productSelect={productSelect}
                          setProductSelect={setProductSelect}
                          updateData={supplyChainsDetails}
                        />

                        <div className="d-flex align-items-center justify-content-end">
                          <button
                            type="submit"
                            className="w-fit  mt-3 btn btn-success save-customer"
                          >
                            {loading && (
                              <>
                                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                              </>
                            )}
                            Update Supply Chain
                          </button>
                        </div>
                      </CardBody>
                    </Card>
                  </AvForm>
                </Col>
              </Row>
            )}
            <div>
              <Row>
                <Col sm={6}>
                  <Card className="shadow mb-4 bg-white rounded card-supply-chain">
                    <CardBody className="">
                      <div>
                        <div className="d-flex align-items-center justify-content-between mb-3">
                          <h6 className="chain-details mb-0">
                            Supply Chain Details
                          </h6>
                          <Link
                            to={`/product/update/${supplyChainsDetails?.product?._id}`}
                          >
                            &nbsp;
                          </Link>
                        </div>
                        <Row>
                          <Col className="" sm={6}>
                            <div
                              className="d-flex align-items-center justify-content-between mb-2"
                              style={{ width: '200px' }}
                            >
                              <p className="m-0 p-0" style={{ width: '80px' }}>
                                Chain Id{' '}
                              </p>
                              :
                              <h6
                                className="m-0 p-0"
                                style={{
                                  textTransform: 'capitalize',
                                  width: '80px',
                                }}
                              >
                                {supplyChainsDetails?.chainId}
                              </h6>
                            </div>
                            <div
                              className="d-flex align-items-center justify-content-between mb-2"
                              style={{ width: '200px' }}
                            >
                              <p className="m-0 p-0" style={{ width: '80px' }}>
                                Created At{' '}
                              </p>
                              :
                              <h6
                                className="m-0 p-0"
                                style={{
                                  textTransform: 'capitalize',
                                }}
                              >
                                {moment(supplyChainsDetails?.createdAt).format(
                                  'll',
                                )}
                              </h6>
                            </div>
                            <div
                              className="d-flex align-items-center justify-content-between mb-2"
                              style={{ width: '200px' }}
                            >
                              <p className="m-0 p-0" style={{ width: '80px' }}>
                                Level Limit
                              </p>
                              :
                              <h6
                                className="m-0 p-0"
                                style={{
                                  textTransform: 'capitalize',
                                  width: '80px',
                                }}
                              >
                                {supplyChainsDetails?.levelLimit}
                              </h6>
                            </div>
                          </Col>

                          <Col className="" sm={6}>
                            <div
                              className="d-flex align-items-center justify-content-between mb-2"
                              style={{ width: '250px' }}
                            >
                              <p className="m-0 p-0" style={{ width: '130px' }}>
                                Distribution Bonus
                              </p>
                              :
                              <h6
                                className="m-0 p-0"
                                style={{
                                  textTransform: 'capitalize',
                                  width: '80px',
                                }}
                              >
                                {supplyChainsDetails?.distributionBonus}
                              </h6>
                            </div>
                            <div
                              className="d-flex align-items-center justify-content-between mb-2"
                              style={{ width: '250px' }}
                            >
                              <p className="m-0 p-0" style={{ width: '130px' }}>
                                joining Bonus
                              </p>
                              :
                              <h6
                                className="m-0 p-0"
                                style={{
                                  textTransform: 'capitalize',
                                  width: '80px',
                                }}
                              >
                                {supplyChainsDetails?.joiningBonus}
                              </h6>
                            </div>
                            <div
                              className="d-flex align-items-center justify-content-between mb-2"
                              style={{ width: '250px' }}
                            >
                              <p className="m-0 p-0" style={{ width: '130px' }}>
                                Distribution Amount
                              </p>
                              :
                              <h6
                                className="m-0 p-0"
                                style={{
                                  textTransform: 'capitalize',
                                  width: '80px',
                                }}
                              >
                                {supplyChainsDetails?.totalDistributionAmount}
                              </h6>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col sm={3}>
                  <Card className="shadow mb-4 bg-white rounded card-supply-chain">
                    <CardBody>
                      <div>
                        <div className="d-flex align-items-center justify-content-between mb-3">
                          <h6 className="chain-details mb-0">
                            Product Details
                          </h6>
                          <Link
                            to={`/product/update/${supplyChainsDetails?.product?._id}`}
                          >
                            <Badge className="badge-soft-success p-2 px-2">
                              View more
                            </Badge>
                          </Link>
                        </div>
                        <div className="d-flex algin-items-center justify-content-between mt-2 mb-2">
                          <div>
                            <p className="m-0 p-0">
                              {supplyChainsDetails?.product?.primaryLang?.name}
                            </p>
                            <h6 className="font-size-16">
                              {supplyChainsDetails?.product?.offerPrice ? (
                                <>
                                  <span className="text-muted font-size-14">
                                    <del>
                                      {' '}
                                      {supplyChainsDetails?.product?.price}
                                    </del>
                                  </span>
                                  &nbsp;
                                  {currency}{' '}
                                  {supplyChainsDetails?.product?.offerPrice}
                                </>
                              ) : (
                                <>
                                  {currency}{' '}
                                  {supplyChainsDetails?.product?.price}
                                </>
                              )}
                            </h6>
                            <p className="mb-2">
                              stock : {supplyChainsDetails?.product?.stock}
                            </p>
                          </div>
                          <div>
                            <img
                              src={
                                supplyChainsDetails?.product &&
                                supplyChainsDetails?.product?.images
                                  ? supplyChainsDetails?.product?.images[0]?.url
                                  : defaultImg
                              }
                              alt={'product images'}
                              className="avatar-md rounded"
                            />
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col sm={3}>
                  <Card>
                    <CardBody
                      className="position-relative d-flex flex-column justify-content-between"
                      style={{ minHeight: '165px' }}
                    >
                      <div style={{ maxWidth: '150px' }}>
                        <p>Visualize Chain</p>
                        <span className="text-grey font-size-12">
                          you can visualize the virtual supply chain{' '}
                        </span>
                      </div>
                      <img
                        src={flowChart}
                        className="avatar-md rounded"
                        style={{
                          position: 'absolute',
                          top: 10,
                          right: 30,
                        }}
                      />
                      <div>
                        <Link
                          to={`/supply-chain-tree/${supplyChainsDetails?._id}`}
                        >
                          <Button
                            type="button"
                            color=""
                            className="btn btn-rounded btn-sm w-100 btn-outline-success me-2"
                          >
                            Visualize
                          </Button>
                        </Link>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
            <h6 className="mb-3">Promoters of this supply chain</h6>
            <ChainPromoters supplyChainId={params?.id} />
          </div>
        </Container>
      </div>
    </>
  )
}

export default UpdateSupplyChain
