import React from 'react'
import { Container } from 'reactstrap'

import MetaTag from 'components/Common/Meta-tag'
import BreadcrumbPremium from 'components/Common/Breadcrumb2'
import WithdrawalList from './DatatableTables'

function WithdrawalLists() {
  return (
    <>
      <MetaTag title={'All Withdrawal Lists '} />
      <div className="page-content">
        <BreadcrumbPremium
          root={['/promoter-dashboard', '/Wallet-Withdrawal-List']}
        />
        <Container fluid>
          <div className="container-fluid">
            <WithdrawalList />
          </div>
        </Container>
      </div>
    </>
  )
}

export default WithdrawalLists
