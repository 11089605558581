import React from 'react'
import { Container } from 'reactstrap'

import Breadcrumbs from '../../components/Common/Breadcrumb'
import DatatableTables from './DatatableTables'
import MetaTag from 'components/Common/Meta-tag'

function ProductPages() {
  return (
    <>
      <MetaTag title={'All Pages'} />

      <div className="page-content">
        <Breadcrumbs title="Dashboard" breadcrumbItem="All Pages" />
        <Container fluid>
          <div className="container-fluid">
            <DatatableTables />
          </div>
        </Container>
      </div>
    </>
  )
}

export default ProductPages
