import { AvForm } from 'availity-reactstrap-validation'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Modal, ModalBody, Row } from 'reactstrap'
import { createLuckyDraw, updateLuckyDraw } from 'store/actions'
import LuckyDrawForm from './Form'
import { useHistory } from 'react-router-dom'

const LuckyDrawModal = ({ show, onCloseClick, updateData }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { loading } = useSelector(state => ({
    loading: state.SupplyChain.loading,
  }))
  const [chainSelect, setChainSelect] = useState('')

  function handleValidSubmit(values) {
    const createLuckyDrawData = {
      ...values,
      supplyChain: chainSelect,
      limit: parseInt(values?.limit),
      skip: parseInt(values?.skip),
    }

    if (updateData !== null) {
      dispatch(
        updateLuckyDraw(
          createLuckyDrawData,
          updateData?._id,
          onCloseClick,
          history,
        ),
      )
    } else {
      dispatch(createLuckyDraw(createLuckyDrawData, onCloseClick))
    }
  }

  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      centered={true}
      style={deleteModalStyle}
    >
      <ModalBody style={deleteModalBodyStyle} className="p-4">
        <AvForm
          onValidSubmit={(e, v) => {
            handleValidSubmit(v)
          }}
        >
          <Row xl="12">
            <Col xl="12">
              <LuckyDrawForm
                chainSelect={chainSelect}
                setChainSelect={setChainSelect}
                updateData={updateData}
              />
              <button
                type="submit"
                className="col-12 mt-3 btn btn-success save-customer"
              >
                {loading && (
                  <>
                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                  </>
                )}
                {updateData ? 'Update Lucky Draw' : 'Create LuckyDraw'}
              </button>
            </Col>
          </Row>
        </AvForm>
      </ModalBody>
    </Modal>
  )
}

LuckyDrawModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
  updateData: PropTypes.any,
}

export default LuckyDrawModal

const deleteModalStyle = {
  width: 600,
  minWidth: 600,
}
const deleteModalBodyStyle = {
  minHeight: 270,
}
