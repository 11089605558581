import React, { useEffect, useState } from 'react'
import { Col, FormGroup, Row } from 'reactstrap'
import Select from 'react-select'
import { debounce } from 'lodash'
import PropTypes from 'prop-types'
import { AvField } from 'availity-reactstrap-validation'
import { useDispatch, useSelector } from 'react-redux'
import { getAllSupplyChains } from 'store/actions'

function LuckyDrawForm({ setChainSelect, updateData, chainSelect }) {
  const dispatch = useDispatch()

  const { proLoading } = useSelector(state => ({
    proLoading: state.SupplyChain.loading,
  }))

  //product dropdown
  const [chainSearch, setChainSearch] = useState()
  const [chainName, setChainName] = useState('select chainId..')

  useEffect(() => {
    dispatch(getAllSupplyChains(1, 10, chainSearch))
  }, [dispatch, chainSearch])

  const { SupplyChains } = useSelector(state => ({
    SupplyChains: state.SupplyChain.supplyChains,
  }))
  const debounceChainSearch = debounce(value => setChainSearch(value), 600)

  const handleChainEnters = textEntered => {
    debounceChainSearch(textEntered)
  }

  function handlerChainFinalValue(event) {
    setChainName(event.label)
    setChainSelect(event.value)
  }

  const supplyChainsOptions = SupplyChains?.supplyChains?.map(
    (result, index) => {
      return {
        key: index,
        label: `${result?.chainId} - ${result?.product?.primaryLang?.name}`,
        value: result?._id,
      }
    },
  )
  useEffect(() => {
    if (updateData) {
      setChainName(updateData?.supplyChain?.chainId || 'select chainId..')
      setChainSelect(updateData?.supplyChain?._id || null)
    }
  }, [updateData])

  // console.log(updateData)

  //end of product dropdown

  return (
    <>
      <Row>
        <Col md="12">
          <p className="p text-muted mb-2">Supply Chain</p>
          <FormGroup className="mb-3">
            <div className="ajax-selectmt-lg-0 select2-container">
              <Select
                onInputChange={handleChainEnters}
                value={chainSelect}
                placeholder={chainName}
                onChange={handlerChainFinalValue}
                options={supplyChainsOptions}
                classNamePrefix="select2-selection"
                isLoading={proLoading}
              />
            </div>
          </FormGroup>
        </Col>
        <Col md="6">
          <AvField
            name="limit"
            label="Limit"
            type="number"
            validate={{
              min: {
                value: 2,
                errorMessage: 'value greater than 2 is required',
              },
            }}
            value={updateData?.limit}
          />
        </Col>
        <Col md="6">
          <AvField
            name="skip"
            label="Skip"
            className="mb-3"
            type="number"
            validate={{
              min: {
                value: 0,
                errorMessage: 'value must be positive integer',
              },
            }}
            value={updateData?.skip}
          />
        </Col>
      </Row>
    </>
  )
}

export default LuckyDrawForm

LuckyDrawForm.propTypes = {
  chainSelect: PropTypes.any,
  setChainSelect: PropTypes.func,
  updateData: PropTypes.any,
}
