import { call, put, takeEvery } from 'redux-saga/effects'

import {
  GET_SHOPS,
  GET_UNAPPROVED_SHOPS,
  GET_BANNED_SHOPS,
  CHANGE_SHOP_STATUS,
  GET_SHOP_DETAILS,
  CREATE_SHOP,
  UPDATE_SHOP,
  DELETE_SHOP,
} from './actionTypes'
import {
  getShopsSuccess,
  getShopsFail,
  getUnapprovedShopsSuccess,
  getUnapprovedShopsFail,
  getBannedShopsSuccess,
  getBannedShopsFail,
  changeShopStatusSuccess,
  changeShopStatusFail,
  getShopDetailsSuccess,
  getShopDetailsFail,
  createShopFail,
  createShopSuccess,
  updateShopSuccess,
  updateShopFail,
  deleteShopSuccess,
  deleteShopFail,
} from './actions'
import { get, post, ApiPut, del } from 'helpers/api_helper'
import { Notification } from '../../components/Common/Notification'

// function getZestonProductsAPi({ searchText, page }) {
//   if (searchText) {
//     return get(`/zestonProduct/all?search=${searchText && searchText}`)
//   } else {
//     return get(`/zestonProduct/all?page=${page ? page : 1}&limit=10`)
//   }
// }

function getShopsAPi({ page }) {
  return get(`/shop/all?page=${page ? page : 1}&limit=10`)
}

// function getShopsAPi() {
//   return get("/shop/all")
// }

function getUnapprovedShopsAPi({ page }) {
  return get(`/shop/unapproved/all?page=${page ? page : 1}&limit=10`)
}

function getBannedShopsAPi({ page }) {
  return get(`/shop/banned/all?page=${page ? page : 1}&limit=10`)
}

const changeShopStatusApi = ({ history }) => {
  return post(`/shop/status`, history)
}

const getShopDetailsAPi = shopId => {
  return get(`/shop/${shopId}`)
}

const createShopApi = ({ shop }) => {
  return post('/shop/new', shop)
}

const updateShopApi = ({ shop, shopId }) => {
  return ApiPut(`/shop/${shopId}`, shop)
}

const deleteShopApi = shopId => {
  return del(`/shop/${shopId}`)
}

function* fetchShops({ payload }) {
  try {
    const response = yield call(getShopsAPi, payload)
    yield put(getShopsSuccess(response))
  } catch (error) {
    yield put(getShopsFail(error))
  }
}

function* fetchUnapprovedShops({ payload }) {
  try {
    const response = yield call(getUnapprovedShopsAPi, payload)
    yield put(getUnapprovedShopsSuccess(response))
  } catch (error) {
    yield put(getUnapprovedShopsFail(error))
  }
}

function* fetchBannedShops({ payload }) {
  try {
    const response = yield call(getBannedShopsAPi, payload)
    yield put(getBannedShopsSuccess(response))
  } catch (error) {
    yield put(getBannedShopsFail(error))
  }
}

function* onChangeShopStatus({ payload }) {
  try {
    const response = yield call(changeShopStatusApi, payload)
    yield put(changeShopStatusSuccess(response))
    Notification({
      type: 'success',
      message: 'Shop Status Changed Successfully!',
      title: '',
    })
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: 'error',
        message: error.response?.data?.message,
        title: '',
      })
    }
    yield put(changeShopStatusFail(error))
  }
}

function* fetchShopDetail({ payload: shopId }) {
  try {
    const response = yield call(getShopDetailsAPi, shopId)
    yield put(getShopDetailsSuccess(response))
  } catch (error) {
    yield put(getShopDetailsFail(error))
  }
}

function* onCreateShop({ payload }) {
  try {
    const response = yield call(createShopApi, payload)
    yield put(createShopSuccess(response))
    Notification({
      type: 'success',
      message: 'Shop Created Successfully!',
      title: '',
    })
    payload.history.goBack()
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: 'error',
        message: error.response?.data?.message,
        title: '',
      })
    }
    yield put(createShopFail(error))
  }
}

function* onUpdateShop({ payload }) {
  try {
    const response = yield call(updateShopApi, payload)
    yield put(updateShopSuccess(response))
    Notification({
      type: 'success',
      message: 'Shop Updated Successfully!',
      title: '',
    })
    payload.history.goBack()
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: 'error',
        message: error.response?.data?.message,
        title: '',
      })
    }
    yield put(updateShopFail(error))
  }
}

function* onDeleteShop({ shopId, history }) {
  try {
    const response = yield call(deleteShopApi, shopId)
    yield put(deleteShopSuccess(response))
    Notification({
      type: 'success',

      message: 'Shop Deleted Successfully!',
      title: '',
    })
    history.goBack()
  } catch (error) {
    yield put(deleteShopFail(error))
  }
}

function* shopSaga() {
  yield takeEvery(GET_SHOPS, fetchShops)
  yield takeEvery(GET_UNAPPROVED_SHOPS, fetchUnapprovedShops)
  yield takeEvery(GET_BANNED_SHOPS, fetchBannedShops)
  yield takeEvery(CHANGE_SHOP_STATUS, onChangeShopStatus)
  yield takeEvery(GET_SHOP_DETAILS, fetchShopDetail)
  yield takeEvery(CREATE_SHOP, onCreateShop)
  yield takeEvery(UPDATE_SHOP, onUpdateShop)
  yield takeEvery(DELETE_SHOP, onDeleteShop)
}

export default shopSaga
