import {
  API_SUCCESS,
  API_FAIL,
  GET_CHARTS_DATA,
  GET_DASHBOARD_DATA,
  GET_DASHBOARD_DATA_SUCCESS,
  GET_DASHBOARD_DATA_FAIL,
  GET_PROMOTER_DASHBOARD_DATA,
  GET_PROMOTER_DASHBOARD_DATA_SUCCESS,
  GET_PROMOTER_DASHBOARD_DATA_FAIL,
} from './actionTypes'

export const apiSuccess = (actionType, data) => ({
  type: API_SUCCESS,
  payload: { actionType, data },
})

export const apiFail = (actionType, error) => ({
  type: API_FAIL,
  payload: { actionType, error },
})

// charts data
export const getChartsData = periodType => ({
  type: GET_CHARTS_DATA,
  payload: periodType,
})

//Dashboard Data

export const getDashboardData = () => ({
  type: GET_DASHBOARD_DATA,
})

export const getDashboardDataSuccess = dashboardData => ({
  type: GET_DASHBOARD_DATA_SUCCESS,
  payload: dashboardData,
})

export const getDashboardDataFail = error => ({
  type: GET_DASHBOARD_DATA_FAIL,
  payload: error,
})

// promoter dashboard data

export const getPromoterDashboardData = () => ({
  type: GET_PROMOTER_DASHBOARD_DATA,
})

export const getPromoterDashboardDataSuccess = promoterDashboardData => ({
  type: GET_PROMOTER_DASHBOARD_DATA_SUCCESS,
  payload: promoterDashboardData,
})

export const getPromoterDashboardDataFail = error => ({
  type: GET_PROMOTER_DASHBOARD_DATA_FAIL,
  payload: error,
})
