import React, { Fragment, useEffect, useState } from 'react'
import {
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  UncontrolledTooltip,
  FormGroup,
} from 'reactstrap'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// data-table related plugins
import BootstrapTable from 'react-bootstrap-table-next'
import { debounce, map, range } from 'lodash'
import Select from 'react-select'

import ToolkitProvider from 'react-bootstrap-table2-toolkit'

//actions
import {
  getAllProducts,
  getCourseProviderCourses,
  getCourseProviders,
  getCourseRegistrations,
  getOrders,
  // updateCourseRegistration,
} from 'store/actions'

import '../../assets/scss/data-tables.scss'

import MyPagination from 'components/Common/MyPagination'
// import DeleteModal from 'components/Common/DeleteModal'
// import CourseCrud from './Crud/CourseProviderCrud'
import { Markup } from 'interweave'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import moment from 'moment'

const CourseRegistration = () => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [searchText, setSearchText] = useState('')

  const {
    loading,
    courseRegistrations,
    courseProviders,
    providerLoading,
    orders,
    orderLoading,
    products,
    productLoading,
    courseProviderCourses,
  } = useSelector(state => ({
    loading: state.CourseRegistration.loading,
    courseRegistrations: state.CourseRegistration.courseRegistrations,
    courseProviders: state.CourseProvider.courseProviders,
    providerLoading: state.CourseProvider.loading,
    orders: state?.Orders?.orders,
    orderLoading: state?.Orders?.loading,
    products: state.Products.products,
    productLoading: state.Products.loading,
    courseProviderCourses:
      state.CourseRegistration.courseProviderCourses?.products,
  }))

  const role = sessionStorage.getItem('role')

  const [courseProviderSelect, setCourseProviderSelect] = useState({
    id: '',
    name: 'select course provider',
  })

  const [providerSearch, setProviderSearch] = useState('')

  const debounceProviderSearch = debounce(
    value => setProviderSearch(value),
    600,
  )
  const handleProviderEnters = textEntered => {
    debounceProviderSearch(textEntered)
  }

  function handlerProviderFinalValue(event) {
    const provider = {
      ...courseProviderSelect,
      name: event.label,
      id: event.value,
    }
    setCourseProviderSelect(provider)
  }

  const providerOptions = [
    {
      label: 'All Providers',
      value: '',
    },
    {
      options: courseProviders?.courseProviders?.map((result, index) => ({
        key: index,
        label: result?.name,
        value: result?._id,
      })),
    },
  ]

  useEffect(() => {
    if (role === 'admin') {
      dispatch(getCourseProviders(1, '', 10, providerSearch))
    }
  }, [providerSearch])

  const [orderSelect, setOrderSelect] = useState({
    id: '',
    orderId: 'select order',
  })

  const [orderSearch, setOrderSearch] = useState('')

  const debounceOrderSearch = debounce(value => setOrderSearch(value), 600)
  const handleOrderEnters = textEntered => {
    debounceOrderSearch(textEntered)
  }

  function handlerOrderFinalValue(event) {
    const order = {
      ...orderSelect,
      orderId: event.label,
      id: event.value,
    }
    setOrderSelect(order)
  }

  const orderOptions = [
    {
      label: 'All Orders',
      value: '',
    },
    {
      options: (orders && orders?.length >= 1 ? orders : [])?.map(
        (result, index) => ({
          key: index,
          label: result?.orderId,
          value: result?._id,
        }),
      ),
    },
  ]

  useEffect(() => {
    if (role === 'admin') {
      dispatch(getOrders(1, 30, orderSearch, 'course', ''))
    }
  }, [dispatch, orderSearch])

  const [productSelect, setProductSelect] = useState({
    id: '',
    name: role === 'admin' ? 'select product' : 'select course',
  })

  const [productSearch, setProductSearch] = useState('')

  const debounceProductSearch = debounce(value => setProductSearch(value), 600)
  const handleProductEnters = textEntered => {
    debounceProductSearch(textEntered)
  }

  function handlerProductFinalValue(event) {
    const product = {
      ...productSelect,
      name: event.label,
      id: event.value,
    }
    setProductSelect(product)
  }

  const handleProductData = () => {
    switch (role) {
      case 'admin':
        return products
      case 'courseProvider':
        return courseProviderCourses
      default:
        break
    }
  }

  const productOptions = [
    {
      label: 'All Products',
      value: '',
    },
    {
      options: (handleProductData() && handleProductData()?.length >= 1
        ? handleProductData()
        : []
      )?.map((result, index) => ({
        key: index,
        label: result?.primaryLang?.name,
        value: result?._id,
      })),
    },
  ]

  useEffect(() => {
    if (role === 'admin') {
      dispatch(getAllProducts(1, '', 10, productSearch))
    } else {
      dispatch(getCourseProviderCourses(1, '', 10, productSearch))
    }
  }, [dispatch, productSearch])

  const totalPages = Math.ceil(courseRegistrations?.total / 10)
  const pages = range(1, totalPages + 1)

  const [sort, setSort] = useState('')
  // const [status, setStatus] = useState()

  const [finalDateFormatted, setFinalDateFormatted] = useState({
    from: '',
    to: '',
  })

  const handleDateSearch = () => {
    if (
      finalDateFormatted?.from?.length >= 1 &&
      finalDateFormatted?.to?.length >= 1
    ) {
      return finalDateFormatted
    } else {
      return {
        from: '',
        to: '',
      }
    }
  }

  useEffect(() => {
    dispatch(
      getCourseRegistrations(
        page,
        sort,
        10,
        searchText,
        handleDateSearch(),
        courseProviderSelect?.id,
        orderSelect?.id,
        productSelect?.id,
      ),
    )
  }, [
    dispatch,
    page,
    sort,
    searchText,
    finalDateFormatted,
    courseProviderSelect,
    orderSelect,
    productSelect,
  ])

  // const handleStatusChange = (v, id) => {
  //   setStatus(v)
  //   dispatch(
  //     updateCourseRegistration(
  //       {
  //         status: status ? 'registered' : 'processing',
  //       },
  //       id,
  //     ),
  //   )
  // }

  // const [isOpen, setIsOpen] = useState({ status: false, id: '' })

  // const handleDelete = id => {
  //   setIsOpen({ status: true, id: id })
  // }

  // const handleDeleteEvent = () => {
  //   setIsOpen({ ...isOpen, status: false })
  // }

  const columns = [
    {
      dataField: 'createdAt',
      text: 'Date',
      sort: true,
    },
    {
      dataField: 'order',
      text: 'Order',
      sort: true,
    },
    {
      dataField: 'product.primaryLang.name',
      text: 'Product',
      sort: true,
    },
    {
      dataField: role === 'admin' && 'courseProvider.name',
      text: role === 'admin' && 'Course Provider',
      sort: true,
    },
    {
      dataField: 'name',
      text: 'Name',
      sort: true,
    },
    // {
    //   dataField: 'email',
    //   text: 'Email',
    //   sort: true,
    // },
    // {
    //   dataField: "imageData",
    //   filterText: "image",
    //   text: "Image",
    //   sort: true,
    // },

    {
      dataField: 'phone',
      text: 'Phone',
      sort: true,
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
    },

    // {
    //   dataField: "activeData",
    //   text: "Status",
    // },
    // {
    //   dataField: "isPublished",
    //   text: "Published",
    // },
    // {
    //   dataField: "isHighlighted",
    //   text: "Type",
    // },

    {
      dataField: 'action',
      text: 'Action',
    },
  ]

  const tableData = map(
    courseRegistrations?.courseRegistrations,
    (item, index) => ({
      ...item,
      no: index,
      order:
        role === 'admin' ? (
          <Link to={`/order/${item?.order?._id}`}>{item?.order?.orderId}</Link>
        ) : (
          <p className="m-0 text-primary">{item?.order?.orderId}</p>
        ),
      status: (
        <p
          className={`${
            item?.status === 'registered' ? 'text-success' : 'text-info'
          } m-0`}
        >
          {item?.status === 'registered' ? 'Registered' : 'Processing'}
        </p>
        // <>
        //   <div
        //     className="form-check form-switch form-switch-md me-4 d-flex justify-content-between p-0"
        //     style={{ minWidth: '100px' }}
        //   >
        //     <label
        //       className={`form-check-label ${
        //         item?.status === 'registered' ? 'text-success' : 'text-info'
        //       }`}
        //       htmlFor="statusSwitch"
        //     >
        //       {item?.status === 'registered' ? 'Registered' : 'Processing'}
        //     </label>
        //     <input
        //       type="checkbox"
        //       className={
        //         'form-check-input ' +
        //         `${item?.status === 'registered' && 'bg-success'}`
        //       }
        //       id="statusSwitch"
        //       style={{
        //         border: item?.status === 'registered' && 'none',
        //       }}
        //       onChange={() => {
        //         handleStatusChange(
        //           item?.status === 'registered' ? false : true,
        //           item?._id,
        //         )
        //       }}
        //       checked={item?.status === 'registered'}
        //     />
        //   </div>
        // </>
      ),
      // imageData: (
      //   <>
      //     <img
      //       src={
      //         item?.images && item?.images[0] ? item?.images[0]?.url : defaultImg
      //       }
      //       width="50px"
      //     />
      //   </>
      // ),
      // title: (
      //   <div className="d-flex algin-items-center text-start   justify-content-start ">
      //     <p className="mb-0 mt-1">{item?.title}</p>
      //     {sort === 'all' && (
      //       <>
      //         {item?.isPublished && (
      //           <Badge
      //             className={'font-size-11 mx-2 p-2 badge-soft-success '}
      //             style={{
      //               width: 'fit-content',
      //             }}
      //             pill
      //           >
      //             Published
      //           </Badge>
      //         )}
      //         {item?.isHighlighted && (
      //           <Badge
      //             className={'font-size-11 me-2 p-2 badge-soft-info  '}
      //             style={{
      //               width: 'fit-content',
      //             }}
      //             pill
      //           >
      //             Highlighted
      //           </Badge>
      //         )}
      //       </>
      //     )}
      //   </div>
      // ),
      content: (
        <div
          className="text-break-spaces position-relative"
          style={{ maxWidth: 350 }}
        >
          <Markup content={item?.content?.slice(0, 140)} className="markup" />
          <span
            style={{
              position: 'absolute',
              bottom: 0,
              right: 100,
            }}
          >
            {' '}
            {item?.content?.length > 140 ? '...' : ''}
          </span>
        </div>
      ),

      action: (
        <div>
          <Link
            to={`/courseRegistrations/${item?._id}`}
            className="btn-md me-3"
            id={'view'}
          >
            <i
              className="mdi mdi-eye"
              style={{ color: 'green', fontSize: '20px' }}
            />
          </Link>
          <UncontrolledTooltip
            id="downloadTip"
            target={'view'}
            placement="bottom"
          >
            View
          </UncontrolledTooltip>
          {/* <Link
            to={{ pathname: `/courseForm/update/${item?._id}` }}
            className="btn-md"
           
          >
            <i className="bx bx-edit me-2 " style={{ fontSize: '1.3rem' }} />
          </Link> */}
          {/* <Button
            id={`Delete${index}`}
            type="button"
            color="white"
            className="ms-1 btn "
            onClick={() => {
              handleDelete(item._id)
            }}
          >
            <i
              className="bx bx-trash me-2"
              style={{ color: 'red', marginBottom: '10px', fontSize: '1.3rem' }}
            />
          </Button> */}
        </div>
      ),
      createdAt: moment(item.createdAt).format('lll'),
    }),
  )

  const debounceBlogSearch = debounce(value => setSearchText(value), 600)

  const listChange = sortValue => {
    setSort(sortValue)
  }

  const headerTap = [
    {
      value: '',
      title: 'All',
    },

    {
      value: 'processing',
      title: 'Processing',
    },
    {
      value: 'registered',
      title: 'Registered ',
    },
  ]

  return (
    <React.Fragment>
      <Row>
        {/* <DeleteModal
          show={isOpen?.status}
          onCloseClick={() => setIsOpen({ data: {}, status: false })}
          onDeleteClick={handleDeleteEvent}
        /> */}
        <Col className="col-12">
          <Card>
            <CardBody>
              <ToolkitProvider
                keyField="_id"
                key={'id'}
                columns={columns}
                data={tableData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    {/* <FormGroup className="mb-2">
                      <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                        <Col md="4">
                          <Select
                            onInputChange={handleProductEnters}
                            value={productSelect?.name}
                            placeholder={productSelect?.name}
                            onChange={handlerProductFinalValue}
                            options={productOptions}
                            classNamePrefix="select2-selection"
                            isLoading={productLoading}
                          />
                        </Col>
                      </div>
                    </FormGroup> */}
                    <Row className="mb-2">
                      <Col md="3">
                        <div
                          className="btn-group mt-xl-0"
                          role="group"
                          aria-label="Basic radio toggle button group"
                        >
                          {headerTap?.map((tap, key) => (
                            <Fragment key={key}>
                              <input
                                type="radio"
                                className="btn-check"
                                name="btnRadio"
                                id={`btnRadio${tap?.value}`}
                                autoComplete="off"
                                checked={tap.value === sort ? true : false}
                                onChange={() => listChange(tap?.value)}
                              />
                              <label
                                className="btn btn-success"
                                htmlFor={`btnRadio${tap?.value}`}
                              >
                                {tap?.title}
                              </label>
                            </Fragment>
                          ))}
                        </div>
                      </Col>
                      {role === 'admin' && (
                        <>
                          <Col md="3">
                            <FormGroup className="mb-2">
                              <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                                <Select
                                  onInputChange={handleProviderEnters}
                                  value={courseProviderSelect?.name}
                                  placeholder={courseProviderSelect?.name}
                                  onChange={handlerProviderFinalValue}
                                  options={providerOptions}
                                  classNamePrefix="select2-selection"
                                  isLoading={providerLoading}
                                />
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup className="mb-2">
                              <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                                <Select
                                  onInputChange={handleOrderEnters}
                                  value={orderSelect?.orderId}
                                  placeholder={orderSelect?.orderId}
                                  onChange={handlerOrderFinalValue}
                                  options={orderOptions}
                                  classNamePrefix="select2-selection"
                                  isLoading={orderLoading}
                                />
                              </div>
                            </FormGroup>
                          </Col>
                        </>
                      )}
                      <Col md="3">
                        <FormGroup className="mb-2">
                          <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                            <Select
                              onInputChange={handleProductEnters}
                              value={productSelect?.name}
                              placeholder={productSelect?.name}
                              onChange={handlerProductFinalValue}
                              options={productOptions}
                              classNamePrefix="select2-selection"
                              isLoading={productLoading}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={3}>
                        <div className="search-box w-100 ms-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <AvForm>
                              <AvField
                                name="searchText"
                                placeholder="Search"
                                type="text"
                                onChange={e =>
                                  debounceBlogSearch(e.target.value)
                                }
                              />
                            </AvForm>
                            {/* <SearchBar {...toolkitProps.searchProps} /> */}
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="d-flex sortWrapper mx-2 me-3 mb-3 align-items-center ">
                          <p
                            className="mb-0 pb-0 mx-1"
                            style={{ whiteSpace: 'nowrap' }}
                          >
                            Sort By Date :
                          </p>
                          <AvForm>
                            <div className="d-flex date_wrapper">
                              <div className="d-flex align-items-start position-relative">
                                <AvField
                                  name="dateRange"
                                  type="date"
                                  validate={{
                                    dateRange: {
                                      start: { value: -5, units: 'years' },
                                      end: { value: 5, units: 'years' },
                                    },
                                  }}
                                  onChange={e =>
                                    setFinalDateFormatted({
                                      ...finalDateFormatted,
                                      from: e.target.value,
                                    })
                                  }
                                  value={finalDateFormatted?.from}
                                />
                                <button
                                  onClick={() =>
                                    setFinalDateFormatted({
                                      ...finalDateFormatted,
                                      from: '',
                                    })
                                  }
                                  type="button"
                                  className="btn btn-white editable-cancel date_close btn-sm"
                                >
                                  <i className="mdi mdi-close text-danger "></i>
                                </button>
                              </div>
                              <div className="d-flex align-items-start position-relative">
                                <AvField
                                  name="dateRange"
                                  className="mx-1"
                                  type="date"
                                  validate={{
                                    dateRange: {
                                      start: { value: -5, units: 'years' },
                                      end: { value: 5, units: 'years' },
                                    },
                                  }}
                                  onChange={e =>
                                    setFinalDateFormatted({
                                      ...finalDateFormatted,
                                      to: e.target.value,
                                    })
                                  }
                                  value={finalDateFormatted?.to}
                                />
                                <button
                                  onClick={() =>
                                    setFinalDateFormatted({
                                      ...finalDateFormatted,
                                      to: '',
                                    })
                                  }
                                  type="button"
                                  className="btn btn-white editable-cancel date_close btn-sm"
                                >
                                  <i className="mdi mdi-close text-danger "></i>
                                </button>
                              </div>
                            </div>
                          </AvForm>
                        </div>
                      </Col>
                    </Row>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        {courseRegistrations?.total >= 1 ? (
                          <>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={'id'}
                                    key={'id'}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    // selectRow={selectRow}
                                    classes={'table align-middle table-nowrap'}
                                    headerWrapperClasses={'thead-light'}
                                    {...toolkitProps.baseProps}
                                    // onTableChange={handleTableChange}
                                    // {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <MyPagination
                              totalPages={pages}
                              page={page}
                              setPage={setPage}
                            />
                          </>
                        ) : (
                          <p className="my-4 mx-3 ">
                            No Course Registrations !
                          </p>
                        )}
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default CourseRegistration
