import {
  GET_IDEALSPLY_WALLET_WITHDRAWALLISTS,
  GET_IDEALSPLY_WALLET_WITHDRAWALLISTS_SUCCESS,
  GET_IDEALSPLY_WALLET_WITHDRAWALLISTS_FAIL,
  GET_IDEALSPLY_WALLET_WITHDRAWALLIST_DETAILS,
  GET_IDEALSPLY_WALLET_WITHDRAWALLIST_DETAILS_SUCCESS,
  GET_IDEALSPLY_WALLET_WITHDRAWALLIST_DETAILS_FAIL,
  CREATE_IDEALSPLY_WALLET_WITHDRAWALLIST,
  CREATE_IDEALSPLY_WALLET_WITHDRAWALLIST_SUCCESS,
  CREATE_IDEALSPLY_WALLET_WITHDRAWALLIST_FAIL,
  UPDATE_IDEALSPLY_WALLET_WITHDRAWALLIST,
  UPDATE_IDEALSPLY_WALLET_WITHDRAWALLIST_SUCCESS,
  UPDATE_IDEALSPLY_WALLET_WITHDRAWALLIST_FAIL,
  DELETE_IDEALSPLY_WALLET_WITHDRAWALLIST,
  DELETE_IDEALSPLY_WALLET_WITHDRAWALLIST_SUCCESS,
  DELETE_IDEALSPLY_WALLET_WITHDRAWALLIST_FAIL,
} from './actionTypes'

const INIT_STATE = {
  idealsplyWalletWithdrawalLists: {
    idealsplyWalletWithdrawalLists: [],
    total: '',
    page: '',
  },
  idealsplyWalletWithdrawalListDetails: {},
  error: {},
  loading: false,
}

const IdealsplyWalletWithdrawalList = (state = INIT_STATE, action) => {
  switch (action.type) {
    // WithdrawalList - This line cannot be edited or removed
    case GET_IDEALSPLY_WALLET_WITHDRAWALLISTS:
    case GET_IDEALSPLY_WALLET_WITHDRAWALLIST_DETAILS:
    case CREATE_IDEALSPLY_WALLET_WITHDRAWALLIST:
    case UPDATE_IDEALSPLY_WALLET_WITHDRAWALLIST:
    case DELETE_IDEALSPLY_WALLET_WITHDRAWALLIST:
      return {
        ...state,
        loading: true,
      }

    case DELETE_IDEALSPLY_WALLET_WITHDRAWALLIST_FAIL:
    case GET_IDEALSPLY_WALLET_WITHDRAWALLISTS_FAIL:
    case GET_IDEALSPLY_WALLET_WITHDRAWALLIST_DETAILS_FAIL:
    case UPDATE_IDEALSPLY_WALLET_WITHDRAWALLIST_FAIL:
    case CREATE_IDEALSPLY_WALLET_WITHDRAWALLIST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_IDEALSPLY_WALLET_WITHDRAWALLISTS_SUCCESS:
      return {
        ...state,
        idealsplyWalletWithdrawalLists: action.payload,
        error: {},
        loading: false,
      }

    case GET_IDEALSPLY_WALLET_WITHDRAWALLIST_DETAILS_SUCCESS:
      return {
        ...state,
        idealsplyWalletWithdrawalListDetails: action.payload,
        error: {},
        loading: false,
      }

    case CREATE_IDEALSPLY_WALLET_WITHDRAWALLIST_SUCCESS:
      return {
        ...state,
        idealsplyWalletWithdrawalLists: {
          ...state.idealsplyWalletWithdrawalLists,
          idealsplyWalletWithdrawalLists: [
            ...state.idealsplyWalletWithdrawalLists
              .idealsplyWalletWithdrawalLists,
            action.payload,
          ],
          total: state.idealsplyWalletWithdrawalLists.total + 1,
        },
        idealsplyWalletWithdrawalListDetails: action.payload,
        error: {},
        loading: false,
      }

    case UPDATE_IDEALSPLY_WALLET_WITHDRAWALLIST_SUCCESS:
      return {
        ...state,
        idealsplyWalletWithdrawalLists: {
          ...state.idealsplyWalletWithdrawalLists,
          idealsplyWalletWithdrawalLists:
            state.idealsplyWalletWithdrawalLists.idealsplyWalletWithdrawalLists.map(
              withdrawallist =>
                withdrawallist._id === action.payload._id
                  ? { ...withdrawallist, ...action.payload }
                  : withdrawallist,
            ),
        },
        idealsplyWalletWithdrawalListDetails: action.payload,
        loading: false,
        error: {},
      }

    case DELETE_IDEALSPLY_WALLET_WITHDRAWALLIST_SUCCESS:
      return {
        ...state,
        idealsplyWalletWithdrawalLists: {
          ...state.idealsplyWalletWithdrawalLists,
          idealsplyWalletWithdrawalLists:
            state.idealsplyWalletWithdrawalLists.idealsplyWalletWithdrawalLists.filter(
              withdrawallist => withdrawallist._id !== action.payload._id,
            ),
        },
        error: {},
        loading: false,
      }
    default:
      return state
  }
}

export default IdealsplyWalletWithdrawalList
