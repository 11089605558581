import MetaTag from 'components/Common/Meta-tag'
import { CurrencySwitch } from 'hooks/Currency'
import { map } from 'lodash'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import { Card, CardBody, Container, Spinner } from 'reactstrap'
import { getPromoterProduct } from 'store/actions'
import noImage from '../../../assets/images/Defualt/noimage.png'

function Products() {
  const dispatch = useDispatch()
  const currency = CurrencySwitch()
  const history = useHistory()

  const { loading, products } = useSelector(state => ({
    loading: state.Promoter.productLoading,
    products: state?.Promoter?.promoterProduct?.supplyChainProducts,
  }))

  useEffect(() => {
    dispatch(getPromoterProduct(1))
  }, [dispatch])

  return (
    <div>
      <MetaTag title="Products" />

      <div className="page-content ">
        <Container fluid>
          <div className="container-fluid ">
            <div className="w-25 m-2 ">
              <p className="text-muted font-size-14">Products</p>
            </div>
            <hr />
            <div>
              <div className="d-flex flex-wrap justify-content-start">
                {map(products, (item, key) => (
                  <Card
                    key={key}
                    onClick={() =>
                      history.push(`/promoter/product/${item?._id}`)
                    }
                    style={{
                      borderRadius: '10px',
                      cursor: 'pointer',
                    }}
                    className="hover-zoom2 me-4"
                  >
                    <CardBody className="px-0 pt-0 mt-0 mx-0">
                      <div className="product-img m-0 position-relative">
                        <img
                          src={
                            item?.images[0]?.url
                              ? item?.images[0]?.url
                              : noImage
                          }
                          alt={item?.public_id}
                          className="img-fluid mx-auto d-block"
                          style={{
                            height: '200px',
                            width: '200px',
                            borderRadius: '10px',
                            borderBottomLeftRadius: 0,
                            borderBottomRightRadius: 0,
                          }}
                        />
                      </div>
                      <div className="mt-4 text-center">
                        <h6 className="mb-3 text-truncate">
                          <Link
                            to={'/promoter/product/' + item?._id}
                            className="text-dark"
                          >
                            {item?.primaryLang?.name}{' '}
                          </Link>
                        </h6>

                        <h6 className="my-0">
                          <span className="text-muted me-2">
                            <del>
                              {currency} {item?.price}
                            </del>
                          </span>
                          <b>
                            {currency} {item?.offerPrice}
                          </b>
                        </h6>
                      </div>
                    </CardBody>
                  </Card>
                ))}
              </div>

              {loading ? (
                <>
                  <Spinner color="secondary" className="d-block m-auto" />
                </>
              ) : (
                <>
                  {products?.total === 0 && (
                    <p className="my-2 mx-3 ">No Products!</p>
                  )}
                </>
              )}
            </div>
          </div>
        </Container>
      </div>
    </div>
  )
}

export default Products
