import React, { Fragment, useEffect, useState } from 'react'
import {
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  UncontrolledTooltip,
  Badge,
} from 'reactstrap'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// data-table related plugins
import BootstrapTable from 'react-bootstrap-table-next'
import { debounce, map, range } from 'lodash'

import ToolkitProvider from 'react-bootstrap-table2-toolkit'
import { defaultImg } from 'assets/images'

//actions
import {
  getCourseProviderCourses,
  updateCourseRegistration,
} from 'store/actions'

import '../../assets/scss/data-tables.scss'

import MyPagination from 'components/Common/MyPagination'
// import DeleteModal from 'components/Common/DeleteModal'
// import CourseCrud from './Crud/CourseProviderCrud'
import { Markup } from 'interweave'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import moment from 'moment'
import useCloudinary from 'hooks/useCloudinaryImage'

const CourseProviderCoursesList = () => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [searchText, setSearchText] = useState('')

  const { loading, courseProviderCourses } = useSelector(state => ({
    loading: state.CourseRegistration.loading,
    courseProviderCourses: state.CourseRegistration.courseProviderCourses,
  }))

  const totalPages = Math.ceil(courseProviderCourses?.total / 10)
  const pages = range(1, totalPages + 1)

  // const [sort, setSort] = useState('all')
  const [status, setStatus] = useState()

  useEffect(() => {
    dispatch(getCourseProviderCourses(page, '', 10, searchText))
  }, [dispatch, page, searchText])

  const handleStatusChange = (v, id) => {
    setStatus(v)
    dispatch(
      updateCourseRegistration(
        {
          status: status ? 'registered' : 'processing',
        },
        id,
      ),
    )
  }

  // const [isOpen, setIsOpen] = useState({ status: false, id: '' })

  // const handleDelete = id => {
  //   setIsOpen({ status: true, id: id })
  // }

  // const handleDeleteEvent = () => {
  //   setIsOpen({ ...isOpen, status: false })
  // }

  const columns = [
    {
      dataField: 'itemCode',
      text: 'Item Code',
      sort: true,
    },
    {
      dataField: 'primaryLang.name',
      text: 'Course',
      sort: true,
    },
    {
      dataField: 'imageData',
      text: 'Image',
      sort: true,
    },
    // {
    //   dataField: 'categoryData',
    //   text: 'Categories',
    //   sort: true,
    // },
    // {
    //   dataField: 'price',
    //   text: 'Price',
    //   sort: true,
    // },
    // {
    //   dataField: 'stock',
    //   text: 'Stock',
    //   sort: true,
    // },
    // {
    //   dataField: "imageData",
    //   filterText: "image",
    //   text: "Image",
    //   sort: true,
    // },
    // {
    //   dataField: "activeData",
    //   text: "Status",
    // },
    // {
    //   dataField: "isPublished",
    //   text: "Published",
    // },
    // {
    //   dataField: "isHighlighted",
    //   text: "Type",
    // },

    // {
    //   dataField: 'action',
    //   text: 'Action',
    // },
  ]

  const tableData = map(courseProviderCourses?.products, (item, index) => ({
    ...item,
    no: index,
    imageData: (
      <>
        <img
          src={
            item?.images && item?.images[0]
              ? useCloudinary(item?.images[0]?.url, 'w_900')
              : defaultImg
          }
          width="100px"
        />
      </>
    ),
    categoryData: (
      <>
        {item?.categories?.map((category, index) => {
          return (
            <Fragment key={index}>
              <Badge
                className={
                  'mx-1 font-size-12 badge-soft-info'
                  // `${colors[Math.floor(Math.random() * colors.length)]}`
                }
                color="black"
                pill
              >
                {category?.primaryLang?.name}
              </Badge>
            </Fragment>
          )
        })}
      </>
    ),
    price: <span>₹ {item?.price}</span>,
    stock: (
      <span className={item?.stock <= 0 ? 'text-danger' : ''}>
        {item?.stock}
      </span>
    ),
    status: (
      <>
        <div className="form-check form-switch form-switch-md me-4">
          <label
            className={`form-check-label ${
              item?.status === 'registered' ? 'text-success' : 'text-info'
            }`}
            htmlFor="statusSwitch"
          >
            {item?.status === 'registered' ? 'Registered' : 'Processing'}
          </label>
          <input
            type="checkbox"
            className={
              'form-check-input ' +
              `${item?.status === 'registered' && 'bg-success'}`
            }
            id="statusSwitch"
            style={{
              border: item?.status === 'registered' && 'none',
            }}
            onChange={() => {
              handleStatusChange(
                item?.status === 'registered' ? false : true,
                item?._id,
              )
            }}
            checked={item?.status === 'registered'}
          />
        </div>
      </>
    ),
    // imageData: (
    //   <>
    //     <img
    //       src={
    //         item?.images && item?.images[0] ? item?.images[0]?.url : defaultImg
    //       }
    //       width="50px"
    //     />
    //   </>
    // ),
    // title: (
    //   <div className="d-flex algin-items-center text-start   justify-content-start ">
    //     <p className="mb-0 mt-1">{item?.title}</p>
    //     {sort === 'all' && (
    //       <>
    //         {item?.isPublished && (
    //           <Badge
    //             className={'font-size-11 mx-2 p-2 badge-soft-success '}
    //             style={{
    //               width: 'fit-content',
    //             }}
    //             pill
    //           >
    //             Published
    //           </Badge>
    //         )}
    //         {item?.isHighlighted && (
    //           <Badge
    //             className={'font-size-11 me-2 p-2 badge-soft-info  '}
    //             style={{
    //               width: 'fit-content',
    //             }}
    //             pill
    //           >
    //             Highlighted
    //           </Badge>
    //         )}
    //       </>
    //     )}
    //   </div>
    // ),
    content: (
      <div
        className="text-break-spaces position-relative"
        style={{ maxWidth: 350 }}
      >
        <Markup content={item?.content?.slice(0, 140)} className="markup" />
        <span
          style={{
            position: 'absolute',
            bottom: 0,
            right: 100,
          }}
        >
          {' '}
          {item?.content?.length > 140 ? '...' : ''}
        </span>
      </div>
    ),

    action: (
      <div>
        <Link
          to={`/courseRegistrations/${item?._id}`}
          className="btn-md me-3"
          id={'view'}
        >
          <i
            className="mdi mdi-eye"
            style={{ color: 'green', fontSize: '20px' }}
          />
        </Link>
        <UncontrolledTooltip
          id="downloadTip"
          target={'view'}
          placement="bottom"
        >
          View
        </UncontrolledTooltip>
        {/* <Link
            to={{ pathname: `/courseForm/update/${item?._id}` }}
            className="btn-md"
           
          >
            <i className="bx bx-edit me-2 " style={{ fontSize: '1.3rem' }} />
          </Link> */}
        {/* <Button
            id={`Delete${index}`}
            type="button"
            color="white"
            className="ms-1 btn "
            onClick={() => {
              handleDelete(item._id)
            }}
          >
            <i
              className="bx bx-trash me-2"
              style={{ color: 'red', marginBottom: '10px', fontSize: '1.3rem' }}
            />
          </Button> */}
      </div>
    ),
    createdAt: moment(item.createdAt).format('lll'),
  }))

  const debounceBlogSearch = debounce(value => setSearchText(value), 600)

  // const listChange = sortValue => {
  //   setSort(sortValue)
  // }

  // const headerTap = [
  //   {
  //     value: 'all',
  //     title: 'All',
  //   },

  //   {
  //     value: 'service',
  //     title: 'SERVICE',
  //   },
  //   {
  //     value: 'tds',
  //     title: 'TDS ',
  //   },
  // ]

  return (
    <React.Fragment>
      <Row>
        {/* <DeleteModal
          show={isOpen?.status}
          onCloseClick={() => setIsOpen({ data: {}, status: false })}
          onDeleteClick={handleDeleteEvent}
        /> */}
        <Col className="col-8">
          <Card>
            <CardBody>
              <ToolkitProvider
                keyField="_id"
                key={'id'}
                columns={columns}
                data={tableData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      {/* <Col lg={5} className="mb-3">
                        <div
                          className="btn-group mt-xl-0"
                          role="group"
                          aria-label="Basic radio toggle button group"
                        >
                          {headerTap?.map((tap, key) => (
                            <Fragment key={key}>
                              <input
                                type="radio"
                                className="btn-check"
                                name="btnRadio"
                                id={`btnRadio${tap?.value}`}
                                autoComplete="off"
                                checked={tap.value === sort ? true : false}
                                onChange={() => listChange(tap?.value)}
                              />
                              <label
                                className="btn btn-success"
                                htmlFor={`btnRadio${tap?.value}`}
                              >
                                {tap?.title}
                              </label>
                            </Fragment>
                          ))}
                        </div>
                      </Col> */}
                      <Col lg={4}>
                        <div className="search-box w-100 ms-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <AvForm>
                              <AvField
                                name="searchText"
                                placeholder="Search"
                                type="text"
                                onChange={e =>
                                  debounceBlogSearch(e.target.value)
                                }
                              />
                            </AvForm>
                            {/* <SearchBar {...toolkitProps.searchProps} /> */}
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>
                      </Col>
                      {/* <Col lg={3}>
                        <div className="text-sm-end">
                          <Link
                            to={{
                              pathname: '/courseForm/create/new',
                            }}
                          >
                            <Button
                              type="button"
                              color="success"
                              className="btn-rounded  mb-2 me-2"
                            >
                              <i className="mdi mdi-plus me-1" />
                              Create Course Provider
                            </Button>
                          </Link>
                        </div>
                      </Col> */}
                    </Row>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        {courseProviderCourses?.total >= 1 ? (
                          <>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={'id'}
                                    key={'id'}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    // selectRow={selectRow}
                                    classes={'table align-middle table-nowrap'}
                                    headerWrapperClasses={'thead-light'}
                                    {...toolkitProps.baseProps}
                                    // onTableChange={handleTableChange}
                                    // {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <MyPagination
                              totalPages={pages}
                              page={page}
                              setPage={setPage}
                            />
                          </>
                        ) : (
                          <p className="my-4 mx-3 ">No Courses Yet !</p>
                        )}
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default CourseProviderCoursesList
