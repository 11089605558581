import MetaTag from 'components/Common/Meta-tag'
import React from 'react'
import { Container } from 'reactstrap'
import Profile from './Profile'
import BreadcrumbPremium from 'components/Common/Breadcrumb2'

function ChainPromoterDetail() {
  return (
    <div>
      <MetaTag title={'ChainPromoterDetail'} />
      <div className="page-content">
        <BreadcrumbPremium
          root={[
            '/promoter-dashboard',
            '/chain-promoter',
            'Chain Promoter Details',
          ]}
        />

        <Container fluid>
          <div className="container-fluid">
            <Profile />
          </div>
        </Container>
      </div>
    </div>
  )
}

export default ChainPromoterDetail
// m,llc
