import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGIN_PHONE,
  LOGIN_PHONE_SUCCESS,
  LOGIN_OTP_USER,
  LOGIN_OTP_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  SOCIAL_LOGIN,
} from './actionTypes'

export const loginUser = (user, history) => {
  return {
    type: LOGIN_USER,
    payload: { user, history },
  }
}

export const loginSuccess = user => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  }
}

export const logoutUser = history => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  }
}

// ......................
export const loginPhone = phonenumber => {
  return {
    type: LOGIN_PHONE,
    payload: { phonenumber },
  }
}

export const loginPhoneSuccess = user => {
  return {
    type: LOGIN_PHONE_SUCCESS,
    payload: user,
  }
}
// .....................
export const loginOtpUser = (otpData, history) => {
  return {
    type: LOGIN_OTP_USER,
    payload: { otpData, history },
  }
}

export const loginOtpSuccess = user => {
  return {
    type: LOGIN_OTP_SUCCESS,
    payload: user,
  }
}
export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}

export const socialLogin = (data, history, type) => {
  return {
    type: SOCIAL_LOGIN,
    payload: { data, history, type },
  }
}
