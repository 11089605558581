import { call, put, takeEvery, all, fork } from 'redux-saga/effects'

// Crypto Redux States
import {
  GET_CHARTS_DATA,
  GET_DASHBOARD_DATA,
  GET_PROMOTER_DASHBOARD_DATA,
} from './actionTypes'
import {
  apiSuccess,
  apiFail,
  getDashboardDataSuccess,
  getDashboardDataFail,
} from './actions'

import { get } from 'helpers/api_helper'
import {
  getPromoterDashboardDataFail,
  getPromoterDashboardDataSuccess,
} from 'store/actions'

function getDashboardDataAPi() {
  return get('/static/dashboard/data')
}

// promoter dashboard api

function promoterDashboardDataApi() {
  return get(`/static/promoter/dashboard/data`)
}

function* getChartsData({}) {
  try {
    var response
    yield put(apiSuccess(GET_CHARTS_DATA, response))
  } catch (error) {
    yield put(apiFail(GET_CHARTS_DATA, error))
  }
}

function* fetchDashboardData() {
  try {
    const response = yield call(getDashboardDataAPi)
    yield put(getDashboardDataSuccess(response))
  } catch (error) {
    yield put(getDashboardDataFail(error))
  }
}

//promoter dashboard data

function* promoterDashboardData({ payload }) {
  try {
    const response = yield call(promoterDashboardDataApi, payload)
    yield put(getPromoterDashboardDataSuccess(response))
  } catch (error) {
    yield put(getPromoterDashboardDataFail(error))
  }
}

export function* watchGetChartsData() {
  yield takeEvery(GET_CHARTS_DATA, getChartsData)
}

function* dashboardSaga() {
  yield all([fork(watchGetChartsData)])

  yield takeEvery(GET_DASHBOARD_DATA, fetchDashboardData)
  yield takeEvery(GET_PROMOTER_DASHBOARD_DATA, promoterDashboardData)
}

export default dashboardSaga
