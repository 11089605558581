import React from 'react'
import TimeField from 'react-simple-timefield'
import PropTypes from 'prop-types'

function TimePicker({ state, setState }) {
  const onTimeChangeHandler = val => {
    setState(val.target.value)
  }

  return (
    <TimeField
      value={state}
      onChange={value => onTimeChangeHandler(value)}
      input={''}
      colon=":"
    />
  )
}

export default TimePicker

TimePicker.propTypes = {
  state: PropTypes.any,
  setState: PropTypes.func,
}
