import { AvField, AvForm } from 'availity-reactstrap-validation'
import MyPagination from 'components/Common/MyPagination'
import { CurrencySwitch } from 'hooks/Currency'
import { debounce, map, range } from 'lodash'
import moment from 'moment'
import React, { Fragment, useEffect, useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import ToolkitProvider from 'react-bootstrap-table2-toolkit'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Badge, Card, CardBody, Col, FormGroup, Row, Spinner } from 'reactstrap'
import { getAllTransactions, getOrders, getUsers } from 'store/actions'
import Select from 'react-select'

function TransactionsTable() {
  const dispatch = useDispatch()
  const currency = CurrencySwitch()

  const { allTransactionsList, loading, orders, users } = useSelector(
    state => ({
      allTransactionsList: state?.Revenues?.allTransations,
      loading: state.Revenues?.loading,
      orders: state?.Orders?.orders,
      users: state.Users.users?.users,
    }),
  )

  const [page, setPage] = useState(1)
  const [searchText, setSearchText] = useState('')
  const [totalItemLimit, setTotalItemLimit] = useState(30)
  const [status, setStatus] = useState('')
  const [orderSearch, setOrderSearch] = useState('')
  const [orderSelect, setOrderSelect] = useState('Search Orders')
  const [orderSelectId, setOrderSelectId] = useState('')

  const [userSearch, setUserSearch] = useState('')
  const [userSelect, setUserSelect] = useState('Search Users')
  const [userSelectId, setUserSelectId] = useState('')

  useEffect(() => {
    const credential = {
      page,
      search: searchText || '',
      user: userSelectId || '',
      order: orderSelectId || '',
      status,
      limit: totalItemLimit,
    }
    dispatch(getAllTransactions(credential))
  }, [
    dispatch,
    page,
    searchText,
    userSelectId,
    orderSelectId,
    status,
    totalItemLimit,
  ])

  useEffect(() => {
    dispatch(getOrders(1, 10, orderSearch, '', ''))
  }, [orderSearch])

  const columns = [
    {
      dataField: 'date',
      text: 'Date',
      sort: true,
    },
    {
      dataField: 'name',
      text: 'Name',
      sort: true,
    },
    {
      dataField: 'order',
      text: 'Order Id',
      sort: true,
    },

    {
      dataField: 'phonePeTransactionId',
      text: 'PhonePe Id',
      sort: true,
    },
    {
      dataField: 'amount',
      text: 'Amount',
      sort: true,
    },
    {
      dataField: 'aggregator',
      text: 'Payment Option',
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
    },
  ]

  const handlePaymentOption = paymentOption => {
    switch (paymentOption) {
      case 'phonePe':
        return {
          paymentOptionName: 'PhonePe',
          backgroundColor: 'bg-primary',
        }
      case 'ima wallet':
        return {
          paymentOptionName: 'IMA Wallet',
          backgroundColor: 'bg-success',
        }
      default:
        return {
          paymentOptionName: '',
          backgroundColor: 'bg-info',
        }
    }
  }

  const debounceTransactionSearch = debounce(value => setSearchText(value), 600)
  const handleBadgeColor = status => {
    switch (status) {
      case 'PENDING':
        return 'warning'
      case 'FAILED':
        return 'danger'
      case 'INITIATED':
        return 'info'
      case 'SUCCESS':
        return 'success'
      case 'ERRORED':
        return 'danger'
      default:
        return 'secondary'
    }
  }

  const transactionsData = map(allTransactionsList?.transactions, item => ({
    ...item,
    order: (
      <Link to={`/order/${item?.order?._id}`}>
        <p>{item?.order?.orderId}</p>
      </Link>
    ),
    name: (
      <Link to={`/users/${item?.user?._id}`}>
        <p>{item?.user?.name ? item?.user?.name : item?.user?.phone}</p>
      </Link>
    ),
    date: <p>{moment(item?.createdAt).format('lll')}</p>,
    amount: (
      <span>
        {currency} {item?.amount}
      </span>
    ),
    aggregator: (
      <>
        <Badge
          className={`px-2 py-2 ${
            handlePaymentOption(item?.aggregator).backgroundColor
          }`}
        >
          {handlePaymentOption(item?.aggregator).paymentOptionName}
        </Badge>
      </>
    ),
    instrument: <p>{item?.paymentInstrument}</p>,
    status: (
      <Badge
        className={`px-2 py-2 badge-soft-${handleBadgeColor(item?.status)}`}
      >
        {item?.status}
      </Badge>
    ),
  }))

  const totalPages = Math.ceil(allTransactionsList?.total / totalItemLimit)
  const pages = range(1, totalPages + 1)

  const listChange = sortValue => {
    setStatus(sortValue)
  }

  const tableTitles = [
    {
      title: 'All',
      value: '',
    },
    {
      title: 'PENDING',
      value: 'PENDING',
    },

    {
      title: 'INITIATED',
      value: 'INITIATED',
    },
    {
      title: 'SUCCESS',
      value: 'SUCCESS',
    },
    {
      title: 'FAILED',
      value: 'FAILED',
    },
    {
      title: 'ERRORED',
      value: 'ERRORED',
    },
  ]

  useEffect(() => {
    const currentLimit = localStorage.getItem('promoterTransactionLimit')
    if (currentLimit) {
      setTotalItemLimit(currentLimit)
    }
  }, [])

  const handlePageLimit = e => {
    if (e.target.value >= 1) {
      setTotalItemLimit(parseInt(e.target.value))
      localStorage.setItem('phonePeTransactionLimit', e.target.value)
    }
  }

  const debounceOrderSearch = debounce(value => setOrderSearch(value), 600)
  const handleOrderEnters = textEntered => {
    debounceOrderSearch(textEntered)
  }
  function handlerOrderFinalValue(event) {
    setOrderSelect(event.label)
    setOrderSelectId(event.value)
  }
  const OrderOptions = [
    {
      label: 'All Orders',
      id: '',
    },
    {
      options: (orders?.length >= 1 ? orders : [])?.map((result, index) => ({
        key: index,
        label: result?.orderId,
        value: result?._id,
      })),
    },
  ]

  useEffect(() => {
    dispatch(getUsers(page, '', 10, userSearch))
  }, [dispatch, page, userSearch])

  const debounceUserSearch = debounce(value => setUserSearch(value), 600)

  const handleUserEnters = textEntered => {
    debounceUserSearch(textEntered)
  }
  function handlerUserFinalValue(event) {
    setUserSelect(event.label1)
    setUserSelectId(event.value)
  }
  const UserOptions = [
    {
      label: 'All Users',
      id: '',
    },
    {
      options: (users?.length >= 1 ? users : [])?.map((result, index) => ({
        key: index,
        label1: result?.phone,
        label: (
          <>
            {result?.phone} {result?.name}
          </>
        ),
        value: result?._id,
      })),
    },
  ]

  const headerFilter = (
    <>
      <Col
        sm="5"
        className="d-flex align-items-center justify-content-between filter-header-1 "
      >
        <div
          className="btn-group mt-xl-0"
          role="group"
          aria-label="Basic radio toggle button group"
        >
          {map(tableTitles, (head, key) => (
            <Fragment key={key}>
              <input
                type="radio"
                className="btn-check"
                name="btnradio"
                id={head.value + key}
                autoComplete="off"
                checked={status === head.value}
                onChange={() => listChange(head?.value || '')}
              />
              <label
                className="btn mb-0 btn-outline-info d-flex align-items-center "
                htmlFor={head.value + key}
                style={{
                  whiteSpace: 'nowrap',
                }}
              >
                {head?.title}
              </label>
            </Fragment>
          ))}
        </div>
      </Col>
      <Col sm="6" className="filter-header-2">
        <div className="d-flex align-items-center justify-content-start filter-subheader-2">
          <AvForm className="form-horizontal me-2 w-75 d-flex align-items-center">
            <div className="mx-1">
              <span className="">
                Show
                <i className="fas fa-sort-numeric-down-alt mx-1"></i>
              </span>
            </div>
            <div className="" style={{ width: '50px' }}>
              <AvField
                name="pageLimit"
                className="form-control"
                value={totalItemLimit}
                onChange={e => handlePageLimit(e)}
                type="text"
                required
              />
            </div>
          </AvForm>

          <FormGroup className="w-100 me-3">
            <div className="ajax-select mt-lg-0 select2-container">
              <Select
                onInputChange={handleOrderEnters}
                value={orderSelect}
                placeholder={orderSelect}
                onChange={handlerOrderFinalValue}
                options={OrderOptions}
                classNamePrefix="select2-selection"
                // isLoading={loading}
              />
            </div>
          </FormGroup>
          <FormGroup className="w-100">
            <div className="ajax-select mt-lg-0 select2-container">
              <Select
                onInputChange={handleUserEnters}
                value={userSelect}
                placeholder={userSelect}
                onChange={handlerUserFinalValue}
                options={UserOptions}
                classNamePrefix="select2-selection"
                // isLoading={loading}
              />
            </div>
          </FormGroup>
        </div>
      </Col>
    </>
  )

  return (
    <div>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <Row className="mb-2">{headerFilter}</Row>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <ToolkitProvider
                keyField="_id"
                key={'id'}
                columns={columns}
                data={transactionsData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col sm="4">
                        <div className="search-box ms-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            {/* <SearchBar {...toolkitProps.searchProps} /> */}
                            <AvForm>
                              <AvField
                                name="searchText"
                                placeholder="Search"
                                type="text"
                                onChange={e =>
                                  debounceTransactionSearch(e.target.value)
                                }
                              />
                            </AvForm>
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>
                      </Col>
                      {/* <Col sm="8">
                        <div className="text-sm-end">
                          <Link to="/promoter/create/new">
                            <Button
                              type="button"
                              color="success"
                              className="btn-rounded  mb-2 me-2"
                            >
                              <i className="mdi mdi-plus me-1" />
                              Create Promoter
                            </Button>
                          </Link>
                        </div>
                      </Col> */}
                    </Row>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        {allTransactionsList?.transactions ? (
                          <>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={'id'}
                                    key={'id'}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    // selectRow={selectRow}
                                    classes={
                                      'table align-middle table-nowrap floating-action'
                                    }
                                    headerWrapperClasses={'thead-light'}
                                    {...toolkitProps.baseProps}
                                    // onTableChange={handleTableChange}
                                    // {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <MyPagination
                              totalPages={pages}
                              page={page}
                              setPage={setPage}
                            />
                          </>
                        ) : (
                          <p className="my-4 mx-3 ">No Transactions !</p>
                        )}
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default TransactionsTable
