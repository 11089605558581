import {
  PROMOTER_DASHBOARD_PAGE_VIEW_DETAILS,
  PROMOTER_DASHBOARD_PAGE_VIEW_DETAILS_FAIL,
  PROMOTER_DASHBOARD_PAGE_VIEW_DETAILS_SUCCESS,
  GET_PROMOTER_DASHBOARD_EARNINGS_DETAILS,
  GET_PROMOTER_DASHBOARD_EARNINGS_DETAILS_FAIL,
  GET_PROMOTER_DASHBOARD_EARNINGS_DETAILS_SUCCESS,
  GET_PROMOTER_PRODUCT,
  GET_PROMOTER_PRODUCT_FAIL,
  GET_PROMOTER_PRODUCT_SUCCESS,
  GET_PROMOTER_PRODUCT_DETAILS,
  GET_PROMOTER_PRODUCT_DETAILS_FAIL,
  GET_PROMOTER_PRODUCT_DETAILS_SUCCESS,
  GET_PROMOTER_PROFILE_DETAILS,
  GET_PROMOTER_PROFILE_DETAILS_SUCCESS,
  GET_PROMOTER_PROFILE_DETAILS_FAIL,
  GET_PROMOTER_PROFILE_UPDATE,
  GET_PROMOTER_PROFILE_UPDATE_SUCCESS,
  GET_PROMOTER_PROFILE_UPDATE_FAIL,
  GET_PROMOTER_TRANSACTION_GRAPH,
  GET_PROMOTER_TRANSACTION_GRAPH_SUCCESS,
  GET_PROMOTER_TRANSACTION_GRAPH_FAIL,
} from './actionTypes'

const INIT_STATE = {
  //page view
  dashboardPageView: {},
  dashboardLoading: false,
  // earnings
  dashboardEarnings: {},
  // product
  promoterProduct: {},
  productLoading: false,
  // product details
  promoterProductDetails: {},
  productDetailsLoading: false,

  promoterProfile: {},

  promoterTransactionGraphGrowthReward: {},
  promoterTransactionGraphLeadBonus: {},

  withdrawalRequest: {},
}

const Promoter = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PROMOTER_PRODUCT_DETAILS:
      return {
        ...state,
        loading: true,
      }

    case PROMOTER_DASHBOARD_PAGE_VIEW_DETAILS:
    case GET_PROMOTER_DASHBOARD_EARNINGS_DETAILS:
    case GET_PROMOTER_PRODUCT:
      return {
        ...state,

        userLoading: true,
        dashboardLoading: true,
        productLoading: true,
        productDetailsLoading: true,
      }

    case PROMOTER_DASHBOARD_PAGE_VIEW_DETAILS_FAIL:
    case GET_PROMOTER_DASHBOARD_EARNINGS_DETAILS_FAIL:
    case GET_PROMOTER_PRODUCT_FAIL:
    case GET_PROMOTER_PRODUCT_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        earningLoading: false,
        userLoading: false,
        dashboardLoading: false,
        productLoading: false,
        productDetailsLoading: false,
      }

    // page view

    case PROMOTER_DASHBOARD_PAGE_VIEW_DETAILS_SUCCESS:
      return {
        ...state,
        dashboardPageView: action.payload,
        dashboardLoading: false,
      }
    // earnings

    case GET_PROMOTER_DASHBOARD_EARNINGS_DETAILS_SUCCESS:
      return {
        ...state,
        dashboardEarnings: action.payload,
        dashboardLoading: false,
      }
    // product

    case GET_PROMOTER_PRODUCT_SUCCESS:
      return {
        ...state,
        promoterProduct: action.payload,
        productLoading: false,
      }

    // promoter product details

    case GET_PROMOTER_PRODUCT_DETAILS_SUCCESS:
      return {
        ...state,
        productDetailsLoading: false,
        promoterProductDetails: action.payload,
      }

    // promoter profile data

    case GET_PROMOTER_PROFILE_DETAILS:
      return {
        ...state,
        promoterLoading: true,
      }
    case GET_PROMOTER_PROFILE_DETAILS_SUCCESS:
      return {
        ...state,
        promoterLoading: false,
        promoterProfile: action.payload,
      }
    case GET_PROMOTER_PROFILE_DETAILS_FAIL:
      return {
        ...state,
        promoterLoading: false,
        error: action.payload,
      }
    case GET_PROMOTER_PROFILE_UPDATE:
      return {
        ...state,
        promoterLoading: true,
      }
    case GET_PROMOTER_PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        promoterLoading: false,
        promoterProfile: action.payload,
      }
    case GET_PROMOTER_PROFILE_UPDATE_FAIL:
      return {
        ...state,
        promoterLoading: false,
        error: action.payload,
      }

    case GET_PROMOTER_TRANSACTION_GRAPH:
      return {
        ...state,
        loading: true,
      }
    case GET_PROMOTER_TRANSACTION_GRAPH_SUCCESS:
      if (action?.payload?.sort === 'growthrewards') {
        return {
          ...state,
          loading: false,
          promoterTransactionGraphGrowthReward: action?.payload,
        }
      } else {
        return {
          ...state,
          loading: false,
          promoterTransactionGraphLeadBonus: action?.payload,
        }
      }

    case GET_PROMOTER_TRANSACTION_GRAPH_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    // promoter dashboard withdrawal req

    default:
      return state
  }
}

export default Promoter
