import { useHistory } from 'react-router'
import { Col, Container, Row } from 'reactstrap'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, CardBody, CardTitle } from 'reactstrap'
import { AvForm } from 'availity-reactstrap-validation'

import Breadcrumbs from '../../../components/Common/Breadcrumb'

//actions
import { createCoupon } from 'store/actions'

import MetaTag from 'components/Common/Meta-tag'
import Form from './Form'

const CreateCoupon = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const { loading, error } = useSelector(state => ({
    loading: state.Coupon.loading,
    error: state.Coupon.error,
  }))

  const [couponDuration, setCouponDuration] = useState('Duration')
  const [selectedUsers, setSelectedUsers] = useState([])

  function handleValidSubmit(values) {
    const createCouponData = {
      ...values,
      durationType: couponDuration,
      whitelistUsersList: selectedUsers,
    }
    dispatch(createCoupon(createCouponData, history))
  }

  const FormOption = {
    setSelectedUsers,
    couponDuration,
    setCouponDuration,
    isUpdate: false,
  }

  return (
    <>
      <MetaTag title="Create Groups" />

      <div className="page-content">
        <Breadcrumbs title="Groups" breadcrumbItem="Create Group" />
        <Container fluid>
          <div className="container-fluid">
            <AvForm
              onValidSubmit={(e, v) => {
                handleValidSubmit(v)
              }}
            >
              <Form formOption={FormOption} />

              <Row xl="12">
                <Col xl="12">
                  <Card>
                    <CardBody>
                      <CardTitle className="h4 mb-4 text_deals_green"></CardTitle>
                      <Row>
                        <Col>
                          <div className="text-end">
                            <button
                              type="submit"
                              className="col-12 btn btn-success save-customer"
                            >
                              {loading && (
                                <>
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                </>
                              )}
                              Create Coupon
                            </button>
                          </div>
                          {error?.message && (
                            <p
                              className="mt-2 mb-0 pb-0"
                              style={{ textAlign: 'end', color: '#ff0000a3' }}
                            >
                              {' '}
                              <span>!</span> {error?.message}
                            </p>
                          )}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </AvForm>
          </div>
        </Container>
      </div>
    </>
  )
}

export default CreateCoupon

export const CouponDuration = [
  {
    id: 1,
    title: 'DURATION',
    value: 'Duration',
  },
  {
    id: 2,
    title: 'FULL TIME',
    value: 'Full time',
  },
  {
    id: 3,
    title: 'START TIME',
    value: 'Start time',
  },
]

export const couponTypeOption = [
  'Custom Value',
  'Custom Percentage',
  // "New User",
  // "First Order",
  // "Free Shipping",
  // "Whitelist Users",
  // "Product Set",
  // "Category Set",
]
