/* WithdrawalLists - This line cannot be edited or removed */
export const GET_IDEALSPLY_WALLET_WITHDRAWALLISTS =
  'GET_IDEALSPLY_WALLET_WITHDRAWALLISTS'
export const GET_IDEALSPLY_WALLET_WITHDRAWALLISTS_SUCCESS =
  'GET_IDEALSPLY_WALLET_WITHDRAWALLISTS_SUCCESS'
export const GET_IDEALSPLY_WALLET_WITHDRAWALLISTS_FAIL =
  'GET_IDEALSPLY_WALLET_WITHDRAWALLISTS_FAIL'

/* IDEALSPLY_WithdrawalList_DETAILS */
export const GET_IDEALSPLY_WALLET_WITHDRAWALLIST_DETAILS =
  'GET_IDEALSPLY_WALLET_WITHDRAWALLIST_DETAILS'
export const GET_IDEALSPLY_WALLET_WITHDRAWALLIST_DETAILS_SUCCESS =
  'GET_IDEALSPLY_WALLET_WITHDRAWALLIST_DETAILS_SUCCESS'
export const GET_IDEALSPLY_WALLET_WITHDRAWALLIST_DETAILS_FAIL =
  'GET_IDEALSPLY_WALLET_WITHDRAWALLIST_DETAILS_FAIL'

/* CREATE IDEALSPLY_WithdrawalList */
export const CREATE_IDEALSPLY_WALLET_WITHDRAWALLIST =
  'CREATE_IDEALSPLY_WALLET_WITHDRAWALLIST'
export const CREATE_IDEALSPLY_WALLET_WITHDRAWALLIST_SUCCESS =
  'CREATE_IDEALSPLY_WALLET_WITHDRAWALLIST_SUCCESS'
export const CREATE_IDEALSPLY_WALLET_WITHDRAWALLIST_FAIL =
  'CREATE_IDEALSPLY_WALLET_WITHDRAWALLIST_FAIL'

/* Edit IDEALSPLY_WithdrawalList */
export const UPDATE_IDEALSPLY_WALLET_WITHDRAWALLIST =
  'UPDATE_IDEALSPLY_WALLET_WITHDRAWALLIST'
export const UPDATE_IDEALSPLY_WALLET_WITHDRAWALLIST_SUCCESS =
  'UPDATE_IDEALSPLY_WALLET_WITHDRAWALLIST_SUCCESS'
export const UPDATE_IDEALSPLY_WALLET_WITHDRAWALLIST_FAIL =
  'UPDATE_IDEALSPLY_WALLET_WITHDRAWALLIST_FAIL'

/* Delete IDEALSPLY_WithdrawalList */
export const DELETE_IDEALSPLY_WALLET_WITHDRAWALLIST =
  'DELETE_IDEALSPLY_WALLET_WITHDRAWALLIST'
export const DELETE_IDEALSPLY_WALLET_WITHDRAWALLIST_SUCCESS =
  'DELETE_IDEALSPLY_WALLET_WITHDRAWALLIST_SUCCESS'
export const DELETE_IDEALSPLY_WALLET_WITHDRAWALLIST_FAIL =
  'DELETE_IDEALSPLY_WALLET_WITHDRAWALLIST_FAIL'
