import { call, put, takeEvery } from 'redux-saga/effects'

// Login Redux States
import {
  LOGOUT_USER,
  LOGIN_USER,
  LOGIN_OTP_USER,
  LOGIN_PHONE,
} from './actionTypes'
import {
  apiError,
  loginSuccess,
  logoutUserSuccess,
  loginOtpSuccess,
  loginPhoneSuccess,
} from './actions'
import { post, get } from 'helpers/api_helper'
import {
  errorNotification,
  Notification,
} from '../../../components/Common/Notification'

function loginApi(user) {
  return post('/dashboard/login/mail', user)
}
function loginPhoneApi({ phonenumber }) {
  return post('/dashboard/login/phone', phonenumber)
}
function loginOtpApi({ otpData }) {
  return post('/dashboard/verify_otp', otpData)
}
function logoutApi() {
  return get('/logout')
}

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(loginApi, user)
    if (response?.token) {
      sessionStorage.setItem('token', response?.token)
      sessionStorage.setItem('role', response?.role)
      yield put(loginSuccess(response))
      Notification({
        type: 'success',
        message: 'Logged In Successfully!',
        title: '',
      })
      const role = sessionStorage.getItem('role')
      const token = sessionStorage.getItem('token')
      if (token && role !== 'undefined' && role?.length >= 1) {
        history.push('/dashboard')
      }
    }
  } catch (error) {
    yield put(apiError(error.response.data))
    errorNotification()
  }
}
function* loginPhone({ payload }) {
  try {
    const response = yield call(loginPhoneApi, payload)
    // localStorage.setItem('session_key', response.session_key)
    yield put(loginPhoneSuccess(response))
    // if (response.userId){
    //   history.push("/login-otp")
    // }
  } catch (error) {
    yield put(apiError(error))
  }
}
function* loginOtpUser({ payload }) {
  try {
    // payload['otp']['session_key'] = localStorage.getItem('session_key')
    const response = yield call(loginOtpApi, payload)
    if (response?.token) {
      sessionStorage.setItem('token', response?.token)
      sessionStorage.setItem('role', response?.role)
      yield put(loginOtpSuccess(response))
      Notification({
        type: 'success',
        message: 'Logged In Successfully!',
        title: '',
      })
      // const role = sessionStorage.getItem("role")
      // const token = sessionStorage.getItem("token")
      // if (token && role !== "undefined" && role?.length >= 1) {
      //   history.push("/dashboard")
      // }
    }
  } catch (error) {
    yield put(apiError(error.response.data))
    errorNotification()
  }
  //   if (response?.token) {
  //     localStorage.setItem("token", response?.token)
  //     sessionStorage.setItem("role", response?.role)
  //   }
  //   if (response?.error_message) {
  //     yield put(apiError(response?.error_message))
  //   } else {
  //     yield put(loginOtpSuccess(response))
  //     Notification({
  //       type: "success",
  //       message: "Logged In Successfully!",
  //       title: "",
  //     })
  //     const role = sessionStorage.getItem("role")
  //     const token = sessionStorage.getItem("token")
  //     if (token && role !== "undefined" && role?.length >= 1) {
  //       payload.history.push("/dashboard")
  //     }
  //     // payload.history.push("/dashboard")
  //   }
  // } catch (error) {
  //   yield put(apiError(error))
  // }
}
// function* loginOtpUser({ payload,history }) {
//   try {
//     // payload['otp']['session_key'] = localStorage.getItem('session_key')
//     const response = yield call(loginOtpApi, payload)
//     console.log("otp response: ", response)
//     if (response?.token) {
//       localStorage.setItem("token", response.token)
//     }
//     if (response?.error_message) {
//       yield put(apiError(response?.error_message))
//     } else {
//       yield put(loginOtpSuccess(response))
//       payload.history.push("/dashboard")

//     }
//   } catch (error) {
//     yield put(apiError(error))
//   }
// }
function* logoutUser({ payload: { history } }) {
  try {
    const token = sessionStorage.getItem('token')

    const response = yield call(logoutApi, token)
    if (response) {
      yield put(logoutUserSuccess(response))
      Notification({
        type: 'success',
        message: 'Logged out successfully',
        title: '',
      })
      sessionStorage.clear()
      history.push('/login')
    }
  } catch (error) {
    yield put(apiError(error.response.data))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGIN_PHONE, loginPhone)
  yield takeEvery(LOGIN_OTP_USER, loginOtpUser)

  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
