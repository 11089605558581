import Select from 'react-select'
import { Label, TabContent, TabPane } from 'reactstrap'
import { Col, Container, Row } from 'reactstrap'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, CardBody, CardSubtitle, CardTitle, FormGroup } from 'reactstrap'
import { AvField, AvForm } from 'availity-reactstrap-validation'

import Breadcrumbs from '../../../components/Common/Breadcrumb'

//actions
import {
  createGroup,
  getAllProducts,
  getCategories,
  getCollections,
} from 'store/actions'

import LanguageTab from 'components/Common/LanguageTab'
import { debounce, map } from 'lodash'
import { LanguageSwitch } from 'hooks/LanguageSwitch'
import { useHistory } from 'react-router-dom'
import MetaTag from 'components/Common/Meta-tag'
import { useSiteVersion } from 'hooks/useSiteVersion'
import UseImageUploader from 'components/imageUploader/useImageUploader'

const CrateGroups = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { isPremium, isBasic } = useSiteVersion()
  const {
    loading,
    Collections,
    CollectionLoading,
    categories,
    catLoading,
    products,
    proLoading,
    // stores,
    // StoreLoading,
  } = useSelector(state => ({
    loading: state.Group.loading,
    Collections: state.Collection.collections,
    CollectionLoading: state.Collection.loading,
    categories: state.Category.categories.categories,
    catLoading: state.Category.loading,
    products: state.Products.products,
    proLoading: state.Products.loading,
    // stores: state.Stores.stores?.stores,
    // StoreLoading: state.Stores.loading,
  }))

  const [bannerImagesPreview, setBannerImagesPreview] = useState([])
  const [activeTab, setActiveTab] = useState('1')

  //collection///
  const [collectionSelect, setCollectionSelect] = useState({
    searchText: '',
    id: '',
    title: 'Search a Collection',
  })
  const [selectedCollectionArray, setSelectedCollectionArray] = useState([])
  const [selectedCollectionId, setSelectedCollectionId] = useState([])
  /////

  //category///
  const [categorySelect, setCategorySelect] = useState({
    searchText: '',
    id: '',
    title: 'Search a Category',
  })
  const [selectedCategoryArray, setSelectedCategoryArray] = useState([])
  const [selectedCategoryId, setSelectedCategoryId] = useState([])
  /////

  //product///
  const [productSelect, setproductSelect] = useState({
    searchText: '',
    id: '',
    title: 'Search a Product',
  })
  const [selectedProductArray, setSelectedProductArray] = useState([])
  const [selectedProductId, setSelectedProductId] = useState([])
  /////

  const [platform, setPlatform] = useState({
    web: false,
    mobile: false,
    common: true,
    title: 'common',
  })

  const [layout, setLayout] = useState('list_vertical')

  const [groupType, setGroupType] = useState('single collection')

  useEffect(() => {
    dispatch(getCollections(1, '', collectionSelect?.searchText))
  }, [dispatch, collectionSelect?.searchText])

  useEffect(() => {
    dispatch(getCategories(1, '', categorySelect?.searchText))
  }, [dispatch, categorySelect?.searchText])

  useEffect(() => {
    dispatch(getAllProducts(1, 'all', '', productSelect?.searchText))
  }, [dispatch, productSelect?.searchText])

  useEffect(() => {
    setSelectedProductArray([])
    setSelectedProductId([])
    setproductSelect({
      searchText: '',
      id: '',
      title: 'Search a Product',
    })

    setSelectedCategoryArray([])
    setSelectedCategoryId([])
    setCategorySelect({
      searchText: '',
      id: '',
      title: 'Search a Category',
    })

    setSelectedCollectionArray([])
    setSelectedCollectionId([])
    setCollectionSelect({
      searchText: '',
      id: '',
      title: 'Search a Collection',
    })
  }, [groupType])

  function handleValidSubmit(values) {
    const createGroupData = {
      // store: finalStoreId,
      primaryLang: values.primaryLang,
      secondaryLang: values.secondaryLang,
      images: bannerImagesPreview,
      priority: parseInt(values.priority),
      platform: platform.title,
      layout: layout,
      type: groupType,
      categories: selectedCategoryId,
      collections: selectedCollectionId,
      singleCollection: selectedCategoryId[0],
      singleCategory: selectedCollectionId[0],
      products: selectedProductId,
    }
    dispatch(createGroup(createGroupData, history))
  }

  //collection dropdown
  const debounceCollectionSearch = debounce(
    value => setCollectionSelect(value),
    600,
  )
  const handleCollectionEnters = textEntered => {
    debounceCollectionSearch({
      ...collectionSelect,
      searchText: textEntered,
    })
  }

  function handlerCollectionFinalValue(event) {
    const collection = {
      ...collectionSelect,
      title: event.label,
      id: event.value,
      image: event.image,
    }
    setCollectionSelect(collection)

    if (groupType === 'single collection') {
      setSelectedCollectionArray([collection])
      setSelectedCollectionId([collection?.id])
    } else {
      setSelectedCollectionArray([...selectedCollectionArray, collection])
      setSelectedCollectionId([...selectedCategoryId, collection?.id])
    }
  }

  const collectionOptions = [
    {
      options:
        Collections?.length &&
        Collections?.map((result, index) => ({
          key: index,
          label: result?.primaryLang?.title,
          value: result._id,
          image: result?.logoImages[0]?.url,
        })),
    },
  ]

  const handleRemoveSeletedCollection = id => {
    const filteredColl = selectedCollectionArray?.filter(i => i.id !== id)
    setSelectedCollectionArray(filteredColl)

    const filteredCollId = selectedCollectionId?.filter(i => i === id)
    setSelectedCollectionId(filteredCollId)
  }
  //end of collection dropdown

  //category dropdown
  const debounceCategorySearch = debounce(
    value => setCategorySelect(value),
    600,
  )
  const handleCategoryEnters = textEntered => {
    debounceCategorySearch({
      ...categorySelect,
      searchText: textEntered,
    })
  }

  function handlerCategoryFinalValue(event) {
    const category = {
      ...categorySelect,
      title: event.label,
      id: event.value,
      image: event.image,
    }
    setCategorySelect(category)
    if (groupType === 'single category') {
      setSelectedCategoryArray([category])
      setSelectedCategoryId([category?.id])
    } else {
      setSelectedCategoryArray([...selectedCategoryArray, category])
      setSelectedCategoryId([...selectedCategoryId, category?.id])
    }
  }

  const categoryOptions = [
    {
      options:
        categories?.length &&
        categories?.map((result, index) => ({
          key: index,
          label: result?.primaryLang?.name,
          value: result._id,
          image: result?.logoImages[0]?.url,
        })),
    },
  ]

  const handleRemoveSeletedCategory = id => {
    const filteredCate = selectedCategoryArray?.filter(i => i.id !== id)
    setSelectedCategoryArray(filteredCate)

    const filteredCateId = selectedCategoryId?.filter(i => i !== id)
    setSelectedCategoryId(filteredCateId)
  }
  //end of category dropdown

  //product dropdown
  const debounceProductSearch = debounce(value => setproductSelect(value), 600)
  const handleProductEnters = textEntered => {
    debounceProductSearch({
      ...productSelect,
      searchText: textEntered,
    })
  }

  function handlerProductFinalValue(event) {
    const product = {
      ...productSelect,
      title: event.label,
      id: event.value,
      image: event.image,
      varients: event.varients,
    }

    setproductSelect(product)
    setSelectedProductArray([...selectedProductArray, product])
    setSelectedProductId([...selectedProductId, event.value])
  }

  const productOptions = [
    {
      options: (products?.length >= 1 ? products : [])?.map(
        (result, index) => ({
          key: index,
          label: `${result?.primaryLang?.name} ${
            result.hasVarients
              ? result?.varients?.length >= 1
                ? `(${result?.varients[0]?.value} ${result?.varients[0]?.unit})`
                : ''
              : ''
          }`,
          value: result?._id,
          image: result?.images[0]?.url,
        }),
      ),
    },
  ]

  const handleRemoveSeletedproduct = id => {
    const filteredProd = selectedProductArray?.filter(i => i.id !== id)
    setSelectedProductArray(filteredProd)

    const filteredProdId = selectedProductId?.filter(i => i !== id)
    setSelectedProductId(filteredProdId)
  }
  //end of product dropdown

  const isMult = LanguageSwitch()?.isMult

  const handleGroupTypes = () => {
    if (isPremium) return GroupTypesData
    else if (isBasic)
      return GroupTypesData?.filter(
        gp => gp.value !== 'single collection' && gp.value !== 'collections',
      )
  }

  const handleGroupLayouts = () => {
    if (isPremium) return LayoutData
    else if (isBasic)
      return LayoutData?.filter(
        layout =>
          layout.value === 'list_vertical' ||
          layout.value === 'list_horizontal',
      )
  }

  return (
    <>
      <MetaTag title={'Create Groups'} />

      <div className="page-content">
        <Breadcrumbs title="Groups" breadcrumbItem="Create Group" />
        <Container fluid>
          <div className="container-fluid">
            <AvForm
              onValidSubmit={(e, v) => {
                handleValidSubmit(v)
              }}
            >
              <Row xl="12">
                {isPremium && (
                  <Col className="col-12">
                    <Card>
                      <CardBody>
                        <Row>
                          <Col className="col-6">
                            <div className="w-100">
                              <CardTitle className="h4 mb-4 text_deals_green">
                                Platform
                              </CardTitle>
                              <Row className="my-2">
                                <div className="col-4  align-items-center  d-flex">
                                  <input
                                    type="radio"
                                    name="Platform"
                                    id={'common'}
                                    value={'common'}
                                    onChange={v => {
                                      setPlatform({
                                        ...setPlatform,
                                        title: 'common',
                                        common: v,
                                      })
                                    }}
                                    checked={platform?.common}
                                  />
                                  <label
                                    className="mb-0 pb-0 mx-3 cursor-pointer"
                                    htmlFor={'common'}
                                  >
                                    Common
                                  </label>
                                </div>
                                <div className="col-4 align-items-center  d-flex">
                                  <input
                                    type="radio"
                                    name="Platform"
                                    id={'web'}
                                    checked={platform?.web}
                                    value={'web'}
                                    onChange={v => {
                                      setPlatform({
                                        ...setPlatform,
                                        title: 'web',
                                        web: v,
                                      })
                                    }}
                                  />
                                  <label
                                    className="mb-0 pb-0 mx-3 cursor-pointer"
                                    htmlFor={'web'}
                                  >
                                    Web
                                  </label>
                                </div>
                                <div className="col-4  align-items-center  d-flex">
                                  <input
                                    type="radio"
                                    name="Platform"
                                    id={'mobile'}
                                    value={'mobile'}
                                    onChange={v => {
                                      setPlatform({
                                        ...setPlatform,
                                        title: 'mobile',
                                        mobile: v,
                                      })
                                    }}
                                    checked={platform?.mobile}
                                  />
                                  <label
                                    className="mb-0 pb-0 mx-3 cursor-pointer"
                                    htmlFor={'mobile'}
                                  >
                                    Mobile
                                  </label>
                                </div>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                )}

                <Row xl="12">
                  <Col xl="12">
                    <Card>
                      <CardBody>
                        <CardTitle className="h4 mb-4 text_deals_green">
                          Group Type
                        </CardTitle>
                        <Row>
                          <div
                            className="btn-group mt-2 mt-xl-0 d-flex align-items-center justify-content-start"
                            role="group"
                            aria-label="Basic radio toggle button group"
                          >
                            {map(handleGroupTypes(), (item, key) => (
                              <div key={key} className="me-3">
                                <input
                                  type="radio"
                                  className="btn-check"
                                  name="btnradio-groupType"
                                  id={item.value + 'groupType'}
                                  autoComplete="off"
                                  checked={groupType === item.value}
                                  value={item.value}
                                  onChange={e => setGroupType(e.target.value)}
                                />
                                <label
                                  className="btn btn-outline-info"
                                  htmlFor={item.value + 'groupType'}
                                >
                                  {item.title}
                                </label>
                              </div>
                            ))}
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Col xl="12">
                  <Card>
                    <CardBody>
                      <CardTitle className="h4 mb-4 text_deals_green">
                        Layout
                      </CardTitle>
                      <Row>
                        <div
                          className="btn-group mt-2 mt-xl-0 d-flex align-items-center justify-content-start"
                          role="group"
                          aria-label="Basic radio toggle button group"
                        >
                          {map(handleGroupLayouts(), (item, key) => (
                            <div key={key} className="me-3">
                              <input
                                type="radio"
                                className="btn-check"
                                name="btnradio-layout"
                                checked={layout === item.value}
                                id={item.value + 'layout'}
                                autoComplete="off"
                                value={item.value}
                                onChange={e => setLayout(e.target.value)}
                              />
                              <label
                                className="btn btn-outline-info"
                                htmlFor={item.value + 'layout'}
                              >
                                {item.title}
                              </label>
                            </div>
                          ))}
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>

                {groupType?.length >= 1 && (
                  <Col xl="12">
                    <Card>
                      <CardBody>
                        <Row>
                          {isPremium && (
                            <>
                              {(groupType === 'collections' ||
                                groupType === 'single collection') && (
                                <Col xl="4" lg="4" md="6" sm="12">
                                  <CardTitle className="h4 mb-4 text_deals_green ">
                                    Collections
                                  </CardTitle>
                                  <FormGroup className="mb-3">
                                    <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                                      <Select
                                        onInputChange={handleCollectionEnters}
                                        value={collectionSelect?.title}
                                        placeholder={collectionSelect?.title}
                                        onChange={handlerCollectionFinalValue}
                                        options={collectionOptions}
                                        classNamePrefix="select2-selection"
                                        isLoading={CollectionLoading}
                                      />
                                    </div>
                                  </FormGroup>
                                  {map(selectedCollectionArray, (item, key) => (
                                    <div key={key} className="mb-2">
                                      <div className="d-flex align-items-center">
                                        <img
                                          className="img-thumbnail rounded-circle "
                                          style={{
                                            height: '60px',
                                            width: '60px',
                                          }}
                                          src={item?.image}
                                          alt="product"
                                        />
                                        <p className="pb-0 mb-0 px-3">
                                          {item?.title}
                                        </p>
                                        <i
                                          className="bx bx-trash-alt "
                                          style={{
                                            fontSize: '1rem',
                                            color: 'red',
                                            cursor: 'pointer',
                                          }}
                                          onClick={() =>
                                            handleRemoveSeletedCollection(
                                              item?.id,
                                            )
                                          }
                                        ></i>
                                      </div>
                                    </div>
                                  ))}
                                </Col>
                              )}
                            </>
                          )}

                          {(groupType === 'categories' ||
                            groupType === 'single category') && (
                            <Col xl="4" lg="4" md="6" sm="12">
                              <CardTitle className="h4 mb-4 text_deals_green ">
                                Category
                              </CardTitle>
                              <FormGroup className="mb-3">
                                <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                                  <Select
                                    onInputChange={handleCategoryEnters}
                                    value={categorySelect?.title}
                                    placeholder={categorySelect?.title}
                                    onChange={handlerCategoryFinalValue}
                                    options={categoryOptions}
                                    classNamePrefix="select2-selection"
                                    isLoading={catLoading}
                                  />
                                </div>
                              </FormGroup>
                              {map(selectedCategoryArray, (item, key) => (
                                <div key={key} className="mb-2">
                                  <div className="d-flex align-items-center">
                                    <img
                                      className="img-thumbnail rounded-circle "
                                      style={{ height: '60px', width: '60px' }}
                                      src={item?.image}
                                      alt="product"
                                    />
                                    <p className="pb-0 mb-0 px-3">
                                      {item?.title}
                                    </p>
                                    <i
                                      className="bx bx-trash-alt "
                                      style={{
                                        fontSize: '1rem',
                                        color: 'red',
                                        cursor: 'pointer',
                                      }}
                                      onClick={() =>
                                        handleRemoveSeletedCategory(item?.id)
                                      }
                                    ></i>
                                  </div>
                                </div>
                              ))}
                            </Col>
                          )}
                          {(groupType === 'single category' ||
                            groupType === 'single collection') && (
                            <Col xl="4" lg="4" md="6" sm="12">
                              <CardTitle className="h4 mb-4 text_deals_green ">
                                Product
                              </CardTitle>
                              <FormGroup className="mb-3">
                                <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                                  <Select
                                    onInputChange={handleProductEnters}
                                    value={productSelect?.title}
                                    placeholder={productSelect?.title}
                                    onChange={handlerProductFinalValue}
                                    options={productOptions}
                                    classNamePrefix="select2-selection"
                                    isLoading={proLoading}
                                  />
                                </div>
                              </FormGroup>
                              {map(selectedProductArray, (item, key) => (
                                <div key={key} className="mb-2">
                                  <div className="d-flex align-items-center">
                                    <img
                                      className="img-thumbnail rounded-circle "
                                      style={{
                                        height: '60px',
                                        width: '60px',
                                      }}
                                      src={item?.image}
                                      alt="product"
                                    />
                                    <p className="pb-0 mb-0 px-3">
                                      {item?.title}
                                    </p>
                                    <i
                                      className="bx bx-trash-alt "
                                      style={{
                                        fontSize: '1rem',
                                        color: 'red',
                                        cursor: 'pointer',
                                      }}
                                      onClick={() =>
                                        handleRemoveSeletedproduct(item?.id)
                                      }
                                    ></i>
                                  </div>
                                </div>
                              ))}
                            </Col>
                          )}
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                )}

                <Col xl="12">
                  <Card>
                    <CardBody>
                      {isMult && (
                        <LanguageTab
                          setActiveTab={setActiveTab}
                          activeTab={activeTab}
                        />
                      )}
                      <Col xl="4" lg="4" md="6" sm="12">
                        <CardTitle className="h4 mb-4 text_deals_green">
                          Priority
                        </CardTitle>
                        <div className="mb-3">
                          <AvField name="priority" label="" type="number" />
                        </div>
                      </Col>

                      <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                          <Col className="col-12 d-flex flex-wrap">
                            <div className="col-12 mb-3">
                              <AvField
                                name="primaryLang.title"
                                className=""
                                label="Title"
                                type="text"
                              />
                            </div>
                            <div className="col-12 mb-3">
                              <AvField
                                name="primaryLang.description"
                                label="Description"
                                type="textarea"
                                errorMessage="Invalid description"
                                rows="7"
                              />
                            </div>
                          </Col>
                        </TabPane>

                        <TabPane tabId="2">
                          <Col className="col-12 d-flex flex-wrap">
                            <div className="col-12 mb-3">
                              <div className="d-flex justify-content-between mb-2">
                                <CardSubtitle>Title</CardSubtitle>
                                <CardSubtitle>اسم</CardSubtitle>
                              </div>
                              <AvField
                                style={{ textAlign: 'right' }}
                                name="secondaryLang.title"
                                label=""
                                type="text"
                              />
                            </div>
                            <div className="col-12 mb-3">
                              <div className="d-flex justify-content-between mb-2">
                                <CardSubtitle>Description</CardSubtitle>
                                <CardSubtitle>وصف</CardSubtitle>
                              </div>
                              <AvField
                                style={{ textAlign: 'right' }}
                                name="secondaryLang.description"
                                label=""
                                type="textarea"
                                rows="7"
                              />
                            </div>
                          </Col>
                        </TabPane>
                      </TabContent>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col className="col-12"></Col>
              </Row>
              <Card>
                <CardBody>
                  <Row>
                    <Col md="6">
                      <Label className="mb-2">Banner</Label>
                      <UseImageUploader
                        imageSize={4}
                        imagesPreview={bannerImagesPreview}
                        setImagesPreview={setBannerImagesPreview}
                        uploadUrl={'/categories'}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Row xl="12">
                <Col xl="12">
                  <Card>
                    <CardBody>
                      <CardTitle className="h4 mb-4 text_deals_green"></CardTitle>
                      <Row>
                        <Col>
                          <div className="text-end">
                            <button
                              type="submit"
                              className="col-12 btn btn-success save-customer"
                            >
                              {loading && (
                                <>
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                </>
                              )}
                              Create Group
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </AvForm>
          </div>
        </Container>
      </div>
    </>
  )
}

export default CrateGroups

export const GroupTypesData = [
  {
    id: 11,
    title: 'Single Collection',
    value: 'single collection',
  },
  {
    id: 22,
    title: 'Collections',
    value: 'collections',
  },

  {
    id: 33,
    title: 'Single Category',
    value: 'single category',
  },
  {
    id: 44,
    title: 'Categories',
    value: 'categories',
  },
]

export const LayoutData = [
  {
    id: 1,
    title: 'LIST VERTICAL',
    value: 'list_vertical',
  },
  {
    id: 2,
    title: 'LIST HORIZONTAL',
    value: 'list_horizontal',
  },
  {
    id: 3,
    title: 'LIST VERTICAL SMALL',
    value: 'list_vertical_sm',
  },
  {
    id: 4,
    title: 'HIGHLIGHT',
    value: 'highlight',
  },
  {
    id: 5,
    title: ' CAROUSEL',
    value: 'carousel',
  },
]
