import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Modal, ModalBody, Row } from 'reactstrap'
import { WinnerApprove } from 'store/actions'
import animation from 'assets/images/brands/animation.gif'
import luckyDrawLoad from 'assets/images/other/luckyDrawLoad.gif'

import { useParams } from 'react-router-dom'
const WinnerModal = ({ show, onCloseClick }) => {
  const dispatch = useDispatch()
  const params = useParams()

  const [showWinner, setShowWinner] = useState(false)

  const { loading, winnerDetails } = useSelector(state => ({
    winnerDetails: state.SupplyChain.InitiateLuckyDraw?.winner,
    loading: state.SupplyChain.loading,
  }))

  function handleApprove() {
    onCloseClick()
    const idSent = {
      winner: winnerDetails?._id,
    }
    dispatch(WinnerApprove(idSent, params?.id))
  }

  useEffect(() => {
    setTimeout(() => {
      setShowWinner(true)
    }, 4000)
  }, [])

  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      centered={true}
      style={WinnerModalStyle}
    >
      <ModalBody style={WinnerModalBodyStyle} className="p-4 ">
        {showWinner ? (
          <Row xl="12">
            <Col
              xl="12"
              style={{
                minHeight: 300,
              }}
              className="d-flex flex-column justify-content-between"
            >
              <img
                src={animation}
                style={{
                  position: 'absolute',
                  bottom: 70,
                  left: 25,
                  transform: 'rotate(40deg)',
                }}
              />
              <img
                src={animation}
                style={{
                  position: 'absolute',
                  bottom: 70,
                  right: 25,
                  transform: 'rotate(-40deg)',
                }}
              />
              <div className="d-flex flex-column align-items-center justify-content-center ">
                <h4 className="text-bolder text-success mt-1 fw-bold">
                  Lucky draw Winner
                </h4>
                <div
                  className="position-relative d-flex align-items-center justify-content-center "
                  style={{ height: '160px' }}
                >
                  {!winnerDetails?.promoter?.name && (
                    <h4
                      style={{
                        minWidth: '250px',
                      }}
                      className="name position-absolute text-center top-50 start-50 translate-middle"
                    >
                      {winnerDetails?.promoter?.phone}
                    </h4>
                  )}
                  {winnerDetails?.promoter?.name && (
                    <h4
                      style={{
                        minWidth: '250px',
                      }}
                      className="name position-absolute text-center top-50 start-50 translate-middle"
                    >
                      {winnerDetails?.promoter?.name}
                    </h4>
                  )}
                </div>
              </div>
              <div>
                <Row>
                  {/* <div className=" text-center">
                    <h6>
                      Chain Position :{' '}
                      <span className="mr-3">
                        {winnerDetails?.chainPosition}
                      </span>
                    </h6>
                  </div> */}
                  <div className=" text-center">
                    <h6>
                      Ref Id :{' '}
                      <span className="mr-3">{winnerDetails?.ref_id}</span>
                    </h6>
                  </div>
                </Row>
                <div className="d-flex">
                  <button
                    type="submit"
                    className="col-6 mt-3 btn btn-outline-info save-customer"
                    onClick={onCloseClick}
                  >
                    {loading && (
                      <>
                        <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                      </>
                    )}
                    Close
                  </button>
                  <button
                    type="button"
                    className="col-6 mt-3 btn btn-success save-customer ms-2"
                    onClick={handleApprove}
                  >
                    {loading && (
                      <>
                        <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                      </>
                    )}
                    Approve
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        ) : (
          <Row>
            <h6 className="text-center">Picking a winner...</h6>
            <img src={luckyDrawLoad} alt="luckyDrawLoad" />

            <p className="text-center text-muted">
              Please wait, we are finding the winner{' '}
            </p>
          </Row>
        )}
      </ModalBody>
    </Modal>
  )
}

WinnerModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
}

export default WinnerModal

const WinnerModalStyle = {
  width: 400,
  minWidth: 400,
}
const WinnerModalBodyStyle = {
  minHeight: 320,
}
