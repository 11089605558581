import React from 'react'
import Switch from 'react-switch'
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  FormGroup,
  Table,
} from 'reactstrap'
import { CardTitle, TabContent, TabPane } from 'reactstrap'
import { Col, Container, Label, Row } from 'reactstrap'
import Select from 'react-select'
import { debounce, map } from 'lodash'
import DatePikerTime from 'components/Common/TimePicker'

import Breadcrumbs from '../../../../components/Common/Breadcrumb'

// Form Editor
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import FormEditors from '../Create/Editor'

import { WithContext as ReactTags } from 'react-tag-input'
import 'assets/css/style.css'

const KeyCodes = {
  comma: 188,
  enter: 13,
}

const delimiters = [KeyCodes.comma, KeyCodes.enter]

//actions
import {
  getArea_lists,
  getProductDetails,
  updateProduct,
  getCategories,
  checkProductName,
  getPages,
  getCourseProviders,
  clearProductData,
  // getStoreList,
} from 'store/actions'

import LanguageTab from 'components/Common/LanguageTab'
import { LanguageSwitch } from 'hooks/LanguageSwitch'
import MetaTag from 'components/Common/Meta-tag'
import { UnitsList } from '../Create/Create'
import UseImageUploader from 'components/imageUploader/useImageUploader'
import { useSiteVersion } from 'hooks/useSiteVersion'
import { lastSpaceRemover } from 'hooks/LastSpaceRemover'

const UpdateProduct = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams()
  const { isPremium } = useSiteVersion()
  const {
    productDetails,
    loading,
    categoryList,
    currency,
    productCheck,
    pagesList,
    pageLoading,
    courseProviders,
    providerLoading,
  } = useSelector(state => ({
    productDetails: state.Products.productDetails,
    loading: state.Products.loading,
    categories: state.Category.categories,
    categoryList: state.Category.categories?.categories,
    // storeList: state.Stores.storeList,
    currency: state.Settings.settings.company?.defaultCurrencySymbol,
    productCheck: state.Products.checkProductNames,
    pagesList: state.Pages.pages,
    pageLoading: state.Pages.loading,
    courseProviders: state.CourseProvider.courseProviders,
    providerLoading: state.CourseProvider.loading,
  }))

  const [selectedMulti, setSelectedMulti] = useState(null)

  const optionGroup = categoryList?.map(category => {
    return {
      value: category._id,
      label: category.primaryLang.name,
    }
  })

  useEffect(() => {
    dispatch(clearProductData())
  }, [dispatch])

  function handleMulti(categoryData) {
    setSelectedMulti(categoryData)
    const arrangedCategories = categoryData.map(a => a.value)
    setCategories(arrangedCategories)
  }

  const [tags, setTags] = React.useState([])

  const suggestions = categoryList?.map(category => {
    return {
      id: category._id,
      text: category.primaryLang.name,
    }
  })

  const handleDeleteTag = i => {
    setTags(tags.filter((tag, index) => index !== i))
    const arrangedKeywords = tags?.map(a => a.text)
    setKeywords(arrangedKeywords)
  }

  const handleAddition = tag => {
    setTags([...tags, tag])
    const arrangedKeywords = [...tags, tag].map(a => a.text)
    setKeywords(arrangedKeywords)
  }

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice()
    newTags.splice(currPos, 1)
    newTags.splice(newPos, 0, tag)
    // re-render
    setTags(newTags)
    const arrangedKeywords = newTags.map(a => a.text)
    setKeywords(arrangedKeywords)
  }

  const handleTagClick = () => {}

  const [varients, setVarients] = useState([
    {
      value: '',
      unit: 'kg',
      cost: '',
      price: '',
      offerPrice: '',
      primePrice: '',
      stock: '',
    },
  ])

  const [imagesPreview, setImagesPreview] = useState([])

  // const [taxPercentage, setTaxPercentage] = useState(0)
  // const [fileSize, setFileSize] = useState("")

  //baseData
  // const [itemCode, setItemCode] = useState("")
  // const [barcode, setBarcode] = useState("")
  const [primaryLangName, setPrimaryLangName] = useState('')
  const [primaryLangDescription, setPrimaryLangDescription] = useState('')
  const [secondaryLangDescription, setSecondaryLangDescription] = useState('')
  const [secondaryLangName, setSecondaryLangName] = useState('')
  const [primaryLangHighlights, setPrimaryLangHighlights] = useState('')
  const [secondaryLangHighlights, setSecondaryLangHighlights] = useState('')

  //classifications
  const [keywords, setKeywords] = useState([])
  const [categories, setCategories] = useState([])

  //packageData
  // const [weight, setWeight] = useState(productDetails?.weight)
  const [areaList, setAreaList] = useState([])

  //labels
  const [isFeatured, setIsFeatured] = useState(false)
  const [isCourse, setIsCourse] = useState(false)
  const [isReturnViable, setIsReturnViable] = useState(
    productDetails?.isReturnViable,
  )

  const [isDailyProduct, setIsDailyProduct] = useState(false)
  const [isSpecialOffer, setIsSpecialOffer] = useState(false)
  const [isPageAttached, setIsPageAttached] = useState(false)

  const [maxOrderQuantity, setMaxOrderQuantity] = useState('')
  const [minOrderQuantity, setMinOrderQuantity] = useState('')

  const [selectDateTimeFrom, setSelectDateTimeFrom] = useState('00:00')
  const [selectDateTimeTo, setSelectDateTimeTo] = useState('00:00')

  const [pageSelect, setPageSelect] = useState({
    id: '',
    title: 'Search a Pages',
  })
  const [courseProviderSelect, setCourseProviderSelect] = useState({
    id: null,
    name: 'Search course provider',
  })

  //values
  const [taxValue, setTaxValue] = useState(0)
  const [isDiscounted, setIsDiscounted] = useState(false)
  const [discountPercentage, setDiscountPercentage] = useState(0)
  const [discountValue, setDiscountValue] = useState(0)
  // const [price, setPrice] = useState(0)
  // const [cost, setCost] = useState(0)
  // const [profit, setProfit] = useState(0)
  const [sellingPrice, setSellingPrice] = useState(0)
  const [isTaxIncluded, setIsTaxIncluded] = useState(false)

  //stock

  const [hasVarients, setHasVarients] = useState(true)

  const [activeTab, setActiveTab] = useState('1')

  const isMult = LanguageSwitch()?.isMult

  const primaryHighlights = productDetails?.primaryLang?.highlights
    ? productDetails?.primaryLang?.highlights
    : ''
  const secondaryHighlights = productDetails?.secondaryLang?.highlights
    ? productDetails?.secondaryLang?.highlights
    : ''

  const primaryDescription = productDetails?.primaryLang?.description
    ? productDetails?.primaryLang?.description
    : ''

  useEffect(() => {
    const availableAreasNew = productDetails?.availableAreas?.map(i => i._id)

    setAreaList(availableAreasNew ? availableAreasNew : [])
  }, [productDetails])

  useEffect(() => {
    // dispatch(getStoreList())
    dispatch(getCategories())
    dispatch(getArea_lists(1))
  }, [dispatch])

  useEffect(() => {
    setPrimaryLangHighlights(primaryHighlights)
    setSecondaryLangHighlights(secondaryHighlights)
  }, [])

  useEffect(() => {
    setPrimaryLangName(productDetails?.primaryLang?.name)
    // setPrimaryKeywordsArray(productDetails?.primaryLang?.keywords)
    setPrimaryLangDescription(primaryDescription)
    setPrimaryLangHighlights(primaryHighlights)
  }, [productDetails])

  useEffect(() => {
    setImagesPreview(productDetails?.images)
  }, [productDetails])

  useEffect(() => {
    setSecondaryLangName(productDetails?.secondaryLang?.name)
    setSecondaryLangDescription(productDetails?.secondaryLang?.description)
    // setSecondaryKeywordsArray(productDetails?.secondaryLang?.keywords)
    setSecondaryLangHighlights(secondaryHighlights)
  }, [productDetails])

  useEffect(() => {
    setSellingPrice(productDetails?.sellingPrice)

    setTaxValue(productDetails?.taxValue)
    setDiscountPercentage(productDetails?.discountPercentage)
    setDiscountValue(productDetails?.discountValue)
    setIsDiscounted(productDetails?.isDiscounted)
    setIsTaxIncluded(productDetails?.isTaxIncluded)
    setHasVarients(productDetails?.hasVarients)
    setVarients(productDetails?.varients)
  }, [productDetails])

  useEffect(() => {}, [productDetails])

  useEffect(() => {
    setIsFeatured(productDetails?.isFeatured)
  }, [productDetails])
  console.log(productDetails)
  useEffect(() => {
    setIsReturnViable(productDetails?.isCourse)
    setIsCourse(productDetails?.isCourse)
  }, [productDetails])

  useEffect(() => {
    setIsDailyProduct(productDetails?.isDailyProduct)
    setIsSpecialOffer(productDetails?.isSpecialOffer)
    setMinOrderQuantity(productDetails?.minOrderQuantity)
    setMaxOrderQuantity(productDetails?.maxOrderQuantity)
    setSelectDateTimeFrom(productDetails?.availableTimeFrom)
    setSelectDateTimeTo(productDetails?.availableTimeTo)
    setIsPageAttached(productDetails?.isPageAttached)
    setPageSelect({
      id: productDetails?.page?._id,
      title: productDetails?.page?.title,
    })
    setCourseProviderSelect({
      id: productDetails?.courseProvider?._id ?? null,
      name: productDetails?.courseProvider?.name
        ? productDetails?.courseProvider?.name
        : 'Select course provider',
    })
  }, [productDetails])

  useEffect(() => {
    const categoryList = productDetails?.categories

    const newList = map(categoryList, i => ({
      ...selectedMulti,
      label: i?.primaryLang?.name,
      value: i?._id,
    }))
    setSelectedMulti(newList)

    const idList = map(categoryList, i => i?._id)

    setCategories(idList)
  }, [productDetails?.categories])

  useEffect(() => {
    const keyWordList = productDetails?.keywords
    const modifiedList = []
    keyWordList?.map(i => modifiedList?.push({ id: i, text: i }))

    setTags(modifiedList)
    setKeywords(keyWordList)
  }, [productDetails])

  function handleValidSubmit(e, values) {
    const product = {
      // store: values.store,
      ...values,
      images: imagesPreview,
      // itemCode: itemCode,
      // barcode: barcode,
      primaryLang: {
        name: lastSpaceRemover(primaryLangName),
        description: primaryLangDescription,
        highlights: primaryLangHighlights,
      },
      secondaryLang: {
        name: lastSpaceRemover(secondaryLangName),
        description: secondaryLangDescription,
        highlights: secondaryLangHighlights,
      },
      keywords,
      categories,
      varients: varients,
      hasVarients: hasVarients,

      sellingPrice: sellingPrice,
      isTaxIncluded: isTaxIncluded,
      taxValue: taxValue,
      isDiscounted: isDiscounted,
      discountPercentage: discountPercentage,
      discountValue: discountValue,

      //labels
      isPageAttached: isPageAttached,
      page: isPageAttached ? pageSelect?.id : null || null,
      isReturnViable: isReturnViable,
      isDailyProduct: isDailyProduct,
      isSpecialOffer: isSpecialOffer,
      isFeatured: isFeatured,
      isCourse: isCourse,
      courseProvider: courseProviderSelect?.id,
      // extras: extras,
      maxOrderQuantity: parseInt(maxOrderQuantity),
      minOrderQuantity: parseInt(minOrderQuantity),
      availableTimeFrom: selectDateTimeFrom,
      availableTimeTo: selectDateTimeTo,

      availableAreas: areaList,

      taxPercentage: parseInt(values?.taxPercentage),
      packageData: {
        breadth: parseInt(values?.packageData?.breadth),
        height: parseInt(values?.packageData?.height),
        length: parseInt(values?.packageData?.length),
        weight: parseInt(values?.packageData?.weight),
      },
    }

    dispatch(updateProduct(product, params.id, history))
  }

  const updateProductWhenImageChange = (newImageId, type, newImageObj) => {
    if (type === 'remove') {
      const newImage = imagesPreview?.filter(i => i?.public_id !== newImageId)
      if (newImageId) {
        dispatch(
          updateProduct({ ...productDetails, images: newImage }, params.id),
        )
      }
    }
    if (type === 'add') {
      const newImage = [...imagesPreview, newImageObj]
      if (newImageId && newImage) {
        dispatch(
          updateProduct({ ...productDetails, images: newImage }, params.id),
        )
      }
    }
  }

  ///

  useEffect(() => {
    dispatch(getProductDetails(params?.id))
  }, [dispatch])

  const validateName = v => {
    let name = v.target.value
    name = name.replace(/-/g, '')
    name = name.replace('/', '')
    v.target.value = name

    setPrimaryLangName(name)
  }

  const [productName, setProductName] = useState()

  const debounceProductName = debounce(value => setProductName(value), 600)

  useEffect(() => {
    if (productDetails?._id && productName) {
      dispatch(checkProductName(productName, productDetails?._id))
    }
  }, [productName])

  //page select
  const [pageSearch, setPageSearch] = useState('')

  const debounceProductSearch = debounce(value => setPageSearch(value), 600)
  const handleProductEnters = textEntered => {
    debounceProductSearch(textEntered)
  }

  function handlerPageFinalValue(event) {
    const product = {
      ...pageSelect,
      title: event.label,
      id: event.value,
      image: event.image,
      varients: event.varients,
    }
    setPageSelect(product)
  }

  const pageOptions = [
    {
      options: pagesList?.pages?.map((result, index) => ({
        key: index,
        label: result?.title,
        value: result?._id,
      })),
    },
  ]

  useEffect(() => {
    dispatch(getPages('', '', pageSearch))
  }, [pageSearch])

  const [providerSearch, setProviderSearch] = useState('')

  const debounceProviderSearch = debounce(
    value => setProviderSearch(value),
    600,
  )
  const handleProviderEnters = textEntered => {
    debounceProviderSearch(textEntered)
  }

  function handlerProviderFinalValue(event) {
    const provider = {
      ...courseProviderSelect,
      name: event.label,
      id: event.value,
    }
    setCourseProviderSelect(provider)
  }

  const providerOptions = [
    {
      options: courseProviders?.courseProviders?.map((result, index) => ({
        key: index,
        label: result?.name,
        value: result?._id,
      })),
    },
  ]

  useEffect(() => {
    dispatch(getCourseProviders(1, '', 10, providerSearch))
  }, [providerSearch])

  return (
    <>
      <MetaTag title="Update Product" />

      <div className="page-content">
        <Breadcrumbs title="Products" breadcrumbItem="Update Product" />
        <Container fluid>
          <div className="container-fluid">
            <AvForm
              onValidSubmit={(e, v) => {
                handleValidSubmit(e, v)
              }}
            >
              <Row xl="12">
                <Col xl="12">
                  <Card>
                    <CardBody>
                      <CardTitle className="h4 mb-4 text_deals_green ">
                        <div style={{ position: 'absolute' }} className="">
                          Base Data
                        </div>
                        <div>
                          <div
                            className="text-end"
                            style={{ position: 'relative', top: 0 }}
                          >
                            <button
                              type="submit"
                              className="btn btn-success save-customer px-4"
                            >
                              {loading && (
                                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                              )}
                              Save
                            </button>
                          </div>
                        </div>
                      </CardTitle>
                      <Row>
                        {isPremium && (
                          <Col className="col-7 d-flex flex-wrap flex-column  justify-content-start">
                            <div>
                              {/* <Button
                                color={isDailyProduct ? 'success' : 'light'}
                                className="special_buttons mb-2 no-eff me-2"
                                type="button"
                                onClick={() => {
                                  setIsDailyProduct(!isDailyProduct)
                                }}
                              >
                                Daily
                                <br /> Product
                              </Button>

                              <Button
                                color={isSpecialOffer ? 'success' : 'light'}
                                className="special_buttons mb-2 no-eff me-2"
                                type="button"
                                onClick={() => {
                                  setIsSpecialOffer(!isSpecialOffer)
                                }}
                              >
                                Special
                                <br /> Offer
                              </Button> */}

                              <Button
                                color={isPageAttached ? 'success' : 'light'}
                                className="special_buttons mb-2 no-eff me-2"
                                type="button"
                                onClick={() => {
                                  setIsPageAttached(!isPageAttached)
                                }}
                              >
                                Attach
                                <br /> Page
                              </Button>
                              <Button
                                color="light"
                                className="special_buttons border-none mb-2 no-eff me-2"
                                type="button"
                                style={{
                                  cursor: 'default',
                                }}
                              >
                                <p className="mb-2"> Return Viable</p>
                                <Switch
                                  uncheckedIcon={<OffSymbol />}
                                  checkedIcon={<OnSymbol />}
                                  className="w-100 ms-2 mb-sm-8"
                                  onColor="#2a9c72"
                                  onChange={v => {
                                    setIsReturnViable(v)
                                  }}
                                  checked={isReturnViable ?? false}
                                />
                              </Button>
                              <Button
                                color="light"
                                className="special_buttons border-none mb-1 no-eff me-3"
                                type="button"
                                style={{
                                  cursor: 'default',
                                }}
                              >
                                <p className="mb-2">Featured</p>
                                <Switch
                                  uncheckedIcon={<OffSymbol />}
                                  checkedIcon={<OnSymbol />}
                                  className="w-100 ms-3 mb-sm-8"
                                  onColor="#2a9c72"
                                  onChange={v => {
                                    setIsFeatured(v)
                                  }}
                                  checked={isFeatured ?? false}
                                />
                              </Button>
                              <Button
                                color="light"
                                className="special_buttons border-none mb-1 no-eff me-3"
                                type="button"
                                style={{
                                  cursor: 'default',
                                }}
                              >
                                <p className="mb-2">Course</p>
                                <Switch
                                  uncheckedIcon={<OffSymbol />}
                                  checkedIcon={<OnSymbol />}
                                  className="w-100 ms-3 mb-sm-8"
                                  onColor="#2a9c72"
                                  onChange={v => {
                                    setIsCourse(v)
                                  }}
                                  checked={isCourse ?? false}
                                />
                              </Button>
                            </div>
                          </Col>
                        )}
                        <Col className="col-5 d-flex flex-wrap flex-column justify-content-start">
                          <div className="d-flex">
                            <div className="mb-3">
                              <AvField
                                name="itemCode"
                                label="Item Code"
                                type="text"
                                value={productDetails?.itemCode}
                              />
                            </div>

                            <div className="mb-3 mx-3">
                              <AvField
                                name="barcode"
                                label="Barcode"
                                type="text"
                                value={productDetails.barcode}
                              />
                            </div>
                          </div>
                        </Col>

                        {isCourse ? (
                          <div className="w-25 mt-2">
                            <p
                              style={{
                                fontWeight: 500,
                                marginBottom: '0.5rem',
                              }}
                            >
                              Course Providers
                            </p>
                            <FormGroup className="mb-3">
                              <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                                <Select
                                  onInputChange={handleProviderEnters}
                                  value={courseProviderSelect?.name}
                                  placeholder={courseProviderSelect?.name}
                                  onChange={handlerProviderFinalValue}
                                  options={providerOptions}
                                  classNamePrefix="select2-selection"
                                  isLoading={providerLoading}
                                />
                              </div>
                            </FormGroup>
                          </div>
                        ) : (
                          <></>
                        )}

                        {isPremium && (
                          <div className="mt-4 mx-2 d-flex">
                            {isDailyProduct ? (
                              <div className="d-flex me-4 flex-wrap flex-column align-items-start justify-content-center mb-4 timePickerWrapper">
                                <div className="d-flex align-items-center justify-content-center ">
                                  <label className="m-0 me-2">
                                    Available Time From :{' '}
                                  </label>
                                  <DatePikerTime
                                    setState={setSelectDateTimeFrom}
                                    state={selectDateTimeFrom}
                                  />
                                </div>
                                <div>&nbsp;&nbsp;&nbsp;</div>
                                <div className="d-flex align-items-center justify-content-center">
                                  <label className="m-0 me-2">
                                    Available Time To :{' '}
                                  </label>
                                  <DatePikerTime
                                    setState={setSelectDateTimeTo}
                                    state={selectDateTimeTo}
                                  />
                                </div>
                              </div>
                            ) : (
                              ''
                            )}
                            {isSpecialOffer ? (
                              <div className="me-4 d-flex  align-items-start jus">
                                <div className="me-4">
                                  <AvField
                                    name="minOrderQuantity"
                                    label="Min Order Quantity"
                                    type="number"
                                    onChange={v => {
                                      setMinOrderQuantity(v.target.value)
                                    }}
                                    value={minOrderQuantity}
                                    min={0}
                                  />
                                </div>
                                <div className="">
                                  <AvField
                                    name="maxOrderQuantity"
                                    label="Max Order Quantity"
                                    type="number"
                                    onChange={v => {
                                      setMaxOrderQuantity(v.target.value)
                                    }}
                                    value={maxOrderQuantity}
                                    min={0}
                                  />
                                </div>
                              </div>
                            ) : (
                              ''
                            )}
                            {isPageAttached ? (
                              <div className="w-25">
                                <p
                                  style={{
                                    fontWeight: 500,
                                    marginBottom: '0.5rem',
                                  }}
                                >
                                  Pages
                                </p>
                                <FormGroup className="mb-3">
                                  <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                                    <Select
                                      onInputChange={handleProductEnters}
                                      value={pageSelect?.title}
                                      placeholder={pageSelect?.title}
                                      onChange={handlerPageFinalValue}
                                      options={pageOptions}
                                      classNamePrefix="select2-selection"
                                      isLoading={pageLoading}
                                    />
                                  </div>
                                </FormGroup>
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        )}

                        <div className="mt-5">
                          <UseImageUploader
                            imageSize={4}
                            imagesPreview={imagesPreview}
                            setImagesPreview={setImagesPreview}
                            onChangeUpdate={updateProductWhenImageChange}
                            uploadUrl={'/products'}
                            isMultiple
                          />
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row xl="12">
                <Col className="col-12">
                  <Card>
                    <CardBody>
                      {isMult && (
                        <LanguageTab
                          setActiveTab={setActiveTab}
                          activeTab={activeTab}
                        />
                      )}
                      <TabContent activeTab={activeTab} className="">
                        <TabPane tabId="1">
                          <Row>
                            <Col className="col-12 d-flex flex-wrap">
                              <Col className="col-12 d-flex flex-wrap">
                                <div className="col-12 mb-3">
                                  <AvField
                                    name="primaryLang.name"
                                    label="Name"
                                    type="text"
                                    onKeyUp={v => validateName(v)}
                                    errorMessage="Invalid product name"
                                    validate={{
                                      required: { value: true },
                                    }}
                                    value={
                                      productDetails?.primaryLang &&
                                      productDetails?.primaryLang?.name
                                    }
                                    onChange={e => {
                                      debounceProductName(e.target.value)
                                    }}
                                  />
                                  {productName && (
                                    <>
                                      {productCheck?.response === false && (
                                        <h6
                                          className="pt-1"
                                          style={{ color: 'red' }}
                                        >
                                          Not Available{' '}
                                        </h6>
                                      )}
                                    </>
                                  )}
                                </div>

                                {/* //Description */}
                                <div className="col-12 mb-3">
                                  <Label
                                    htmlFor="lbltype"
                                    className="form-label mt-2"
                                  >
                                    Description
                                  </Label>
                                  <Col sm="12" className="mt-2">
                                    {primaryLangDescription?.length >= 1 && (
                                      <FormEditors
                                        content={primaryLangDescription}
                                        setContent={setPrimaryLangDescription}
                                      />
                                    )}
                                    {primaryLangDescription?.length <= 0 && (
                                      <FormEditors
                                        content={primaryLangDescription}
                                        setContent={setPrimaryLangDescription}
                                      />
                                    )}
                                  </Col>
                                </div>

                                {/* Highlights */}
                                <div className="col-12 mb-3">
                                  <Label
                                    htmlFor="lbltype"
                                    className="form-label mt-2"
                                  >
                                    Highlights
                                  </Label>
                                  <Col sm="12" className="mt-2">
                                    {primaryLangHighlights?.length >= 1 && (
                                      <FormEditors
                                        content={primaryLangHighlights}
                                        setContent={setPrimaryLangHighlights}
                                      />
                                    )}
                                    {primaryLangHighlights?.length <= 0 && (
                                      <FormEditors
                                        content={primaryLangHighlights}
                                        setContent={setPrimaryLangHighlights}
                                      />
                                    )}
                                  </Col>
                                </div>
                              </Col>
                            </Col>
                          </Row>
                        </TabPane>

                        <TabPane tabId="2">
                          <Row>
                            <Col className="col-12 d-flex flex-wrap">
                              <Col className="col-12 d-flex flex-wrap">
                                <div className="col-12 mb-3">
                                  <div className="d-flex justify-content-between mb-2">
                                    <CardSubtitle>Name</CardSubtitle>
                                    <CardSubtitle>اسم</CardSubtitle>
                                  </div>
                                  <AvField
                                    value={productDetails?.secondaryLang?.name}
                                    name="secondaryLang.name"
                                    label=""
                                    type="text"
                                    onChange={v => {
                                      setSecondaryLangName(v.target.value)
                                    }}
                                  />
                                </div>
                                <div className="col-12 mb-3">
                                  <div className="d-flex justify-content-between mb-2">
                                    <CardSubtitle>Description</CardSubtitle>
                                    <CardSubtitle>وصف</CardSubtitle>
                                  </div>
                                  <AvField
                                    name="secondaryLang.description"
                                    label=""
                                    type="textarea"
                                    onChange={v => {
                                      setSecondaryLangDescription(
                                        v.target.value,
                                      )
                                    }}
                                    rows="7"
                                    value={
                                      productDetails?.secondaryLang?.description
                                    }
                                  />
                                </div>

                                <div className="col-12 mb-3">
                                  <div className="d-flex justify-content-between mb-2">
                                    <CardSubtitle>Highlights</CardSubtitle>
                                    <CardSubtitle>يسلط الضوء</CardSubtitle>
                                  </div>
                                  <Col sm="12" className="mt-4">
                                    <FormEditors
                                      content={secondaryLangHighlights}
                                      setContent={setSecondaryLangHighlights}
                                    />
                                  </Col>
                                </div>
                              </Col>
                            </Col>
                          </Row>
                        </TabPane>
                      </TabContent>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              {/* classifications */}
              <Row>
                <Col xl="12">
                  <Card>
                    <CardBody>
                      {/* <CardTitle
                        className="h4 mb-4 text_deals_green"
                        
                      >
                        Classifications
                      </CardTitle> */}
                      <Row>
                        <Col xl="12">
                          <Row>
                            <div className="mb-3">
                              <label className="control-label">
                                Categories
                              </label>
                              <Select
                                value={selectedMulti}
                                isMulti={true}
                                onChange={e => {
                                  handleMulti(e)
                                }}
                                options={optionGroup}
                                classNamePrefix="select2-selection"
                              />
                            </div>

                            <div className="col-12 mb-3">
                              <Label htmlFor="lbltype" className="form-label">
                                Keywords
                              </Label>
                              <ReactTags
                                tags={tags}
                                placeholder="Type TEXT and enter"
                                suggestions={suggestions}
                                delimiters={delimiters}
                                handleDelete={handleDeleteTag}
                                handleAddition={handleAddition}
                                handleDrag={handleDrag}
                                handleTagClick={handleTagClick}
                                // inputFieldPosition="inline"
                                autocomplete
                                allowUnique
                                inline
                              />
                            </div>
                          </Row>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col xl="12">
                  <Card>
                    <CardBody>
                      <Row>
                        <Row className="mb-2">
                          {isPremium && (
                            <div className="align-items-center d-flex mb-3">
                              <Label check for="checkbox">
                                {' '}
                                Has Varients ?
                              </Label>
                              <Switch
                                uncheckedIcon={<OffSymbol />}
                                checkedIcon={<OnSymbol />}
                                className="me-1 mb-sm-8 mx-3"
                                onColor="#626ed4"
                                onChange={v => {
                                  setHasVarients(v)
                                }}
                                checked={hasVarients}
                              />
                            </div>
                          )}
                          {hasVarients ? (
                            <Col className="col-12 d-flex flex-wrap">
                              <Col md="12" className=" mt-2 mb-2">
                                <CardTitle className="h4 mb-4 text_deals_green d-flex align-items-center">
                                  Product varients
                                  <button
                                    className="mx-2 btn bg-light d-flex align-items-center justify-content-center"
                                    onClick={e => {
                                      e.preventDefault()
                                      setVarients(currentVarient => [
                                        ...currentVarient,
                                        {
                                          value: '',
                                          unit: 'kg',
                                          cost: '',
                                          price: '',
                                          offerPrice: '',
                                          primePrice: '',
                                          stock: '',
                                        },
                                      ])
                                    }}
                                  >
                                    <i
                                      className="bx bx-plus"
                                      style={{ fontSize: '20px' }}
                                    ></i>
                                  </button>
                                </CardTitle>
                                <div className="table-responsive">
                                  <AvForm>
                                    <Table
                                      responsive
                                      striped
                                      className="table-nowrap mb-0"
                                    >
                                      <thead>
                                        <tr>
                                          <th
                                            colSpan={2}
                                            className="bg-gray text-center"
                                          >
                                            Product
                                          </th>
                                          <th
                                            colSpan={4}
                                            className="text-center bg-green"
                                          >
                                            Prices ({currency})
                                          </th>
                                          <th className="bg-yellow text-center">
                                            Stock (Count)
                                          </th>
                                          <th className="bg-lightYellow text-center">
                                            Remove
                                          </th>
                                        </tr>
                                        <tr>
                                          <th className="bg-lightGray text-center">
                                            Value
                                          </th>
                                          <th className="bg-lightGray text-center">
                                            Unit
                                          </th>

                                          <th className="bg-lightGreen">
                                            Cost
                                          </th>
                                          <th className="bg-lightGreen">
                                            Price
                                          </th>
                                          <th className="bg-lightGreen">
                                            Offer Price
                                          </th>
                                          <th className="bg-lightGreen">
                                            Prime Price
                                          </th>

                                          <th className="bg-lightYellow text-center">
                                            Stock
                                          </th>
                                        </tr>
                                      </thead>
                                      {varients?.map((s, key) => (
                                        <tbody
                                          key={key}
                                          className="editor_input"
                                        >
                                          <td className="bg-lightGray text-center">
                                            <AvField
                                              name="varient.value"
                                              type="text"
                                              value={s.value}
                                              onChange={e => {
                                                const value = e.target.value
                                                setVarients(currentVarient =>
                                                  currentVarient.map(x =>
                                                    x === s
                                                      ? {
                                                          ...x,
                                                          value,
                                                        }
                                                      : x,
                                                  ),
                                                )
                                              }}
                                            />
                                          </td>
                                          <td className="bg-lightGray text-center vertical-middle">
                                            <div className="d-flex align-items-center unit_select">
                                              <AvField
                                                name="varient.unit"
                                                type="select"
                                                value={s.unit}
                                                onChange={e => {
                                                  const unit = e.target.value
                                                  setVarients(currentVarient =>
                                                    currentVarient.map(x =>
                                                      x === s
                                                        ? {
                                                            ...x,
                                                            unit,
                                                          }
                                                        : x,
                                                    ),
                                                  )
                                                }}
                                              >
                                                {UnitsList?.map((item, key) => (
                                                  <option
                                                    key={key}
                                                    value={item}
                                                  >
                                                    {item}
                                                  </option>
                                                ))}
                                              </AvField>
                                              <i className="fas fa-angle-down mx-1"></i>
                                            </div>
                                          </td>
                                          <td className="bg-lightGreen">
                                            <AvField
                                              name="varient.cost"
                                              type="number"
                                              value={s.cost}
                                              onChange={e => {
                                                const cost = e.target.value
                                                setVarients(currentVarient =>
                                                  currentVarient.map(x =>
                                                    x === s
                                                      ? {
                                                          ...x,
                                                          cost,
                                                        }
                                                      : x,
                                                  ),
                                                )
                                              }}
                                            />
                                          </td>
                                          <td className="cursor-pointer bg-lightGreen ">
                                            <AvField
                                              name="varient.price"
                                              type="number"
                                              value={s.price}
                                              onChange={e => {
                                                const price = e.target.value
                                                setVarients(currentVarient =>
                                                  currentVarient.map(x =>
                                                    x === s
                                                      ? {
                                                          ...x,
                                                          price,
                                                        }
                                                      : x,
                                                  ),
                                                )
                                              }}
                                            />
                                          </td>
                                          <td className="bg-lightGreen">
                                            <AvField
                                              name="varient.offerPrice"
                                              type="number"
                                              value={s.offerPrice}
                                              onChange={e => {
                                                const offerPrice =
                                                  e.target.value
                                                setVarients(currentVarient =>
                                                  currentVarient.map(x =>
                                                    x === s
                                                      ? {
                                                          ...x,
                                                          offerPrice,
                                                        }
                                                      : x,
                                                  ),
                                                )
                                              }}
                                            />
                                          </td>{' '}
                                          <td className="bg-lightGreen">
                                            <AvField
                                              name="varient.primePrice"
                                              type="number"
                                              value={s.primePrice}
                                              onChange={e => {
                                                const primePrice =
                                                  e.target.value
                                                setVarients(currentVarient =>
                                                  currentVarient.map(x =>
                                                    x === s
                                                      ? {
                                                          ...x,
                                                          primePrice,
                                                        }
                                                      : x,
                                                  ),
                                                )
                                              }}
                                            />
                                          </td>{' '}
                                          <td className="bg-lightYellow text-center">
                                            <div className="d-flex align-items-center">
                                              <AvField
                                                name="varient.stock"
                                                type="number"
                                                value={s.stock}
                                                onChange={e => {
                                                  const stock = e.target.value
                                                  setVarients(currentVarient =>
                                                    currentVarient.map(x =>
                                                      x === s
                                                        ? {
                                                            ...x,
                                                            stock,
                                                          }
                                                        : x,
                                                    ),
                                                  )
                                                }}
                                              />
                                            </div>
                                          </td>
                                          <td>
                                            <button
                                              className="mx-2 btn"
                                              onClick={e => {
                                                e.preventDefault()
                                                setVarients(currentVarient =>
                                                  currentVarient.filter(
                                                    x => x !== s,
                                                  ),
                                                )
                                              }}
                                            >
                                              <i
                                                className="bx bx-trash"
                                                style={{
                                                  fontSize: '20px',
                                                }}
                                              ></i>
                                            </button>
                                          </td>
                                        </tbody>
                                      ))}
                                    </Table>
                                  </AvForm>
                                </div>
                              </Col>
                            </Col>
                          ) : (
                            <Row xl="12">
                              <Col xl="12">
                                <Row>
                                  <Col
                                    className="col-12 d-flex flex-wrap justify-content-space-evenly"
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                    }}
                                  >
                                    <div className="col-2 mb-3">
                                      <AvField
                                        name="cost"
                                        label="Cost"
                                        type="number"
                                        value={productDetails.cost}
                                      />
                                    </div>
                                    <div className="col-2 mb-3">
                                      <AvField
                                        name="price"
                                        label="Price"
                                        type="number"
                                        value={productDetails.price}
                                      />
                                    </div>
                                    <div className="col-2 mb-3">
                                      <AvField
                                        name="offerPrice"
                                        label="Offer Price"
                                        type="number"
                                        value={productDetails.offerPrice}
                                      />
                                    </div>
                                    <div className="col-2 mb-3">
                                      <AvField
                                        name="primePrice"
                                        label="Prime Price"
                                        type="number"
                                        value={productDetails.primePrice}
                                      />
                                    </div>
                                    <div className="col-2 mb-3">
                                      <AvField
                                        name="stock"
                                        label="Stock"
                                        type="number"
                                        value={productDetails.stock || 0}
                                      />
                                    </div>
                                  </Col>
                                  <div className="col-2 mb-3">
                                    <AvField
                                      name="taxPercentage"
                                      label="Tax Percentage"
                                      type="number"
                                      value={productDetails?.taxPercentage}
                                    />
                                  </div>
                                </Row>
                              </Col>
                            </Row>
                          )}
                        </Row>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              {/* packageData */}
              <Row>
                <Col xl="12">
                  <Card>
                    <CardBody>
                      <CardTitle className="h4 mb-4" text_deals_green>
                        Package Details
                      </CardTitle>
                      <Row>
                        <Col className="col-2 mb-3 me-3">
                          <AvField
                            name="packageData.length"
                            label="Length"
                            type="number"
                            value={productDetails?.packageData?.length}
                          />
                        </Col>
                        <Col className="col-2 mb-3 me-3">
                          <AvField
                            name="packageData.breadth"
                            label="Breadth"
                            type="number"
                            value={productDetails?.packageData?.breadth}
                          />
                        </Col>
                        <Col className="col-2 mb-3 me-3">
                          <AvField
                            name="packageData.height"
                            label="Height"
                            type="number"
                            value={productDetails?.packageData?.height}
                          />
                        </Col>
                        <Col className="col-2 mb-3 me-3">
                          <AvField
                            name="packageData.weight"
                            label="weight"
                            type="number"
                            value={productDetails?.packageData?.weight}
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row xl="12">
                <Col xl="12">
                  <Card>
                    <CardBody>
                      <CardTitle className="h4 mb-4 text_deals_green"></CardTitle>
                      <Row>
                        <Col>
                          <div className="text-end">
                            <button
                              type="submit"
                              className="col-12 btn btn-success save-customer"
                            >
                              {loading && (
                                <>
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                </>
                              )}
                              Save
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </AvForm>
          </div>
        </Container>
      </div>
    </>
  )
}

export default UpdateProduct

const OffSymbol = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        fontSize: 12,
        color: '#fff',
        paddingRight: 2,
      }}
    >
      {' '}
      No
    </div>
  )
}

const OnSymbol = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        fontSize: 12,
        color: '#fff',
        paddingRight: 2,
      }}
    >
      {' '}
      Yes
    </div>
  )
}
