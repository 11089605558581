import BreadcrumbPremium from 'components/Common/Breadcrumb2'
import React, { useEffect, useState } from 'react'
import { Button, Container, FormGroup } from 'reactstrap'
import HTMLRendererChainTree from './ChainTreeHtml'
import { useDispatch, useSelector } from 'react-redux'
import {
  getAllChainPromoters,
  syncChainTree,
  visualizeChainTree,
} from 'store/actions'
import { useParams } from 'react-router-dom'
import flowChartGif from 'assets/images/other/flowChart.gif'
import { MdOutlineCloudSync } from 'react-icons/md'
import Select from 'react-select'
import { debounce } from 'lodash'

const SupplyChainTree = () => {
  const dispatch = useDispatch()
  const params = useParams()

  const {
    treeChainData,
    chainTreeLoad,
    syncTreeLoad,
    chainPromoters,
    chainPromoterLoad,
  } = useSelector(state => ({
    treeChainData: state.SupplyChain.treeChainData,
    chainTreeLoad: state.SupplyChain.chainTreeLoad,
    syncTreeLoad: state.SupplyChain.syncTreeLoad,
    chainPromoters: state.SupplyChain.chainPromoters,
    chainPromoterLoad: state.SupplyChain.loading,
  }))
  //   const [htmlContent, setHtmlContent] = useState('')

  //   useEffect(() => {
  //     const loadLocalHtmlFile = async () => {
  //       try {
  //         const response = await fetch('./tree.html')
  //         if (!response.ok) {
  //           throw new Error('Failed to fetch the HTML file')
  //         }
  //         const fileContent = await response.text()
  //         setHtmlContent(fileContent)
  //       } catch (error) {
  //         console.error(error)
  //       }
  //     }

  //     loadLocalHtmlFile()
  //   }, [])

  const [chainPromoterSelect, setChainPromoterSelect] = useState({
    title: 'Search Chain Promoter',
    id: '',
  })

  useEffect(() => {
    dispatch(visualizeChainTree(params?.id, chainPromoterSelect?.id || ''))
  }, [dispatch, params?.id, chainPromoterSelect?.id])

  const handleSyncChain = () => {
    dispatch(syncChainTree(params?.id, chainPromoterSelect?.id || ''))
  }

  //chainPromoterSelect
  const [chainPromoterSearch, setChainPromoterSearch] = useState('')
  const debounceChainPromoterSearch = debounce(
    value => setChainPromoterSearch(value),
    600,
  )

  useEffect(() => {
    const credentials = {
      page: 1,
      sort: '',
      limit: 10,
      searchText: chainPromoterSearch,
      supplyChain: params?.id,
      promoter: '',
    }
    dispatch(getAllChainPromoters(credentials))
  }, [dispatch, chainPromoterSearch, params?.id])

  const handleChainPromoterEnters = textEntered => {
    debounceChainPromoterSearch(textEntered)
  }

  function handlerChainPromoterFinalValue(event) {
    const chainPromoter = {
      ...chainPromoterSelect,
      title: event.label,
      id: event.value,
    }
    setChainPromoterSelect(chainPromoter)
  }

  const chainPromoterOptions = [
    {
      label: 'none',
      id: '',
    },
    {
      options: (chainPromoters?.total >= 1
        ? chainPromoters?.chainPromoters
        : []
      )?.map((result, index) => ({
        key: index,
        label: result?.ref_id,
        value: result?._id,
      })),
    },
  ]

  return (
    <div className="page-content">
      <div className="d-flex justify-content-between align-items-start ">
        <BreadcrumbPremium
          root={['/promoter-dashboard', '/supply-chain', '/supply-chain-tree']}
        />

        <FormGroup className="w-25 me-3">
          <div className="ajax-select mt-lg-0 select2-container">
            <Select
              onInputChange={handleChainPromoterEnters}
              value={chainPromoterSelect?.title}
              placeholder={chainPromoterSelect?.title}
              onChange={handlerChainPromoterFinalValue}
              options={chainPromoterOptions}
              classNamePrefix="select2-selection"
              isLoading={chainPromoterLoad}
            />
          </div>
        </FormGroup>
        <Button
          type="button"
          color=""
          onClick={handleSyncChain}
          className="btn-rounded btn-outline-success mb-2 me-2"
        >
          {syncTreeLoad ? (
            <MdOutlineCloudSync className="me-2 font-size-16 spinner" />
          ) : (
            <MdOutlineCloudSync className="me-2 font-size-16" />
          )}
          Sync Chain
        </Button>
      </div>

      <Container fluid>
        <div className="container-fluid">
          {chainTreeLoad ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ height: '400px' }}
            >
              <img src={flowChartGif} alt="gif" />
            </div>
          ) : (
            <HTMLRendererChainTree treeData={treeChainData?.treeData} />
          )}
          {/* <div dangerouslySetInnerHTML={{ __html: htmlContent }} /> */}
        </div>
      </Container>
    </div>
  )
}

export default SupplyChainTree
