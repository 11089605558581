import React, { useState } from 'react'
import { Card, CardBody, Col, Media, Badge, Button, Alert } from 'reactstrap'
import PropTypes from 'prop-types'

import avatar from 'assets/images/brands/slack.png'
// import luck from 'assets/images/brands/luck.jpeg'
import moment from 'moment'
import WinnerModal from './WinnerModal'

import { InitiateLuckyDraw } from 'store/actions'
import { useDispatch, useSelector } from 'react-redux'
import { RxColorWheel } from 'react-icons/rx'
import { useParams } from 'react-router-dom'

const Settings = ({ luckyDrawDetails }) => {
  const params = useParams()
  const dispatch = useDispatch()

  const winner = luckyDrawDetails?.winner

  const { loading, errorMsg } = useSelector(state => ({
    errorMsg: state.SupplyChain.error?.message,
    loading: state.SupplyChain.loading,
  }))

  const [winnerModal, setWinnerModal] = useState({
    status: false,
    data: {},
  })

  const handleOpenWinnerModal = () => {
    setWinnerModal({ status: true, data: winnerModal?.data })
  }

  const handleInitiateLuckyDraw = () => {
    dispatch(InitiateLuckyDraw(params?.id, handleOpenWinnerModal))
  }

  return (
    <React.Fragment>
      {winnerModal?.status && (
        <WinnerModal
          onCloseClick={() => setWinnerModal({ status: false, data: {} })}
          show={winnerModal?.status}
          updateData={winnerModal?.data}
        />
      )}
      <Col xl={4}>
        {!winner ? (
          <Card>
            <CardBody className="p-2 pb-3">
              {errorMsg?.length >= 1 && (
                <Alert color="danger">{errorMsg}</Alert>
              )}
              <div className="img-luck-div pb-1 text-black">
                {/* <img
                  className="img-luck border-round"
                  style={{ borderRadius: '8px' }}
                  src={luck}
                /> */}
                {luckyDrawDetails?.isDrew == false
                  ? 'Have you taken the lot yet ?'
                  : ''}{' '}
                <br />
                <span className="text-muted">
                  {' '}
                  lucky draw count is {luckyDrawDetails?.count}, Click the
                  button and pick the lot, it will generate a random lot
                </span>
              </div>
              <Button
                className="winner_btn bg-light mx-2 m-0 mt-3"
                disabled={
                  luckyDrawDetails?.isRangeFilled === true &&
                  luckyDrawDetails?.isDrew === false &&
                  luckyDrawDetails?.count <= 0
                    ? false
                    : true
                }
                onClick={handleInitiateLuckyDraw}
              >
                {loading && (
                  <>
                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                  </>
                )}
                <RxColorWheel className="wheel me-2 " /> Lucky Draw
              </Button>
            </CardBody>
          </Card>
        ) : (
          <Card>
            <CardBody>
              <div className="media">
                <div className="me-3">
                  <img
                    src={avatar}
                    alt=""
                    className="avatar-sm rounded-circle img-thumbnail"
                  />
                </div>
                <div className="media-body">
                  <Media>
                    <div className="media-body d-flex">
                      <div className="text-muted">
                        <h5 className="mb-1">{winner?.ref_id}</h5>

                        <p className="mb-0">{winner?.promoter?.name}</p>
                        <p className="mb-0">{winner?.promoter?.phone}</p>
                      </div>
                      <div>
                        <Badge
                          className={
                            'font-size-12 ms-3 p-2 badge-soft-success mt-2 d-flex align-items-center justify-content-center '
                          }
                          style={{
                            width: 'fit-content',
                          }}
                        >
                          Winner
                        </Badge>
                      </div>
                    </div>
                  </Media>

                  <hr />

                  <div className="d-flex align-items-center ">
                    <p className="text-muted text-truncate m-0 p-0">
                      Drew Date :&nbsp;
                    </p>{' '}
                    <h6 className="m-0 p-0">
                      {moment(luckyDrawDetails?.drewDate).format('ll')}
                    </h6>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        )}
      </Col>
    </React.Fragment>
  )
}

export default Settings
Settings.propTypes = {
  luckyDrawDetails: PropTypes.any,
}
