import React, { Fragment, useEffect, useState } from 'react'
import { Row, Col, Card, CardBody, Button, Spinner, Badge } from 'reactstrap'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// data-table related plugins
import BootstrapTable from 'react-bootstrap-table-next'
import { debounce, map, range } from 'lodash'

import ToolkitProvider from 'react-bootstrap-table2-toolkit'

//actions
import {
  getAllSupplyChains,
  deleteSupplyChain,
  updateSupplyChain,
} from 'store/actions'

import '../../assets/scss/data-tables.scss'

import MyPagination from 'components/Common/MyPagination'
import DeleteModal from 'components/Common/DeleteModal'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import { CurrencySwitch } from 'hooks/Currency'

const SupplyChains = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [page, setPage] = useState(1)
  const [searchText, setSearchText] = useState('')
  const [sort, setSort] = useState('active')

  const { SupplyChains, loading } = useSelector(state => ({
    SupplyChains: state.SupplyChain.supplyChains,
    loading: state.SupplyChain.loading,
  }))

  const currency = CurrencySwitch()

  useEffect(() => {
    dispatch(getAllSupplyChains(page, 10, searchText, sort))
  }, [dispatch, page, searchText, sort])

  const totalPages = Math.ceil(SupplyChains?.total / 10)
  const pages = range(1, totalPages + 1)

  const [isOpen, setIsOpen] = useState({ status: false, id: '' })

  // const handleDelete = id => {
  //   setIsOpen({ status: true, id: id })
  // }

  const handleDeleteEvent = () => {
    setIsOpen({ ...isOpen, status: false })
    dispatch(deleteSupplyChain(isOpen?.id))
  }

  const handleSwitchChange = (item, isActive) => {
    const finalData = {
      ...item,
      isActive: isActive,
    }

    dispatch(updateSupplyChain(finalData, item?._id, ''))
  }

  // const handleRestore = id => {
  //   console.log(id)
  //   // dispatch(restoreProduct(id, ''))
  // }

  const columns = [
    {
      dataField: 'chainId',
      text: 'Chain Id',
      sort: true,
    },
    {
      dataField: 'product',
      text: 'Product',
      sort: true,
    },
    {
      dataField: 'joiningBonus',
      text: 'Lead Bonus',
      sort: true,
    },
    // {
    //   dataField: "totalDistributionAmount",
    //   text: "Total Distribution Amount",
    //   sort: true,
    // },
    {
      dataField: 'levelLimit',
      text: 'Level Limit',
    },
    {
      dataField: 'distributionBonus',
      text: 'Growth Rewards',
    },
    {
      dataField: 'chainPromotersCount',
      text: 'Chain Promoters',
    },
    {
      dataField: 'status',
      text: 'Status',
    },
    {
      dataField: 'action',
      text: 'Action',
    },
  ]

  const supplyChainData = map(SupplyChains?.supplyChains, (item, index) => ({
    ...item,
    product: (
      <Link
        to={`/product/update/${item?.product?._id}`}
        id="promoterName"
        style={{ maxWidth: '150px', whiteSpace: 'wrap' }}
      >
        {item?.product.primaryLang.name}
      </Link>
    ),

    // chainId: <p className="text-danger">{item.chainId}</p>,
    joiningBonus: (
      <>
        {currency}&nbsp;
        <span>{item?.joiningBonus}</span>
      </>
    ),
    distributionBonus: (
      <>
        {currency}&nbsp;
        <span>{item?.distributionBonus}</span>
      </>
    ),
    status: (
      <div className="form-check form-switch form-switch-md mb-3">
        <input
          type="checkbox"
          className="form-check-input"
          id="customSwitchsizemd"
          onChange={e => {
            handleSwitchChange(item, e.target.checked)
          }}
          checked={item?.isActive}
        />
        <label className="form-check-label" htmlFor="customSwitchsizemd">
          {item?.isActive ? `Active` : `Inactive`}
        </label>
      </div>
    ),
    action: (
      <div>
        <Button
          id={`view${index}`}
          type="button"
          color="white"
          className="ms-1 btn btn-sm"
        >
          <Link to={`/supply-chain/update/${item?._id}`} className="btn-md">
            <i className="bx bx-show-alt me-2 " style={{ fontSize: '20px' }} />
          </Link>
        </Button>

        {/* {sort === 'deleted' ? (
          <Button
            id={`restore${index}`}
            type="button"
            color="white"
            className="ms-1 btn btn-sm"
            onClick={() => {
              handleRestore(item._id)
            }}
          >
            <i
              className="bx bx-revision me-2"
              style={{ color: 'green', fontSize: '20px' }}
            />
          </Button>
        ) : (
          <Button
            id={`Delete${index}`}
            type="button"
            color="white"
            className="ms-1 btn btn-sm"
            onClick={() => {
              handleDelete(item._id)
            }}
          >
            <i
              className="bx bx-trash me-2"
              style={{
                color: 'red',
                fontSize: '18px',
              }}
            />
          </Button>
        )} */}
      </div>
    ),
  }))

  // Select All Button operation
  const selectRow = {
    mode: 'checkbox',
  }
  const debounceProductSearch = debounce(value => setSearchText(value), 600)

  const listChange = sortValue => {
    setSort(sortValue)
    history?.push({
      pathname: location.pathname,
      search: `p=${page}&s=${sortValue}`,
      state: {
        page: page,
        sort: sortValue,
      },
    })
    // dispatch(getAllProducts(page, sortValue, totalItemLimit, ""))
  }
  const tableTitles = [
    {
      title: 'Active',
      value: 'active',
    },

    {
      title: 'Inactive',
      value: 'inactive',
    },
    // {
    //   title: 'Deleted',
    //   value: 'deleted',
    // },
  ]
  return (
    <React.Fragment>
      <Row>
        <DeleteModal
          show={isOpen?.status}
          onCloseClick={() => setIsOpen({ ...isOpen, status: false })}
          onDeleteClick={handleDeleteEvent}
        />
        <Col className="col-12">
          <Card>
            <CardBody>
              <ToolkitProvider
                keyField="_id"
                key={'id'}
                columns={columns}
                data={supplyChainData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col sm="4">
                        <div
                          className="btn-group mt-2 mt-xl-0"
                          role="group"
                          aria-label="Basic radio toggle button group"
                        >
                          {map(tableTitles, (head, key) => (
                            <Fragment key={key}>
                              <input
                                type="radio"
                                className="btn-check"
                                name="btnradio"
                                id={head.value + key}
                                autoComplete="off"
                                checked={sort === head.value}
                                onChange={() => listChange(head?.value || '')}
                              />
                              <label
                                className="btn btn-success d-flex align-items-center w-100"
                                htmlFor={head.value + key}
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {head?.title}

                                <Badge
                                  className={`font-size-12 ms-2 ${
                                    sort === head.value
                                      ? 'badge-soft-warning'
                                      : 'badge-soft-light'
                                  }`}
                                  pill
                                >
                                  {head?.count}
                                </Badge>
                              </label>
                            </Fragment>
                          ))}
                        </div>
                      </Col>
                      <Col sm="4">
                        <div className="search-box w-100 ms-2 mb-2 mt-2 d-inline-block">
                          <div className="position-relative">
                            <AvForm>
                              <AvField
                                name="searchText"
                                type="text"
                                value={searchText}
                                onChange={e =>
                                  debounceProductSearch(e.target.value)
                                }
                              />
                            </AvForm>
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>
                      </Col>
                      <Col sm="4">
                        <div className="text-sm-end mt-2">
                          <Link to="/supply-chain/create/new">
                            <Button
                              type="button"
                              color="success"
                              className="btn-rounded  mb-2 me-2"
                            >
                              <i className="mdi mdi-plus me-1" />
                              Create supplyChain
                            </Button>
                          </Link>
                        </div>
                      </Col>
                    </Row>

                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        <>
                          <Row>
                            <p>Total :&nbsp;{SupplyChains?.total} </p>

                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                  keyField={'id'}
                                  key={'id'}
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  selectRow={selectRow}
                                  classes={'table align-middle table-nowrap'}
                                  headerWrapperClasses={'thead-light'}
                                  {...toolkitProps.baseProps}
                                  // onTableChange={handleTableChange}
                                  // {...paginationTableProps}
                                />
                              </div>
                            </Col>
                          </Row>
                          <MyPagination
                            totalPages={pages}
                            page={page}
                            setPage={setPage}
                          />
                        </>
                        {SupplyChains?.total == 0 && (
                          <p className="my-4 mx-3 ">No SupplyChains !</p>
                        )}
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default SupplyChains
