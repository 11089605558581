import React from 'react'

import UserDetail from './Details'
import MetaTag from 'components/Common/Meta-tag'

function UserDetails() {
  return (
    <>
      <MetaTag title={'About user'} />
      <UserDetail />
      {/* <div className="page-content"> */}
      {/* <ZestonProductDetail /> */}
      {/* <Breadcrumbs title="Products" breadcrumbItem="Product" /> */}
      {/* <Container fluid> */}
      {/* <div className="container-fluid">
                        <Row>
                            <Col lg="12">
                                <ZestonProductDetail />
                            </Col>

                            <Col lg="4">
                                <ItemList />
                            </Col>
                        </Row>

                    </div> */}
      {/* </Container> */}
      {/* </div> */}
    </>
  )
}

export default UserDetails
