import React, { useEffect, useState } from 'react'
import { Row, Col, Spinner, Badge } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'

// data-table related plugins
import BootstrapTable from 'react-bootstrap-table-next'

import { map, range } from 'lodash'

import ToolkitProvider from 'react-bootstrap-table2-toolkit'
import MyPagination from '../../../components/Common/MyPagination'

import '../../../assets/scss/data-tables.scss'
import moment from 'moment'
import { CurrencySwitch } from 'hooks/Currency'
import { Link, useParams } from 'react-router-dom'
import { getUserOrderDetails } from 'store/actions'

const OrderDatatable = () => {
  const dispatch = useDispatch()
  const params = useParams()

  const { orders, userOrderDetails, loading } = useSelector(state => ({
    orders: state.Orders.userOrderDetails.orders,
    userOrderDetails: state.Orders.userOrderDetails,
    loading: state.Users.loading,
  }))

  const [page, setPage] = useState(1)

  const total = userOrderDetails?.totalOrders

  //pagination
  const totalPages = Math.ceil(total / 10)
  const pages = range(1, totalPages + 1)

  const handleOrderStatus = status => {
    switch (status) {
      case 'Processing':
        return 'info'
      case 'Shipped':
        return 'info'
      case 'Delivered':
        return 'success'
      case 'Cancelled':
        return 'warning'
      case 'Returned':
        return 'danger'
      case 'Fulfilled':
        return 'success'
      case 'Partially Fulfilled':
        return 'success'
      default:
        return 'info'
    }
  }

  const columns = [
    {
      dataField: 'orderId',
      text: 'Order Id',
    },
    {
      dataField: 'createdAt',
      text: 'Date',
    },
    {
      dataField: 'orderStatus',
      text: 'Status',
    },
    {
      dataField: 'items',
      text: 'Items',
    },
    {
      dataField: 'paymentType',
      text: 'PaymentType',
      sort: true,
    },
    {
      dataField: 'itemsPrice',
      text: 'Sub Total',
      sort: true,
    },
    {
      dataField: 'shippingPrice',
      text: 'Shipping',
      sort: true,
    },
    {
      dataField: 'couponDiscount',
      text: 'Coupon',
      sort: true,
    },
    // {
    //   dataField: "taxValue",
    //   text: "VAT Total (15%)",
    //   sort: true,
    // },
    {
      dataField: 'totalPrice',
      text: 'Total',
      sort: true,
    },
  ]

  const currency = CurrencySwitch()

  const userCartData = map(orders, item => ({
    ...item,
    orderId: <Link to={`/order/${item?._id}`}>{item?.orderId}</Link>,
    createdAt: (
      <p style={{ fontSize: '13px' }} className="m-0 p-0">
        {moment(item?.createdAt).format('DD-MM-YY, h:mm A')}
      </p>
    ),
    paymentType: (
      <Badge
        className={
          item?.paymentType === 'Cash on Delivery'
            ? 'font-size-12 badge-soft-primary'
            : 'font-size-12 badge-soft-success'
        }
        pill
      >
        {item?.paymentType === 'Cash on Delivery' ? 'COD' : 'Online'}
      </Badge>
    ),
    orderStatus: (
      <Badge
        className={
          'px-2 py-2 badge-soft-' + `${handleOrderStatus(item?.orderStatus)}`
        }
      >
        {' '}
        {item?.orderStatus}{' '}
      </Badge>
    ),
    items: (
      <p style={{ fontSize: '13px' }} className="m-0 p-0">
        {item?.orderItems?.length}
      </p>
    ),
    itemsPrice: (
      <p style={{ fontSize: '13px' }} className="m-0 p-0">
        {currency} {item?.itemsPrice?.toFixed(2)}
      </p>
    ),
    shippingPrice: (
      <p style={{ fontSize: '13px' }} className="m-0 p-0">
        {/* {currency} {item?.shippingPrice?.toFixed(2)} */}
        {item?.shippingPrice
          ? currency + ' ' + item?.shippingPrice?.toFixed(2)
          : 'Free'}
      </p>
    ),
    couponDiscount: (
      <p style={{ fontSize: '13px' }} className="m-0 p-0">
        {item?.couponDiscount
          ? '- ' + currency + ' ' + item?.couponDiscount?.toFixed(2)
          : 0}
      </p>
    ),
    taxValue: (
      <p style={{ fontSize: '13px' }} className="m-0 p-0">
        {currency}
        {item?.taxValue?.toFixed(2)}
      </p>
    ),
    totalPrice: (
      <p style={{ fontSize: '15px' }} className="m-0 p-0">
        {currency + ' '}
        {item?.totalPrice?.toFixed(2)}
      </p>
    ),
  }))

  const handleTableChange = () => {}

  useEffect(() => {
    dispatch(getUserOrderDetails(params.id, page))
  }, [dispatch, page])

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <ToolkitProvider
            keyField="_id"
            columns={columns}
            data={userCartData}
            search
          >
            {toolkitProps => (
              <React.Fragment>
                {loading ? (
                  <Spinner color="secondary" className="d-block m-auto" />
                ) : (
                  <>
                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          <BootstrapTable
                            // keyField={"id"}
                            key={'id'}
                            responsive
                            bordered={false}
                            striped={false}
                            // defaultSorted={defaultSorted}
                            // selectRow={selectRow}
                            classes={'table align-middle table-nowrap'}
                            headerWrapperClasses={'thead-light'}
                            {...toolkitProps.baseProps}
                            onTableChange={handleTableChange}
                          />
                        </div>
                      </Col>
                    </Row>
                    <MyPagination
                      totalPages={pages}
                      page={page}
                      setPage={setPage}
                    />
                  </>
                )}
              </React.Fragment>
            )}
          </ToolkitProvider>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default OrderDatatable
