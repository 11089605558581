import {
  GET_WITHDRAWALLISTS,
  GET_WITHDRAWALLISTS_SUCCESS,
  GET_WITHDRAWALLISTS_FAIL,
  GET_WITHDRAWALLIST_DETAILS,
  GET_WITHDRAWALLIST_DETAILS_SUCCESS,
  GET_WITHDRAWALLIST_DETAILS_FAIL,
  CREATE_WITHDRAWALLIST,
  CREATE_WITHDRAWALLIST_SUCCESS,
  CREATE_WITHDRAWALLIST_FAIL,
  UPDATE_WITHDRAWALLIST,
  UPDATE_WITHDRAWALLIST_SUCCESS,
  UPDATE_WITHDRAWALLIST_FAIL,
  DELETE_WITHDRAWALLIST_SUCCESS,
  DELETE_WITHDRAWALLIST_FAIL,
  DELETE_WITHDRAWALLIST,
} from './actionTypes'

// WithdrawalList - This line cannot be edited or removed
export const getWithdrawalLists = (page, sort, limit, promoter) => ({
  type: GET_WITHDRAWALLISTS,
  payload: { page, sort, limit, promoter },
})

export const getWithdrawalListsSuccess = withdrawallists => ({
  type: GET_WITHDRAWALLISTS_SUCCESS,
  payload: withdrawallists,
})

export const getWithdrawalListsFail = error => ({
  type: GET_WITHDRAWALLISTS_FAIL,
  payload: error,
})

export const getWithdrawalListDetails = withdrawallistId => ({
  type: GET_WITHDRAWALLIST_DETAILS,
  payload: withdrawallistId,
})

export const getWithdrawalListDetailsSuccess = withdrawallistDetails => ({
  type: GET_WITHDRAWALLIST_DETAILS_SUCCESS,
  payload: withdrawallistDetails,
})

export const getWithdrawalListDetailsFail = error => ({
  type: GET_WITHDRAWALLIST_DETAILS_FAIL,
  payload: error,
})

export const createWithdrawalList = (withdrawallist, history) => ({
  type: CREATE_WITHDRAWALLIST,
  payload: { withdrawallist, history },
})

export const createWithdrawalListSuccess = withdrawallist => ({
  type: CREATE_WITHDRAWALLIST_SUCCESS,
  payload: withdrawallist,
})

export const createWithdrawalListFail = error => ({
  type: CREATE_WITHDRAWALLIST_FAIL,
  payload: error,
})

export const getWithdrawalListDetail = withdrawallistId => ({
  type: GET_WITHDRAWALLIST_DETAILS,
  payload: withdrawallistId,
})

export const updateWithdrawalList = (
  withdrawallist,
  withdrawallistId,
  history,
) => ({
  type: UPDATE_WITHDRAWALLIST,
  payload: { withdrawallist, withdrawallistId, history },
})

export const updateWithdrawalListSuccess = withdrawallist => ({
  type: UPDATE_WITHDRAWALLIST_SUCCESS,
  payload: withdrawallist,
})

export const updateWithdrawalListFail = error => ({
  type: UPDATE_WITHDRAWALLIST_FAIL,
  payload: error,
})

export const deleteWithdrawalList = (withdrawallistId, history) => ({
  type: DELETE_WITHDRAWALLIST,
  payload: { withdrawallistId, history },
})

export const deleteWithdrawalListSuccess = withdrawallist => ({
  type: DELETE_WITHDRAWALLIST_SUCCESS,
  payload: withdrawallist,
})

export const deleteWithdrawalListFail = error => ({
  type: DELETE_WITHDRAWALLIST_FAIL,
  payload: error,
})
