import MetaTag from 'components/Common/Meta-tag'
import React from 'react'
import { Badge, Col, Container, Row } from 'reactstrap'
import Profile from './Profile'
import PageView from './PageView'
// import Earnings from "./Earnings"
import ChainPromoters from 'pages/ChainPromoter/DatatableTables'
import { useParams } from 'react-router-dom'
import PromoterTransactions from 'pages/PromoterTransaction/PromoterTransactions'
import { useSelector } from 'react-redux'
import { CurrencySwitch } from 'hooks/Currency'
import LuckyDraws from 'pages/luckyDraw/DatatableTables'
import BreadcrumbPremium from 'components/Common/Breadcrumb2'

function PromoterDetails() {
  const params = useParams()

  const { supplyChainTransactions, promoterDetails } = useSelector(state => ({
    promoterDetails: state?.Users?.promoterDetails,
    supplyChainTransactions: state.SupplyChain.supplyChainTransactions,
  }))

  const currency = CurrencySwitch()

  return (
    <div>
      <MetaTag title={'Promoter Details'} />
      <div className="page-content">
        <BreadcrumbPremium
          root={[
            '/promoter-dashboard',
            '/promoters',
            `${promoterDetails?.phone} ${
              promoterDetails?.name ? promoterDetails?.name : ''
            }`,
          ]}
        />

        <Container fluid>
          <div className="container-fluid">
            <Profile />
            <Row>
              <Col md={12}>
                <p className="">Chain promoters under this promoter</p>
                <ChainPromoters promoterId={params?.id} />
              </Col>

              <Col md={12}>
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <h6 className="chain-transaction">Transactions</h6>
                  <div>
                    <Badge className="badge-soft-success p-1 px-2 font-size-13">
                      Wallet Balance: {currency}{' '}
                      {supplyChainTransactions?.walletBalance}
                    </Badge>
                  </div>
                </div>
                <PromoterTransactions promoterId={params?.id} />
              </Col>

              <Col md={12}>
                <LuckyDraws promoterId={params?.id} />
              </Col>

              <Col md={12}>
                <PageView />
              </Col>

              {/* <Col md={12}>
                <Earnings />
              </Col>
            */}
            </Row>
          </div>
        </Container>
      </div>
    </div>
  )
}

export default PromoterDetails
