import React from 'react'
import { Container } from 'reactstrap'

import MetaTag from 'components/Common/Meta-tag'
import BreadcrumbPremium from 'components/Common/Breadcrumb2'
import IdealsplyWalletWithdrawalList from './DatatableTables'

function IdealsplyWalletWithdrawalLists() {
  return (
    <>
      <MetaTag title={'Withdrawal Lists '} />
      <div className="page-content">
        <BreadcrumbPremium
          root={['/promoter-dashboard', '/idealsply-Wallet-Withdrawal-List']}
        />
        <Container fluid>
          <div className="container-fluid">
            <IdealsplyWalletWithdrawalList />
          </div>
        </Container>
      </div>
    </>
  )
}

export default IdealsplyWalletWithdrawalLists
