import {
  GET_IDEALSPLY_WALLET_WITHDRAWALLISTS,
  GET_IDEALSPLY_WALLET_WITHDRAWALLISTS_SUCCESS,
  GET_IDEALSPLY_WALLET_WITHDRAWALLISTS_FAIL,
  GET_IDEALSPLY_WALLET_WITHDRAWALLIST_DETAILS,
  GET_IDEALSPLY_WALLET_WITHDRAWALLIST_DETAILS_SUCCESS,
  GET_IDEALSPLY_WALLET_WITHDRAWALLIST_DETAILS_FAIL,
  CREATE_IDEALSPLY_WALLET_WITHDRAWALLIST,
  CREATE_IDEALSPLY_WALLET_WITHDRAWALLIST_SUCCESS,
  CREATE_IDEALSPLY_WALLET_WITHDRAWALLIST_FAIL,
  UPDATE_IDEALSPLY_WALLET_WITHDRAWALLIST,
  UPDATE_IDEALSPLY_WALLET_WITHDRAWALLIST_SUCCESS,
  UPDATE_IDEALSPLY_WALLET_WITHDRAWALLIST_FAIL,
  DELETE_IDEALSPLY_WALLET_WITHDRAWALLIST_SUCCESS,
  DELETE_IDEALSPLY_WALLET_WITHDRAWALLIST_FAIL,
  DELETE_IDEALSPLY_WALLET_WITHDRAWALLIST,
} from './actionTypes'

// IdealsplyWalletWithdrawalList - This line cannot be edited or removed
export const getIdealsplyWalletWithdrawalLists = (
  page,
  sort,
  limit,
  promoter,
) => ({
  type: GET_IDEALSPLY_WALLET_WITHDRAWALLISTS,
  payload: { page, sort, limit, promoter },
})

export const getIdealsplyWalletWithdrawalListsSuccess =
  idealsplyWalletWithdrawallists => ({
    type: GET_IDEALSPLY_WALLET_WITHDRAWALLISTS_SUCCESS,
    payload: idealsplyWalletWithdrawallists,
  })

export const getIdealsplyWalletWithdrawalListsFail = error => ({
  type: GET_IDEALSPLY_WALLET_WITHDRAWALLISTS_FAIL,
  payload: error,
})

export const getIdealsplyWalletWithdrawalListDetails =
  idealsplyWalletWithdrawallistId => ({
    type: GET_IDEALSPLY_WALLET_WITHDRAWALLIST_DETAILS,
    payload: idealsplyWalletWithdrawallistId,
  })

export const getIdealsplyWalletWithdrawalListDetailsSuccess =
  idealsplyWalletWithdrawallistDetails => ({
    type: GET_IDEALSPLY_WALLET_WITHDRAWALLIST_DETAILS_SUCCESS,
    payload: idealsplyWalletWithdrawallistDetails,
  })

export const getIdealsplyWalletWithdrawalListDetailsFail = error => ({
  type: GET_IDEALSPLY_WALLET_WITHDRAWALLIST_DETAILS_FAIL,
  payload: error,
})

export const createIdealsplyWalletWithdrawalList = (
  idealsplyWalletWithdrawallist,
  history,
) => ({
  type: CREATE_IDEALSPLY_WALLET_WITHDRAWALLIST,
  payload: { idealsplyWalletWithdrawallist, history },
})

export const createIdealsplyWalletWithdrawalListSuccess =
  idealsplyWalletWithdrawallist => ({
    type: CREATE_IDEALSPLY_WALLET_WITHDRAWALLIST_SUCCESS,
    payload: idealsplyWalletWithdrawallist,
  })

export const createIdealsplyWalletWithdrawalListFail = error => ({
  type: CREATE_IDEALSPLY_WALLET_WITHDRAWALLIST_FAIL,
  payload: error,
})

export const getIdealsplyWalletWithdrawalListDetail =
  idealsplyWalletWithdrawallistId => ({
    type: GET_IDEALSPLY_WALLET_WITHDRAWALLIST_DETAILS,
    payload: idealsplyWalletWithdrawallistId,
  })

export const updateIdealsplyWalletWithdrawalList = (
  idealsplyWalletWithdrawallist,
  idealsplyWalletWithdrawallistId,
  history,
) => ({
  type: UPDATE_IDEALSPLY_WALLET_WITHDRAWALLIST,
  payload: {
    idealsplyWalletWithdrawallist,
    idealsplyWalletWithdrawallistId,
    history,
  },
})

export const updateIdealsplyWalletWithdrawalListSuccess =
  idealsplyWalletWithdrawallist => ({
    type: UPDATE_IDEALSPLY_WALLET_WITHDRAWALLIST_SUCCESS,
    payload: idealsplyWalletWithdrawallist,
  })

export const updateIdealsplyWalletWithdrawalListFail = error => ({
  type: UPDATE_IDEALSPLY_WALLET_WITHDRAWALLIST_FAIL,
  payload: error,
})

export const deleteIdealsplyWalletWithdrawalList = (
  idealsplyWalletWithdrawallistId,
  history,
) => ({
  type: DELETE_IDEALSPLY_WALLET_WITHDRAWALLIST,
  payload: { idealsplyWalletWithdrawallistId, history },
})

export const deleteIdealsplyWalletWithdrawalListSuccess =
  idealsplyWalletWithdrawallist => ({
    type: DELETE_IDEALSPLY_WALLET_WITHDRAWALLIST_SUCCESS,
    payload: idealsplyWalletWithdrawallist,
  })

export const deleteIdealsplyWalletWithdrawalListFail = error => ({
  type: DELETE_IDEALSPLY_WALLET_WITHDRAWALLIST_FAIL,
  payload: error,
})
