//area
export const GET_AREA_LISTS = 'GET_AREA_LISTS'
export const GET_AREA_LISTS_SUCCESS = 'GET_AREA_LISTS_SUCCESS'
export const GET_AREA_LISTS_FAIL = 'GET_AREA_LISTS_FAIL'

export const CREATE_AREA_LIST = 'CREATE_AREA_LIST'
export const CREATE_AREA_LIST_SUCCESS = 'CREATE_AREA_LIST_SUCCESS'
export const CREATE_AREA_LIST_FAIL = 'CREATE_AREA_LIST_FAIL'

export const UPDATE_AREA_LIST = 'UPDATE_AREA_LIST'
export const UPDATE_AREA_LIST_SUCCESS = 'UPDATE_AREA_LIST_SUCCESS'
export const UPDATE_AREA_LIST_FAIL = 'UPDATE_AREA_LIST_FAIL'

export const DELETE_AREA_LIST = 'DELETE_AREA_LIST'
export const DELETE_AREA_LIST_SUCCESS = 'DELETE_AREA_LIST_SUCCESS'
export const DELETE_AREA_LIST_FAIL = 'DELETE_AREA_LIST_FAIL'

export const GET_AREA_LIST_DETAIL = 'GET_AREA_LIST_DETAILS'
export const GET_AREA_LIST_DETAIL_SUCCESS = 'GET_AREA_LIST_DETAILS_SUCCESS'
export const GET_AREA_LIST_DETAIL_FAIL = 'GET_AREA_LIST_DETAILS_FAIL'
