/* PROJECTS */
export const GET_COUPONS = 'GET_COUPONS'
export const GET_COUPONS_SUCCESS = 'GET_COUPONS_SUCCESS'
export const GET_COUPONS_FAIL = 'GET_COUPONS_FAIL'

export const GET_COUPON_DETAILS = 'GET_COUPON_DETAILS'
export const GET_COUPON_DETAILS_SUCCESS = 'GET_COUPON_DETAILS_SUCCESS'
export const GET_COUPON_DETAILS_FAIL = 'GET_COUPON_DETAILS_FAIL'

/**
 * add user
 */
export const CREATE_COUPON = 'CREATE_COUPON'
export const CREATE_COUPON_SUCCESS = 'CREATE_COUPON_SUCCESS'
export const CREATE_COUPON_FAIL = 'CREATE_COUPON_FAIL'

/**
 * Edit user
 */
export const UPDATE_COUPON = 'UPDATE_COUPON'
export const UPDATE_COUPON_SUCCESS = 'UPDATE_COUPON_SUCCESS'
export const UPDATE_COUPON_FAIL = 'UPDATE_COUPON_FAIL'

/**
 * Delete user
 */
export const DELETE_COUPON = 'DELETE_COUPON'
export const DELETE_COUPON_SUCCESS = 'DELETE_COUPON_SUCCESS'
export const DELETE_COUPON_FAIL = 'DELETE_COUPON_FAIL'
