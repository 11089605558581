import React, { useEffect, useState } from 'react'
import { Badge, Card, Col, Row, UncontrolledTooltip } from 'reactstrap'
import { MdEmail } from 'react-icons/md'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { deletePromoter, getPromoterDetails } from 'store/actions'
import moment from 'moment'
import DeleteModal from 'components/Common/DeleteModal'
import { AiFillEdit } from 'react-icons/ai'
import { CurrencySwitch } from 'hooks/Currency'

function Profile() {
  const params = useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  const currency = CurrencySwitch()

  const { promoterDetails } = useSelector(state => ({
    promoterDetails: state?.Users?.promoterDetails,
  }))
  useEffect(() => {
    dispatch(getPromoterDetails(params?.id))
  }, [params?.id])

  const [isOpen, setIsOpen] = useState({ stauts: false, id: '' })

  const handleDeleteEvent = () => {
    setIsOpen({ ...isOpen, stauts: false })
    dispatch(deletePromoter(isOpen?.id, history))
  }

  return (
    <div>
      <DeleteModal
        show={isOpen?.stauts}
        onCloseClick={() => setIsOpen({ ...isOpen, stauts: false })}
        onDeleteClick={handleDeleteEvent}
      />
      <Card className="shadow p-3 mb-4 bg-white rounded position-relative">
        <Link
          to={`/promoter/update/${params?.id}`}
          style={{
            position: 'absolute',
            right: 20,
            top: 10,
          }}
        >
          <AiFillEdit size={'18px'} id="EditPromoterDetailsId" />
          <UncontrolledTooltip target={'EditPromoterDetailsId'}>
            Edit Promoter Details
          </UncontrolledTooltip>
        </Link>
        <Row>
          <Col sm={5}>
            <div className="d-flex align-items-center ">
              <h6>{promoterDetails?.name}</h6>
              {promoterDetails?.isImaUser ? (
                <Badge className="badge-soft-success p-1 px-1 font-size-10 mar-gn">
                  Ima User
                </Badge>
              ) : null}
            </div>
            <p>{promoterDetails?.address}</p>
            <h6 className="d-flex align-items-center">
              <i
                style={{ color: 'green' }}
                className="bx bx-phone-call font-size-18 "
              />
              &nbsp;
              {promoterDetails?.phone}
            </h6>
            <h6 className="d-flex align-items-center">
              <MdEmail style={{ color: 'green' }} className="font-size-18" />
              &nbsp;
              {promoterDetails?.email}
            </h6>

            <Row>
              <Col className="text-dark" sm={12}>
                {' '}
                Joined In{' '}
                <span className="text-muted">
                  {moment(promoterDetails?.createdAt).format('l h:mm A')}
                </span>
              </Col>
            </Row>
          </Col>
          <Col className="pt-3" sm={4}>
            <div>
              <div className="d-flex align-items-center">
                <h6>Referral ID :</h6>
                <h6>&nbsp;{promoterDetails?.ref_id}</h6>
              </div>
              <div className="d-flex align-items-center">
                <h6 className="m-0">Wallet Balance :</h6>&nbsp;
                <Badge className="badge-soft-success p-1 px-2 font-size-15">
                  {currency} {promoterDetails?.walletBalance}
                </Badge>
              </div>
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  )
}

export default Profile
