import { call, put, takeEvery } from 'redux-saga/effects'

// Crypto Redux States
import {
  CREATE_GROUP,
  DELETE_GROUP,
  GET_GROUPS,
  GET_GROUP_DETAILS,
  UPDATE_GROUP,
} from './actionTypes'
import {
  createGroupFail,
  createGroupSuccess,
  deleteGroupFail,
  deleteGroupSuccess,
  getGroupsDtailsFail,
  getGroupsDtailsSuccess,
  getGroupsFail,
  getGroupsSuccess,
  updateGroupFail,
  updateGroupSuccess,
} from './actions'

import { post, get, ApiPut, del } from '../../helpers/api_helper'
import { Notification } from '../../components/Common/Notification'

function groupsApi({ page, limit, searchText, store }) {
  if (store) {
    return get(
      `/group/admin/all?page=${page ? page : 1}&limit=${
        limit ? limit : 10
      }&search=${searchText}&store=${store}`,
    )
  } else if (searchText) {
    return get(
      `/group/admin/all?page=${page ? page : 1}&limit=${
        limit ? limit : 10
      }&search=${searchText}`,
    )
  } else {
    return get(
      `/group/admin/all?page=${page ? page : 1}&limit=${limit ? limit : 10}`,
    )
  }
}

const getGroupsDetailsAPi = groupId => {
  return get(`/group/admin/${groupId}`)
}

function createGroupsApi({ group }) {
  return post('/group/admin/new', group)
}

function updateGroupsApi({ group, groupId }) {
  return ApiPut(`/group/admin/${groupId}`, group)
}

function deleteGroupsApi(groupId) {
  return del(`/group/admin/${groupId}`)
}

function* fetchGroups({ payload }) {
  try {
    const response = yield call(groupsApi, payload)
    yield put(getGroupsSuccess(response))
  } catch (error) {
    yield put(getGroupsFail(error))
  }
}

function* fetchGroupDetails({ payload: zestonProductId }) {
  try {
    const response = yield call(getGroupsDetailsAPi, zestonProductId)
    yield put(getGroupsDtailsSuccess(response))
  } catch (error) {
    yield put(getGroupsDtailsFail(error))
  }
}

function* onCreateGroup({ payload }) {
  try {
    const response = yield call(createGroupsApi, payload)
    yield put(createGroupSuccess(response))
    Notification({
      type: 'success',
      message: 'Group Created Successfully!',
      title: '',
    })
    payload.history.push('/groups')
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: 'error',
        message: error.response?.data?.message,
        title: '',
      })
    }
    yield put(createGroupFail(error))
  }
}

function* onUpdateGroups({ payload }) {
  try {
    const response = yield call(updateGroupsApi, payload)
    yield put(updateGroupSuccess(response))
    Notification({
      type: 'success',
      message: 'Group Updated Successfully!',
      title: '',
    })
    payload.history.goBack()
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: 'error',
        message: error.response?.data?.message,
        title: '',
      })
    }
    yield put(updateGroupFail(error))
  }
}

function* onDeleteGroups({ groupId, history }) {
  try {
    const response = yield call(deleteGroupsApi, groupId)
    yield put(deleteGroupSuccess(response))
    Notification({
      type: 'success',

      message: 'Group Deleted Successfully!',
      title: '',
    })
    history.goBack()
  } catch (error) {
    yield put(deleteGroupFail(error))
  }
}

function* groupsSaga() {
  yield takeEvery(GET_GROUPS, fetchGroups)
  yield takeEvery(GET_GROUP_DETAILS, fetchGroupDetails)
  yield takeEvery(CREATE_GROUP, onCreateGroup)
  yield takeEvery(UPDATE_GROUP, onUpdateGroups)
  yield takeEvery(DELETE_GROUP, onDeleteGroups)
}

export default groupsSaga
