import { call, put, takeEvery } from 'redux-saga/effects'

import {
  GET_IDEALSPLY_WALLET_WITHDRAWALLISTS,
  GET_IDEALSPLY_WALLET_WITHDRAWALLIST_DETAILS,
  CREATE_IDEALSPLY_WALLET_WITHDRAWALLIST,
  UPDATE_IDEALSPLY_WALLET_WITHDRAWALLIST,
  DELETE_IDEALSPLY_WALLET_WITHDRAWALLIST,
} from './actionTypes'
import {
  getIdealsplyWalletWithdrawalListsFail,
  getIdealsplyWalletWithdrawalListsSuccess,
  getIdealsplyWalletWithdrawalListDetailsSuccess,
  getIdealsplyWalletWithdrawalListDetailsFail,
  createIdealsplyWalletWithdrawalListFail,
  createIdealsplyWalletWithdrawalListSuccess,
  updateIdealsplyWalletWithdrawalListSuccess,
  updateIdealsplyWalletWithdrawalListFail,
  deleteIdealsplyWalletWithdrawalListSuccess,
  deleteIdealsplyWalletWithdrawalListFail,
} from './actions'

import { get, post, ApiPut, del } from 'helpers/api_helper'
import { Notification } from '../../components/Common/Notification'

// IdealsplyWalletWithdrawalList - This line cannot be edited or removed
function getIdealsplyWalletWithdrawalListsAPi({ page, sort, limit, promoter }) {
  return get(
    `/idealsply-wallet/admin/all?status=${sort ? sort : ''}&page=${
      page ? page : 1
    }&limit=${limit ? limit : 10}&promoter=${promoter ? promoter : ''}`,
  )
}

const getIdealsplyWalletWithdrawalListDetailsAPi =
  idealsplyWalletWithdrawallistId => {
    return get(
      `/idealsply-wallet/admin/single/${idealsplyWalletWithdrawallistId}`,
    )
  }

const createIdealsplyWalletWithdrawalListApi = ({
  idealsplyWalletWithdrawallist,
}) => {
  return post('/idealsply-wallet/admin/new', idealsplyWalletWithdrawallist)
}

const updateIdealsplyWalletWithdrawalListApi = ({
  idealsplyWalletWithdrawallist,
  idealsplyWalletWithdrawallistId,
}) => {
  return ApiPut(
    `/idealsply-wallet/admin${idealsplyWalletWithdrawallistId}`,
    idealsplyWalletWithdrawallist,
  )
}

const deleteIdealsplyWalletWithdrawalListApi = ({
  idealsplyWalletWithdrawallistId,
}) => {
  return del(`/idealsply-wallet/admin/${idealsplyWalletWithdrawallistId}`)
}

function* fetchIdealsplyWalletWithdrawalLists({ payload }) {
  try {
    const response = yield call(getIdealsplyWalletWithdrawalListsAPi, payload)
    yield put(getIdealsplyWalletWithdrawalListsSuccess(response))
  } catch (error) {
    yield put(getIdealsplyWalletWithdrawalListsFail(error))
  }
}

function* fetchIdealsplyWalletWithdrawalListDetails({
  payload: idealsplyWalletWithdrawallistId,
}) {
  try {
    const response = yield call(
      getIdealsplyWalletWithdrawalListDetailsAPi,
      idealsplyWalletWithdrawallistId,
    )
    yield put(getIdealsplyWalletWithdrawalListDetailsSuccess(response))
  } catch (error) {
    yield put(getIdealsplyWalletWithdrawalListDetailsFail(error))
  }
}

function* onCreateIdealsplyWalletWithdrawalList({ payload }) {
  try {
    const response = yield call(createIdealsplyWalletWithdrawalListApi, payload)
    if (response?.message) {
      Notification({
        type: 'error',
        message: response?.message,
        title: '',
      })
    } else if (response) {
      yield put(createIdealsplyWalletWithdrawalListSuccess(response))
      payload?.history?.push('/idealsplyWalletWithdrawallists')
      doneNotification('IdealsplyWalletWithdrawalList Created Successfully!')
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: 'error',
        message: error?.response?.data?.message,
        title: '',
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: 'error',
        message: 'file size is Too Large',
        title: '',
      })
    }
    yield put(createIdealsplyWalletWithdrawalListFail(error))
  }
}

function* onUpdateIdealsplyWalletWithdrawalList({ payload }) {
  try {
    const response = yield call(updateIdealsplyWalletWithdrawalListApi, payload)
    if (response) {
      Notification({
        type: 'success',
        message: 'IdealsplyWalletWithdrawalList Updated Successfully!',
        title: '',
      })
      yield put(updateIdealsplyWalletWithdrawalListSuccess(response))
      if (payload.history) {
        payload.history.push('/idealsplyWalletWithdrawallists')
      }
    }
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: 'error',
        message: error.response?.data?.message,
        title: '',
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: 'error',
        message: 'file size is Too Large',
        title: '',
      })
    }
    yield put(updateIdealsplyWalletWithdrawalListFail(error.response.data))
  }
}

function* onDeleteIdealsplyWalletWithdrawalList({ payload }) {
  try {
    const response = yield call(deleteIdealsplyWalletWithdrawalListApi, payload)
    yield put(deleteIdealsplyWalletWithdrawalListSuccess(response))
    Notification({
      type: 'success',
      message: 'IdealsplyWalletWithdrawalList Deleted Successfully!',
      title: '',
    })
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: 'error',
        message: error?.response?.data?.message,
        title: '',
      })
    }
    yield put(deleteIdealsplyWalletWithdrawalListFail(error?.response?.data))
  }
}

function* IdealsplyWalletWithdrawallistSaga() {
  yield takeEvery(
    GET_IDEALSPLY_WALLET_WITHDRAWALLISTS,
    fetchIdealsplyWalletWithdrawalLists,
  )
  yield takeEvery(
    GET_IDEALSPLY_WALLET_WITHDRAWALLIST_DETAILS,
    fetchIdealsplyWalletWithdrawalListDetails,
  )
  yield takeEvery(
    CREATE_IDEALSPLY_WALLET_WITHDRAWALLIST,
    onCreateIdealsplyWalletWithdrawalList,
  )
  yield takeEvery(
    UPDATE_IDEALSPLY_WALLET_WITHDRAWALLIST,
    onUpdateIdealsplyWalletWithdrawalList,
  )
  yield takeEvery(
    DELETE_IDEALSPLY_WALLET_WITHDRAWALLIST,
    onDeleteIdealsplyWalletWithdrawalList,
  )
}

export default IdealsplyWalletWithdrawallistSaga

function doneNotification(message) {
  Notification({
    type: 'success',
    message: message,
    title: '',
  })
}
