import {
  GET_AREA_LISTS,
  GET_AREA_LISTS_FAIL,
  GET_AREA_LISTS_SUCCESS,
  GET_AREA_LIST_DETAIL,
  GET_AREA_LIST_DETAIL_SUCCESS,
  GET_AREA_LIST_DETAIL_FAIL,
  CREATE_AREA_LIST,
  CREATE_AREA_LIST_SUCCESS,
  CREATE_AREA_LIST_FAIL,
  UPDATE_AREA_LIST,
  UPDATE_AREA_LIST_SUCCESS,
  UPDATE_AREA_LIST_FAIL,
  DELETE_AREA_LIST,
  DELETE_AREA_LIST_SUCCESS,
  DELETE_AREA_LIST_FAIL,
} from './actionTypes'

//all area_lists
export const getArea_lists = ({ page, limit, searchText }) => ({
  type: GET_AREA_LISTS,
  payload: { page, limit, searchText },
})

//all Area_lists
export const getArea_listsSuccess = area_lists => ({
  type: GET_AREA_LISTS_SUCCESS,
  payload: area_lists,
})

export const getArea_listsFail = error => ({
  type: GET_AREA_LISTS_FAIL,
  payload: error,
})

export const getArea_listsDetails = area_listId => ({
  type: GET_AREA_LIST_DETAIL,
  payload: area_listId,
})

export const getArea_listsDtailsSuccess = area_listDetails => ({
  type: GET_AREA_LIST_DETAIL_SUCCESS,
  payload: area_listDetails,
})

export const getArea_listsDtailsFail = error => ({
  type: GET_AREA_LIST_DETAIL_FAIL,
  payload: error,
})

export const createArea_list = area_list => ({
  type: CREATE_AREA_LIST,
  payload: { area_list },
})

export const createArea_listSuccess = area_list => ({
  type: CREATE_AREA_LIST_SUCCESS,
  payload: area_list,
})

export const createArea_listFail = error => ({
  type: CREATE_AREA_LIST_FAIL,
  payload: error,
})

export const updateArea_list = (area_list, area_listId) => ({
  type: UPDATE_AREA_LIST,
  payload: { area_list, area_listId },
})

export const updateArea_listSuccess = area_list => ({
  type: UPDATE_AREA_LIST_SUCCESS,
  payload: area_list,
})

export const updateArea_listFail = error => ({
  type: UPDATE_AREA_LIST_FAIL,
  payload: error,
})

export const deleteArea_list = area_listId => ({
  type: DELETE_AREA_LIST,
  area_listId,
})

export const deleteArea_listSuccess = area_list => ({
  type: DELETE_AREA_LIST_SUCCESS,
  payload: area_list,
})

export const deleteArea_listFail = error => ({
  type: DELETE_AREA_LIST_FAIL,
  payload: error,
})
