import React, { Fragment, useEffect, useState } from 'react'
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Spinner,
  Badge,
  FormGroup,
} from 'reactstrap'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
// data table related plugins
import BootstrapTable from 'react-bootstrap-table-next'
import { debounce, map, range } from 'lodash'
import ToolkitProvider from 'react-bootstrap-table2-toolkit'
import Select from 'react-select'
import { useWindowSize } from 'hooks/useWindowSize'

//actions
import {
  getAllLuckyDraws,
  deleteLuckyDraw,
  LuckyDrawWon,
  LuckyDrawWinnings,
  getAllSupplyChains,
} from 'store/actions'

import '../../assets/scss/data-tables.scss'
import MyPagination from 'components/Common/MyPagination'
import DeleteModal from 'components/Common/DeleteModal'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import moment from 'moment'
import LuckyDrawModal from './singleView/Modal'
import PropTypes from 'prop-types'

const LuckyDraws = ({ promoterId }) => {
  const dispatch = useDispatch()
  const width = useWindowSize()

  const role = sessionStorage.getItem('role')

  const [page, setPage] = useState(1)
  const [searchText, setSearchText] = useState('')
  const [sort, setSort] = useState('all')

  const [luckyDrawModal, setLuckyDrawModal] = useState({
    status: false,
    data: {},
  })

  const { LuckyDraws, loading, SupplyChains, supplyChainLoad } = useSelector(
    state => ({
      LuckyDraws: state.SupplyChain.luckyDraws,
      loading: state.SupplyChain.loading,

      SupplyChains: state.SupplyChain.supplyChains,
      supplyChainLoad: state.SupplyChain.loading,
    }),
  )

  const isMobile = width?.width <= 425

  const [supplyChainSelect, setSupplyChainSelect] = useState({
    title: 'Search SupplyChain',
    id: '',
  })

  useEffect(() => {
    if (role === 'admin') {
      if (promoterId) {
        dispatch(LuckyDrawWon(promoterId))
      } else {
        dispatch(
          getAllLuckyDraws(page, sort, 10, searchText, supplyChainSelect?.id),
        )
      }
    } else {
      dispatch(LuckyDrawWinnings())
    }
  }, [dispatch, page, sort, searchText, supplyChainSelect])

  // change to .total
  const totalPages = Math.ceil(LuckyDraws?.total / 10)
  const pages = range(1, totalPages + 1)

  const [isOpen, setIsOpen] = useState({ status: false, id: '' })

  const handleDelete = id => {
    setIsOpen({ status: true, id: id })
  }

  const handleDeleteEvent = () => {
    setIsOpen({ ...isOpen, status: false })
    dispatch(deleteLuckyDraw(isOpen?.id))
  }
  const columns = [
    {
      dataField: 'date',
      text: ' date',
      sort: true,
    },
    {
      dataField: 'supplyChain',
      text: 'supplyChain',
      sort: true,
    },
    {
      dataField: 'product',
      text: 'Product',
      sort: true,
    },
    {
      dataField: 'winner.ref_id',
      text: 'winner',
      sort: true,
    },
    {
      dataField: 'limit',
      text: 'limit',
      sort: true,
    },

    {
      dataField: 'action',
      text: 'Action',
    },
  ]
  const productData = map(LuckyDraws?.luckyDraws, (item, index) =>
    // console.log(item),
    ({
      ...item,
      supplyChain: (
        <Link to={`/lucky-draw-details/${item?._id}`}>
          {item?.supplyChain?.chainId}
        </Link>
      ),
      product: (
        <Link
          to={`/product/update/${item?.product?._id}`}
          id="promoterName"
          style={{ maxWidth: '150px', whiteSpace: 'wrap' }}
        >
          {item?.supplyChain?.product?.primaryLang?.name}
        </Link>
      ),
      action: (
        <div>
          <Link to={`/lucky-draw-details/${item?._id}`} className="btn-md">
            <i
              className="bx bx-show-alt me-2 "
              style={{ fontSize: '1.3rem' }}
            />
          </Link>
          {!item?.isDrew && (
            <Button
              id={`Delete${index}`}
              type="button"
              color="white"
              className="ms-1 btn "
              onClick={() => {
                handleDelete(item._id)
              }}
            >
              <i
                className="bx bx-trash me-2"
                style={{
                  color: 'red',
                  marginBottom: '10px',
                  fontSize: '1.3rem',
                }}
              />
            </Button>
          )}
        </div>
      ),
      date: moment(item.createdAt).format('ll'),
    }),
  )

  const debounceProductSearch = debounce(value => setSearchText(value), 600)

  const listChange = sortValue => {
    setSort(sortValue)
  }

  const tableTitles = [
    {
      title: 'All',
      value: 'all',
    },

    {
      title: 'Pending',
      value: 'pending',
    },
    {
      title: 'Completed',
      value: 'completed',
    },
    {
      title: 'Active',
      value: 'active',
    },
    {
      title: 'In Active',
      value: 'inactive',
    },
  ]

  //supplyChainSelect
  const [supplyChainSearch, setSupplyChainSearch] = useState('')

  const debounceSupplyChainSearch = debounce(
    value => setSupplyChainSearch(value),
    600,
  )

  useEffect(() => {
    if (role === 'admin') {
      dispatch(getAllSupplyChains('', 10, supplyChainSearch, ''))
    }
  }, [dispatch, supplyChainSearch, role])

  const handleSupplyChainEnters = textEntered => {
    debounceSupplyChainSearch(textEntered)
  }

  function handlerSupplyChainFinalValue(event) {
    const supplyChain = {
      ...supplyChainSelect,
      title: event.label,
      id: event.value,
    }
    setSupplyChainSelect(supplyChain)
  }

  const supplyChainOptions = [
    {
      label: 'none',
      id: '',
    },
    {
      options: (SupplyChains?.total >= 1
        ? SupplyChains?.supplyChains
        : []
      )?.map((result, index) => ({
        key: index,
        label: `${result?.chainId} - ${result?.product?.primaryLang?.name}`,
        value: result?._id,
      })),
    },
  ]

  return (
    <React.Fragment>
      <Row>
        <DeleteModal
          show={isOpen?.status}
          onCloseClick={() => setIsOpen({ data: {}, status: false })}
          onDeleteClick={handleDeleteEvent}
        />
        {luckyDrawModal?.status && (
          <LuckyDrawModal
            onCloseClick={() => setLuckyDrawModal({ status: false, data: {} })}
            show={luckyDrawModal?.status}
            updateData={luckyDrawModal?.data}
          />
        )}
        <Col className="col-12">
          <Card>
            <CardBody>
              <ToolkitProvider
                keyField="_id"
                key={'id'}
                columns={columns}
                data={productData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    {!promoterId ? (
                      <Row className="mb-2">
                        {role === 'admin' && (
                          <Col sm="4" className="">
                            <div
                              // className="btn-group mt-2 mt-xl-0 mb-2"
                              className={`${
                                isMobile
                                  ? 'btn btn-md d-flex flex-wrap'
                                  : 'btn-group mt-2'
                              } mt-xl-0`}
                              role="group"
                              aria-label="Basic radio toggle button group"
                            >
                              {map(tableTitles, (head, key) => (
                                <Fragment key={key}>
                                  <input
                                    type="radio"
                                    className="btn-check"
                                    name="btnradio"
                                    id={head.value + key}
                                    autoComplete="off"
                                    checked={sort === head.value}
                                    onChange={() =>
                                      listChange(head?.value || '')
                                    }
                                  />
                                  <label
                                    // className="btn btn-success d-flex align-items-center w-100"
                                    className={`${
                                      isMobile
                                        ? 'justify-content-center me-2 btn btn-success d-flex align-items-center'
                                        : 'btn btn-success d-flex align-items-center w-100'
                                    } `}
                                    htmlFor={head.value + key}
                                    style={{
                                      whiteSpace: 'nowrap',
                                    }}
                                  >
                                    {head?.title}

                                    <Badge
                                      className={`font-size-12 ms-2 ${
                                        sort === head.value
                                          ? 'badge-soft-warning'
                                          : 'badge-soft-light'
                                      }`}
                                      pill
                                    >
                                      {head?.count}
                                    </Badge>
                                  </label>
                                </Fragment>
                              ))}
                            </div>
                          </Col>
                        )}

                        <Row>
                          <Col
                            sm="12"
                            md="4"
                            className="d-flex align-items-center justify-content-center mb-3"
                          >
                            <div className="search-box w-100 me-2 d-inline-block">
                              <div className="position-relative">
                                <AvForm>
                                  <AvField
                                    name="searchText"
                                    type="text"
                                    value={searchText}
                                    placeholder={searchText}
                                    onChange={e =>
                                      debounceProductSearch(e.target.value)
                                    }
                                  />
                                </AvForm>
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                            {/* {role === 'admin' && (
                            <FormGroup className="w-100">
                              <div className=" ajax-select mt-lg-0 select2-container">
                                <Select
                                  onInputChange={handleSupplyChainEnters}
                                  value={supplyChainSelect?.title}
                                  placeholder={supplyChainSelect?.title}
                                  onChange={handlerSupplyChainFinalValue}
                                  options={supplyChainOptions}
                                  classNamePrefix="select2-selection"
                                  isLoading={supplyChainLoad}
                                />
                              </div>
                            </FormGroup>
                          )} */}
                          </Col>
                          <Col sm="12" md="4">
                            {role === 'admin' && (
                              <FormGroup className="w-100">
                                <div className=" ajax-select mt-lg-0 select2-container mb-3">
                                  <Select
                                    onInputChange={handleSupplyChainEnters}
                                    value={supplyChainSelect?.title}
                                    placeholder={supplyChainSelect?.title}
                                    onChange={handlerSupplyChainFinalValue}
                                    options={supplyChainOptions}
                                    classNamePrefix="select2-selection"
                                    isLoading={supplyChainLoad}
                                  />
                                </div>
                              </FormGroup>
                            )}
                          </Col>

                          {role === 'admin' && (
                            <Col sm="3" md="4">
                              <div
                                className={`${
                                  isMobile && 'd-flex justify-content-end'
                                } text-sm-end`}
                              >
                                <Link to="#">
                                  <Button
                                    onClick={() =>
                                      setLuckyDrawModal({
                                        status: true,
                                        data: null,
                                      })
                                    }
                                    type="button"
                                    color="success"
                                    className="btn-rounded  mb-2 me-2"
                                  >
                                    <i className="mdi mdi-plus me-1" />
                                    Create LuckyDraw
                                  </Button>
                                </Link>
                              </div>
                            </Col>
                          )}
                        </Row>
                        {/* 
                        {role === 'admin' && (
                          <Col sm="3">
                            <div className="text-sm-end mt-2 mb-2">
                              <Link to="#">
                                <Button
                                  onClick={() =>
                                    setLuckyDrawModal({
                                      status: true,
                                      data: null,
                                    })
                                  }
                                  type="button"
                                  color="success"
                                  className="btn-rounded  mb-2 me-2"
                                >
                                  <i className="mdi mdi-plus me-1" />
                                  Create LuckyDraw
                                </Button>
                              </Link>
                            </div>
                          </Col>
                        )} */}
                      </Row>
                    ) : (
                      <h6 className="chain-transaction mb-3">Lucky Draw Won</h6>
                    )}
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        {LuckyDraws?.luckyDraws?.length > 0 ? (
                          <>
                            <Row>
                              <p>Total :&nbsp;{LuckyDraws?.total} </p>

                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={'id'}
                                    key={'id'}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    // selectRow={selectRow}
                                    classes={'table align-middle table-nowrap'}
                                    headerWrapperClasses={'thead-light'}
                                    {...toolkitProps.baseProps}
                                    // onTableChange={handleTableChange}
                                    // {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                            {!promoterId && role === 'admin' && (
                              <MyPagination
                                totalPages={pages}
                                page={page}
                                setPage={setPage}
                              />
                            )}
                          </>
                        ) : (
                          <p className="my-4 mx-3 ">No LuckyDraws !</p>
                        )}
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default LuckyDraws
LuckyDraws.propTypes = {
  promoterId: PropTypes.string,
}
