import { call, put, takeEvery } from 'redux-saga/effects'

// Crypto Redux States
import {
  GET_ALL_PRODUCTS,
  GET_ALL_PRODUCTS_LIST,
  GET_DELETED_PRODUCTS,
  GET_PRODUCT_DETAILS,
  CREATE_PRODUCT,
  UPDATE_PRODUCT,
  CHANGE_PRODUCT_STATUS,
  DELETE_PRODUCT,
  HARD_DELETE_PRODUCT,
  RESTORE_PRODUCT,
  CREATE_DUPLICATE_PRODUCT,
  GET_ALL_PRODUCTS_FOR_DOWNLOAD,
  UPDATE_BULK_PRODUCTS,
  CHECK_PRODUCT_NAME,
  GET_REVIEWS,
  GET_REVIEW_DETAILS,
  CREATE_REVIEW,
  UPDATE_REVIEW,
  DELETE_REVIEW,
  GET_PRODUCT_SORT_COUNT,
  WHATSAPP_API,
} from './actionTypes'
import {
  getAllProductsSuccess,
  getAllProductsFail,
  getAllProductsListSuccess,
  getAllProductsListFail,
  getDeletedProductsSuccess,
  getDeletedProductsFail,
  getProductDetailsSuccess,
  getProductDetailsFail,
  createProductFail,
  createProductSuccess,
  updateProductSuccess,
  updateProductFail,
  changeProductStatusSuccess,
  changeProductStatusFail,
  deleteProductSuccess,
  deleteProductFail,
  hardDeleteProductSuccess,
  hardDeleteProductFail,
  restoreProductSuccess,
  restoreProductFail,
  createDuplicateProductSuccess,
  createDuplicateProductFail,
  getAllProductsForDownloadSuccess,
  getAllProductsForDownloadFail,
  updateBulkProductsSuccess,
  updateBulkProductsFail,
  checkProductNameSuccess,
  checkProductNameFail,
  getAllReviewSuccess,
  getAllReviewFail,
  getReviewDetailsSuccess,
  getReviewDetailsFail,
  createReviewFail,
  createReviewSuccess,
  updateReviewFail,
  updateReviewSuccess,
  deleteReviewSuccess,
  deleteReviewFail,
  getProductSortCountSuccess,
  getProductSortCountFail,
  whatsappApiSuccess,
  whatsappApiFail,
} from './actions'
import { get, post, ApiPut, del } from 'helpers/api_helper'
import { Notification } from '../../components/Common/Notification'

function getAllProductsAPi({
  page,
  sort,
  limit,
  searchText,
  store,
  courseProvider,
}) {
  if (store) {
    return get(
      `/product/admin/all/?sort=${sort ? sort : 'all'}&page=${
        page ? page : 1
      }&limit=${limit ? limit : 10}&search=${searchText}&store=${store}`,
    )
  } else if (searchText) {
    return get(
      `/product/admin/all/?sort=${sort ? sort : 'all'}&page=${page}&limit=${
        limit ? limit : 10
      }&search=${searchText}`,
    )
  } else if (courseProvider) {
    return get(
      `/product/admin/all/?sort=${sort ? sort : 'all'}&page=${
        page ? page : 1
      }&limit=${
        limit ? limit : 10
      }&search=${searchText}&store=${store}&courseProvider=${
        courseProvider ? courseProvider : ''
      }`,
    )
  } else {
    return get(
      `/product/admin/all/?sort=${sort ? sort : 'all'}&page=${
        page ? page : 1
      }&limit=${limit ? limit : 10}`,
    )
  }
}

function getProductSortCountApi() {
  return get(`/product/admin/count/all`)
}

const getAllProductsListAPi = store => {
  if (store) {
    return get(`/product/admin/list/all?store=${store}`)
  } else {
    return get(`/product/admin/list/all`)
  }
}

function getDeletedProductsAPi() {
  return get('/product/admin/deleted/all')
}

const updateBulkProductsApi = ({ bulkProducts }) => {
  return post('/product/admin/update/bulk', bulkProducts)
}

const getProductDetailsAPi = productId => {
  return get(`/product/admin/single/${productId}`)
}

const createProductApi = ({ product }) => {
  return post('/product/admin/new', product)
}

const updateProductApi = ({ product, productId }) => {
  return ApiPut(`/product/admin/${productId}`, product)
}

const getAllProductsForDownloadAPi = () => {
  return get(`/product/admin/download/all`)
}

const changeProductStatusApi = ({ productId }) => {
  return post(`/product/admin/status/${productId}`)
}

const deleteProductApi = productId => {
  return del(`/product/admin/${productId}`)
}

const hardDeleteProductApi = productId => {
  return del(`/product/admin/hard/${productId}`)
}

const restoreProductApi = productId => {
  return post(`/product/admin/restore/${productId}`)
}

const createDuplicateProductAPi = ({ productId }) => {
  return post(`product/admin/duplicate/${productId}`)
}

function checkProductName({ name, productId }) {
  if (productId) {
    return post(`/product/admin/check/name?product=${productId}`, {
      name: name,
    })
  } else return post(`/product/admin/check/name`, { name: name })
}

function getAllReviewAPi({ page, limit, searchText, product }) {
  if (product) {
    return get(
      `/review/admin/all?page=${page ? page : 1}&limit=${
        limit ? limit : 10
      }&search=${searchText && searchText}&product=${product && product}`,
    )
  } else
    return get(
      `/review/admin/all?page=${page ? page : 1}&limit=${
        limit ? limit : 10
      }&search=${searchText && searchText}`,
    )
}

const getReviewDetailsAPi = reviewId => {
  return get(`/review/admin/${reviewId}`)
}

const createReviewApi = ({ review }) => {
  return post('/review/admin/new', review)
}

const updateReviewApi = ({ review, reviewId }) => {
  return ApiPut(`/review/admin/${reviewId}`, review)
}

const deleteReviewApi = ({ reviewId }) => {
  return del(`/review/admin/${reviewId}`)
}

const whatsappBroadCastApi = ({ productId }) => {
  return post(`/whatsapp-api/broadcast/product/${productId}`)
}

// export const imageUpload = async (id,deleteUrl) => {
//   try {
//     const res = await post(deleteUrl, {
//       public_id: id,
//     })
//     if (res?.result === "ok") {
//       Notification({
//         type: "success",
//         message: "Image removed",
//         title: "",
//       })
//       return 'success'
//     } else {
//       Notification({
//         type: "error",
//         message: "Image delete faild, try again",
//         title: "",
//       })
//       return 'error'
//     }
//   } catch (error) {
//     Notification({
//       type: "error",
//       message: "Image delete faild, try again",
//       title: "",
//     })
//   }
//   return post(`product/admin/duplicate/${productId}`)
// }
// function* fetchProducts({ payload }) {
//   try {
//     const response = yield call(getProductsAPi, payload)
//     yield put(getProductsSuccess(response))
//   } catch (error) {
//     yield put(getProductsFail(error))
//   }
// }

function* fetchAllProducts({ payload }) {
  try {
    const response = yield call(getAllProductsAPi, payload)
    yield put(getAllProductsSuccess(response))
  } catch (error) {
    yield put(getAllProductsFail(error))
  }
}

function* fetchAllProductsList({ payload: store }) {
  try {
    const response = yield call(getAllProductsListAPi, store)
    yield put(getAllProductsListSuccess(response))
  } catch (error) {
    yield put(getAllProductsListFail(error))
  }
}

function* fetchProductSortCount({ payload }) {
  try {
    const response = yield call(getProductSortCountApi, payload)
    yield put(getProductSortCountSuccess(response))
  } catch (error) {
    yield put(getProductSortCountFail(error))
  }
}

function* fetchDeletedProducts() {
  try {
    const response = yield call(getDeletedProductsAPi)
    yield put(getDeletedProductsSuccess(response))
  } catch (error) {
    yield put(getDeletedProductsFail(error))
  }
}

function* onUpdateBulkProducts({ payload }) {
  try {
    const response = yield call(updateBulkProductsApi, payload)
    yield put(updateBulkProductsSuccess(response))
    // payload.history.push("/products")
    doneNotification(response.message)
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: 'error',
        message: error.response?.data?.message,
        title: '',
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: 'error',
        message: 'file size is Too Large',
        title: '',
      })
    }
    yield put(updateBulkProductsFail(error))
  }
}

function* fetchProductDetails({ payload: productId }) {
  try {
    const response = yield call(getProductDetailsAPi, productId)

    yield put(getProductDetailsSuccess(response))
  } catch (error) {
    yield put(getProductDetailsFail(error))
  }
}

function* onCreateProduct({ payload }) {
  try {
    const response = yield call(createProductApi, payload)
    if (response?.message) {
      Notification({
        type: 'error',
        message: response?.message,
        title: '',
      })
    } else if (response) {
      yield put(createProductSuccess(response))
      payload.history.push('/products')
      doneNotification('product Created Successfully!')
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: 'error',
        message: error?.response?.data?.message,
        title: '',
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: 'error',
        message: 'file size is Too Large',
        title: '',
      })
    }
    yield put(createProductFail(error))
  }
}

function* onUpdateProduct({ payload }) {
  try {
    const response = yield call(updateProductApi, payload)
    if (response) {
      Notification({
        type: 'success',
        message: 'product Updated Successfully!',
        title: '',
      })
      yield put(updateProductSuccess(response))
      if (payload.history) {
        payload.history.push('/products')
      }
    }
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: 'error',
        message: error.response?.data?.message,
        title: '',
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: 'error',
        message: 'file size is Too Large',
        title: '',
      })
    }
    yield put(updateProductFail(error.response.data))
  }
}

function* fetchAllProductsForDownload() {
  try {
    const response = yield call(getAllProductsForDownloadAPi)
    yield put(getAllProductsForDownloadSuccess(response))
  } catch (error) {
    yield put(getAllProductsForDownloadFail(error))
  }
}

function* onChangeProductStatus({ payload }) {
  try {
    const response = yield call(changeProductStatusApi, payload)
    yield put(changeProductStatusSuccess(response))
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: 'error',
        message: error?.response?.data?.message,
        title: '',
      })
    }
    yield put(changeProductStatusFail(error?.response?.data))
  }
}

function* onDeleteProduct({ productId, history }) {
  try {
    const response = yield call(deleteProductApi, productId)
    yield put(deleteProductSuccess(response))
    Notification({
      type: 'success',
      message: 'product Deleted Successfully!',
      title: '',
    })
    history.goBack()
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: 'error',
        message: error?.response?.data?.message,
        title: '',
      })
    }
    yield put(deleteProductFail(error?.response?.data))
  }
}

function* onHardDeleteProduct({ productId, history }) {
  try {
    const response = yield call(hardDeleteProductApi, productId)
    yield put(hardDeleteProductSuccess(response))
    Notification({
      type: 'success',

      message: 'product Hard Deleted Successfully!',
      title: '',
    })
    history.goBack()
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: 'error',
        message: error?.response?.data?.message,
        title: '',
      })
    }
    yield put(hardDeleteProductFail(error?.response?.data))
  }
}

function* onRestoreProduct({ productId, history }) {
  try {
    const response = yield call(restoreProductApi, productId)
    yield put(restoreProductSuccess(response))
    Notification({
      type: 'success',
      message: 'product Restored Successfully!',
      title: '',
    })
    history.goBack()
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: 'error',
        message: error?.response?.data?.message,
        title: '',
      })
    }
    yield put(restoreProductFail(error?.response?.data))
  }
}

function* onCreateDuplicateProduct({ payload }) {
  try {
    const response = yield call(createDuplicateProductAPi, payload)
    yield put(createDuplicateProductSuccess(response))
    Notification({
      type: 'success',
      message: 'product Duplicated Successfully',
      title: '',
    })
  } catch (error) {
    yield put(createDuplicateProductFail(error?.response?.data))
  }
}

// product name check

function* onCheckPageTitle({ payload }) {
  try {
    const response = yield call(checkProductName, payload)
    yield put(checkProductNameSuccess(response))
  } catch (error) {
    yield put(checkProductNameFail(error))
  }
}

function* fetchAllReview({ payload }) {
  try {
    const response = yield call(getAllReviewAPi, payload)
    yield put(getAllReviewSuccess(response))
  } catch (error) {
    yield put(getAllReviewFail(error))
  }
}
function* fetchReviewDetails({ payload: productId }) {
  try {
    const response = yield call(getReviewDetailsAPi, productId)
    yield put(getReviewDetailsSuccess(response))
  } catch (error) {
    yield put(getReviewDetailsFail(error))
  }
}

function* onCreateReview({ payload }) {
  try {
    const response = yield call(createReviewApi, payload)
    if (response?._id) {
      try {
        const response = yield call(getAllReviewAPi, {
          page: 1,
          limit: 10,
          searchText: '',
          product: '',
        })
        yield put(getAllReviewSuccess(response))
      } catch (error) {
        yield put(getAllReviewFail(error))
      }
      yield put(createReviewSuccess(response))
      payload?.onCloseClick()
      doneNotification('review Created Successfully!')
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: 'error',
        message: error?.response?.data?.message,
        title: '',
      })
    }
    yield put(createReviewFail(error))
  }
}

function* onUpdateReview({ payload }) {
  try {
    const response = yield call(updateReviewApi, payload)
    if (response) {
      doneNotification('review Created Successfully!')
      yield put(updateReviewSuccess(response))
      payload?.onCloseClick()
      try {
        const response = yield call(getAllReviewAPi, {
          page: 1,
          limit: 10,
          searchText: '',
          product: '',
        })
        yield put(getAllReviewSuccess(response))
      } catch (error) {
        yield put(getAllReviewFail(error))
      }
    }
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: 'error',
        message: error.response?.data?.message,
        title: '',
      })
    }

    yield put(updateReviewFail(error.response.data))
  }
}

function* onDeleteReview({ payload }) {
  try {
    const response = yield call(deleteReviewApi, payload)
    yield put(deleteReviewSuccess(response))
    doneNotification('review Deleted Successfully!')
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: 'error',
        message: error?.response?.data?.message,
        title: '',
      })
    }
    yield put(deleteReviewFail(error?.response?.data))
  }
}

function* whatsappBroadCast({ payload }) {
  try {
    const response = yield call(whatsappBroadCastApi, payload)
    yield put(whatsappApiSuccess(response))
    Notification({
      type: 'success',
      message: 'success',
      title: '',
    })
  } catch (error) {
    yield put(whatsappApiFail(error?.response?.data))
  }
}

function* productSaga() {
  yield takeEvery(GET_ALL_PRODUCTS, fetchAllProducts)
  yield takeEvery(GET_PRODUCT_SORT_COUNT, fetchProductSortCount)

  yield takeEvery(GET_ALL_PRODUCTS_LIST, fetchAllProductsList)
  yield takeEvery(GET_DELETED_PRODUCTS, fetchDeletedProducts)
  yield takeEvery(GET_PRODUCT_DETAILS, fetchProductDetails)
  yield takeEvery(CREATE_PRODUCT, onCreateProduct)
  yield takeEvery(UPDATE_PRODUCT, onUpdateProduct)
  yield takeEvery(CHANGE_PRODUCT_STATUS, onChangeProductStatus)
  yield takeEvery(DELETE_PRODUCT, onDeleteProduct)
  yield takeEvery(HARD_DELETE_PRODUCT, onHardDeleteProduct)
  yield takeEvery(RESTORE_PRODUCT, onRestoreProduct)
  yield takeEvery(CREATE_DUPLICATE_PRODUCT, onCreateDuplicateProduct)
  yield takeEvery(GET_ALL_PRODUCTS_FOR_DOWNLOAD, fetchAllProductsForDownload)
  yield takeEvery(UPDATE_BULK_PRODUCTS, onUpdateBulkProducts)
  yield takeEvery(CHECK_PRODUCT_NAME, onCheckPageTitle)

  yield takeEvery(GET_REVIEWS, fetchAllReview)
  yield takeEvery(GET_REVIEW_DETAILS, fetchReviewDetails)
  yield takeEvery(CREATE_REVIEW, onCreateReview)
  yield takeEvery(UPDATE_REVIEW, onUpdateReview)
  yield takeEvery(DELETE_REVIEW, onDeleteReview)
  yield takeEvery(WHATSAPP_API, whatsappBroadCast)
}

export default productSaga

// function* fetchAllActiveProducts({ payload }) {
//   try {
//     const response = yield call(getAllActiveProductsAPi, payload)
//     yield put(getAllActiveProductsSuccess(response))
//   } catch (error) {
//     yield put(getAllActiveProductsFail(error))
//   }
// }

// function* fetchAllInactiveProducts({ payload }) {
//   try {
//     const response = yield call(getAllInactiveProductsAPi, payload)
//     yield put(getAllInactiveProductsSuccess(response))
//   } catch (error) {
//     yield put(getAllInactiveProductsFail(error))
//   }
// }

// function* fetchAllDeletedProducts({ payload }) {
//   try {
//     const response = yield call(getAllDeletedProductsAPi, payload)
//     yield put(getAllDeletedProductsSuccess(response))
//   } catch (error) {
//     yield put(getAllDeletedProductsFail(error))
//   }
// }

function doneNotification(message) {
  Notification({
    type: 'success',
    message: message,
    title: '',
  })
}
