import { call, put, takeEvery } from 'redux-saga/effects'

import {
  GET_COURSEFORMS,
  GET_COURSEFORM_DETAILS,
  CREATE_COURSEFORM,
  UPDATE_COURSEFORM,
  DELETE_COURSEFORM,
} from './actionTypes'
import {
  getCourseFormsFail,
  getCourseFormsSuccess,
  getCourseFormDetailsSuccess,
  getCourseFormDetailsFail,
  createCourseFormFail,
  createCourseFormSuccess,
  updateCourseFormSuccess,
  updateCourseFormFail,
  deleteCourseFormSuccess,
  deleteCourseFormFail,
} from './actions'

import { get, post, ApiPut, del } from 'helpers/api_helper'
import { Notification } from '../../components/Common/Notification'

// CourseForm - This line cannot be edited or removed
function getCourseFormsAPi({ page, sort, limit, searchText }) {
  return get(
    `/course-provider/all/?sort=${sort}&page=${page ? page : 1}&limit=${
      limit ? limit : 10
    }&search=${searchText}`,
  )
}

const getCourseFormDetailsAPi = courseformId => {
  return get(`/courseform/admin/single/${courseformId}`)
}

const createCourseFormApi = ({ courseform }) => {
  return post('/courseform/admin/new', courseform)
}

const updateCourseFormApi = ({ courseform }) => {
  return ApiPut(`/course-provider`, courseform)
}

const deleteCourseFormApi = ({ courseformId }) => {
  return del(`/courseform/admin/${courseformId}`)
}

function* fetchCourseForms({ payload }) {
  try {
    const response = yield call(getCourseFormsAPi, payload)
    yield put(getCourseFormsSuccess(response))
  } catch (error) {
    yield put(getCourseFormsFail(error))
  }
}

function* fetchCourseFormDetails({ payload: courseformId }) {
  try {
    const response = yield call(getCourseFormDetailsAPi, courseformId)
    yield put(getCourseFormDetailsSuccess(response))
  } catch (error) {
    yield put(getCourseFormDetailsFail(error))
  }
}

function* onCreateCourseForm({ payload }) {
  try {
    const response = yield call(createCourseFormApi, payload)
    if (response?.message) {
      Notification({
        type: 'error',
        message: response?.message,
        title: '',
      })
    } else if (response) {
      yield put(createCourseFormSuccess(response))
      payload?.history?.push('/courseforms')
      doneNotification('CourseForm Created Successfully!')
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: 'error',
        message: error?.response?.data?.message,
        title: '',
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: 'error',
        message: 'file size is Too Large',
        title: '',
      })
    }
    yield put(createCourseFormFail(error))
  }
}

function* onUpdateCourseForm({ payload }) {
  try {
    const response = yield call(updateCourseFormApi, payload)
    if (response) {
      Notification({
        type: 'success',
        message: 'CourseForm Updated Successfully!',
        title: '',
      })
      yield put(updateCourseFormSuccess(response))
      if (payload.history) {
        payload.history.push('/courseforms')
      }
    }
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: 'error',
        message: error.response?.data?.message,
        title: '',
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: 'error',
        message: 'file size is Too Large',
        title: '',
      })
    }
    yield put(updateCourseFormFail(error.response.data))
  }
}

function* onDeleteCourseForm({ CourseFormId }) {
  try {
    const response = yield call(deleteCourseFormApi, CourseFormId)
    yield put(deleteCourseFormSuccess(response))
    Notification({
      type: 'success',
      message: 'CourseForm Deleted Successfully!',
      title: '',
    })
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: 'error',
        message: error?.response?.data?.message,
        title: '',
      })
    }
    yield put(deleteCourseFormFail(error?.response?.data))
  }
}

function* courseformSaga() {
  yield takeEvery(GET_COURSEFORMS, fetchCourseForms)
  yield takeEvery(GET_COURSEFORM_DETAILS, fetchCourseFormDetails)
  yield takeEvery(CREATE_COURSEFORM, onCreateCourseForm)
  yield takeEvery(UPDATE_COURSEFORM, onUpdateCourseForm)
  yield takeEvery(DELETE_COURSEFORM, onDeleteCourseForm)
}

export default courseformSaga

function doneNotification(message) {
  Notification({
    type: 'success',
    message: message,
    title: '',
  })
}
