import {
  GET_WITHDRAWALLISTS,
  GET_WITHDRAWALLISTS_SUCCESS,
  GET_WITHDRAWALLISTS_FAIL,
  GET_WITHDRAWALLIST_DETAILS,
  GET_WITHDRAWALLIST_DETAILS_SUCCESS,
  GET_WITHDRAWALLIST_DETAILS_FAIL,
  CREATE_WITHDRAWALLIST,
  CREATE_WITHDRAWALLIST_SUCCESS,
  CREATE_WITHDRAWALLIST_FAIL,
  UPDATE_WITHDRAWALLIST,
  UPDATE_WITHDRAWALLIST_SUCCESS,
  UPDATE_WITHDRAWALLIST_FAIL,
  DELETE_WITHDRAWALLIST,
  DELETE_WITHDRAWALLIST_SUCCESS,
  DELETE_WITHDRAWALLIST_FAIL,
} from './actionTypes'

const INIT_STATE = {
  withdrawalLists: {
    withdrawalLists: [],
    total: '',
    page: '',
  },
  withdrawalListDetails: {},
  error: {},
  loading: false,
}

const WithdrawalList = (state = INIT_STATE, action) => {
  switch (action.type) {
    // WithdrawalList - This line cannot be edited or removed
    case GET_WITHDRAWALLISTS:
    case GET_WITHDRAWALLIST_DETAILS:
    case CREATE_WITHDRAWALLIST:
    case UPDATE_WITHDRAWALLIST:
    case DELETE_WITHDRAWALLIST:
      return {
        ...state,
        loading: true,
      }

    case DELETE_WITHDRAWALLIST_FAIL:
    case GET_WITHDRAWALLISTS_FAIL:
    case GET_WITHDRAWALLIST_DETAILS_FAIL:
    case UPDATE_WITHDRAWALLIST_FAIL:
    case CREATE_WITHDRAWALLIST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_WITHDRAWALLISTS_SUCCESS:
      return {
        ...state,
        withdrawalLists: action.payload,
        error: {},
        loading: false,
      }

    case GET_WITHDRAWALLIST_DETAILS_SUCCESS:
      return {
        ...state,
        withdrawalListDetails: action.payload,
        error: {},
        loading: false,
      }

    case CREATE_WITHDRAWALLIST_SUCCESS:
      return {
        ...state,
        withdrawalLists: {
          ...state.withdrawalLists,
          withdrawalLists: [
            ...state.withdrawalLists.withdrawalLists,
            action.payload,
          ],
          total: state.withdrawalLists.total + 1,
        },
        withdrawalListDetails: action.payload,
        error: {},
        loading: false,
      }

    case UPDATE_WITHDRAWALLIST_SUCCESS:
      return {
        ...state,
        withdrawalLists: {
          ...state.withdrawalLists,
          withdrawalLists: state.withdrawalLists.withdrawalLists.map(
            withdrawallist =>
              withdrawallist._id === action.payload._id
                ? { ...withdrawallist, ...action.payload }
                : withdrawallist,
          ),
        },
        withdrawalListDetails: action.payload,
        loading: false,
        error: {},
      }

    case DELETE_WITHDRAWALLIST_SUCCESS:
      return {
        ...state,
        withdrawalLists: {
          ...state.withdrawalLists,
          withdrawalLists: state.withdrawalLists.withdrawalLists.filter(
            withdrawallist => withdrawallist._id !== action.payload._id,
          ),
        },
        error: {},
        loading: false,
      }
    default:
      return state
  }
}

export default WithdrawalList
