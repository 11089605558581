import React, { useEffect } from 'react'
import { Card, CardBody, Container } from 'reactstrap'

import MetaTag from 'components/Common/Meta-tag'
import { useDispatch, useSelector } from 'react-redux'
import { getCourseProviderProfile } from 'store/actions'
import CourseRegistration from 'pages/courseRegistration/DataTables'

const Dashboard = () => {
  const dispatch = useDispatch()

  const { courseProviderProfile } = useSelector(state => ({
    courseProviderProfile: state.CourseProvider.courseProviderProfile,
  }))

  useEffect(() => {
    dispatch(getCourseProviderProfile())
  }, [])

  return (
    <>
      <MetaTag title={'Dashboard'} />

      <div className="page-content">
        <Container fluid>
          <div className="container-fluid">
            <div className="col-12">
              <Card>
                <CardBody>
                  <h4>{courseProviderProfile?.name}</h4>
                  <div className="d-flex align-items-center mb-2">
                    <i className="bx bx-phone text-primary me-2"> </i>
                    <p className="m-0">{courseProviderProfile?.phone}</p>
                  </div>
                  <div className="d-flex align-items-center">
                    <i className="bx bx-mail-send text-primary me-2"> </i>
                    <p className="m-0">{courseProviderProfile?.email}</p>
                  </div>

                  {/* <div className="d-flex justify-content-end">
                    <Link
                      to={'/courseForm/update'}
                      className="btn btn-sm btn-success"
                    >
                      Update Course Form
                    </Link>
                  </div> */}
                </CardBody>
              </Card>
            </div>
            <h5>Course Registration</h5>
            <CourseRegistration />
          </div>
        </Container>
      </div>
    </>
  )
}

export default Dashboard
