import { takeEvery, fork, put, all } from 'redux-saga/effects'

//Account Redux states
import { REGISTER_USER } from './actionTypes'
import { registerUserSuccessful, registerUserFailed } from './actions'

// Is user register successfull then direct plot user in redux.
function* registerUser({}) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === 'jwt') {
      // const response = yield call(postJwtRegister, "/post-jwt-register", user)
      const response = ''
      yield put(registerUserSuccessful(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === 'fake') {
      // const response = yield call(postFakeRegister, user)
      const response = ''

      yield put(registerUserSuccessful(response))
    }
  } catch (error) {
    yield put(registerUserFailed(error))
  }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser)
}

function* accountSaga() {
  yield all([fork(watchUserRegister)])
}

export default accountSaga
