import React from 'react'
import PropTypes from 'prop-types'
import { Alert, Modal, ModalBody } from 'reactstrap'
import Spinner from 'components/Common/Spinner'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import { useDispatch, useSelector } from 'react-redux'
import { createUser } from 'store/actions'

const CreateUserModal = ({ onCloseClick, show }) => {
  const dispatch = useDispatch()

  const { loading, error } = useSelector(state => ({
    loading: state.Users.userCreateLoad,
    error: state.Users.error,
  }))

  const handleValidSubmit = value => {
    dispatch(createUser(value, onCloseClick))
  }
  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      centered={true}
      style={deleteModalStyle}
      className="delete-modal"
    >
      <ModalBody style={deleteModalBodyStyle} className="p-4">
        <AvForm
          className="form-horizontal "
          onValidSubmit={(e, v) => {
            handleValidSubmit(v)
          }}
        >
          {error?.length > 1 ? <Alert color="danger">{error}</Alert> : null}
          <div className="mb-3">
            <AvField
              name="phone"
              label="Phone *"
              type="number"
              required
              placeholder="Enter phone number"
            />
          </div>
          <div className="mb-3">
            <AvField
              name="email"
              label="Email"
              className="form-control"
              placeholder="Enter email"
              type="email"
            />
          </div>
          <div className="mb-3">
            <AvField
              name="name"
              label="name"
              type="text"
              placeholder="Enter name"
            />
          </div>

          <div className="hstack gap-2 mt-5">
            <button
              className="btn btn-outline-dark w-100"
              style={{ borderRadius: 10 }}
              onClick={onCloseClick}
              type="button"
            >
              Cancel
            </button>
            <button
              type="submit"
              style={{ borderRadius: 10 }}
              className="btn btn-success w-100"
            >
              Create&nbsp;
              {loading && <Spinner />}
            </button>
          </div>
        </AvForm>
      </ModalBody>
    </Modal>
  )
}

CreateUserModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.bool,
}

export default CreateUserModal

const deleteModalStyle = {
  width: 400,
}
const deleteModalBodyStyle = {
  minHeight: 300,
}
