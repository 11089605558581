import { call, put, takeEvery } from 'redux-saga/effects'

import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATION_DETAILS,
  CREATE_NOTIFICATION,
  UPDATE_NOTIFICATION,
  DELETE_NOTIFICATION,
} from './actionTypes'
import {
  getNotificationsFail,
  getNotificationsSuccess,
  getNotificationDetailsSuccess,
  getNotificationDetailsFail,
  createNotificationFail,
  createNotificationSuccess,
  updateNotificationSuccess,
  updateNotificationFail,
  deleteNotificationSuccess,
  deleteNotificationFail,
} from './actions'

import { get, post, ApiPut, del } from 'helpers/api_helper'
import { Notification } from '../../components/Common/Notification'

// Notification - This line cannot be edited or removed
function getNotificationsAPi({ page, limit, searchText, sort }) {
  // /notification/promoter/all
  return get(
    `/notification/admin/all/?type=${sort && sort}&page=${
      page ? page : 1
    }&limit=${limit ? limit : 10}&search=${searchText && searchText}`,
  )
}

const getNotificationDetailsAPi = notificationId => {
  return get(`/notification/admin/${notificationId}`)
}

const createNotificationApi = ({ notification }) => {
  return post('/notification/admin/new', notification)
}

const updateNotificationApi = ({ notification, notificationId }) => {
  return ApiPut(`/notification/admin/${notificationId}`, notification)
}

const deleteNotificationApi = ({ notificationId }) => {
  console.log(notificationId)
  return del(`/notification/admin/${notificationId}`)
}

function* fetchNotifications({ payload }) {
  try {
    const response = yield call(getNotificationsAPi, payload)
    yield put(getNotificationsSuccess(response))
  } catch (error) {
    yield put(getNotificationsFail(error))
  }
}

function* fetchNotificationDetails({ payload: notificationId }) {
  try {
    const response = yield call(getNotificationDetailsAPi, notificationId)
    yield put(getNotificationDetailsSuccess(response))
  } catch (error) {
    yield put(getNotificationDetailsFail(error))
  }
}

function* onCreateNotification({ payload }) {
  try {
    const response = yield call(createNotificationApi, payload)
    if (response?.message) {
      Notification({
        type: 'error',
        message: response?.message,
        title: '',
      })
    } else if (response) {
      yield put(createNotificationSuccess(response))
      if (payload?.onCloseClick) {
        payload?.onCloseClick()
      }
      doneNotification('Notification Created Successfully!')
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: 'error',
        message: error?.response?.data?.message,
        title: '',
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: 'error',
        message: 'file size is Too Large',
        title: '',
      })
    }
    yield put(createNotificationFail(error))
  }
}

function* onUpdateNotification({ payload }) {
  try {
    const response = yield call(updateNotificationApi, payload)
    if (response) {
      Notification({
        type: 'success',
        message: 'Notification Updated Successfully!',
        title: '',
      })
      yield put(updateNotificationSuccess(response))
      if (payload?.onCloseClick) {
        payload.onCloseClick()
      }
    }
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: 'error',
        message: error.response?.data?.message,
        title: '',
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: 'error',
        message: 'file size is Too Large',
        title: '',
      })
    }
    yield put(updateNotificationFail(error.response.data))
  }
}

function* onDeleteNotification({ payload }) {
  try {
    const response = yield call(deleteNotificationApi, payload)
    yield put(deleteNotificationSuccess(response))
    Notification({
      type: 'success',
      message: 'Notification Deleted Successfully!',
      title: '',
    })
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: 'error',
        message: error?.response?.data?.message,
        title: '',
      })
    }
    yield put(deleteNotificationFail(error?.response?.data))
  }
}

function* notificationSaga() {
  yield takeEvery(GET_NOTIFICATIONS, fetchNotifications)
  yield takeEvery(GET_NOTIFICATION_DETAILS, fetchNotificationDetails)
  yield takeEvery(CREATE_NOTIFICATION, onCreateNotification)
  yield takeEvery(UPDATE_NOTIFICATION, onUpdateNotification)
  yield takeEvery(DELETE_NOTIFICATION, onDeleteNotification)
}

export default notificationSaga

function doneNotification(message) {
  Notification({
    type: 'success',
    message: message,
    title: '',
  })
}
