/* SHOPS */
export const GET_SHOPS = 'GET_SHOPS'
export const GET_SHOPS_SUCCESS = 'GET_SHOPS_SUCCESS'
export const GET_SHOPS_FAIL = 'GET_SHOPS_FAIL'

/* UNAPPROVED SHOPS */
export const GET_UNAPPROVED_SHOPS = 'GET_UNAPPROVED_SHOPS'
export const GET_UNAPPROVED_SHOPS_SUCCESS = 'GET_UNAPPROVED_SHOPS_SUCCESS'
export const GET_UNAPPROVED_SHOPS_FAIL = 'GET_UNAPPROVED_SHOPS_FAIL'

/* BANNED SHOPS */
export const GET_BANNED_SHOPS = 'GET_BANNED_SHOPS'
export const GET_BANNED_SHOPS_SUCCESS = 'GET_BANNED_SHOPS_SUCCESS'
export const GET_BANNED_SHOPS_FAIL = 'GET_BANNED_SHOPS_FAIL'

/* Change Shop status*/
export const CHANGE_SHOP_STATUS = 'CHANGE_SHOP_STATUS'
export const CHANGE_SHOP_STATUS_SUCCESS = 'CHANGE_SHOP_STATUS_SUCCESS'
export const CHANGE_SHOP_STATUS_FAIL = 'CHANGE_SHOP_STATUS_FAIL'

/* SHOP_DETAILS */
export const GET_SHOP_DETAILS = 'GET_SHOP_DETAILS'
export const GET_SHOP_DETAILS_SUCCESS = 'GET_SHOP_DETAILS_SUCCESS'
export const GET_SHOP_DETAILS_FAIL = 'GET_SHOP_DETAILS_FAIL'

/* CREATE SHOP*/
export const CREATE_SHOP = 'CREATE_SHOP'
export const CREATE_SHOP_SUCCESS = 'CREATE_SHOP_SUCCESS'
export const CREATE_SHOP_FAIL = 'CREATE_SHOP_FAIL'

/* Edit SHOP*/
export const UPDATE_SHOP = 'UPDATE_SHOP'
export const UPDATE_SHOP_SUCCESS = 'UPDATE_SHOP_SUCCESS'
export const UPDATE_SHOP_FAIL = 'UPDATE_SHOP_FAIL'

/* Delete SHOP*/
export const DELETE_SHOP = 'DELETE_SHOP'
export const DELETE_SHOP_SUCCESS = 'DELETE_SHOP_SUCCESS'
export const DELETE_SHOP_FAIL = 'DELETE_SHOP_FAIL'
