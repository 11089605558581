import React from 'react'
import PropTypes from 'prop-types'
import ReactApexChart from 'react-apexcharts'

const chartapex = ({ GraphData }) => {
  const myGraphData = Object?.values(
    GraphData?.data !== undefined ? GraphData?.data : '',
  )

  const series = [{ name: 'Amount', data: myGraphData }]
  const options = {
    chart: {
      toolbar: 'false',
      dropShadow: {
        enabled: !0,
        color: '#000',
        top: 18,
        left: 7,
        blur: 8,
        opacity: 0.2,
      },
    },
    title: { text: 'Promoters Growth Reward (Monthly)', align: 'left' },
    xaxis: {
      title: '',
      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
    },
    dataLabels: {
      enabled: !1,
    },
    colors: ['#556ee6'],
    stroke: {
      curve: 'smooth',
      width: 3,
    },
  }

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="line"
      height="350"
      // className="apexcharts-canvas apexchartscq310u9c apexcharts-theme-light"
    />
  )
}

export default chartapex

chartapex.propTypes = {
  GraphData: PropTypes.any,
}
