import React, { Fragment, useEffect, useState } from 'react'
import { Row, Col, Card, CardBody, Button, Spinner } from 'reactstrap'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// data-table related plugins
import BootstrapTable from 'react-bootstrap-table-next'

import { debounce, map, range } from 'lodash'

import ToolkitProvider from 'react-bootstrap-table2-toolkit'

import '../../assets/scss/data-tables.scss'

import MyPagination from 'components/Common/MyPagination'
import DeleteModal from 'components/Common/DeleteModal'
import { defaultImg } from 'assets/images'
import { deleteNotification, getNotifications } from 'store/actions'
import NotificationModal from './Crud/Modal'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import NotificationViewModal from './Crud/ModalView'
import moment from 'moment'

const Notification = () => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [searchText, setSearchText] = useState('')
  const [sort, setSort] = useState('')

  const role = sessionStorage.getItem('role')

  const { total, notifications, loading } = useSelector(state => ({
    notifications: state.Notification.notifications.notifications,
    total: state.Notification.notifications.total,
    loading: state.Notification.loading,
  }))

  useEffect(() => {
    dispatch(getNotifications(page, 10, searchText, sort))
  }, [dispatch, page, searchText, sort])

  const totalPages = Math.ceil(total / 10)
  const pages = range(1, totalPages + 1)

  const [isOpen, setIsOpen] = useState({ status: false, id: '' })
  const [notificationModal, setNotificationModal] = useState({
    status: false,
    data: '',
  })
  const [notificationViewModal, setNotificationViewModal] = useState({
    status: false,
    data: {},
  })

  const handleDelete = id => {
    setIsOpen({ status: true, id: id })
  }

  const handleDeleteEvent = () => {
    setIsOpen({ ...isOpen, status: false })
    dispatch(deleteNotification(isOpen?.id))
  }

  const columns = [
    {
      dataField: 'sl',
      text: 'sl',
    },

    {
      dataField: 'title',
      text: 'Title',
      sort: true,
    },
    {
      dataField: 'createdAt',
      text: 'Date',
      sort: true,
    },
    {
      dataField: 'link',
      text: 'Link',
      sort: true,
    },
    {
      dataField: 'user',
      text: 'User',
      sort: true,
    },
    {
      dataField: 'type',
      text: 'Type',
      sort: true,
    },
    {
      dataField: 'action',
      text: 'Action',
    },
  ]

  const data = map(notifications, (item, index) => ({
    ...item,
    key: index,
    createdAt: moment(item?.createdAt).format('lll'),
    sl: <span>{index + 1}</span>,
    user: (
      <span>
        {item?.user?.phone} - {item?.user?.name}
      </span>
    ),
    imageData: (
      <div>
        <img
          src={
            item?.images && item?.images[0] ? item?.images[0]?.url : defaultImg
          }
          width="500px"
          height="50%"
        />
      </div>
    ),

    action: (
      <div>
        {role === 'admin' ? (
          <Link
            to={`#`}
            className="btn-md"
            onClick={() =>
              setNotificationModal({
                data: item,
                status: true,
              })
            }
          >
            <i className="bx bx-edit me-2 " style={{ fontSize: '1.3rem' }} />
          </Link>
        ) : (
          <Link
            to={`#`}
            className="btn-md"
            onClick={() =>
              setNotificationViewModal({
                data: item,
                status: true,
              })
            }
          >
            <i className="bx bx-show me-2 " style={{ fontSize: '1.3rem' }} />
          </Link>
        )}
        {role === 'admin' && (
          <Button
            id={`Delete${index}`}
            type="button"
            color="white"
            className="ms-1 btn "
            onClick={() => {
              handleDelete(item?._id)
            }}
          >
            <i
              className="bx bx-trash me-2"
              style={{ color: 'red', marginBottom: '10px', fontSize: '1.3rem' }}
            />
          </Button>
        )}
      </div>
    ),
  }))

  const debounceProductSearch = debounce(value => setSearchText(value), 600)

  const listChange = sortValue => {
    setSort(sortValue)
  }

  return (
    <React.Fragment>
      <Row>
        <DeleteModal
          show={isOpen?.status}
          onCloseClick={() => setIsOpen({ ...isOpen, status: false })}
          onDeleteClick={handleDeleteEvent}
        />
        {notificationModal?.status && (
          <NotificationModal
            onCloseClick={() =>
              setNotificationModal({ status: false, data: {} })
            }
            show={notificationModal?.status}
            updateData={notificationModal?.data}
          />
        )}
        {notificationViewModal?.status && (
          <NotificationViewModal
            onCloseClick={() =>
              setNotificationViewModal({ status: false, data: {} })
            }
            show={notificationViewModal?.status}
            updateData={notificationViewModal?.data}
          />
        )}
        <Col className="col-12">
          <Card>
            <CardBody>
              <ToolkitProvider
                keyField="_id"
                key={'id'}
                columns={columns}
                data={data}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col sm="3">
                        <Fragment>
                          <input
                            type="radio"
                            className="btn-check"
                            name="btnradio"
                            id="btnradio1"
                            autoComplete="off"
                            checked={sort === ''}
                            onChange={() => listChange('')}
                          />
                          <label
                            style={{
                              borderRadius: 0,
                            }}
                            className="btn btn-success"
                            htmlFor="btnradio1"
                          >
                            All
                          </label>
                          <input
                            type="radio"
                            className="btn-check"
                            name="btnradio"
                            id="btnradioCommon"
                            autoComplete="off"
                            checked={sort === 'common'}
                            onChange={() => listChange('common')}
                          />
                          <label
                            className="btn btn-success"
                            htmlFor="btnradioCommon"
                            style={{
                              borderRadius: 0,
                            }}
                          >
                            Common
                          </label>
                          <input
                            type="radio"
                            className="btn-check"
                            name="btnradio"
                            id="btnradioIndividual"
                            autoComplete="off"
                            checked={sort === 'individual'}
                            onChange={() => listChange('individual')}
                          />
                          <label
                            style={{
                              borderRadius: 0,
                            }}
                            className="btn btn-success"
                            htmlFor="btnradioIndividual"
                          >
                            Individual
                          </label>
                        </Fragment>
                      </Col>
                      <Col sm="6">
                        <div className="search-box w-75 ms-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <AvForm>
                              <AvField
                                name="searchText"
                                placeholder="Search"
                                type="text"
                                value={searchText}
                                onChange={e =>
                                  debounceProductSearch(e.target.value)
                                }
                              />
                            </AvForm>
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>
                      </Col>

                      {role === 'admin' && (
                        <Col sm="3">
                          <div className="text-sm-end">
                            <Link
                              to="#"
                              onClick={() =>
                                setNotificationModal({
                                  status: true,
                                  data: '',
                                })
                              }
                            >
                              <Button
                                type="button"
                                color="success"
                                className="btn-rounded  mb-2 me-2"
                              >
                                <i className="mdi mdi-plus me-1" />
                                Create Notification
                              </Button>
                            </Link>
                          </div>
                        </Col>
                      )}
                    </Row>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        {notifications?.length >= 1 ? (
                          <>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={'_id'}
                                    key={'_id'}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    // selectRow={selectRow}
                                    classes={'table align-middle table-nowrap'}
                                    headerWrapperClasses={'thead-light'}
                                    {...toolkitProps.baseProps}
                                    // onTableChange={handleTableChange}
                                    // {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <MyPagination
                              totalPages={pages}
                              page={page}
                              setPage={setPage}
                            />
                          </>
                        ) : (
                          <p className="my-4 mx-3 ">No Notifications !</p>
                        )}
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Notification
