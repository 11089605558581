import { AvField, AvForm } from 'availity-reactstrap-validation'
import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Alert, CardSubtitle, Modal, ModalBody, Row } from 'reactstrap'
import { createCourseProvider, updateCourseProvider } from 'store/actions'

const CourseProviderModal = ({ show, onCloseClick, updateData }) => {
  const dispatch = useDispatch()
  const { loading, errorMsg } = useSelector(state => ({
    loading: state.SupplyChain.loading,
  }))

  function handleValidSubmit(values) {
    const providerData = {
      name: values?.name,
      phone: values?.phone,
      email: values?.email,
      password: values?.password
        ? values?.password
        : updateData?.user?.password,
    }
    if (updateData) {
      dispatch(
        updateCourseProvider(providerData, updateData?._id, onCloseClick),
      )
    } else {
      dispatch(createCourseProvider(providerData, onCloseClick))
    }
  }

  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      centered={true}
      style={deleteModalStyle}
    >
      <ModalBody style={deleteModalBodyStyle} className="p-4">
        {errorMsg?.length >= 1 && <Alert color="danger">{errorMsg}</Alert>}

        <CardSubtitle className="mb-3">{`${
          updateData ? 'Update' : 'Create'
        } Course Provider`}</CardSubtitle>
        <AvForm
          onValidSubmit={(e, v) => {
            handleValidSubmit(v)
          }}
        >
          <Row xl="12">
            <div className="col-12 mb-4 px-3">
              <AvField
                name="name"
                label={
                  <>
                    Name<sup>*</sup>
                  </>
                }
                value={updateData?.name || ''}
                type="text"
                required
              />
            </div>
            <div className="col-12 mb-4 px-3">
              <AvField
                name="phone"
                label="Phone"
                value={updateData?.phone || ''}
                type="number"
              />
            </div>
            <div className="row mb-2">
              <div className={`${updateData ? 'col-12' : 'col-6'} px-3`}>
                <AvField
                  name="email"
                  value={updateData?.email || ''}
                  label={
                    <>
                      Email<sup>*</sup>
                    </>
                  }
                  type="email"
                  required
                />
              </div>
              {updateData ? (
                ''
              ) : (
                <div className="col-6 px-0">
                  <AvField
                    name="password"
                    required
                    value={updateData?.password ?? ''}
                    label={
                      <>
                        Password<sup>*</sup>
                      </>
                    }
                    type="password"
                  />
                </div>
              )}
            </div>

            <div className="d-flex align-items-center justify-content-end px-3 mt-4">
              <button
                type="button"
                className="mt-3 me-2 btn btn-danger save-customer"
                onClick={onCloseClick}
              >
                {loading && (
                  <>
                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                  </>
                )}
                Cancel
              </button>
              <button
                type="submit"
                className={`mt-3 btn ${
                  updateData ? 'btn-primary' : 'btn-success'
                } save-customer`}
              >
                {loading && (
                  <>
                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                  </>
                )}
                {updateData ? 'Update' : 'Create'}
              </button>
            </div>
          </Row>
        </AvForm>
      </ModalBody>
    </Modal>
  )
}

CourseProviderModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
  updateData: PropTypes.any,
}

export default CourseProviderModal

const deleteModalStyle = {
  width: 600,
  minWidth: 600,
}
const deleteModalBodyStyle = {
  minHeight: 100,
}
