import { call, put, takeEvery } from 'redux-saga/effects'

import {
  GET_COURSE_PROVIDERS,
  GET_COURSE_PROVIDER_DETAILS,
  CREATE_COURSE_PROVIDER,
  UPDATE_COURSE_PROVIDER,
  DELETE_COURSE_PROVIDER,
  GET_COURSE_PROVIDER_PROFILE,
  UPDATE_COURSE_PROVIDER_PROFILE,
} from './actionTypes'
import {
  getCourseProvidersFail,
  getCourseProvidersSuccess,
  getCourseProviderDetailsSuccess,
  getCourseProviderDetailsFail,
  createCourseProviderFail,
  createCourseProviderSuccess,
  updateCourseProviderSuccess,
  updateCourseProviderFail,
  deleteCourseProviderSuccess,
  deleteCourseProviderFail,
  getCourseProviderProfileSuccess,
  getCourseProviderProfileFail,
  updateCourseProviderProfileSuccess,
  updateCourseProviderProfileFail,
} from './actions'

import { get, post, ApiPut, del } from 'helpers/api_helper'
import { Notification } from '../../components/Common/Notification'

// CourseProvider - This line cannot be edited or removed
function getCourseProvidersAPi({ page, sort, limit, searchText }) {
  return get(
    `/course-provider/admin/all/?sort=${sort}&page=${page ? page : 1}&limit=${
      limit ? limit : 10
    }&search=${searchText}`,
  )
}

const getCourseProviderDetailsAPi = courseProviderId => {
  return get(`/course-provider/admin/${courseProviderId}`)
}

const createCourseProviderApi = ({ courseProvider }) => {
  return post('/course-provider/admin/new', courseProvider)
}

const updateCourseProviderApi = ({ courseProvider, courseProviderId }) => {
  return ApiPut(`/course-provider/admin/${courseProviderId}`, courseProvider)
}

const deleteCourseProviderApi = courseProviderId => {
  return del(`/course-provider/admin/${courseProviderId}`)
}

function* fetchCourseProviders({ payload }) {
  try {
    const response = yield call(getCourseProvidersAPi, payload)
    yield put(getCourseProvidersSuccess(response))
  } catch (error) {
    yield put(getCourseProvidersFail(error))
  }
}

function* fetchCourseProviderDetails({ payload: courseProviderId }) {
  try {
    const response = yield call(getCourseProviderDetailsAPi, courseProviderId)
    yield put(getCourseProviderDetailsSuccess(response))
  } catch (error) {
    yield put(getCourseProviderDetailsFail(error))
  }
}

function* onCreateCourseProvider({ payload }) {
  console.log(payload)
  try {
    const response = yield call(createCourseProviderApi, payload)
    if (response?.message) {
      Notification({
        type: 'error',
        message: response?.message,
        title: '',
      })
    } else if (response) {
      yield put(createCourseProviderSuccess(response))
      doneNotification('CourseProvider Created Successfully!')
      if (payload?.onCloseClick) {
        payload?.onCloseClick()
      }
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: 'error',
        message: error?.response?.data?.message,
        title: '',
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: 'error',
        message: 'file size is Too Large',
        title: '',
      })
    }
    yield put(createCourseProviderFail(error))
  }
}

function* onUpdateCourseProvider({ payload }) {
  try {
    const response = yield call(updateCourseProviderApi, payload)
    if (response) {
      Notification({
        type: 'success',
        message: 'CourseProvider Updated Successfully!',
        title: '',
      })
      yield put(updateCourseProviderSuccess(response))
      if (payload?.history) {
        payload?.history.push('/courseProviders')
      }
    }
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: 'error',
        message: error.response?.data?.message,
        title: '',
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: 'error',
        message: 'file size is Too Large',
        title: '',
      })
    }
    yield put(updateCourseProviderFail(error.response.data))
  }
}

function* onDeleteCourseProvider({ payload }) {
  try {
    const response = yield call(deleteCourseProviderApi, payload)
    yield put(deleteCourseProviderSuccess(response))
    Notification({
      type: 'success',
      message: 'CourseProvider Deleted Successfully!',
      title: '',
    })
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: 'error',
        message: error?.response?.data?.message,
        title: '',
      })
    }
    yield put(deleteCourseProviderFail(error?.response?.data))
  }
}

// CourseProviderProfile - This line cannot be edited or removed
function getCourseProviderProfileAPi() {
  return get(`/course-provider`)
}

const updateCourseProviderProfileApi = ({ courseProviderProfile }) => {
  return ApiPut(`/course-provider`, courseProviderProfile)
}

function* fetchCourseProviderProfile({ payload }) {
  try {
    const response = yield call(getCourseProviderProfileAPi, payload)
    yield put(getCourseProviderProfileSuccess(response))
  } catch (error) {
    yield put(getCourseProviderProfileFail(error))
  }
}

function* onUpdateCourseProviderProfile({ payload }) {
  try {
    const response = yield call(updateCourseProviderProfileApi, payload)
    if (response) {
      Notification({
        type: 'success',
        message: 'CourseProviderProfile Updated Successfully!',
        title: '',
      })
      yield put(updateCourseProviderProfileSuccess(response))
      if (payload.history) {
        payload.history.push('/')
      }
    }
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: 'error',
        message: error.response?.data?.message,
        title: '',
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: 'error',
        message: 'file size is Too Large',
        title: '',
      })
    }
    yield put(updateCourseProviderProfileFail(error.response.data))
  }
}

function* courseproviderSaga() {
  yield takeEvery(GET_COURSE_PROVIDER_PROFILE, fetchCourseProviderProfile)
  yield takeEvery(UPDATE_COURSE_PROVIDER_PROFILE, onUpdateCourseProviderProfile)

  yield takeEvery(GET_COURSE_PROVIDERS, fetchCourseProviders)
  yield takeEvery(GET_COURSE_PROVIDER_DETAILS, fetchCourseProviderDetails)
  yield takeEvery(CREATE_COURSE_PROVIDER, onCreateCourseProvider)
  yield takeEvery(UPDATE_COURSE_PROVIDER, onUpdateCourseProvider)
  yield takeEvery(DELETE_COURSE_PROVIDER, onDeleteCourseProvider)
}

export default courseproviderSaga

function doneNotification(message) {
  Notification({
    type: 'success',
    message: message,
    title: '',
  })
}
