import React, { useState } from 'react'
import { Row, Col, Card, UncontrolledTooltip, CardBody } from 'reactstrap'
import PropTypes from 'prop-types'
import { useCopyText } from 'hooks/useCopyText'
import { GoDotFill } from 'react-icons/go'
import { CurrencySwitch } from 'hooks/Currency'
import moment from 'moment'
import { Link } from 'react-router-dom'
import PromoterUpdateModal from './profile-update'
import { useHistory } from 'react-router-dom'

//Import Image

const CardWelcome = ({ profile }) => {
  const currency = CurrencySwitch()
  const history = useHistory()
  const { copyText } = useCopyText()

  const handleCopy = text => {
    copyText(text)
  }

  const [promoterUpdateModal, setPromoterUpdateModal] = useState({
    status: false,
    data: {},
  })

  return (
    <React.Fragment>
      <PromoterUpdateModal
        onCloseClick={() => setPromoterUpdateModal({ status: false, data: {} })}
        show={promoterUpdateModal?.status}
        updateData={promoterUpdateModal?.data}
      />
      <Card>
        <div>
          <Row>
            <Col sm="6">
              <div className="p-4 position-relative">
                <h6 className="text-primary">
                  {profile?.ref_id}
                  <i
                    onClick={() => handleCopy(profile?.ref_id)}
                    className="bx bx-copy mx-2 "
                    style={{ cursor: 'copy' }}
                    title="copy ref id"
                  ></i>
                </h6>
                <p className="m-0 p-0">
                  {profile?.name}{' '}
                  <GoDotFill
                    id="profileIsActive"
                    style={{ cursor: 'pointer' }}
                    size={'17px'}
                    className={profile?.isActive ? 'text-success' : ''}
                  />
                  <UncontrolledTooltip target={'profileIsActive'}>
                    Active
                  </UncontrolledTooltip>
                </p>
                <p className="mb-2">{profile?.phone}</p>

                <span>{profile?.address}</span>
                <br />
                <p className="font-size-12">
                  {moment(profile?.createdAt).format('ll')}
                </p>

                <div className="text-muted">
                  <p className="mb-1">
                    <i className="mdi mdi-circle-medium align-middle text-primary me-1" />{' '}
                    Account Number :{' '}
                    <span className="text-black">
                      {' '}
                      {profile?.accountDetails?.accountNumber}
                    </span>
                  </p>
                  <p className="mb-1">
                    <i className="mdi mdi-circle-medium align-middle text-primary me-1" />{' '}
                    IFSC :{' '}
                    <span className="text-black">
                      {profile?.accountDetails?.ifsc}
                    </span>
                  </p>
                </div>
              </div>
            </Col>
            <Col sm="6" className="p-4">
              <div className="align-items-start d-flex justify-content-start">
                <div className="d-flex">
                  <Card className="bg-green">
                    <CardBody>
                      <p className="mb-2">
                        <i className="mdi mdi-circle align-middle font-size-10 me-2 text-warning" />{' '}
                        Growth Reward
                      </p>
                      <h6 className="text-black font-size-15 text-center mb-0">
                        {currency}&nbsp;{profile?.totalGrowthRewardAmount}
                      </h6>
                    </CardBody>
                  </Card>

                  <Card className="ms-3 bg-yellow">
                    <CardBody>
                      <p className="mb-2">
                        <i className="mdi mdi-circle align-middle font-size-10 me-2 text-warning" />{' '}
                        Lead Bonus
                      </p>
                      <h6 className="text-black font-size-15 text-center mb-0">
                        {currency}&nbsp;{profile?.totalLeadBonusAmount}
                      </h6>
                    </CardBody>
                  </Card>
                </div>
              </div>
              <div className="mt-4">
                <Link
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={() =>
                    setPromoterUpdateModal({
                      status: true,
                      data: profile,
                    })
                  }
                  to={`#`}
                  // to={`/promoter/update/${profile?._id}`}
                  className="btn btn-outline-light  btn-md me-2 w-sm"
                >
                  Edit Profile
                </Link>

                <button
                  type="button"
                  className={`me-2 btn tickbtn my-custom-success-soft btn-success btn-md`}
                  style={{ minWidth: '200px' }}
                  onClick={() => history.push('/lucky-draw')}
                >
                  Lucky Draw Winnings
                  <span className="corner">
                    <i className="bx bx-check" />
                  </span>
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </Card>
    </React.Fragment>
  )
}

export default CardWelcome
CardWelcome.propTypes = {
  profile: PropTypes.any,
}
