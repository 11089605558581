import {
  GET_SHOPS,
  GET_SHOPS_SUCCESS,
  GET_SHOPS_FAIL,
  GET_UNAPPROVED_SHOPS,
  GET_UNAPPROVED_SHOPS_SUCCESS,
  GET_UNAPPROVED_SHOPS_FAIL,
  GET_BANNED_SHOPS,
  GET_BANNED_SHOPS_SUCCESS,
  GET_BANNED_SHOPS_FAIL,
  CHANGE_SHOP_STATUS,
  CHANGE_SHOP_STATUS_SUCCESS,
  CHANGE_SHOP_STATUS_FAIL,
  GET_SHOP_DETAILS,
  GET_SHOP_DETAILS_SUCCESS,
  GET_SHOP_DETAILS_FAIL,
  CREATE_SHOP,
  CREATE_SHOP_SUCCESS,
  CREATE_SHOP_FAIL,
  UPDATE_SHOP,
  UPDATE_SHOP_SUCCESS,
  UPDATE_SHOP_FAIL,
  DELETE_SHOP,
  DELETE_SHOP_SUCCESS,
  DELETE_SHOP_FAIL,
} from './actionTypes'

const INIT_STATE = {
  shops: [],
  unapprovedShops: [],
  bannedShops: [],
  shopDetails: {},
  shopVarients: {},
  error: {},
  loading: false,
  createShopError: null,
}

const Shops = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SHOPS:
    case GET_UNAPPROVED_SHOPS:
    case GET_BANNED_SHOPS:
    case CHANGE_SHOP_STATUS:
    case GET_SHOP_DETAILS:
    case CREATE_SHOP:
    case UPDATE_SHOP:
    case DELETE_SHOP:
      return {
        ...state,
        loading: true,
      }

    case CREATE_SHOP_FAIL:
      return {
        ...state,
        createShopError: action.payload,
        loading: false,
      }
    case CHANGE_SHOP_STATUS_FAIL:
    case GET_UNAPPROVED_SHOPS_FAIL:
    case UPDATE_SHOP_FAIL:
    case DELETE_SHOP_FAIL:
    case GET_BANNED_SHOPS_FAIL:
    case GET_SHOPS_FAIL:
    case GET_SHOP_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_SHOPS_SUCCESS:
      return {
        ...state,
        shops: action.payload,
        error: {},
        loading: false,
      }

    case GET_UNAPPROVED_SHOPS_SUCCESS:
      return {
        ...state,
        unapprovedShops: action.payload,
        error: {},
        loading: false,
      }

    case GET_BANNED_SHOPS_SUCCESS:
      return {
        ...state,
        bannedShops: action.payload,
        error: {},
        loading: false,
      }

    case CHANGE_SHOP_STATUS_SUCCESS:
      return {
        ...state,
        error: {},
        payload: action.payload,
        // shops: [...state.shops].map(shop =>
        //   shop._id.toString() === action.payload.shops._id.toString()
        //     ? { shop, ...action.payload.shops }
        //     : shop
        // ),
        // loading: false,
      }

    case GET_SHOP_DETAILS_SUCCESS:
      return {
        ...state,
        shopDetails: action.payload.data.findShop,
        //TODO: change findShopProduct to shopProduct in backend
        shopVarients: action.payload.data.shopVarients,
        error: {},
        loading: false,
      }

    case CREATE_SHOP_SUCCESS:
      return {
        ...state,
        shops: [...state.shops, action.payload],
        createShopError: null,
        error: {},
        loading: false,
      }

    case UPDATE_SHOP_SUCCESS:
      return {
        ...state,
        shops: [...state.shops].map(shop =>
          shop._id.toString() === action.payload.data._id.toString()
            ? { shop, ...action.payload.data }
            : shop,
        ),
        error: {},
        loading: false,
      }

    case DELETE_SHOP_SUCCESS:
      return {
        ...state,
        shops: state.shops.filter(
          shop => shop._id.toString() !== action.payload.data._id.toString(),
        ),
        error: {},
        loading: false,
      }

    default:
      return state
  }
}

export default Shops
