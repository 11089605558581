import {
  GET_COURSEFORMS,
  GET_COURSEFORMS_SUCCESS,
  GET_COURSEFORMS_FAIL,
  GET_COURSEFORM_DETAILS,
  GET_COURSEFORM_DETAILS_SUCCESS,
  GET_COURSEFORM_DETAILS_FAIL,
  CREATE_COURSEFORM,
  CREATE_COURSEFORM_SUCCESS,
  CREATE_COURSEFORM_FAIL,
  UPDATE_COURSEFORM,
  UPDATE_COURSEFORM_SUCCESS,
  UPDATE_COURSEFORM_FAIL,
  DELETE_COURSEFORM,
  DELETE_COURSEFORM_SUCCESS,
  DELETE_COURSEFORM_FAIL,
} from './actionTypes'

const INIT_STATE = {
  courseforms: {
    courseforms: [],
    total: '',
    page: '',
  },
  courseformDetails: {},
  error: {},
  loading: false,
}

const CourseForm = (state = INIT_STATE, action) => {
  switch (action.type) {
    // CourseForm - This line cannot be edited or removed
    case GET_COURSEFORMS:
    case GET_COURSEFORM_DETAILS:
    case CREATE_COURSEFORM:
    case UPDATE_COURSEFORM:
    case DELETE_COURSEFORM:
      return {
        ...state,
        loading: true,
      }

    case DELETE_COURSEFORM_FAIL:
    case GET_COURSEFORMS_FAIL:
    case GET_COURSEFORM_DETAILS_FAIL:
    case UPDATE_COURSEFORM_FAIL:
    case CREATE_COURSEFORM_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_COURSEFORMS_SUCCESS:
      return {
        ...state,
        courseforms: action.payload,
        error: {},
        loading: false,
      }

    case GET_COURSEFORM_DETAILS_SUCCESS:
      return {
        ...state,
        courseformDetails: action.payload,
        error: {},
        loading: false,
      }

    case CREATE_COURSEFORM_SUCCESS:
      return {
        ...state,
        courseforms: {
          ...state.courseforms,
          courseforms: [...state.courseforms.courseforms, action.payload],
          total: state.courseforms.total + 1,
        },
        courseformDetails: action.payload,
        error: {},
        loading: false,
      }

    case UPDATE_COURSEFORM_SUCCESS:
      return {
        ...state,
        courseforms: {
          ...state.courseforms,
          courseforms: state.courseforms.courseforms.map(courseform =>
            courseform._id === action.payload._id
              ? { ...courseform, ...action.payload }
              : courseform,
          ),
        },
        courseformDetails: action.payload,
        loading: false,
        error: {},
      }

    case DELETE_COURSEFORM_SUCCESS:
      return {
        ...state,
        courseforms: {
          ...state.courseforms,
          courseforms: state.courseforms.courseforms.filter(
            courseform => courseform._id !== action.payload._id,
          ),
        },
        error: {},
        loading: false,
      }
    default:
      return state
  }
}

export default CourseForm
