import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Row, Col, BreadcrumbItem } from 'reactstrap'
import { useHistory } from 'react-router-dom'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'

const BreadcrumbPremium = props => {
  const history = useHistory()
  const role = sessionStorage.getItem('role')

  // promoter-dashboard

  const [breadcrumb, setBreadcrumb] = useState([])
  const [activeItem, setActiveItem] = useState()

  const { root } = props

  const handleConvertString = str => {
    const removeSlash = str?.replace('/', '')
    if (removeSlash?.includes('-')) {
      return removeSlash?.split('-')?.join(' ')
    } else {
      return removeSlash
    }
  }

  const handleLink = link => {
    if (link === '/promoter-dashboard' && role === 'promoter') {
      return link?.split('-')[1]
    }
    return link
  }

  useEffect(() => {
    if (root?.length <= 0) {
      return []
    }

    const modifiedBreadcrumbData = root?.map(item => ({
      link: handleLink(item),
      title: handleConvertString(item),
    }))

    setBreadcrumb(modifiedBreadcrumbData)

    const active = modifiedBreadcrumbData[modifiedBreadcrumbData?.length - 1]
    setActiveItem(active)
  }, [root])

  return (
    <Row>
      <Col xs="12">
        <div className="page-title-box d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <MdKeyboardArrowLeft
              className="mx-2"
              size={'20px'}
              style={{ cursor: 'pointer' }}
              onClick={() => history.goBack()}
            />

            <MdKeyboardArrowRight
              size={'20px'}
              style={{ cursor: 'pointer' }}
              onClick={() => history.goForward()}
            />

            <div className="page-title-right ms-4 premium-ui ">
              <ol className="breadcrumb m-0">
                {breadcrumb?.map((item, index) => (
                  <BreadcrumbItem
                    key={index}
                    className={
                      item?.link === activeItem?.link ? 'bg-success' : ''
                    }
                    style={{
                      zIndex: breadcrumb?.length - index,
                      backgroundColor: '#fff',
                    }}
                  >
                    <Link
                      to={item?.link || '#'}
                      className={
                        item?.link === activeItem?.link ? 'text-white' : ''
                      }
                      style={{ textTransform: 'capitalize' }}
                    >
                      {item?.title}
                    </Link>
                  </BreadcrumbItem>
                ))}
              </ol>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  )
}

BreadcrumbPremium.propTypes = {
  root: PropTypes.array,
}

export default BreadcrumbPremium
