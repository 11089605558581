/* Notifications - This line cannot be edited or removed */
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS'
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS'
export const GET_NOTIFICATIONS_FAIL = 'GET_NOTIFICATIONS_FAIL'

/* Notification_DETAILS */
export const GET_NOTIFICATION_DETAILS = 'GET_NOTIFICATION_DETAILS'
export const GET_NOTIFICATION_DETAILS_SUCCESS =
  'GET_NOTIFICATION_DETAILS_SUCCESS'
export const GET_NOTIFICATION_DETAILS_FAIL = 'GET_NOTIFICATION_DETAILS_FAIL'

/* CREATE Notification */
export const CREATE_NOTIFICATION = 'CREATE_NOTIFICATION'
export const CREATE_NOTIFICATION_SUCCESS = 'CREATE_NOTIFICATION_SUCCESS'
export const CREATE_NOTIFICATION_FAIL = 'CREATE_NOTIFICATION_FAIL'

/* Edit Notification */
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION'
export const UPDATE_NOTIFICATION_SUCCESS = 'UPDATE_NOTIFICATION_SUCCESS'
export const UPDATE_NOTIFICATION_FAIL = 'UPDATE_NOTIFICATION_FAIL'

/* Delete Notification */
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION'
export const DELETE_NOTIFICATION_SUCCESS = 'DELETE_NOTIFICATION_SUCCESS'
export const DELETE_NOTIFICATION_FAIL = 'DELETE_NOTIFICATION_FAIL'
