import { call, put, takeEvery } from 'redux-saga/effects'

import {
  GET_DEDUCTIONS,
  GET_DEDUCTION_DETAILS,
  CREATE_DEDUCTION,
  UPDATE_DEDUCTION,
  DELETE_DEDUCTION,
  DOWNLOAD_DEDUCTIONS,
} from './actionTypes'
import {
  getDeductionsFail,
  getDeductionsSuccess,
  getDeductionDetailsSuccess,
  getDeductionDetailsFail,
  createDeductionFail,
  createDeductionSuccess,
  updateDeductionSuccess,
  updateDeductionFail,
  deleteDeductionSuccess,
  deleteDeductionFail,
  downloadDeductionsSuccess,
  downloadDeductionsFail,
} from './actions'

import { get, post, ApiPut, del } from 'helpers/api_helper'
import { Notification } from '../../components/Common/Notification'

// Deduction - This line cannot be edited or removed
function getDeductionsAPi({
  page,
  sort,
  searchText,
  sortByDate,
  promoter,
  withdrawalRequest,
}) {
  return get(
    `/deduction/admin/all/?deductionType=${sort}&page=${
      page ? page : 1
    }&search=${searchText}&dateFrom=${sortByDate?.from}&dateTo=${
      sortByDate?.to
    }&promoter=${promoter ? promoter : ''}&withdrawalRequest=${
      withdrawalRequest ? withdrawalRequest : ''
    }`,
  )
}

const getDeductionDetailsAPi = deductionId => {
  return get(`/deduction/admin/${deductionId}`)
}

const createDeductionApi = ({ deduction }) => {
  return post('/deduction/admin/new', deduction)
}

const updateDeductionApi = ({ deduction, deductionId }) => {
  return ApiPut(`/deduction/admin/${deductionId}`, deduction)
}

const deleteDeductionApi = deductionId => {
  return del(`/deduction/admin/${deductionId}`)
}

function downloadDeductionsAPi({
  sort,
  searchText,
  sortByDate,
  promoter,
  withdrawalRequest,
}) {
  return get(
    `/deduction/admin/download/all?deductionType=${sort}&search=${searchText}&dateFrom=${
      sortByDate?.from
    }&dateTo=${sortByDate?.to}&promoter=${
      promoter ? promoter : ''
    }&withdrawalRequest=${withdrawalRequest ? withdrawalRequest : ''}`,
  )
}

function* fetchDeductions({ payload }) {
  try {
    const response = yield call(getDeductionsAPi, payload)
    yield put(getDeductionsSuccess(response))
  } catch (error) {
    yield put(getDeductionsFail(error))
  }
}

function* fetchDeductionDetails({ payload: deductionId }) {
  try {
    const response = yield call(getDeductionDetailsAPi, deductionId)
    yield put(getDeductionDetailsSuccess(response))
  } catch (error) {
    yield put(getDeductionDetailsFail(error))
  }
}

function* onCreateDeduction({ payload }) {
  try {
    const response = yield call(createDeductionApi, payload)
    if (response?.message) {
      Notification({
        type: 'error',
        message: response?.message,
        title: '',
      })
    } else if (response) {
      yield put(createDeductionSuccess(response))
      doneNotification('Deduction Created Successfully!')
      if (payload?.onCloseClick) {
        payload?.onCloseClick()
      }
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: 'error',
        message: error?.response?.data?.message,
        title: '',
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: 'error',
        message: 'file size is Too Large',
        title: '',
      })
    }
    yield put(createDeductionFail(error))
  }
}

function* onUpdateDeduction({ payload }) {
  try {
    const response = yield call(updateDeductionApi, payload)
    if (response) {
      Notification({
        type: 'success',
        message: 'Deduction Updated Successfully!',
        title: '',
      })
      yield put(updateDeductionSuccess(response))
      if (payload?.onCloseClick) {
        payload?.onCloseClick()
      }
    }
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: 'error',
        message: error.response?.data?.message,
        title: '',
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: 'error',
        message: 'file size is Too Large',
        title: '',
      })
    }
    yield put(updateDeductionFail(error.response.data))
  }
}

function* onDeleteDeduction({ DeductionId }) {
  try {
    const response = yield call(deleteDeductionApi, DeductionId)
    yield put(deleteDeductionSuccess(response))
    Notification({
      type: 'success',
      message: 'Deduction Deleted Successfully!',
      title: '',
    })
    history.goBack()
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: 'error',
        message: error?.response?.data?.message,
        title: '',
      })
    }
    yield put(deleteDeductionFail(error?.response?.data))
  }
}

function* fetchDownloadDeductions({ payload }) {
  try {
    const response = yield call(downloadDeductionsAPi, payload)
    yield put(downloadDeductionsSuccess(response))
  } catch (error) {
    yield put(downloadDeductionsFail(error))
  }
}

function* deductionSaga() {
  yield takeEvery(GET_DEDUCTIONS, fetchDeductions)
  yield takeEvery(GET_DEDUCTION_DETAILS, fetchDeductionDetails)
  yield takeEvery(CREATE_DEDUCTION, onCreateDeduction)
  yield takeEvery(UPDATE_DEDUCTION, onUpdateDeduction)
  yield takeEvery(DELETE_DEDUCTION, onDeleteDeduction)
  yield takeEvery(DOWNLOAD_DEDUCTIONS, fetchDownloadDeductions)
}

export default deductionSaga

function doneNotification(message) {
  Notification({
    type: 'success',
    message: message,
    title: '',
  })
}
