import { combineReducers } from 'redux'

// Front
import Layout from './layout/reducer'

// Authentication
import Login from './auth/login/reducer'
import Account from './auth/register/reducer'
import ForgetPassword from './auth/forgetpwd/reducer'
import Profile from './auth/profile/reducer'

//Product
import Products from './products/reducer'

//shopProduct
import ShopProducts from './shop-products/reducer'

// //store
import Stores from './stores/reducer'

// //shop
import Shops from './shops/reducer'

//Category
import Category from './category/reducer'

//Classifications
import Classifications from './classifications/reducer'

//user
import Users from './users/reducer'

//order
import Orders from './orders/reducer'

// Settings
import Settings from './settings/reducer'

// Revenues
import Revenues from './revenues/reducer'

//Banner
import Banner from './banner/reducer'

//Dashboard
import Dashboard from './dashboard/reducer'

//collections
import Collection from './collections/reducer'

//groups
import Group from './groups/reducer'

//coupon
import Coupon from './coupon/reducer'

//area
import Area from './area_list/reducer'

//pages
import Pages from './product-pages/reducer'

//performance
import Performance from './performance/reducer'
// promoter
import Promoter from './promoter/reducer'

// blog
import Blogs from './blogs/reducer'

// suplychain
import SupplyChain from './supply-chain/reducer'

// NOtification
import Notification from './notification/reducer'
// deduction
import Deduction from './deduction/reducer'
//CourseProvider
import CourseProvider from './courseprovider/reducer'
//CourseRegistration
import CourseRegistration from './courseregistration/reducer'
//CourseForm
import CourseForm from './courseform/reducer'
// withdrawalList
import WithdrawalList from './withdrawallist/reducer'
// IdealsplyWalletWithdrawalList
import IdealsplyWalletWithdrawalList from './idealsplywalletwithdrawallist/reducer'
const rootReducer = combineReducers({
  CourseForm,
  CourseRegistration,
  CourseProvider,
  Deduction,
  Notification,
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  Products,
  ShopProducts,
  Shops,
  Stores,
  Settings,
  Revenues,

  Category,
  Banner,
  Classifications,
  Collection,
  Group,
  Pages,
  Coupon,
  Area,

  Users,
  Orders,
  Dashboard,
  Performance,
  Promoter,
  Blogs,
  SupplyChain,
  WithdrawalList,
  IdealsplyWalletWithdrawalList,
})

export default rootReducer
