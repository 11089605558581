import React, { useEffect, useState } from 'react'
import { Badge, Card, Col, Row } from 'reactstrap'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { deletePromoter, getChainPromoterDetails } from 'store/actions'
import moment from 'moment'
import DeleteModal from 'components/Common/DeleteModal'

import slack from '../../../assets/images/brands/slack.png'
import PromoterTransactions from 'pages/PromoterTransaction/PromoterTransactions'
import { CurrencySwitch } from 'hooks/Currency'

function Profile() {
  const params = useParams()
  const dispatch = useDispatch()
  const history = useHistory()

  const { promoterDetails, supplyChainTransactions } = useSelector(state => ({
    promoterDetails: state?.SupplyChain?.chainPromotersDetails,
    supplyChainTransactions: state.SupplyChain.supplyChainTransactions,
  }))

  const currency = CurrencySwitch()

  useEffect(() => {
    dispatch(getChainPromoterDetails(params?.id))
  }, [params?.id])

  const [isOpen, setIsOpen] = useState({ stauts: false, id: '' })

  const handleDeleteEvent = () => {
    setIsOpen({ ...isOpen, stauts: false })
    dispatch(deletePromoter(isOpen?.id, history))
  }

  return (
    <div>
      <DeleteModal
        show={isOpen?.stauts}
        onCloseClick={() => setIsOpen({ ...isOpen, stauts: false })}
        onDeleteClick={handleDeleteEvent}
      />
      <div>
        <Row className="long-row">
          <Card className="shadow p-3 mb-4  bg-white rounded ">
            <Row className="mt-3 mb-3">
              <Col className="chain-col-1">
                <div className="chian-main-div">
                  <div className="bg-success chain-img">
                    <img src={slack} alt />
                  </div>

                  <div className="chain-div-desc">
                    {' '}
                    <h6 className="chain-head">Chain Promoter</h6>
                    <h6 className="chain-desc">{promoterDetails?.ref_id}</h6>
                  </div>
                </div>
              </Col>
              <Col className="chain-col-2">
                <div className="chain-check-in">
                  <div className="center-vertically">
                    <h6 className="check-h6">Available Space </h6>
                    <h6 className="date-h6">
                      <Badge className="badge-soft-success p-1 px-2">
                        {promoterDetails?.availableSpace}
                      </Badge>
                    </h6>
                  </div>
                </div>
              </Col>
              <Col className="chain-col-2">
                <div className="chain-check-in">
                  <div className="center-vertically">
                    <h6 className="check-h6">Chain Position </h6>
                    <h6 className="date-h6">
                      {promoterDetails?.isDefault ? (
                        <Badge className="badge-soft-success p-1 px-2">
                          From Root
                        </Badge>
                      ) : (
                        <Badge className="badge-soft-success p-1 px-2">
                          {promoterDetails?.chainPosition || 0}
                        </Badge>
                      )}
                    </h6>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="chain-check-in">
                  <div className="center-vertically">
                    <h6 className="check-h6">
                      Created in:{' '}
                      {moment(promoterDetails?.createdAt).format('ll')}
                    </h6>
                    <h6 className="check-h6">
                      Updated in:{' '}
                      {moment(promoterDetails?.updatedAt).format('ll')}
                    </h6>
                  </div>
                </div>
              </Col>
            </Row>
          </Card>
        </Row>
        <Row>
          <Col lg={8}>
            <Card className="shadow p-3 mb-4 bg-white rounded card-supply-chain">
              <div>
                <h6 className="chain-details">Supply Chain Details</h6>
                <Row>
                  <Col className="" sm={6} lg={6}>
                    <Row className="mb-3">
                      <Col
                        className="d-flex align-items-center justify-content-left"
                        md={4}
                      >
                        <h6>Name </h6>
                      </Col>
                      :
                      <Col md={4}>
                        <h6>
                          {' '}
                          {
                            promoterDetails?.supplyChain?.product?.primaryLang
                              ?.name
                          }
                        </h6>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col
                        className="d-flex align-items-center justify-content-left"
                        md={4}
                      >
                        <h6>Chain Id </h6>
                      </Col>
                      :
                      <Col md={4}>
                        <h6> {promoterDetails?.supplyChain?.chainId}</h6>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col
                        className="d-flex align-items-center justify-content-left"
                        md={4}
                      >
                        <h6>createdAt </h6>
                      </Col>
                      :
                      <Col md={4}>
                        <h6>
                          {moment(
                            promoterDetails?.supplyChain?.createdAt,
                          ).format('ll')}
                        </h6>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col
                        className="d-flex align-items-center justify-content-left"
                        md={4}
                      >
                        <h6>Distribution Bonus</h6>
                      </Col>
                      :
                      <Col md={4}>
                        <h6>
                          {' '}
                          {promoterDetails?.supplyChain?.distributionBonus}
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                  <Col className="" sm={6} lg={6}>
                    <Row className="mb-3">
                      <Col
                        className="d-flex align-items-center justify-content-left"
                        md={4}
                      >
                        <h6>Joining Bonus</h6>
                      </Col>
                      :
                      <Col md={4}>
                        <h6> {promoterDetails?.supplyChain?.joiningBonus}</h6>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col
                        className="d-flex align-items-center justify-content-left"
                        md={4}
                      >
                        <h6>Level Limit</h6>
                      </Col>
                      :
                      <Col md={4}>
                        <h6> {promoterDetails?.supplyChain?.levelLimit}</h6>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col
                        className="d-flex align-items-center justify-content-left"
                        md={4}
                      >
                        <h6>Total Distribution Amount</h6>
                      </Col>
                      :
                      <Col md={4}>
                        <h6>
                          {' '}
                          {
                            promoterDetails?.supplyChain
                              ?.totalDistributionAmount
                          }
                        </h6>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col
                        className="d-flex align-items-center justify-content-left"
                        md={4}
                      >
                        <h6> Updated At </h6>
                      </Col>
                      :
                      <Col md={4}>
                        <h6>
                          {moment(
                            promoterDetails?.supplyChain?.updatedAt,
                          ).format('ll')}
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
          <Col lg={4}>
            <div>
              <Card className="shadow p-3 mb-1 bg-white rounded ">
                <h6 className="chain-promoter-details">Promoter Details</h6>
              </Card>
              <Card className="shadow p-3 mb-5 bg-white rounded ">
                <ul className="custom-list">
                  <li className="li-content">
                    Name: {promoterDetails?.promoter?.name}
                  </li>
                  <li className="li-content">
                    Phone: {promoterDetails?.promoter?.phone}
                  </li>
                  <li className="li-content">
                    Email: {promoterDetails?.promoter?.email}
                  </li>
                  <li className="li-content">
                    Address: {promoterDetails?.promoter?.address}
                  </li>
                </ul>
              </Card>
            </div>
          </Col>
        </Row>

        <div className="d-flex align-items-center justify-content-between mb-2">
          <h6 className="chain-transaction">Chain Promoter Transaction</h6>

          <div>
            <Badge className="badge-soft-warning p-1 px-2 font-size-13">
              Wallet Balance: {currency}{' '}
              {supplyChainTransactions?.walletBalance}
            </Badge>
          </div>
        </div>
        <PromoterTransactions promoterId={promoterDetails?.promoter?._id} />
      </div>
    </div>
  )
}

export default Profile
