import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Card, CardBody, Col, Row, UncontrolledTooltip } from 'reactstrap'
import moment from 'moment'
import { BiSolidEditAlt } from 'react-icons/bi'
import LuckyDrawModal from '../Modal'
const CardUser = ({ luckyDrawDetails }) => {
  const supplyChain = luckyDrawDetails?.supplyChain

  const reports = [
    {
      title: 'Limit',
      value: luckyDrawDetails?.limit,
    },
    {
      title: 'Count',
      value: luckyDrawDetails?.count,
    },
    {
      title: 'Drew',
      value: luckyDrawDetails?.isDrew ? 'yes' : 'no',
    },
    {
      title: 'Skip',
      value: luckyDrawDetails?.skip,
    },
    {
      title: 'Drew Date',
      value: luckyDrawDetails?.drewDate
        ? moment(luckyDrawDetails?.drewDate).format('ll')
        : 'no date',
    },
    {
      title: 'Range Filled',
      value: luckyDrawDetails?.isRangeFilled ? 'yes' : 'no',
    },
  ]

  const [luckyDrawModal, setLuckyDrawModal] = useState({
    status: false,
    data: {},
  })

  return (
    <React.Fragment>
      {luckyDrawModal?.status && (
        <LuckyDrawModal
          onCloseClick={() => setLuckyDrawModal({ status: false })}
          show={luckyDrawModal?.status}
          updateData={luckyDrawDetails}
        />
      )}
      {reports?.map((item, key) => (
        <Col lg={2} key={key}>
          <Card className="mini-stats-wid lucky-draw-card">
            <CardBody>
              <div className="d-flex flex-wrap">
                <div className="me-3">
                  <p className="text-muted mb-2">{item?.title}</p>
                  <h6 className="mb-0 text-dark">{item?.value}</h6>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      ))}

      <Col xl={8}>
        <Card>
          <CardBody>
            <p className="mb-3 mt-1">Supply Chain Details:</p>
            <Row className="">
              <Col className="mt-2">
                <h6 className="mb-2 text-muted">
                  Chain Id :
                  <span className="supply-chain-details text-black">
                    {supplyChain?.chainId}
                  </span>
                </h6>
                <h6 className="mb-2 text-muted">
                  createdAt :
                  <span className="supply-chain-details text-black">
                    {moment(supplyChain?.updatedAt).format(
                      'DD/MM/YYYY - h:mm a',
                    )}
                  </span>
                </h6>
              </Col>
              <Col>
                <div className="d-flex">
                  <h6 className="product-img-text text-muted">Product img:</h6>
                  <img
                    src={supplyChain?.product?.images[0]?.url}
                    alt=""
                    className="avatar-sm rounded-circle img-thumbnail product-img"
                  />
                </div>
                <h6 className="mb-2 mt-1">
                  {' '}
                  <span className="text-muted">Product name:</span>
                  <span className="supply-chain-details">
                    {supplyChain?.product?.primaryLang?.name}
                  </span>
                </h6>
              </Col>
            </Row>
            <BiSolidEditAlt
              style={{
                position: 'absolute',
                top: 10,
                right: 10,
              }}
              onClick={() => setLuckyDrawModal({ status: true })}
              className="hover-zoom cursor-pointer text-info font-size-16"
              id="editLuckyDrawDetailsId"
            />
            <UncontrolledTooltip target={'editLuckyDrawDetailsId'}>
              Edit Lucky Draw Details
            </UncontrolledTooltip>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default CardUser
CardUser.propTypes = {
  luckyDrawDetails: PropTypes.any,
  options: PropTypes.any,
  series: PropTypes.any,
}
