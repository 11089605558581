import { call, put, takeEvery } from 'redux-saga/effects'

// Crypto Redux States
import { GET_ALL_CLASSIFICATIONS } from './actionTypes'
import {
  getAllClassificationsSuccess,
  getAllClassificationsFail,
} from './actions'

import { get } from '../../helpers/api_helper'

function getAllClassificationsAPi({ page, sort, limit, searchText }) {
  if (searchText) {
    return get(
      `/category/admin/custom/${sort}?page=${
        page ? page : 1
      }&limit=${limit}&search=${searchText}`,
    )
  } else {
    return get(
      `/category/admin/custom/${sort}?page=${page ? page : 1}&limit=${limit}`,
    )
  }
}

function* fetchAllClassifications({ payload }) {
  try {
    const response = yield call(getAllClassificationsAPi, payload)
    yield put(getAllClassificationsSuccess(response))
  } catch (error) {
    yield put(getAllClassificationsFail(error))
  }
}

function* classificationsSaga() {
  yield takeEvery(GET_ALL_CLASSIFICATIONS, fetchAllClassifications)
}

export default classificationsSaga
