import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useHistory, useLocation, withRouter } from 'react-router-dom'
import { CardBody, Spinner } from 'reactstrap'

//actions
import { logoutUser } from '../../store/actions'

//redux
import { useDispatch } from 'react-redux'

import { Notification } from 'components/Common/Notification'

const Logout = props => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    if (location?.state?.isLocal === true) {
      sessionStorage.clear()
      history.push('/login')
      Notification({
        type: 'success',
        message: 'Logged out successfully',
        title: '',
      })
      // window.location.reload()
    } else {
      dispatch(logoutUser(props.history))
    }
  }, [dispatch])

  return (
    <React.Fragment>
      <div className="container">
        <CardBody className="d-flex" style={{ height: '100vh' }}>
          <Spinner className="d-block m-auto" />
        </CardBody>
      </div>
    </React.Fragment>
  )
}

Logout.propTypes = {
  history: PropTypes.object,
}

export default withRouter(Logout)
