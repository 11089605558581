import React, { Fragment, useEffect, useState } from 'react'
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Spinner,
  Badge,
  UncontrolledTooltip,
} from 'reactstrap'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// data-table related plugins
import BootstrapTable from 'react-bootstrap-table-next'
import { debounce, map, range } from 'lodash'

import ToolkitProvider from 'react-bootstrap-table2-toolkit'

//actions
import {
  downloadDeductions,
  getAllWithdrawalRequests,
  getDeductions,
  getPromoters,
} from 'store/actions'

import '../../assets/scss/data-tables.scss'

import MyPagination from 'components/Common/MyPagination'
// import DeleteModal from 'components/Common/DeleteModal'
import DeductionsModal from './Crud/Modal'
import { Markup } from 'interweave'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import moment from 'moment'
import Select from 'react-select'
import { CSVLink } from 'react-csv'
import { BsFillCloudDownloadFill } from 'react-icons/bs'
// import DownloadDeduction from './DownloadDeductionList'

const DeductionsList = () => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [searchText, setSearchText] = useState('')
  const [deductionModal, setDeductionModal] = useState({
    status: false,
    data: {},
  })

  const {
    loading,
    deductions,
    allPromoters,
    promoterLoad,
    withdrawalRequests,
    downloadDeductionList,
  } = useSelector(state => ({
    loading: state.Deduction.loading,
    deductions: state.Deduction.deductions,
    allPromoters: state?.Users?.promotersList,
    promoterLoad: state.Users?.promoterLoad,
    withdrawalRequests: state.SupplyChain.withDrawalRequests,
    downloadDeductionList: state.Deduction.downloadDeductionList,
  }))

  // const [status, setStatus] = useState({})

  const headers = [
    { label: 'Id', key: '_id' },
    { label: 'Name', key: 'name' },
    { label: 'Phone', key: 'phone' },
    { label: 'Pan Number', key: 'panNumber' },
    { label: 'Deduction Amount', key: 'deductionAmount' },
    { label: 'Deduction Type', key: 'deductionType' },
    { label: 'Deduction Percentage (%)', key: 'deductionPercentage' },
    { label: 'Promoter Name', key: 'promoter' },
    { label: 'Withdrawal Amount', key: 'withrawalAmount' },
    { label: 'Withdrawal Request ID', key: 'requestId' },
    { label: 'Date', key: 'date' },
  ]

  const requestForDownload = downloadDeductionList?.deductions

  const excelDownloadDeductions = map(requestForDownload, item => ({
    ...item,
    _id: item?._id,
    status: item?.status,
    name: item?.name,
    phone: item?.phone,
    panNumber: item?.panNumber,
    deductionAmount: item?.deductionAmount,
    deductionType: item?.deductionType,
    deductionPercentage: item?.deductionPercentage,
    promoter: item?.promoter?.name,
    withrawalAmount: item?.withrawalAmount,
    requestId: item?.withdrawalRequest?.requestId,
    date: moment(item?.amount).format('lll'),
  }))

  const [promoterSelect, setPromoterSelect] = useState({
    title: 'Select Promoter',
    id: null,
  })
  const [promoterSearch, setPromoterSearch] = useState('')

  const debouncePromoterSearch = debounce(
    value => setPromoterSearch(value),
    600,
  )

  const handlePromoterEnters = textEntered => {
    debouncePromoterSearch(textEntered)
  }

  function handlerPromoterFinalValue(event) {
    const promoter = {
      ...promoterSelect,
      title: event.label,
      id: event.value,
    }
    setPromoterSelect(promoter)
  }

  const promoterOptions = [
    {
      label: 'All Promoters',
      id: '',
    },
    {
      options: (allPromoters?.total >= 1 ? allPromoters?.promoters : [])?.map(
        (result, index) => ({
          key: index,
          label: result?.name || result?.phone,
          value: result?._id,
        }),
      ),
    },
  ]

  useEffect(() => {
    dispatch(getPromoters('', promoterSearch))
  }, [dispatch, promoterSearch])

  const [withdrawalRequestSelect, setWithdrawalRequestsSelect] = useState({
    title: 'Select Withdrawal Request',
    id: null,
  })
  const [withdrawalRequestSearch, setWithdrawalRequestSearch] = useState()

  const debounceWithdrawalRequestSearch = debounce(
    value => setWithdrawalRequestSearch(value),
    600,
  )
  // console.log(withdrawalRequestSelect)
  const handleWithdrawalRequestEnters = textEntered => {
    debounceWithdrawalRequestSearch(textEntered)
  }

  function handlerWithdrawalRequestFinalValue(event) {
    const withdrawalRequest = {
      ...withdrawalRequestSelect,
      title: event.label,
      id: event.value,
    }
    setWithdrawalRequestsSelect(withdrawalRequest)
  }

  const withdrawalRequestOptions = [
    {
      label: 'All Withdrawal ReqId',
      id: '',
    },
    {
      options: (withdrawalRequests?.total >= 1
        ? withdrawalRequests?.withdrawalRequests
        : []
      )?.map((result, index) => ({
        key: index,
        label: result?.requestId || result?.phone,
        value: result?._id,
      })),
    },
  ]

  useEffect(() => {
    dispatch(getAllWithdrawalRequests(1, 10, withdrawalRequestSearch))
  }, [dispatch, withdrawalRequestSearch])

  const [finalDateFormatted, setFinalDateFormatted] = useState({
    from: '',
    to: '',
  })

  const handleDateSearch = () => {
    if (
      finalDateFormatted?.from?.length >= 1 &&
      finalDateFormatted?.to?.length >= 1
    ) {
      return finalDateFormatted
    } else {
      return {
        from: '',
        to: '',
      }
    }
  }

  const totalPages = Math.ceil(deductions?.total / 10)
  const pages = range(1, totalPages + 1)

  const [sort, setSort] = useState('')

  useEffect(() => {
    dispatch(
      getDeductions(
        page,
        sort,
        10,
        searchText,
        handleDateSearch(),
        promoterSelect?.id,
        withdrawalRequestSelect?.id,
      ),
    )
  }, [
    dispatch,
    page,
    searchText,
    sort,
    finalDateFormatted,
    promoterSelect,
    withdrawalRequestSelect,
  ])

  useEffect(() => {
    dispatch(
      downloadDeductions(
        sort,
        searchText,
        handleDateSearch(),
        promoterSelect?.id,
        withdrawalRequestSelect?.id,
      ),
    )
  }, [
    dispatch,
    sort,
    searchText,
    finalDateFormatted,
    promoterSelect,
    withdrawalRequestSelect,
  ])

  // const [isOpen, setIsOpen] = useState({ status: false, id: '' })

  // const handleDelete = id => {
  //   setIsOpen({ status: true, id: id })
  // }

  // const handleDeleteEvent = () => {
  //   setIsOpen({ ...isOpen, status: false })
  //   dispatch(deleteDeduction(isOpen?.id))
  // }

  const handleDeductionType = type => {
    switch (type) {
      case 'tds':
        return { name: 'TDS', color: 'info' }
      case 'service':
        return { name: 'Service', color: 'success' }
      default:
        return ''
    }
  }

  const columns = [
    {
      dataField: 'no',
      text: 'No.',
      sort: true,
    },
    {
      dataField: 'createdAt',
      text: 'Date',
      sort: true,
    },
    {
      dataField: 'name',
      text: 'Name',
      sort: true,
    },
    {
      dataField: 'phone',
      text: 'Phone',
      sort: true,
    },
    {
      dataField: 'promoter.name',
      text: 'Promoter',
      sort: true,
    },

    {
      dataField: 'withrawalAmount',
      text: 'Withdrawal Amount',
      sort: true,
    },
    {
      dataField: 'deductionAmount',
      text: 'Deduction Amount',
      sort: true,
    },
    {
      dataField: 'deductionType',
      text: 'Type',
      sort: true,
    },

    // {
    //   dataField: "activeData",
    //   text: "Status",
    // },
    // {
    //   dataField: "isPublished",
    //   text: "Published",
    // },
    // {
    //   dataField: "isHighlighted",
    //   text: "Type",
    // },

    // {
    //   dataField: 'action',
    //   text: 'Action',
    // },
  ]

  const tableData = map(deductions?.deductions, (item, index) => ({
    ...item,
    no: index + 1,
    deductionAmount: (
      <p className="m-0">
        {item?.deductionAmount} ({item?.deductionPercentage}%)
      </p>
    ),
    deductionType: (
      <Badge
        className={
          'px-2 py-2 badge-soft-' +
          `${handleDeductionType(item?.deductionType).color}`
        }
      >
        {' '}
        {handleDeductionType(item?.deductionType).name}{' '}
      </Badge>
    ),
    createdAt: moment(item?.createdAt).format('lll'),
    // imageData: (
    //   <>
    //     <img
    //       src={
    //         item?.images && item?.images[0] ? item?.images[0]?.url : defaultImg
    //       }
    //       width="50px"
    //     />
    //   </>
    // ),
    title: (
      <div className="d-flex algin-items-center text-start   justify-content-start ">
        <p className="mb-0 mt-1">{item?.title}</p>
        {sort === 'all' && (
          <>
            {item?.isPublished && (
              <Badge
                className={'font-size-11 mx-2 p-2 badge-soft-success '}
                style={{
                  width: 'fit-content',
                }}
                pill
              >
                Published
              </Badge>
            )}
            {item?.isHighlighted && (
              <Badge
                className={'font-size-11 me-2 p-2 badge-soft-info  '}
                style={{
                  width: 'fit-content',
                }}
                pill
              >
                Highlighted
              </Badge>
            )}
          </>
        )}
      </div>
    ),
    content: (
      <div
        className="text-break-spaces position-relative"
        style={{ maxWidth: 350 }}
      >
        <Markup content={item?.content?.slice(0, 140)} className="markup" />
        <span
          style={{
            position: 'absolute',
            bottom: 0,
            right: 100,
          }}
        >
          {' '}
          {item?.content?.length > 140 ? '...' : ''}
        </span>
      </div>
    ),

    action: (
      <div>
        <Link
          to={`#`}
          className="btn-md"
          onClick={() => setDeductionModal({ status: true, data: item })}
        >
          <i className="bx bx-edit me-2 " style={{ fontSize: '1.3rem' }} />
        </Link>
        {/* <Button
          id={`Delete${index}`}
          type="button"
          color="white"
          className="ms-1 btn "
          onClick={() => {
            handleDelete(item._id)
          }}
        >
          <i
            className="bx bx-trash me-2"
            style={{ color: 'red', marginBottom: '10px', fontSize: '1.3rem' }}
          />
        </Button> */}
      </div>
    ),
  }))

  const debounceBlogSearch = debounce(value => setSearchText(value), 600)

  const listChange = sortValue => {
    setSort(sortValue)
  }

  const headerTap = [
    {
      value: '',
      title: 'All',
    },

    {
      value: 'service',
      title: 'SERVICE',
    },
    {
      value: 'tds',
      title: 'TDS ',
    },
  ]
  // useEffect(() => {
  //   const sortValues = {
  //     deductionType: sort,
  //     searchText: searchText,
  //     sortByDate: handleDateSearch(),
  //     promoter: promoterSelect?.id,
  //     withdrawalRequest: withdrawalRequestSelect?.id,
  //   }
  //   setStatus(sortValues)
  // }, [
  //   dispatch,
  //   sort,
  //   searchText,
  //   finalDateFormatted,
  //   promoterSelect,
  //   withdrawalRequestSelect,
  // ])

  return (
    <React.Fragment>
      <Row>
        {/* <DeleteModal
          show={isOpen?.status}
          onCloseClick={() => setIsOpen({ data: {}, status: false })}
          onDeleteClick={handleDeleteEvent}
        /> */}
        <DeductionsModal
          onCloseClick={() => setDeductionModal({ status: false, data: {} })}
          show={deductionModal?.status}
          updateData={deductionModal?.data}
          setWithdrawalRequestSearch={setWithdrawalRequestSearch}
          setPromoterSearch={setPromoterSearch}
        />
        <Col className="col-12">
          <Card>
            <CardBody>
              <ToolkitProvider
                keyField="_id"
                key={'id'}
                columns={columns}
                data={tableData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col lg={4} className="mb-3">
                        <div
                          className="btn-group mt-xl-0"
                          role="group"
                          aria-label="Basic radio toggle button group"
                        >
                          {headerTap?.map((tap, key) => (
                            <Fragment key={key}>
                              <input
                                type="radio"
                                className="btn-check"
                                name="btnRadio"
                                id={`btnRadio${tap?.value}`}
                                autoComplete="off"
                                checked={tap.value === sort ? true : false}
                                onChange={() => listChange(tap?.value)}
                              />
                              <label
                                className="btn btn-success"
                                htmlFor={`btnRadio${tap?.value}`}
                              >
                                {tap?.title}
                              </label>
                            </Fragment>
                          ))}
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="d-flex sortWrapper mx-2 me-3 mb-3 align-items-center ">
                          <p
                            className="mb-0 pb-0 mx-1"
                            style={{ whiteSpace: 'nowrap' }}
                          >
                            Sort By Date :
                          </p>
                          <AvForm>
                            <div className="d-flex date_wrapper">
                              <div className="d-flex align-items-start position-relative">
                                <AvField
                                  name="dateRange"
                                  type="date"
                                  validate={{
                                    dateRange: {
                                      start: { value: -5, units: 'years' },
                                      end: { value: 5, units: 'years' },
                                    },
                                  }}
                                  onChange={e =>
                                    setFinalDateFormatted({
                                      ...finalDateFormatted,
                                      from: e.target.value,
                                    })
                                  }
                                  value={finalDateFormatted?.from}
                                />
                                <button
                                  onClick={() =>
                                    setFinalDateFormatted({
                                      ...finalDateFormatted,
                                      from: '',
                                    })
                                  }
                                  type="button"
                                  className="btn btn-white editable-cancel date_close btn-sm"
                                >
                                  <i className="mdi mdi-close text-danger "></i>
                                </button>
                              </div>
                              <div className="d-flex align-items-start position-relative">
                                <AvField
                                  name="dateRange"
                                  className="mx-1"
                                  type="date"
                                  validate={{
                                    dateRange: {
                                      start: { value: -5, units: 'years' },
                                      end: { value: 5, units: 'years' },
                                    },
                                  }}
                                  onChange={e =>
                                    setFinalDateFormatted({
                                      ...finalDateFormatted,
                                      to: e.target.value,
                                    })
                                  }
                                  value={finalDateFormatted?.to}
                                />
                                <button
                                  onClick={() =>
                                    setFinalDateFormatted({
                                      ...finalDateFormatted,
                                      to: '',
                                    })
                                  }
                                  type="button"
                                  className="btn btn-white editable-cancel date_close btn-sm"
                                >
                                  <i className="mdi mdi-close text-danger "></i>
                                </button>
                              </div>
                            </div>
                          </AvForm>
                        </div>
                      </Col>

                      <Col lg={4}>
                        {/* <div className="text-sm-end">
                          <Button
                            type="button"
                            color="success"
                            className="btn-rounded  mb-2 me-2"
                            onClick={() => setDeductionModal({ status: true })}
                          >
                            <i className="mdi mdi-plus me-1" />
                            Create Deductions
                          </Button>
                        </div> */}
                        <>
                          <div className="d-flex justify-content-end">
                            <Button
                              data-tip
                              id="downloadDeduction"
                              data-for="downloadTip"
                              style={{ border: 'none' }}
                              color=""
                              className="w-sm btn-md btn-info  me-0 mt-0 d-flex align-items-center justify-content-center"
                            >
                              {requestForDownload &&
                                requestForDownload?.length > 0 && (
                                  <CSVLink
                                    data={excelDownloadDeductions}
                                    headers={headers}
                                    filename={'deductions.csv'}
                                    className="d-flex text-white align-items-center "
                                  >
                                    <BsFillCloudDownloadFill
                                      size={'14px'}
                                      className="me-2"
                                    />
                                    {`${requestForDownload?.length}`}
                                  </CSVLink>
                                )}
                              {loading && <Spinner />}
                            </Button>
                          </div>
                          <UncontrolledTooltip
                            id="downloadTip"
                            target={'downloadDeduction'}
                          >
                            Download Deductions
                          </UncontrolledTooltip>
                        </>
                      </Col>
                      <Col lg={3}>
                        <div className="search-box w-100 ms-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <AvForm>
                              <AvField
                                name="searchText"
                                placeholder="Search"
                                type="text"
                                onChange={e =>
                                  debounceBlogSearch(e.target.value)
                                }
                              />
                            </AvForm>
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>
                      </Col>
                      <Col md="3">
                        <Select
                          onInputChange={handlePromoterEnters}
                          value={promoterSelect?.title}
                          placeholder={promoterSelect?.title}
                          onChange={handlerPromoterFinalValue}
                          options={promoterOptions}
                          classNamePrefix="select2-selection"
                          isLoading={promoterLoad}
                        />
                      </Col>
                      <Col md="3">
                        <Select
                          onInputChange={handleWithdrawalRequestEnters}
                          value={withdrawalRequestSelect?.title}
                          placeholder={withdrawalRequestSelect?.title}
                          onChange={handlerWithdrawalRequestFinalValue}
                          options={withdrawalRequestOptions}
                          classNamePrefix="select2-selection"
                          isLoading={promoterLoad}
                        />
                      </Col>
                    </Row>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        {deductions?.total >= 1 ? (
                          <>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={'id'}
                                    key={'id'}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    // selectRow={selectRow}
                                    classes={'table align-middle table-nowrap'}
                                    headerWrapperClasses={'thead-light'}
                                    {...toolkitProps.baseProps}
                                    // onTableChange={handleTableChange}
                                    // {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <MyPagination
                              totalPages={pages}
                              page={page}
                              setPage={setPage}
                            />
                          </>
                        ) : (
                          <p className="my-4 mx-3 ">No Deductions !</p>
                        )}
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default DeductionsList
