import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import 'toastr/build/toastr.min.css'

import { Row, Col, CardBody, Card, Alert, Container, Spinner } from 'reactstrap'

//redux
import { useSelector, useDispatch } from 'react-redux'

import { withRouter, Link, useLocation } from 'react-router-dom'

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation'

// actions
import { loginPhone, loginUser } from '../../store/actions'

// import images
import MetaTag from 'components/Common/Meta-tag'
import { LocalLogo2, profile } from 'assets/images'

const Login = ({ history }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { error, loading } = useSelector(state => ({
    error: state.Login.error,
    loading: state.Login.loading,
  }))
  const [showPhoneNumber, setShowPhoneNumber] = useState(false)

  useEffect(() => {
    if (location.state === 'showPhoneNumber') {
      setShowPhoneNumber(true)
    }
  }, [location])
  // const handleValidSubmit = values => {
  //   if (showPhoneNumber) {
  //     dispatch(loginPhone({phone : values?.phone})); // Dispatch loginPhone action if showPhoneNumber is true
  //   } else if (user?.userId){
  //     otpData={
  //       otp:otp,
  //       userId:user?.userId
  //     }
  //     console.log(otpData);
  //     dispatch(loginOtpUser(otpData,history));
  //   }
  //   else {
  //     dispatch(loginUser(values, history)); // Dispatch loginUser action if showPhoneNumber is false
  //   }

  // }
  const handleValidSubmit = values => {
    if (showPhoneNumber) {
      dispatch(loginPhone({ phone: values?.phone }))
      history.push('/login-otp')
    } else {
      dispatch(loginUser(values, history))
    }
  }

  // if (showPhoneNumber) {
  //   dispatch(loginPhone({ phone: values?.phone }))
  // } else {
  //    // Assuming history is available as a prop
  // }
  //   if (showPhoneNumber && !(user?.userId) {
  //         dispatch(loginPhone({phone : values?.phone})); // Dispatch loginPhone action if showPhoneNumber is true
  //       } else if (user?.userId){
  //         otpData={
  //           otp:otp,
  //           userId:user?.userId
  //         }
  //         console.log(otpData);
  //         dispatch(loginOtpUser(otpData,history));
  //       }
  // }

  // useEffect(() => {
  //   if (otp?.length === 4) {
  //     const otpData = {
  //       otp: otp,
  //       userId: user.userId,
  //     }
  //     dispatch(loginOtpUser(otpData, history))
  //   }
  // }, [otp])
  // const handleValidSubmit = (values) => {
  //   // dispatch(loginUser(values, history));
  //   dispatch(loginPhone({phone : values?.phone}));
  // }
  // const handleValidSubmit = values => {
  //   dispatch(loginPhone(values))
  // }

  // const handleChangeOtp = (v) => {

  //   setOtp(v);
  // };
  const token = sessionStorage.getItem('token')

  useEffect(() => {
    if (token) {
      history.push('/dashboard')
      // setShowPhoneNumber(false);
    }
  }, [])

  return (
    <React.Fragment>
      <MetaTag title="Login" />

      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-gray p-4">
                        <h5 className="text-gray">Welcome Back !</h5>
                        <p>Sign in to continue</p>
                      </div>
                    </Col>

                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span
                          className="avatar-title rounded-circle "
                          style={{
                            backgroundColor: '#f8f9fa',
                          }}
                        >
                          <img src={LocalLogo2} alt="logo" height="40" />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(v)
                      }}
                    >
                      {error?.message ? (
                        <Alert color="danger">{error.message}</Alert>
                      ) : null}

                      {!showPhoneNumber ? (
                        <>
                          <div className="mb-3">
                            <AvField
                              name="email"
                              label="Email"
                              className="form-control"
                              placeholder="Enter username"
                              type="text"
                              required
                            />
                          </div>

                          <div className="mb-3">
                            <AvField
                              name="password"
                              label="Password"
                              type="password"
                              required
                              placeholder="Enter Password"
                            />
                          </div>
                        </>
                      ) : (
                        <div className="mb-3">
                          <AvField
                            name="phone"
                            label="Phone Number"
                            type="number"
                            required
                            placeholder="Enter Phone Number"
                          />
                        </div>
                      )}

                      <div className="ckeck-box-login">
                        {/* <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customControlInline"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customControlInline"
                          >
                            Remember me
                          </label>
                        </div> */}
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customControlInline"
                            onChange={() =>
                              setShowPhoneNumber(!showPhoneNumber)
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customControlInline"
                          >
                            Login With Phone number
                          </label>
                        </div>
                      </div>
                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-success btn-block"
                          type="submit"
                        >
                          {loading ? (
                            <>
                              <Spinner color="white" />
                            </>
                          ) : (
                            'Log In'
                          )}
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <Link to="#!" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Forgot your password?
                        </Link>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  © {new Date().getFullYear()} {}. Crafted with{' '}
                  <i className="mdi mdi-heart text-danger" /> by
                  <a target="blank" href="https://osperb.com">
                    {` osperb`}
                  </a>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
