import React, { Fragment, useEffect, useState } from 'react'
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Badge,
  FormGroup,
  UncontrolledTooltip,
} from 'reactstrap'
import { Link, useHistory } from 'react-router-dom'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from 'components/Common/Spinner'

// data-table related plugins
import BootstrapTable from 'react-bootstrap-table-next'

import { debounce, map, range } from 'lodash'

import ToolkitProvider from 'react-bootstrap-table2-toolkit'

//actions
import {
  getAllWithdrawalRequests,
  deleteSupplyChain,
  ApproveWithdrawalRequest,
  getPromoters,
} from 'store/actions'

import '../../assets/scss/data-tables.scss'
import MyPagination from 'components/Common/MyPagination'
import DeleteModal from 'components/Common/DeleteModal'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import moment from 'moment'
import WithdrawalModal from './Crud/Modal'
import { CurrencySwitch } from 'hooks/Currency'
import DownloadRequest from './Request/DownloadRequest'
import { useWindowSize } from 'hooks/useWindowSize'
import { BsFillCloudUploadFill } from 'react-icons/bs'
import BulkRequestModal from './Request/BulkRequestModal'

const WithdrawalRequests = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const currency = CurrencySwitch()
  const width = useWindowSize()

  const [page, setPage] = useState(1)
  const [searchText, setSearchText] = useState('')
  const [sort, setSort] = useState('')
  const [limit, setLimit] = useState(30)

  const role = sessionStorage.getItem('role')

  const [WithdrawalModalOpen, setWithdrawalModalOpen] = useState({
    status: false,
    data: {},
  })

  const [finalDateFormatted, setFinalDateFormatted] = useState({
    from: '',
    to: '',
  })

  const [promoterSelect, setPromoterSelect] = useState({
    title: 'Search Promoter',
  })

  const {
    WithdrawalRequests,
    loading,
    bulkRequestLoad,
    allPromoters,
    promoterLoad,
  } = useSelector(state => ({
    WithdrawalRequests: state.SupplyChain.withDrawalRequests,
    loading: state.SupplyChain.loading,
    bulkRequestLoad: state.SupplyChain.bulkRequestLoad,
    allPromoters: state?.Users?.promotersList,
    promoterLoad: state.Users?.promoterLoad,
  }))

  const isMobile = width?.width <= 425

  const handleDate = () => {
    if (finalDateFormatted?.from && finalDateFormatted?.to) {
      return finalDateFormatted
    } else {
      return {
        from: '',
        to: '',
      }
    }
  }
  useEffect(() => {
    const credentials = {
      page: page,
      sort: sort,
      limit: limit,
      searchText: searchText,
      date: handleDate(),
      promoterId: promoterSelect?.id || '',
    }
    dispatch(getAllWithdrawalRequests(credentials))
  }, [
    dispatch,
    page,
    sort,
    searchText,
    finalDateFormatted,
    promoterSelect,
    limit,
  ])

  const totalPages = Math.ceil(WithdrawalRequests?.total / limit)
  const pages = range(1, totalPages + 1)

  const [isOpen, setIsOpen] = useState({ status: false, id: '' })
  const [uploadRqOpen, setUploadRqOpen] = useState({ status: false, data: '' })

  // const handleDelete = id => {
  //   setIsOpen({ status: true, id: id })
  // }

  const handlePageLimit = e => {
    if (e.target.value >= 1) {
      setLimit(parseInt(e.target.value))
    }
  }

  const handleDeleteEvent = () => {
    setIsOpen({ ...isOpen, stauts: false })
    dispatch(deleteSupplyChain(isOpen?.id))
  }

  const handleApprove = id => {
    dispatch(ApproveWithdrawalRequest(id))
  }
  const columns = [
    {
      dataField: 'requestId',
      text: 'Request Id',
      sort: true,
    },
    {
      dataField: 'promoter',
      text: 'Promoter',
    },
    {
      dataField: 'date',
      text: 'Date',
    },
    {
      dataField: 'amount',
      text: 'Amount',
      sort: true,
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
    },

    {
      dataField: 'action',
      text: 'Action',
    },
  ]

  const handleStatus = status => {
    switch (status) {
      case 'pending':
        return 'text-dark'
      case 'inprogress':
        return 'text-info'
      case 'approved':
        return 'text-success'
      case 'cancelled':
        return 'text-warning'

      default:
        return 'text-black'
    }
  }

  const handleStatusCheck = status => {
    switch (status) {
      case 'pending':
      case 'inprogress':
        return true
      default:
        return false
    }
  }

  const productData = map(WithdrawalRequests?.withdrawalRequests, item => ({
    ...item,
    amount: `${currency} ${item?.amount}`,
    promoter: (
      <span>
        {item?.promoter?.phone} - {item?.promoter?.name}
      </span>
    ),
    date: <span>{moment(item?.createdAt).format('lll')}</span>,
    supplyChain: (
      <Link to={`/lucky-draw/update/${item?._id}`}>{item?.supplyChain}</Link>
    ),

    action: (
      <div className="d-flex algin-items-center">
        {item.status !== 'approved' && (
          <div className="d-flex me-3 algin-items-center justify-content-center">
            <Link
              to={`#`}
              className="btn-md d-flex align-items-center"
              onClick={() =>
                setWithdrawalModalOpen({ status: true, data: item })
              }
            >
              <i className="bx bx-edit me-2 " style={{ fontSize: '1.3rem' }} />
            </Link>
          </div>
        )}

        <div className="pe-2">
          {handleStatusCheck(item?.status) ? (
            <Button
              type="button"
              className="p-1 px-2 btn-outline-success"
              color=""
              onClick={() => {
                handleApprove(item._id)
              }}
            >
              Approve
            </Button>
          ) : (
            <Button type="button" className="p-1 px-2 " color="">
              &nbsp;{' '}
            </Button>
          )}
        </div>
      </div>
    ),
    status: (
      <span
        style={{ textTransform: 'capitalize' }}
        className={handleStatus(item?.status)}
      >
        {item?.status}
      </span>
    ),
    approvedAt: <span>{moment(item?.approvedAt).format('ll')}</span>,
    cancelledAt: <span>{moment(item?.cancelledAt).format('ll')}</span>,
  }))

  const handleColumnCheck = () => {
    switch (sort) {
      case 'approved':
        return [
          ...columns?.filter(i => i.dataField !== 'action'),
          {
            dataField: 'approvedAt',
            text: 'Approved date',
            sort: true,
          },
        ]
      case 'cancelled':
        return [
          ...columns?.filter(i => i.dataField !== 'action'),
          {
            dataField: 'cancelledAt',
            text: 'Cancelled date',
            sort: true,
          },
        ]
      default:
        return columns
    }
  }

  // Select All Button operation
  const debounceProductSearch = debounce(value => setSearchText(value), 600)

  const listChange = sortValue => {
    setSort(sortValue)
    history?.push({
      pathname: location.pathname,
      search: `p=${page}&s=${sortValue}`,
      state: {
        page: page,
        sort: sortValue,
      },
    })
  }

  const tableTitles = [
    {
      title: 'All',
      value: '',
    },

    {
      title: 'Pending',
      value: 'pending',
    },
    {
      title: 'In progress',
      value: 'in progress',
    },
    {
      title: 'Approved',
      value: 'approved',
    },
    {
      title: 'Cancelled',
      value: 'cancelled',
    },
  ]

  //promoterSelect
  const [promoterSearch, setPromoterSearch] = useState('')

  const debouncePromoterSearch = debounce(
    value => setPromoterSearch(value),
    600,
  )

  useEffect(() => {
    if (role === 'admin') {
      dispatch(getPromoters(page, promoterSearch))
    }
  }, [dispatch, promoterSearch, role])

  const handlePromoterEnters = textEntered => {
    debouncePromoterSearch(textEntered)
  }

  function handlerPromoterFinalValue(event) {
    const promoter = {
      ...promoterSelect,
      title: event.label,
      id: event.value,
    }
    setPromoterSelect(promoter)
  }

  const promoterOptions = [
    {
      label: 'All Promoters',
      id: '',
    },
    {
      options: (allPromoters?.total >= 1 ? allPromoters?.promoters : [])?.map(
        (result, index) => ({
          key: index,
          label: result?.name || result?.phone,
          value: result?._id,
        }),
      ),
    },
  ]

  const [selectedItemIds, setSelectedItemIds] = useState([])

  const handleSelectTableRow = () => {
    return {
      mode: 'checkbox',
      onSelect: (row, isSelect) => {
        if (isSelect) {
          setSelectedItemIds([...selectedItemIds, row._id])
        } else {
          setSelectedItemIds(selectedItemIds?.filter(id => id !== row._id))
        }
      },
      onSelectAll: (isSelect, rows) => {
        if (isSelect === true) {
          const selectedItemIds = rows?.map(i => i._id)
          setSelectedItemIds(selectedItemIds)
        } else {
          setSelectedItemIds([])
        }
      },
      selected: selectedItemIds,
    }
  }

  return (
    <React.Fragment>
      <Row>
        <DeleteModal
          show={isOpen?.status}
          onCloseClick={() => setIsOpen({ data: {}, status: false })}
          onDeleteClick={handleDeleteEvent}
        />
        {WithdrawalModalOpen?.status && (
          <WithdrawalModal
            onCloseClick={() =>
              setWithdrawalModalOpen({ status: false, data: {} })
            }
            show={WithdrawalModalOpen?.status}
            updateData={WithdrawalModalOpen?.data}
          />
        )}

        <BulkRequestModal
          show={uploadRqOpen?.status}
          updateData={uploadRqOpen?.data}
          onCloseClick={() => setUploadRqOpen({ status: false, data: '' })}
        />

        <Col className="col-12">
          <Card>
            <CardBody>
              <ToolkitProvider
                keyField="_id"
                key={'id'}
                columns={handleColumnCheck()}
                data={productData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col sm="5">
                        <div
                          // className="btn-group mt-2 mt-xl-0 mb-2"
                          className={`${
                            isMobile
                              ? 'btn btn-md d-flex flex-wrap'
                              : 'btn-group mt-2'
                          }  mt-xl-0`}
                          role="group"
                          aria-label="Basic radio toggle button group"
                        >
                          {map(tableTitles, (head, key) => (
                            <Fragment key={key}>
                              <input
                                type="radio"
                                className="btn-check"
                                name="btnradio"
                                id={head.value + key}
                                autoComplete="off"
                                checked={sort === head.value}
                                onChange={() => listChange(head?.value || '')}
                              />
                              <label
                                // className="btn btn-success d-flex align-items-center w-100"
                                className={`${
                                  isMobile
                                    ? 'justify-content-center me-2 btn btn-success d-flex align-items-center'
                                    : 'btn btn-success d-flex align-items-center w-100'
                                } `}
                                htmlFor={head.value + key}
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {head?.title}

                                <Badge
                                  className={`font-size-12 ms-2 ${
                                    sort === head.value
                                      ? 'badge-soft-warning'
                                      : 'badge-soft-light'
                                  }`}
                                  pill
                                >
                                  {head?.count}
                                </Badge>
                              </label>
                            </Fragment>
                          ))}
                        </div>
                      </Col>
                      <Col sm="4" className="d-flex align-items-center">
                        <div className="search-box ms-2 w-100 d-inline-block ">
                          <div className="position-relative w-100">
                            <AvForm>
                              <AvField
                                name="searchText"
                                type="text"
                                value={searchText}
                                placeholder="Search Request Id"
                                onChange={e =>
                                  debounceProductSearch(e.target.value)
                                }
                              />
                            </AvForm>
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>
                        <AvForm className="form-horizontal mx-2 d-flex align-items-center">
                          <div className="mx-1">
                            <span className="d-flex align-items-center">
                              Show
                              <i className="fas fa-sort-numeric-down-alt mx-1"></i>
                            </span>
                          </div>
                          <div className="" style={{ width: '50px' }}>
                            <AvField
                              name="pageLimit"
                              className="form-control"
                              value={limit}
                              onChange={e => handlePageLimit(e)}
                              type="text"
                              required
                            />
                          </div>
                        </AvForm>
                      </Col>
                      <Col sm="3">
                        <div className="text-sm-end mt-2 mb-2">
                          <Link to="#">
                            <Button
                              onClick={() =>
                                setWithdrawalModalOpen({
                                  status: true,
                                  data: null,
                                })
                              }
                              type="button"
                              color="success"
                              className="btn-rounded  mb-2 me-2"
                            >
                              <i className="mdi mdi-plus me-1" />
                              Withdrawal Request
                            </Button>
                          </Link>
                        </div>
                      </Col>
                    </Row>
                    <div className="w-100 pb-2 d-flex align-items-center mb-3">
                      <div className="d-flex sortWrapper mx-2 me-3 align-items-center ">
                        <p
                          className="mb-0 pb-0 mx-1"
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          Total :
                        </p>
                        <div>
                          {WithdrawalRequests?.withdrawalRequests?.length}
                        </div>
                      </div>

                      <div className="d-flex sortWrapper mx-2 me-3 align-items-center ">
                        <p
                          className="mb-0 pb-0 mx-1"
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          Sort By Date :
                        </p>
                        <AvForm>
                          <div className="d-flex date_wrapper">
                            <div className="d-flex dateRange1 align-items-start position-relative">
                              <AvField
                                name="dateRange"
                                type="date"
                                validate={{
                                  dateRange: {
                                    start: {
                                      value: -5,
                                      units: 'years',
                                    },
                                    end: { value: 5, units: 'years' },
                                  },
                                }}
                                onChange={e =>
                                  setFinalDateFormatted({
                                    ...finalDateFormatted,
                                    from: e.target.value,
                                  })
                                }
                                value={finalDateFormatted?.from}
                              />
                              <button
                                onClick={() =>
                                  setFinalDateFormatted({
                                    ...finalDateFormatted,
                                    from: '',
                                  })
                                }
                                type="button"
                                className="btn btn-white editable-cancel date_close btn-sm"
                              >
                                <i className="mdi mdi-close text-danger "></i>
                              </button>
                            </div>
                            <div className="d-flex dateRange1 align-items-start position-relative">
                              <AvField
                                name="dateRange"
                                className="mx-1"
                                type="date"
                                validate={{
                                  dateRange: {
                                    start: {
                                      value: -5,
                                      units: 'years',
                                    },
                                    end: { value: 5, units: 'years' },
                                  },
                                }}
                                onChange={e =>
                                  setFinalDateFormatted({
                                    ...finalDateFormatted,
                                    to: e.target.value,
                                  })
                                }
                                value={finalDateFormatted?.to}
                              />
                              <button
                                onClick={() =>
                                  setFinalDateFormatted({
                                    ...finalDateFormatted,
                                    to: '',
                                  })
                                }
                                type="button"
                                className="btn btn-white editable-cancel date_close btn-sm"
                              >
                                <i className="mdi mdi-close text-danger "></i>
                              </button>
                            </div>
                          </div>
                        </AvForm>
                      </div>
                      {role === 'admin' && (
                        <FormGroup className="w-25 me-3">
                          <div className="ajax-select mt-3 mt-lg-0 select2-container">
                            <Select
                              onInputChange={handlePromoterEnters}
                              value={promoterSelect?.title}
                              placeholder={promoterSelect?.title}
                              onChange={handlerPromoterFinalValue}
                              options={promoterOptions}
                              classNamePrefix="select2-selection"
                              isLoading={promoterLoad}
                            />
                          </div>
                        </FormGroup>
                      )}

                      <div className="d-flex align-items-center justify-content-start">
                        <Button
                          id="uploadBulkWithdrawalRequestId"
                          type="button"
                          color="warning"
                          className="me-3 btn btn-white btn-sm"
                          onClick={() =>
                            setUploadRqOpen({
                              status: true,
                              data: '',
                            })
                          }
                        >
                          <BsFillCloudUploadFill
                            size={'14px'}
                            className="mx-2"
                          />{' '}
                          requests
                          {bulkRequestLoad && <Spinner />}
                        </Button>
                        <UncontrolledTooltip
                          id="uploadTip"
                          target={'uploadBulkWithdrawalRequestId'}
                        >
                          Upload Multiple Withdrawal Requests
                        </UncontrolledTooltip>
                        <DownloadRequest status={sort} />
                      </div>
                    </div>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        {WithdrawalRequests?.total !== 0 ? (
                          <>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={'id'}
                                    key={'id'}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    classes={'table align-middle table-nowrap'}
                                    headerWrapperClasses={'thead-light'}
                                    {...toolkitProps.baseProps}
                                    selectRow={handleSelectTableRow()}
                                    // onTableChange={handleTableChange}
                                    // {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <MyPagination
                              totalPages={pages}
                              page={page}
                              setPage={setPage}
                            />
                          </>
                        ) : (
                          <p className="my-4 mx-3 ">No Withdrawal Requests !</p>
                        )}
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default WithdrawalRequests
