import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  token,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      if (isAuthProtected && !token && !sessionStorage.getItem('token')) {
        return (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }

      return (
        <Layout>
          <Component {...props} />
        </Layout>
      )
    }}
  />
)

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
  token: PropTypes.string,
}

export default Authmiddleware
