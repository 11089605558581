import { all, fork } from 'redux-saga/effects'

//public
import AccountSaga from './auth/register/saga'
import AuthSaga from './auth/login/saga'
import ForgetSaga from './auth/forgetpwd/saga'
import ProfileSaga from './auth/profile/saga'
import LayoutSaga from './layout/saga'
import productSaga from './products/saga'
import shopProductSaga from './shop-products/saga'
import shopSaga from './shops/saga'
import storeSaga from './stores/saga'
import categorySaga from './category/saga'
import userSaga from './users/saga'
import orderSaga from './orders/saga'
import settingsSaga from './settings/saga'
import revenuesSaga from './revenues/saga'
import bannerSaga from './banner/saga'
import dashboardSaga from './dashboard/saga'
import classificationsSaga from './classifications/saga'
import collectionsSaga from './collections/saga'
import groupsSaga from './groups/saga'
import couponsSaga from './coupon/saga'
import areaSaga from './area_list/saga'
import pagesSaga from './product-pages/saga'
import performanceSaga from './performance/saga'
import promoterSaga from './promoter/saga'
import blogSaga from './blogs/saga'
import supplyChainSaga from './supply-chain/saga'
import NotificationSaga from './notification/saga'

import DeductionSaga from './deduction/saga'

import CourseProviderSaga from './courseprovider/saga'

import CourseFormSaga from './courseform/saga'

import CourseRegistrationSaga from './courseregistration/saga'

import WithdrawalListSaga from './withdrawallist/saga'

import IdealsplyWalletWithdrawallistSaga from './idealsplywalletwithdrawallist/saga'

export default function* rootSaga() {
  yield all([
    fork(WithdrawalListSaga),
    fork(IdealsplyWalletWithdrawallistSaga),
    fork(CourseRegistrationSaga),
    fork(CourseFormSaga),
    fork(CourseProviderSaga),
    fork(DeductionSaga),
    fork(NotificationSaga),
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(productSaga),
    fork(shopProductSaga),
    fork(shopSaga),
    fork(storeSaga),

    fork(categorySaga),
    fork(bannerSaga),
    fork(classificationsSaga),
    fork(collectionsSaga),
    fork(groupsSaga),
    fork(pagesSaga),
    fork(couponsSaga),
    fork(areaSaga),

    fork(userSaga),
    fork(orderSaga),
    fork(settingsSaga),
    fork(revenuesSaga),
    fork(dashboardSaga),

    fork(performanceSaga),
    fork(promoterSaga),
    fork(blogSaga),
    fork(supplyChainSaga),
  ])
}
