/* CourseForms - This line cannot be edited or removed */
export const GET_COURSEFORMS = 'GET_COURSEFORMS'
export const GET_COURSEFORMS_SUCCESS = 'GET_COURSEFORMS_SUCCESS'
export const GET_COURSEFORMS_FAIL = 'GET_COURSEFORMS_FAIL'

/* CourseForm_DETAILS */
export const GET_COURSEFORM_DETAILS = 'GET_COURSEFORM_DETAILS'
export const GET_COURSEFORM_DETAILS_SUCCESS = 'GET_COURSEFORM_DETAILS_SUCCESS'
export const GET_COURSEFORM_DETAILS_FAIL = 'GET_COURSEFORM_DETAILS_FAIL'

/* CREATE CourseForm */
export const CREATE_COURSEFORM = 'CREATE_COURSEFORM'
export const CREATE_COURSEFORM_SUCCESS = 'CREATE_COURSEFORM_SUCCESS'
export const CREATE_COURSEFORM_FAIL = 'CREATE_COURSEFORM_FAIL'

/* Edit CourseForm */
export const UPDATE_COURSEFORM = 'UPDATE_COURSEFORM'
export const UPDATE_COURSEFORM_SUCCESS = 'UPDATE_COURSEFORM_SUCCESS'
export const UPDATE_COURSEFORM_FAIL = 'UPDATE_COURSEFORM_FAIL'

/* Delete CourseForm */
export const DELETE_COURSEFORM = 'DELETE_COURSEFORM'
export const DELETE_COURSEFORM_SUCCESS = 'DELETE_COURSEFORM_SUCCESS'
export const DELETE_COURSEFORM_FAIL = 'DELETE_COURSEFORM_FAIL'
