import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { Badge, Card, CardBody, UncontrolledTooltip } from 'reactstrap'

function InfluencedPromoter() {
  const { orderDetails } = useSelector(state => ({
    orderDetails: state.Orders.orderDetails,
  }))

  return (
    <Link to={`/promoter/${orderDetails?.promoter?._id}`}>
      <Card>
        <CardBody>
          <div className="mb-1 d-flex align-items-start justify-content-between">
            <p className="mb-1">Influenced Promoter</p>

            <Badge
              className={'font-size-13 badge-soft-success'}
              id="orderDetailsRefId"
              color=""
            >
              {orderDetails?.promoter?.ref_id}
            </Badge>
            <UncontrolledTooltip target={'orderDetailsRefId'}>
              Ref Id
            </UncontrolledTooltip>
          </div>
          <h6 className="mb-2 mx-1">{orderDetails?.promoter?.name}</h6>
          <p className="mb-0 d-flex align-items-center">
            {orderDetails?.promoter?.phone && (
              <span className="pe-2 d-flex align-items-center">
                <i className="bx bx-phone-call text-success font-size-16 mx-1"></i>
                {orderDetails?.promoter?.phone}
              </span>
            )}
            {orderDetails?.promoter?.email && (
              <span className="d-flex align-items-center">
                <i className="bx bx-mail-send text-primary font-size-16 mx-1"></i>
                {orderDetails?.promoter?.email}
              </span>
            )}
          </p>
        </CardBody>
      </Card>
    </Link>
  )
}

export default InfluencedPromoter
