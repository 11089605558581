import {
  GET_DEDUCTIONS,
  GET_DEDUCTIONS_SUCCESS,
  GET_DEDUCTIONS_FAIL,
  GET_DEDUCTION_DETAILS,
  GET_DEDUCTION_DETAILS_SUCCESS,
  GET_DEDUCTION_DETAILS_FAIL,
  CREATE_DEDUCTION,
  CREATE_DEDUCTION_SUCCESS,
  CREATE_DEDUCTION_FAIL,
  UPDATE_DEDUCTION,
  UPDATE_DEDUCTION_SUCCESS,
  UPDATE_DEDUCTION_FAIL,
  DELETE_DEDUCTION_SUCCESS,
  DELETE_DEDUCTION_FAIL,
  DELETE_DEDUCTION,
  DOWNLOAD_DEDUCTIONS,
  DOWNLOAD_DEDUCTIONS_SUCCESS,
  DOWNLOAD_DEDUCTIONS_FAIL,
} from './actionTypes'

// Deduction - This line cannot be edited or removed
export const getDeductions = (
  page,
  sort,
  limit,
  searchText,
  sortByDate,
  promoter,
  withdrawalRequest,
) => ({
  type: GET_DEDUCTIONS,
  payload: {
    page,
    sort,
    limit,
    searchText,
    sortByDate,
    promoter,
    withdrawalRequest,
  },
})

export const getDeductionsSuccess = deductions => ({
  type: GET_DEDUCTIONS_SUCCESS,
  payload: deductions,
})

export const getDeductionsFail = error => ({
  type: GET_DEDUCTIONS_FAIL,
  payload: error,
})

export const getDeductionDetails = deductionId => ({
  type: GET_DEDUCTION_DETAILS,
  payload: deductionId,
})

export const getDeductionDetailsSuccess = deductionDetails => ({
  type: GET_DEDUCTION_DETAILS_SUCCESS,
  payload: deductionDetails,
})

export const getDeductionDetailsFail = error => ({
  type: GET_DEDUCTION_DETAILS_FAIL,
  payload: error,
})

export const createDeduction = (deduction, onCloseClick) => ({
  type: CREATE_DEDUCTION,
  payload: { deduction, onCloseClick },
})

export const createDeductionSuccess = deduction => ({
  type: CREATE_DEDUCTION_SUCCESS,
  payload: deduction,
})

export const createDeductionFail = error => ({
  type: CREATE_DEDUCTION_FAIL,
  payload: error,
})

export const getDeductionDetail = deductionId => ({
  type: GET_DEDUCTION_DETAILS,
  payload: deductionId,
})

export const updateDeduction = (deduction, deductionId, onCloseClick) => ({
  type: UPDATE_DEDUCTION,
  payload: { deduction, deductionId, onCloseClick },
})

export const updateDeductionSuccess = deduction => ({
  type: UPDATE_DEDUCTION_SUCCESS,
  payload: deduction,
})

export const updateDeductionFail = error => ({
  type: UPDATE_DEDUCTION_FAIL,
  payload: error,
})

export const deleteDeduction = (deductionId, history) => ({
  type: DELETE_DEDUCTION,
  payload: { deductionId, history },
})

export const deleteDeductionSuccess = deduction => ({
  type: DELETE_DEDUCTION_SUCCESS,
  payload: deduction,
})

export const deleteDeductionFail = error => ({
  type: DELETE_DEDUCTION_FAIL,
  payload: error,
})

export const downloadDeductions = (
  sort,
  searchText,
  sortByDate,
  promoter,
  withdrawalRequest,
) => ({
  type: DOWNLOAD_DEDUCTIONS,
  payload: {
    sort,
    searchText,
    sortByDate,
    promoter,
    withdrawalRequest,
  },
})

export const downloadDeductionsSuccess = downloadDeductions => ({
  type: DOWNLOAD_DEDUCTIONS_SUCCESS,
  payload: downloadDeductions,
})

export const downloadDeductionsFail = error => ({
  type: DOWNLOAD_DEDUCTIONS_FAIL,
  payload: error,
})
