/* Deductions - This line cannot be edited or removed */
export const GET_DEDUCTIONS = 'GET_DEDUCTIONS'
export const GET_DEDUCTIONS_SUCCESS = 'GET_DEDUCTIONS_SUCCESS'
export const GET_DEDUCTIONS_FAIL = 'GET_DEDUCTIONS_FAIL'

/* Deduction_DETAILS */
export const GET_DEDUCTION_DETAILS = 'GET_DEDUCTION_DETAILS'
export const GET_DEDUCTION_DETAILS_SUCCESS = 'GET_DEDUCTION_DETAILS_SUCCESS'
export const GET_DEDUCTION_DETAILS_FAIL = 'GET_DEDUCTION_DETAILS_FAIL'

/* CREATE Deduction */
export const CREATE_DEDUCTION = 'CREATE_DEDUCTION'
export const CREATE_DEDUCTION_SUCCESS = 'CREATE_DEDUCTION_SUCCESS'
export const CREATE_DEDUCTION_FAIL = 'CREATE_DEDUCTION_FAIL'

/* Edit Deduction */
export const UPDATE_DEDUCTION = 'UPDATE_DEDUCTION'
export const UPDATE_DEDUCTION_SUCCESS = 'UPDATE_DEDUCTION_SUCCESS'
export const UPDATE_DEDUCTION_FAIL = 'UPDATE_DEDUCTION_FAIL'

/* Delete Deduction */
export const DELETE_DEDUCTION = 'DELETE_DEDUCTION'
export const DELETE_DEDUCTION_SUCCESS = 'DELETE_DEDUCTION_SUCCESS'
export const DELETE_DEDUCTION_FAIL = 'DELETE_DEDUCTION_FAIL'

//Download Deductions
export const DOWNLOAD_DEDUCTIONS = 'DOWNLOAD_DEDUCTIONS'
export const DOWNLOAD_DEDUCTIONS_SUCCESS = 'DOWNLOAD_DEDUCTIONS_SUCCESS'
export const DOWNLOAD_DEDUCTIONS_FAIL = 'DOWNLOAD_DEDUCTIONS_FAIL'
