import React, { useEffect, useState } from 'react'
import { Col, FormGroup, Row } from 'reactstrap'
import Select from 'react-select'
import { debounce } from 'lodash'
import PropTypes from 'prop-types'
import { AvField } from 'availity-reactstrap-validation'
import { useDispatch, useSelector } from 'react-redux'
import { getPromoters } from 'store/actions'

function WithdrawalRequestForm({ PromoterId, updateData, setSelect, select }) {
  const dispatch = useDispatch()

  const role = sessionStorage.getItem('role')

  const { proLoading } = useSelector(state => ({
    proLoading: state.Products.loading,
  }))
  //product dropdown
  const [promoterSearch, setPromoterSearch] = useState('')

  const { allPromoters } = useSelector(state => ({
    allPromoters: state?.Users?.promotersList,
  }))

  useEffect(() => {
    dispatch(getPromoters(1, promoterSearch))
  }, [dispatch, promoterSearch])

  const debouncePromoterSearch = debounce(
    value => setPromoterSearch(value),
    600,
  )
  const handlePromoterEnters = textEntered => {
    debouncePromoterSearch(textEntered)
  }
  function handlerPromoterFinalValue(event) {
    PromoterId(event.value)
  }

  const promoterOptions = [
    {
      options: allPromoters?.promoters?.map((result, index) => ({
        key: index,
        label: result?.name || result?.phone,
        value: result?._id,
      })),
    },
  ]
  const handleSelectChange = event => {
    const select = event.target.value
    setSelect(select)
  }

  useEffect(() => {
    setSelect(updateData?.status)
  }, [updateData])

  return (
    <>
      {updateData ? (
        <Row className="mb-2">
          <Col md="4" lg="12">
            <AvField
              name="remarks"
              label="Remarks"
              className="mb-3"
              type="textarea"
              value={updateData?.remarks || ''}
            />
          </Col>
          <Col md="4" lg="12">
            <label htmlFor="selectField">Status</label>
            <select
              id="selectField"
              className="form-control"
              value={select}
              onChange={handleSelectChange}
            >
              <option value="pending"> Pending</option>
              <option value="inprogress">In progress</option>
              <option value="approved">Approved</option>
              <option value="cancelled">Cancelled</option>
            </select>
          </Col>
        </Row>
      ) : (
        <Row>
          {role === 'admin' && (
            <>
              <Col md="6">
                <p className="p text-muted mb-2">Search Promoter</p>
                <FormGroup className="mb-3">
                  <div className="ajax-selectmt-lg-0 select2-container">
                    <Select
                      onInputChange={handlePromoterEnters}
                      onChange={handlerPromoterFinalValue}
                      options={promoterOptions}
                      classNamePrefix="select2-selection"
                      isLoading={proLoading}
                    />
                  </div>
                </FormGroup>
              </Col>
            </>
          )}

          <Col sm={role === 'admin' ? '6' : '12'}>
            <AvField
              name="amount"
              label="Amount"
              className="mb-3"
              type="number"
              value={updateData?.amount || ''}
            />
          </Col>
        </Row>
      )}
    </>
  )
}

export default WithdrawalRequestForm

WithdrawalRequestForm.propTypes = {
  PromoterId: PropTypes.any,
  updateData: PropTypes.any,
  setSelect: PropTypes.any,
  select: PropTypes.any,
}
