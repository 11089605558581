/* WithdrawalLists - This line cannot be edited or removed */
export const GET_WITHDRAWALLISTS = 'GET_WITHDRAWALLISTS'
export const GET_WITHDRAWALLISTS_SUCCESS = 'GET_WITHDRAWALLISTS_SUCCESS'
export const GET_WITHDRAWALLISTS_FAIL = 'GET_WITHDRAWALLISTS_FAIL'

/* WithdrawalList_DETAILS */
export const GET_WITHDRAWALLIST_DETAILS = 'GET_WITHDRAWALLIST_DETAILS'
export const GET_WITHDRAWALLIST_DETAILS_SUCCESS =
  'GET_WITHDRAWALLIST_DETAILS_SUCCESS'
export const GET_WITHDRAWALLIST_DETAILS_FAIL = 'GET_WITHDRAWALLIST_DETAILS_FAIL'

/* CREATE WithdrawalList */
export const CREATE_WITHDRAWALLIST = 'CREATE_WITHDRAWALLIST'
export const CREATE_WITHDRAWALLIST_SUCCESS = 'CREATE_WITHDRAWALLIST_SUCCESS'
export const CREATE_WITHDRAWALLIST_FAIL = 'CREATE_WITHDRAWALLIST_FAIL'

/* Edit WithdrawalList */
export const UPDATE_WITHDRAWALLIST = 'UPDATE_WITHDRAWALLIST'
export const UPDATE_WITHDRAWALLIST_SUCCESS = 'UPDATE_WITHDRAWALLIST_SUCCESS'
export const UPDATE_WITHDRAWALLIST_FAIL = 'UPDATE_WITHDRAWALLIST_FAIL'

/* Delete WithdrawalList */
export const DELETE_WITHDRAWALLIST = 'DELETE_WITHDRAWALLIST'
export const DELETE_WITHDRAWALLIST_SUCCESS = 'DELETE_WITHDRAWALLIST_SUCCESS'
export const DELETE_WITHDRAWALLIST_FAIL = 'DELETE_WITHDRAWALLIST_FAIL'
