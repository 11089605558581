import { call, put, takeEvery } from 'redux-saga/effects'

import {
  GET_COURSE_PROVIDER_COURSES,
  GET_COURSE_REGISTRATIONS,
  GET_COURSE_REGISTRATION_DETAILS,
  UPDATE_COURSE_REGISTRATION,
} from './actionTypes'
import {
  getCourseRegistrationsFail,
  getCourseRegistrationsSuccess,
  getCourseRegistrationDetailsSuccess,
  getCourseRegistrationDetailsFail,
  updateCourseRegistrationSuccess,
  updateCourseRegistrationFail,
  getCourseProviderCoursesSuccess,
  getCourseProviderCoursesFail,
} from './actions'

import { get, post } from 'helpers/api_helper'
import { Notification } from '../../components/Common/Notification'

// CourseRegistration - This line cannot be edited or removed
function getCourseRegistrationsAPi({
  page,
  sort,
  limit,
  searchText,
  sortByDate,
  courseProvider,
  order,
  product,
}) {
  return get(
    `/course-registration/admin/all?status=${sort ? sort : ''}&page=${
      page ? page : 1
    }&limit=${limit ? limit : 10}&search=${searchText ? searchText : ''}&from=${
      sortByDate?.from
    }&to=${sortByDate?.to}&courseProvider=${
      courseProvider ? courseProvider : ''
    }&order=${order ? order : ''}&product=${product ? product : ''}`,
  )
}

const getCourseRegistrationDetailsAPi = ({ courseRegistrationId }) => {
  return get(`/course-registration/admin/${courseRegistrationId}`)
}

const updateCourseRegistrationApi = ({
  courseRegistration,
  courseRegistrationId,
}) => {
  return post(
    `/course-registration/admin/status/${courseRegistrationId}`,
    courseRegistration,
  )
}

function getCourseProviderCoursesAPi({ page, sort, limit, searchText }) {
  return get(
    `/product/course-provider/all?sort=${sort ? sort : ''}&page=${
      page ? page : 1
    }&limit=${limit ? limit : 10}&search=${searchText ? searchText : ''}`,
  )
}

function* fetchCourseRegistrations({ payload }) {
  try {
    const response = yield call(getCourseRegistrationsAPi, payload)
    yield put(getCourseRegistrationsSuccess(response))
  } catch (error) {
    yield put(getCourseRegistrationsFail(error))
  }
}

function* fetchCourseRegistrationDetails({ payload: courseRegistrationId }) {
  try {
    const response = yield call(
      getCourseRegistrationDetailsAPi,
      courseRegistrationId,
    )
    yield put(getCourseRegistrationDetailsSuccess(response))
  } catch (error) {
    yield put(getCourseRegistrationDetailsFail(error))
  }
}

function* onUpdateCourseRegistration({ payload }) {
  try {
    const response = yield call(updateCourseRegistrationApi, payload)
    if (response) {
      Notification({
        type: 'success',
        message: 'Status Updated Successfully!',
        title: '',
      })
      yield put(updateCourseRegistrationSuccess(response))
      // try {
      //   const response2 = yield call(
      //     getCourseRegistrationDetailsAPi,
      //     response?._id,
      //   )
      //   yield put(getCourseRegistrationDetailsSuccess(response2))
      // } catch (error) {
      //   yield put(getCourseRegistrationDetailsFail(error))
      // }
    }
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: 'error',
        message: error.response?.data?.message,
        title: '',
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: 'error',
        message: 'file size is Too Large',
        title: '',
      })
    }
    yield put(updateCourseRegistrationFail(error.response.data))
  }
}

function* fetchCourseProviderCourses({ payload }) {
  try {
    const response = yield call(getCourseProviderCoursesAPi, payload)
    yield put(getCourseProviderCoursesSuccess(response))
  } catch (error) {
    yield put(getCourseProviderCoursesFail(error))
  }
}

function* courseregistrationSaga() {
  yield takeEvery(GET_COURSE_REGISTRATIONS, fetchCourseRegistrations)
  yield takeEvery(
    GET_COURSE_REGISTRATION_DETAILS,
    fetchCourseRegistrationDetails,
  )
  yield takeEvery(UPDATE_COURSE_REGISTRATION, onUpdateCourseRegistration)
  yield takeEvery(GET_COURSE_PROVIDER_COURSES, fetchCourseProviderCourses)
}

export default courseregistrationSaga

// function doneNotification(message) {
//   Notification({
//     type: 'success',
//     message: message,
//     title: '',
//   })
// }
