import React, { useEffect, useState } from 'react'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import { Row, Col, Card, CardBody } from 'reactstrap'
import { Button, Spinner } from 'reactstrap'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// data-table related plugins
import BootstrapTable from 'react-bootstrap-table-next'
import { map, range } from 'lodash'

import ToolkitProvider from 'react-bootstrap-table2-toolkit'
import MyPagination from '../../components/Common/MyPagination'

//actions

import '../../assets/scss/data-tables.scss'
import { deleteCollection, getCollections, getStores } from 'store/actions'
import DeleteModal from 'components/Common/DeleteModal'
import { LanguageSwitch } from 'hooks/LanguageSwitch'
import { defaultImg } from 'assets/images'

const Collections = () => {
  const dispatch = useDispatch()

  const { Collections, loading } = useSelector(state => ({
    Collections: state.Collection.collections,
    loading: state.Collection.loading,
  }))

  const [page, setPage] = useState(1)
  const [searchText, setSearchText] = useState('')
  const [limit] = useState('10')
  const [finalStoreId] = useState()

  const [isOpen, setIsOpen] = useState({ status: false, id: '' })

  const handleDeleteEvent = () => {
    setIsOpen({ ...isOpen, status: false })
    dispatch(deleteCollection(isOpen?.id, history))
  }

  const handleDelete = id => {
    setIsOpen({ status: true, id: id })
  }

  useEffect(() => {
    dispatch(getCollections('', limit, searchText, finalStoreId))
  }, [dispatch, page, searchText, finalStoreId])

  useEffect(() => {
    dispatch(getStores(1))
  }, [dispatch])

  const total = Collections?.length

  //pagination
  const totalPages = Math.ceil(total / limit)
  const pages = range(1, totalPages + 1)

  const columns = [
    {
      dataField: 'primaryLang.title',
      text: 'Name',
      sort: true,
    },
    // {
    //   dataField: "store.storeName",
    //   text: "Store",
    //   sort: true,
    // },
    {
      dataField: 'productCount',
      text: 'Products',
      sort: true,
    },
    {
      dataField: 'imageData',
      text: 'Image',
      sort: true,
    },
    {
      dataField: 'action',
      text: 'Actions',
    },
  ]

  const collectionData = map(Collections, (item, index) => ({
    ...item,
    imageData: (
      <>
        <img
          src={
            item?.logoImages && item?.logoImages[0]
              ? item?.logoImages[0]?.url
              : defaultImg
          }
          width="50px"
        />
      </>
    ),
    productCount: item?.products?.length,
    activeData: (
      <>
        <div className="form-check form-switch form-switch-md mb-3">
          <input
            type="checkbox"
            className="form-check-input"
            id="customSwitchsizemd"
            onChange={() => {
              handleSwitchChange(item?._id)
            }}
            checked={item?.isActive}
          />
          <label className="form-check-label" htmlFor="customSwitchsizemd">
            {item?.isActive ? `Active` : `Inactive`}
          </label>
        </div>
      </>
    ),
    action: (
      <div>
        <Link to={`/collections/update/${item?._id}`}>
          <Button
            id={`view${index}`}
            type="button"
            color="white"
            className="ms-1 btn"
          >
            <i
              className="bx bx-show-alt me-2"
              style={{ color: 'green', fontSize: '22px' }}
            />
          </Button>
        </Link>
        <Button
          id={`Delete${index}`}
          type="button"
          color="white"
          className="ms-1 btn "
          onClick={() => {
            handleDelete(item?._id)
          }}
        >
          <i
            className="bx bx-trash me-2"
            style={{ color: 'red', fontSize: '20px' }}
          />
        </Button>
      </div>
    ),
  }))

  const isMult = LanguageSwitch()?.isMult

  columns[0].text = isMult ? 'Primary Name' : 'Name'

  return (
    <React.Fragment>
      <DeleteModal
        show={isOpen?.status}
        onCloseClick={() => setIsOpen({ ...isOpen, status: false })}
        onDeleteClick={handleDeleteEvent}
      />
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <div>
                <ToolkitProvider
                  keyField="_id"
                  columns={
                    isMult
                      ? columns
                      : columns?.filter(
                          i => i.dataField !== 'secondaryLang.title',
                        )
                  }
                  data={collectionData}
                  search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <Row className="mb-2">
                        <Col xl={4} lg={4} md={4} sm={12}>
                          <div className="search-box ms-2 mb-2 d-inline-block">
                            <div className="position-relative">
                              <AvForm>
                                <AvField
                                  name="searchText"
                                  placeholder="Search"
                                  type="text"
                                  onChange={e => setSearchText(e.target.value)}
                                />
                              </AvForm>
                              <i className="bx bx-search-alt search-icon" />
                            </div>
                          </div>
                        </Col>
                        <Col xl={4} lg={4} md={4} sm={6} className=""></Col>
                        <Col
                          xl={4}
                          lg={4}
                          md={4}
                          sm={6}
                          className="d-flex align-items-center justify-content-end"
                        >
                          <div className="text-end ">
                            <Link to="/collections/create/new">
                              <Button
                                type="button"
                                color="success"
                                className="btn-rounded  mb-2 sm:mb-0 me-2"
                              >
                                <i className="mdi mdi-plus me-1" />
                                Create Collections
                              </Button>
                            </Link>
                          </div>
                        </Col>
                      </Row>
                      {loading ? (
                        <Spinner color="secondary" className="d-block m-auto" />
                      ) : (
                        <>
                          {Collections?.length ? (
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={'id'}
                                    key={'id'}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    // selectRow={selectRow}
                                    classes={'table align-middle table-nowrap'}
                                    headerWrapperClasses={'thead-light'}
                                    {...toolkitProps.baseProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          ) : (
                            <p className="my-4 mx-3 ">No Collections!</p>
                          )}
                          <MyPagination
                            totalPages={pages}
                            page={page}
                            setPage={setPage}
                          />
                        </>
                      )}
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Collections
