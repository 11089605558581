import {
  GET_COUPONS,
  GET_COUPONS_FAIL,
  GET_COUPONS_SUCCESS,
  GET_COUPON_DETAILS,
  GET_COUPON_DETAILS_SUCCESS,
  GET_COUPON_DETAILS_FAIL,
  CREATE_COUPON,
  CREATE_COUPON_SUCCESS,
  CREATE_COUPON_FAIL,
  UPDATE_COUPON,
  UPDATE_COUPON_SUCCESS,
  UPDATE_COUPON_FAIL,
  DELETE_COUPON,
  DELETE_COUPON_SUCCESS,
  DELETE_COUPON_FAIL,
} from './actionTypes'

//all coupons
export const getCoupons = ({ page, limit, searchText }) => ({
  type: GET_COUPONS,
  payload: { page, limit, searchText },
})

//all Coupons
export const getCouponsSuccess = coupons => ({
  type: GET_COUPONS_SUCCESS,
  payload: coupons,
})

export const getCouponsFail = error => ({
  type: GET_COUPONS_FAIL,
  payload: error,
})

export const getCouponsDetails = couponId => ({
  type: GET_COUPON_DETAILS,
  payload: couponId,
})

export const getCouponsDtailsSuccess = couponDetails => ({
  type: GET_COUPON_DETAILS_SUCCESS,
  payload: couponDetails,
})

export const getCouponsDtailsFail = error => ({
  type: GET_COUPON_DETAILS_FAIL,
  payload: error,
})

export const createCoupon = (coupon, history) => ({
  type: CREATE_COUPON,
  payload: { coupon, history },
})

export const createCouponSuccess = coupon => ({
  type: CREATE_COUPON_SUCCESS,
  payload: coupon,
})

export const createCouponFail = error => ({
  type: CREATE_COUPON_FAIL,
  payload: error,
})

export const updateCoupon = (coupon, couponId, history) => ({
  type: UPDATE_COUPON,
  payload: { coupon, couponId, history },
})

export const updateCouponSuccess = coupon => ({
  type: UPDATE_COUPON_SUCCESS,
  payload: coupon,
})

export const updateCouponFail = error => ({
  type: UPDATE_COUPON_FAIL,
  payload: error,
})

export const deleteCoupon = couponId => ({
  type: DELETE_COUPON,
  couponId,
})

export const deleteCouponSuccess = coupon => ({
  type: DELETE_COUPON_SUCCESS,
  payload: coupon,
})

export const deleteCouponFail = error => ({
  type: DELETE_COUPON_FAIL,
  payload: error,
})
