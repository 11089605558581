import React, { useEffect } from 'react'
import { Container, Row, Col, Button } from 'reactstrap'

//Import Breadcrumb
import Breadcrumbs from 'components/Common/Breadcrumb'

//Import Components
import CardUser from './card-user'
import CardWelcome from './card-welcome'
import MiniWidget from './mini-widget'
import {
  getPromoterDashboardData,
  getPromoterProfileDetails,
  getPromoterTransactionGraph,
} from 'store/actions'
import { useDispatch, useSelector } from 'react-redux'
import { CurrencySwitch } from 'hooks/Currency'
import ChainPromoters from 'pages/ChainPromoter/DatatableTables'
import PromoterTransactions from 'pages/PromoterTransaction/PromoterTransactions'
import { FaArrowRight } from 'react-icons/fa'
import { useHistory } from 'react-router-dom'

//GrowthReward

const options1 = {
  chart: { sparkline: { enabled: !0 } },
  stroke: { curve: 'smooth', width: 2 },
  colors: ['#f1b44c'],
  fill: {
    type: 'gradient',
    gradient: {
      shadeIntensity: 1,
      inverseColors: !1,
      opacityFrom: 0.45,
      opacityTo: 0.05,
      stops: [25, 100, 100, 100],
    },
  },
  tooltip: { fixed: { enabled: !1 }, x: { show: !1 }, marker: { show: !1 } },
}

//lead bonus

const options2 = {
  chart: { sparkline: { enabled: !0 } },
  stroke: { curve: 'smooth', width: 2 },
  colors: ['#3452e1'],
  fill: {
    type: 'gradient',
    gradient: {
      shadeIntensity: 1,
      inverseColors: !1,
      opacityFrom: 0.45,
      opacityTo: 0.05,
      stops: [25, 100, 100, 100],
    },
  },
  tooltip: { fixed: { enabled: !1 }, x: { show: !1 }, marker: { show: !1 } },
}

const PromoterPanel = () => {
  const dispatch = useDispatch()
  const currency = CurrencySwitch()
  const history = useHistory()

  const { dashboardData, profile, graphGrowthReward, graphLeadBonus } =
    useSelector(state => ({
      dashboardData: state.Dashboard?.promoterDashboardData,
      profile: state?.Promoter?.promoterProfile,
      graphGrowthReward: state?.Promoter?.promoterTransactionGraphGrowthReward,
      graphLeadBonus: state?.Promoter?.promoterTransactionGraphLeadBonus,
    }))

  useEffect(() => {
    dispatch(getPromoterTransactionGraph('growthrewards'))
    dispatch(getPromoterTransactionGraph('leadbonus'))
  }, [dispatch])

  const growthRewardSeries = [
    {
      name: 'GR',
      data: Object?.values(
        graphGrowthReward?.data !== undefined ? graphGrowthReward?.data : '',
      ),
    },
  ]
  const leadBonusSeries = [
    {
      name: 'LB',
      data: Object?.values(
        graphLeadBonus?.data !== undefined ? graphLeadBonus?.data : '',
      ),
    },
  ]

  const reports = [
    {
      title: 'Growth Reward',
      icon: 'mdi mdi-wallet-giftcard',
      color: 'warning',
      value: (
        <>
          {currency}&nbsp;{dashboardData?.todayGrowthRewardAmount}{' '}
          <span className="font-size-13"> - Today</span>
        </>
      ),
      desc: (
        <>
          {currency}&nbsp;{dashboardData?.totalGrowthRewardAmount}{' '}
        </>
      ),
      isChart: true,
      series: growthRewardSeries,
      options: options1,
      arrowUpDown: 'mdi mdi-arrow-up ms-1 text-success',
    },
    {
      title: 'Lead Bonus',
      icon: 'mdi mdi-creation',
      color: 'primary',
      value: (
        <>
          {currency}&nbsp;{dashboardData?.todayLeadBonusAmount}{' '}
          <span className="font-size-13"> - Today</span>
        </>
      ),
      desc: (
        <>
          {currency}&nbsp;{dashboardData?.totalLeadBonusAmount}{' '}
        </>
      ),
      series: leadBonusSeries,
      options: options2,
      isChart: true,
      arrowUpDown: 'mdi mdi-arrow-up ms-1 text-success',
    },
    {
      title: 'Sources',
      icon: 'mdi mdi-account-multiple',
      color: 'info',
      value: (
        <>
          {dashboardData?.todayChainPromotersCount}{' '}
          <span className="font-size-13"> - Today</span>
        </>
      ),
      desc: dashboardData?.totalChainPromotersCount,
      isChart: false,
      arrowUpDown: 'mdi mdi-arrow-up ms-1 text-success',
    },
    {
      title: 'Page Views',
      icon: 'mdi mdi-view-dashboard',
      color: 'info',
      value: (
        <>
          {dashboardData?.todayPageViewsCount}{' '}
          <span className="font-size-13"> - Today</span>
        </>
      ),
      desc: dashboardData?.totalPageViewsCount,
      series: [],
      options: [],
      isChart: false,
      arrowUpDown: 'mdi mdi-arrow-up ms-1 text-success',
    },
  ]

  useEffect(() => {
    dispatch(getPromoterDashboardData())
    dispatch(getPromoterProfileDetails())
  }, [dispatch])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="" breadcrumbItem="Dashboard" />
          <Row>
            <Col xl="8">
              <CardWelcome profile={profile} />
            </Col>
            <Col xl="4">
              <CardUser profile={profile} data={dashboardData} />
            </Col>
            <Col xl="12">
              <Row>
                <MiniWidget reports={reports} />
              </Row>
            </Col>
          </Row>
          <Row>
            <Col sm="6">
              <div className="mb-3 d-flex align-items-start justify-content-between">
                <p className="m-0 p-0">Sources</p>
                <Button
                  onClick={() => history.push('/chain-promoter')}
                  color=""
                  className="btn btn-outline-info btn-sm"
                >
                  View All&nbsp; <FaArrowRight />
                </Button>
              </div>
              <ChainPromoters isPromoterDashboard={true} />
            </Col>
            <Col sm="6">
              <div className="mb-3 d-flex align-items-start justify-content-between ">
                <p className="m-0 p-0">Transactions</p>
                <Button
                  onClick={() => history.push('/promoter-transactions')}
                  color=""
                  className="btn btn-outline-info btn-sm"
                >
                  View All&nbsp; <FaArrowRight />
                </Button>
              </div>

              <PromoterTransactions isPromoterDashboard={true} />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PromoterPanel
