/* STORES */
export const GET_STORES = 'GET_STORES'
export const GET_STORES_SUCCESS = 'GET_STORES_SUCCESS'
export const GET_STORES_FAIL = 'GET_STORES_FAIL'

/* STORE LIST */
export const GET_STORE_LIST = 'GET_STORE_LIST'
export const GET_STORE_LIST_SUCCESS = 'GET_STORE_LIST_SUCCESS'
export const GET_STORE_LIST_FAIL = 'GET_STORE_LIST_FAIL'

/* UNAPPROVED STORES */
export const GET_UNAPPROVED_STORES = 'GET_UNAPPROVED_STORES'
export const GET_UNAPPROVED_STORES_SUCCESS = 'GET_UNAPPROVED_STORES_SUCCESS'
export const GET_UNAPPROVED_STORES_FAIL = 'GET_UNAPPROVED_STORES_FAIL'

/* BANNED STORES */
export const GET_BANNED_STORES = 'GET_BANNED_STORES'
export const GET_BANNED_STORES_SUCCESS = 'GET_BANNED_STORES_SUCCESS'
export const GET_BANNED_STORES_FAIL = 'GET_BANNED_STORES_FAIL'

/* Change STORE status*/
export const CHANGE_STORE_STATUS = 'CHANGE_STORE_STATUS'
export const CHANGE_STORE_STATUS_SUCCESS = 'CHANGE_STORE_STATUS_SUCCESS'
export const CHANGE_STORE_STATUS_FAIL = 'CHANGE_STORE_STATUS_FAIL'

/* STORE_DETAILS */
export const GET_STORE_DETAILS = 'GET_STORE_DETAILS'
export const GET_STORE_DETAILS_SUCCESS = 'GET_STORE_DETAILS_SUCCESS'
export const GET_STORE_DETAILS_FAIL = 'GET_STORE_DETAILS_FAIL'

/* CREATE STORE*/
export const CREATE_STORE = 'CREATE_STORE'
export const CREATE_STORE_SUCCESS = 'CREATE_STORE_SUCCESS'
export const CREATE_STORE_FAIL = 'CREATE_STORE_FAIL'

/* Edit STORE*/
export const UPDATE_STORE = 'UPDATE_STORE'
export const UPDATE_STORE_SUCCESS = 'UPDATE_STORE_SUCCESS'
export const UPDATE_STORE_FAIL = 'UPDATE_STORE_FAIL'

/* Delete STORE*/
export const DELETE_STORE = 'DELETE_STORE'
export const DELETE_STORE_SUCCESS = 'DELETE_STORE_SUCCESS'
export const DELETE_STORE_FAIL = 'DELETE_STORE_FAIL'
