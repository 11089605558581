/* CourseProviders - This line cannot be edited or removed */
export const GET_COURSE_PROVIDERS = 'GET_COURSE_PROVIDERS'
export const GET_COURSE_PROVIDERS_SUCCESS = 'GET_COURSE_PROVIDERS_SUCCESS'
export const GET_COURSE_PROVIDERS_FAIL = 'GET_COURSE_PROVIDERS_FAIL'

/* CourseProvider_DETAILS */
export const GET_COURSE_PROVIDER_DETAILS = 'GET_COURSE_PROVIDER_DETAILS'
export const GET_COURSE_PROVIDER_DETAILS_SUCCESS =
  'GET_COURSE_PROVIDER_DETAILS_SUCCESS'
export const GET_COURSE_PROVIDER_DETAILS_FAIL =
  'GET_COURSE_PROVIDER_DETAILS_FAIL'

/* CREATE CourseProvider */
export const CREATE_COURSE_PROVIDER = 'CREATE_COURSE_PROVIDER'
export const CREATE_COURSE_PROVIDER_SUCCESS = 'CREATE_COURSE_PROVIDER_SUCCESS'
export const CREATE_COURSE_PROVIDER_FAIL = 'CREATE_COURSE_PROVIDER_FAIL'

/* Edit CourseProvider */
export const UPDATE_COURSE_PROVIDER = 'UPDATE_COURSE_PROVIDER'
export const UPDATE_COURSE_PROVIDER_SUCCESS = 'UPDATE_COURSE_PROVIDER_SUCCESS'
export const UPDATE_COURSE_PROVIDER_FAIL = 'UPDATE_COURSE_PROVIDER_FAIL'

/* Delete CourseProvider */
export const DELETE_COURSE_PROVIDER = 'DELETE_COURSE_PROVIDER'
export const DELETE_COURSE_PROVIDER_SUCCESS = 'DELETE_COURSE_PROVIDER_SUCCESS'
export const DELETE_COURSE_PROVIDER_FAIL = 'DELETE_COURSE_PROVIDER_FAIL'

/* CourseProviderProfiles - This line cannot be edited or removed */
export const GET_COURSE_PROVIDER_PROFILE = 'GET_COURSE_PROVIDER_PROFILE'
export const GET_COURSE_PROVIDER_PROFILE_SUCCESS =
  'GET_COURSE_PROVIDER_PROFILE_SUCCESS'
export const GET_COURSE_PROVIDER_PROFILE_FAIL =
  'GET_COURSE_PROVIDER_PROFILE_FAIL'

/* Edit CourseProviderProfile */
export const UPDATE_COURSE_PROVIDER_PROFILE = 'UPDATE_COURSE_PROVIDER_PROFILE'
export const UPDATE_COURSE_PROVIDER_PROFILE_SUCCESS =
  'UPDATE_COURSE_PROVIDER_PROFILE_SUCCESS'
export const UPDATE_COURSE_PROVIDER_PROFILE_FAIL =
  'UPDATE_COURSE_PROVIDER_PROFILE_FAIL'
