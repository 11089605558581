import React from 'react'
import { Container } from 'reactstrap'

import Breadcrumbs from '../../components/Common/Breadcrumb'
import DataTables from './DataTables'
import MetaTag from 'components/Common/Meta-tag'

function Deductions() {
  return (
    <>
      <MetaTag title={'All Deductions'} />

      <div className="page-content">
        <Breadcrumbs title="Dashboard" breadcrumbItem="All Deductions" />
        <Container fluid>
          <div className="container-fluid">
            <DataTables />
          </div>
        </Container>
      </div>
    </>
  )
}

export default Deductions
