import MetaTag from 'components/Common/Meta-tag'
import React from 'react'

import OrderDetail from './Details'
import Breadcrumbs from 'components/Common/Breadcrumb'
import { Container } from 'reactstrap'
// import { Link } from "react-router-dom"
// import OrderEditModal from "../OrderEdit/OrderEditModal"
// import { useSelector } from "react-redux"

function ShopDetails() {
  // const { orderDetails } = useSelector(state => ({
  //   orderDetails: state.Orders.orderDetails,
  // }))

  // const [showModal, setShowModal] = useState({ status: "", data: "" })

  // const openModal = () => {
  //   setShowModal({ status: true, data: orderDetails })
  // }
  // const HandleCloseModal = () => {
  //   setShowModal({ status: false, data: "" })
  // }
  return (
    <>
      {/* <OrderEditModal
        onCloseClick={HandleCloseModal}
        show={showModal?.status}
        showModal={showModal}
      /> */}

      <MetaTag title="Order Details" />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Order"
            breadcrumbItem="Order Details"
            action={
              <>
                {/* <Link to="#" className="me-3" onClick={openModal}>
                  Edit Order
                </Link> */}
              </>
            }
          />
          <OrderDetail />
        </Container>
      </div>
    </>
  )
}

export default ShopDetails
