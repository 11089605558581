import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { AvField } from 'availity-reactstrap-validation'
import { Card, CardBody, CardTitle, Col, Label } from 'reactstrap'
import { FormGroup, Row } from 'reactstrap'
import { TabContent, TabPane } from 'reactstrap'
import Select from 'react-select'
import { debounce, map } from 'lodash'
import LanguageTab from 'components/Common/LanguageTab'
import UseImageUploader from 'components/imageUploader/useImageUploader'
import { getAllProducts, getStoreList } from 'store/actions'
import { LanguageSwitch } from 'hooks/LanguageSwitch'
import { useDispatch, useSelector } from 'react-redux'

function Form({ formOption, updateData }) {
  const {
    logoImagesPreview,
    setLogoImagesPreview,
    setBannerImagesPreview,
    bannerImagesPreview,
    selectedProductId,
    setSelectedProductId,
  } = formOption

  const dispatch = useDispatch()

  const isMultiStore = false
  const isMult = LanguageSwitch()?.isMult

  const { products, prodLoading, storeList } = useSelector(state => ({
    storeList: state.Stores.storeList,
    products: state.Products.products,
    prodLoading: state.Products.loading,
  }))

  //state
  const [productSelect, setproductSelect] = useState({
    searchText: '',
    id: '',
    title: 'Search a Product',
  })
  const [selectedProductArray, setSelectedProductArray] = useState([])
  const [activeTab, setActiveTab] = useState('1')

  //handlers
  const debounceProductSearch = debounce(value => setproductSelect(value), 600)
  const handleProductEnters = textEntered => {
    debounceProductSearch({
      ...productSelect,
      searchText: textEntered,
    })
  }

  function handlerProductFinalValue(event) {
    const product = {
      ...productSelect,
      title: event.label,
      id: event.value,
      image: event.image,
      varients: event.varients,
    }
    setproductSelect(product)
    setSelectedProductArray([...selectedProductArray, product])
    setSelectedProductId([...selectedProductId, event.value])
  }

  const productOptions = [
    {
      options: (products?.length >= 1 ? products : [])?.map(
        (result, index) => ({
          key: index,
          label: `${result?.primaryLang?.name} ${
            result?.varients?.length >= 1
              ? `(${result?.varients[0]?.value} ${result?.varients[0]?.unit})`
              : ''
          }`,
          value: result?._id,
          image: result?.images[0]?.url,
        }),
      ),
    },
  ]

  const handleRemoveSeletedproduct = id => {
    const filteredProd = selectedProductArray?.filter(i => i.id !== id)
    setSelectedProductArray(filteredProd)

    const filteredProdId = selectedProductId?.filter(i => i !== id)
    setSelectedProductId(filteredProdId)
  }

  //effects
  useEffect(() => {
    if (updateData) {
      const updatePordToSelectFormat = map(updateData?.products, prod => ({
        title: prod?.primaryLang?.name,
        image: prod?.images[0]?.url,
        id: prod?._id,
      }))

      setSelectedProductArray(updatePordToSelectFormat)
      const updateProdToIdsArray = map(updateData?.products, prod => prod?._id)
      setSelectedProductId(updateProdToIdsArray)
    }
  }, [updateData])

  useEffect(() => {
    dispatch(getAllProducts(1, 'all', '', productSelect?.searchText))
  }, [dispatch, productSelect?.searchText])

  useEffect(() => {
    dispatch(getStoreList())
  }, [])

  return (
    <>
      <Row>
        {isMultiStore === true && (
          <Col xl="12">
            <Card>
              <CardBody>
                <CardTitle className="h4 mb-4 text_deals_green">
                  Store
                </CardTitle>
                <Row>
                  <Col className="col-6 d-flex flex-wrap">
                    <div className="col-12 mb-3">
                      <AvField
                        type="text"
                        name="store"
                        errorMessage="Select a Store"
                        value={updateData?.store?.storeName}
                        readOnly
                        validate={{
                          required: { value: true },
                        }}
                        // onChange={e => {
                        //   setStore(e.target.value)
                        // }}
                      >
                        <option>Select a Store</option>
                        {storeList?.map((item, key) => (
                          <option key={key} value={item?._id}>
                            {item?.storeName}
                          </option>
                        ))}
                      </AvField>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        )}
        <Col xl="12">
          <Card>
            <CardBody>
              {isMult && (
                <LanguageTab
                  setActiveTab={setActiveTab}
                  activeTab={activeTab}
                />
              )}
              <TabContent activeTab={activeTab} className="">
                <TabPane tabId="1">
                  <Row>
                    <Col className="col-12 d-flex flex-wrap">
                      <Col className="col-12 d-flex flex-wrap">
                        <div className="col-12 mb-3">
                          <AvField
                            name="primaryLang.title"
                            label="Title"
                            type="text"
                            errorMessage="Invalid product name"
                            validate={{
                              required: { value: true },
                            }}
                            value={updateData?.primaryLang?.title ?? ''}
                          />
                        </div>
                        <div className="col-12 mb-3">
                          <AvField
                            name="primaryLang.description"
                            label="Description"
                            type="textarea"
                            errorMessage="Invalid description"
                            rows="7"
                            value={updateData?.primaryLang?.description ?? ''}
                          />
                        </div>
                      </Col>
                    </Col>
                  </Row>
                </TabPane>

                <TabPane tabId="2">
                  <Row>
                    <Col xl="12">
                      <Row>
                        <Col className="col-12 d-flex flex-wrap">
                          <Col className="col-12 d-flex flex-wrap">
                            <div className="col-12 mb-3">
                              <AvField
                                style={{ textAlign: 'right' }}
                                name="secondaryLang.title"
                                label="Title"
                                type="text"
                                value={updateData?.secondaryLang?.title ?? ''}
                              />
                            </div>
                            <div className="col-12 mb-3">
                              <AvField
                                style={{ textAlign: 'right' }}
                                name="secondaryLang.description"
                                label="Description"
                                type="textarea"
                                rows="7"
                                value={
                                  updateData?.secondaryLang?.description ?? ''
                                }
                              />
                            </div>
                          </Col>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>

        <Col xl="12">
          <Card>
            <CardBody>
              <Row>
                <Col xl="4" lg="4" className="mb-3">
                  <CardTitle className="h4 mb-4 text_deals_green">
                    Products
                  </CardTitle>
                  <FormGroup className="mb-3">
                    <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                      <Select
                        onInputChange={handleProductEnters}
                        value={productSelect?.title}
                        placeholder={productSelect?.title}
                        onChange={handlerProductFinalValue}
                        options={productOptions}
                        classNamePrefix="select2-selection"
                        isLoading={prodLoading}
                      />
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col xl="12">
          <Card>
            <CardBody>
              <CardTitle className="h4 mb-2 text_deals_green">
                Selected Products
              </CardTitle>
              {selectedProductArray?.length >= 1 && (
                <>
                  {map(selectedProductArray, (item, key) => (
                    <div key={key} className="mb-2">
                      <div className="d-flex align-items-center">
                        <img
                          className="img-thumbnail rounded-circle "
                          style={{ height: '60px', width: '60px' }}
                          src={item?.image}
                          alt="product"
                        />
                        <p className="pb-0 mb-0 px-3">{item.title}</p>
                        <i
                          className="bx bx-trash-alt "
                          style={{
                            fontSize: '1rem',
                            color: 'red',
                            cursor: 'pointer',
                          }}
                          onClick={() => handleRemoveSeletedproduct(item.id)}
                        ></i>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Card>
        <CardBody>
          <Row>
            <Col md="6">
              <Label className="mb-2">Logo</Label>
              <UseImageUploader
                imageSize={4}
                imagesPreview={logoImagesPreview}
                setImagesPreview={setLogoImagesPreview}
                uploadUrl={'/categories'}
              />
            </Col>
            <Col md="6">
              <Label className="mb-2">Banner</Label>
              <UseImageUploader
                imageSize={4}
                imagesPreview={bannerImagesPreview}
                setImagesPreview={setBannerImagesPreview}
                uploadUrl={'/categories'}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  )
}

export default Form

Form.propTypes = {
  formOption: PropTypes.object,
  updateData: PropTypes.object,
}
