import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { Card, CardBody, Col, Container, Label, Row, Media } from 'reactstrap'

//Maps
import Leaflet from 'leaflet'
import 'leaflet/dist/leaflet.css'

Leaflet.Icon.Default.imagePath = '../node_modules/leaflet'

delete Leaflet.Icon.Default.prototype._getIconUrl

Leaflet.Icon.Default.mergeOptions({
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
})

import { isEmpty } from 'lodash'

//Import Breadcrumb
import Breadcrumbs from 'components/Common/Breadcrumb'

//actions
import { getRevenueDetails } from 'store/revenues/actions'

//redux
import { useSelector, useDispatch } from 'react-redux'
import MetaTag from 'components/Common/Meta-tag'
// import ShopProductDetails from ".";

const RevenueDetails = () => {
  const dispatch = useDispatch()
  const params = useParams()

  const { revenueDetails } = useSelector(state => ({
    revenueDetails: state.Revenues.revenueDetails,
  }))

  useEffect(() => {
    dispatch(getRevenueDetails(params.id))
  }, [dispatch])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTag title={' Shop Revenue Details'} />

        <Container fluid>
          <Breadcrumbs title="Shop" breadcrumbItem="Shop Revenue Details" />
          {!isEmpty(revenueDetails) && (
            <>
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col lg="4">
                          <Media>
                            <Media className="align-self-center" body>
                              <div>
                                <Label>{revenueDetails?.shopName}</Label>
                                <p className="text-muted">
                                  {revenueDetails?.shopId}
                                </p>
                              </div>
                            </Media>
                          </Media>
                        </Col>

                        <Col lg="4" className="align-self-center">
                          <div className="text-lg-center mt-4 mt-lg-0">
                            <Row>
                              <Col xs="4">
                                <div>
                                  <p className="text-truncate mb-2">Orders</p>
                                  <p className="mb-0 text-muted">
                                    {revenueDetails?.totalOrders}
                                  </p>
                                </div>
                              </Col>
                              <Col xs="4">
                                <div>
                                  <p className="text-truncate mb-2">Returned</p>
                                  <p className="mb-0 text-muted">
                                    {revenueDetails?.totalReturnedProductsCount}
                                  </p>
                                </div>
                              </Col>
                              <Col xs="4">
                                <div>
                                  <p className="text-truncate mb-2">Pending</p>
                                  <p className="mb-0 text-muted">
                                    {revenueDetails?.totalPendingProducts}
                                  </p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col className="col-12 col-sm-12 col-xs-12 col-md-6 col-lg-3 col-xl-3">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex flex-wrap">
                        <div className="me-3">
                          <p className="text-muted mb-2">Total Ordered Items</p>
                          <h5 className="mb-0">
                            {revenueDetails?.totalOrderProducts}
                          </h5>
                        </div>

                        <div className="avatar-sm ms-auto">
                          <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                            <i className="bx bx-shopping-bag"></i>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>

                <Col className="col-12 col-sm-12 col-xs-12 col-md-6 col-lg-3 col-xl-3">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex flex-wrap">
                        <div className="me-3">
                          <p className="text-muted mb-2">Items Sold</p>
                          <h5 className="mb-0">
                            {revenueDetails?.totalProductItemsSold}
                          </h5>
                        </div>

                        <div className="avatar-sm ms-auto">
                          <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                            <i className="bx bx-money"></i>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>

                <Col className="col-12 col-sm-12 col-xs-12 col-md-6 col-lg-3 col-xl-3">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex flex-wrap">
                        <div className="me-3">
                          <p className="text-muted mb-2">Total Sold / Profit</p>
                          <h5 className="mb-0">
                            {revenueDetails?.totalProductsSoldQuantity} / (₹{' '}
                            {revenueDetails?.totalShopProfit})
                          </h5>
                        </div>

                        <div className="avatar-sm ms-auto">
                          <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                            <i className="fas fa-chart-line"></i>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>

                <Col className="col-12 col-sm-12 col-xs-12 col-md-6 col-lg-3 col-xl-3">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex flex-wrap">
                        <div className="me-3">
                          <p className="text-muted mb-2">Products Shipped</p>
                          <h5 className="mb-0">
                            {revenueDetails?.totalShippedProductsCount}
                          </h5>
                        </div>

                        <div className="avatar-sm ms-auto">
                          <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                            <i className="fas fa-shipping-fast"></i>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>

                <Col className="col-12 col-sm-12 col-xs-12 col-md-6 col-lg-3 col-xl-3">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex flex-wrap">
                        <div className="me-3">
                          <p className="text-muted mb-2">Order Value (₹)</p>
                          <h5 className="mb-0">
                            ₹ {revenueDetails?.totalOrderPrice}
                          </h5>
                        </div>

                        <div className="avatar-sm ms-auto">
                          <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                            <i className="fas fa-rupee-sign"></i>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>

                <Col className="col-12 col-sm-12 col-xs-12 col-md-6 col-lg-3 col-xl-3">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex flex-wrap">
                        <div className="me-3">
                          <p className="text-muted mb-2">Shipping Price (₹)</p>
                          <h5 className="mb-0">
                            ₹ {revenueDetails?.totalShippingPrice}
                          </h5>
                        </div>

                        <div className="avatar-sm ms-auto">
                          <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                            <i className="fas fa-rupee-sign"></i>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>

                <Col className="col-12 col-sm-12 col-xs-12 col-md-6 col-lg-3 col-xl-3">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex flex-wrap">
                        <div className="me-3">
                          <p className="text-muted mb-2">Item Value (₹)</p>
                          <h5 className="mb-0">
                            ₹ {revenueDetails?.totalItemsPrice}
                          </h5>
                        </div>

                        <div className="avatar-sm ms-auto">
                          <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                            <i className="fas fa-rupee-sign"></i>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>

                <Col className="col-12 col-sm-12 col-xs-12 col-md-6 col-lg-3 col-xl-3">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex flex-wrap">
                        <div className="me-3">
                          <p className="text-muted mb-2">
                            Zeston Price Value (₹)
                          </p>
                          <h5 className="mb-0">
                            ₹ {revenueDetails?.totalZestonPrice}
                          </h5>
                        </div>

                        <div className="avatar-sm ms-auto">
                          <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                            <i className="fas fa-rupee-sign"></i>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>

                <Col className="col-12 col-sm-12 col-xs-12 col-md-6 col-lg-3 col-xl-3">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex flex-wrap">
                        <div className="me-3">
                          <p className="text-muted mb-2">
                            Retail Price Value (₹)
                          </p>
                          <h5 className="mb-0">
                            ₹ {revenueDetails?.totalRetailPrice}
                          </h5>
                        </div>

                        <div className="avatar-sm ms-auto">
                          <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                            <i className="fas fa-rupee-sign"></i>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>

                <Col className="col-12 col-sm-12 col-xs-12 col-md-6 col-lg-3 col-xl-3">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex flex-wrap">
                        <div className="me-3">
                          <p className="text-muted mb-2">Products Delivered</p>
                          <h5 className="mb-0">
                            {revenueDetails?.totalDeliveredProductsCount}
                          </h5>
                        </div>

                        <div className="avatar-sm ms-auto">
                          <div className="avatar-title bg-light rounded-circle text-success font-size-20">
                            <i className="fas fa-shopping-basket"></i>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>

                <Col className="col-12 col-sm-12 col-xs-12 col-md-6 col-lg-3 col-xl-3">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex flex-wrap">
                        <div className="me-3">
                          <p className="text-muted mb-2">
                            Products Returned / Lose
                          </p>
                          <h5 className="mb-0">
                            {revenueDetails?.totalReturnedProductsCount} (₹{' '}
                            {revenueDetails?.totalReturnedProductsPrice}) / (₹{' '}
                            {
                              revenueDetails?.totalReturnedProductsProfitDecrement
                            }
                            )
                          </h5>
                        </div>

                        <div className="avatar-sm ms-auto">
                          <div className="avatar-title bg-light rounded-circle text-danger font-size-20">
                            <i className="fas fa-redo"></i>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>

                <Col className="col-12 col-sm-12 col-xs-12 col-md-6 col-lg-3 col-xl-3">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex flex-wrap">
                        <div className="me-3">
                          <p className="text-muted mb-2">
                            Products Cancelled / Lose
                          </p>
                          <h5 className="mb-0">
                            {revenueDetails?.totalCancelledProductsCount} (₹{' '}
                            {revenueDetails?.totalCancelledProductsPrice}) / (₹{' '}
                            {
                              revenueDetails?.totalCancelledProductsProfitDecrement
                            }
                            )
                          </h5>
                        </div>

                        <div className="avatar-sm ms-auto">
                          <div className="avatar-title bg-light rounded-circle text-danger font-size-20">
                            <i className="fas fa-stop"></i>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

RevenueDetails.propTypes = {
  revenue: PropTypes.object,
  match: PropTypes.any,
  onGetRevenueDetail: PropTypes.func,
}

export default RevenueDetails
