import { AvField, AvForm } from 'availity-reactstrap-validation'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CardTitle, Col, FormGroup, Modal, ModalBody, Row } from 'reactstrap'
import { createDeduction, updateDeduction } from 'store/actions'
import { useEffect } from 'react'
import Select from 'react-select'

import { debounce } from 'lodash'

const DeductionsModal = ({
  show,
  onCloseClick,
  updateData,
  setWithdrawalRequestSearch,
  setPromoterSearch,
}) => {
  const dispatch = useDispatch()
  const { allPromoters, promoterLoad, WithdrawalRequests } = useSelector(
    state => ({
      allPromoters: state?.Users?.promotersList,
      promoterLoad: state.Users?.promoterLoad,
      WithdrawalRequests: state.SupplyChain.withDrawalRequests,
    }),
  )
  const [promoterSelect, setPromoterSelect] = useState({
    title: 'Select Promoter',
    id: null,
  })
  // const [select, setSelect] = useState('')

  const [withdrawalRequestSelect, setWithdrawalRequestsSelect] = useState('')
  const [withdrawalRequestName, setWithdrawalRequestsName] = useState(
    'select WithdrawalRequest Id..',
  )
  const debounceChainSearch = debounce(
    value => setWithdrawalRequestSearch(value),
    600,
  )
  // console.log(withdrawalRequestSelect)
  const handleChainEnters = textEntered => {
    debounceChainSearch(textEntered)
  }

  function handlerChainFinalValue(event) {
    setWithdrawalRequestsName(event.label)
    setWithdrawalRequestsSelect(event.value)
  }

  const supplyChainsOptions = WithdrawalRequests?.withdrawalRequests?.map(
    (result, index) => {
      return {
        key: index,
        label: `${result?.requestId} - ${result?.status}`,
        value: result?._id,
      }
    },
  )
  useEffect(() => {
    if (updateData) {
      setWithdrawalRequestsName(
        updateData?.withdrawalRequest?.requestId ||
          'select WithdrawalRequest Id..',
      )
      setWithdrawalRequestsSelect(updateData?.withdrawalRequest?._id || null)
      setPromoterSelect({
        ...promoterSelect,
        title: updateData?.promoter?.phone
          ? updateData?.promoter?.phone
          : 'Select Promoter',
        id: updateData?.promoter?._id,
      })
    }
  }, [updateData])

  const debouncePromoterSearch = debounce(
    value => setPromoterSearch(value),
    600,
  )

  const handlePromoterEnters = textEntered => {
    debouncePromoterSearch(textEntered)
  }

  function handlerPromoterFinalValue(event) {
    const promoter = {
      ...promoterSelect,
      title: event.label,
      id: event.value,
    }
    setPromoterSelect(promoter)
  }

  const promoterOptions = [
    {
      label: 'All Promoters',
      id: '',
    },
    {
      options: (allPromoters?.total >= 1 ? allPromoters?.promoters : [])?.map(
        (result, index) => ({
          key: index,
          label: result?.name || result?.phone,
          value: result?._id,
        }),
      ),
    },
  ]
  const handleValidSubmit = (e, v) => {
    const Deduction = {
      deductionAmount: parseInt(v?.deductionAmount),
      deductionPercentage: parseInt(v?.deductionPercentage),
      name: v?.name,
      panNumber: v?.panNumber,
      phone: v?.phone,
      withrawalAmount: parseInt(v?.withrawalAmount),
      promoter: promoterSelect?.id,
      withdrawalRequest: withdrawalRequestSelect,
      deductionType: v?.deductionType,
    }
    if (updateData?._id) {
      dispatch(updateDeduction(Deduction, updateData?._id, onCloseClick))
    } else {
      dispatch(createDeduction(Deduction, onCloseClick))
    }
  }
  // const handleSelectChange = event => {
  //   const select = event.target.value
  //   setSelect(select)
  // }

  // useEffect(() => {
  //   setSelect(updateData?.deductionType)
  // }, [updateData])
  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      centered={true}
      style={deleteModalStyle}
    >
      <ModalBody style={deleteModalBodyStyle} className="p-4">
        <CardTitle>
          {updateData?._id ? 'Update' : 'Add New'} Deduction
        </CardTitle>
        <AvForm
          onValidSubmit={(e, v) => {
            handleValidSubmit(e, v)
          }}
        >
          <Row>
            <Col lg={6}>
              <FormGroup className="mb-3 w-100 me-3">
                <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                  <Select
                    onInputChange={handlePromoterEnters}
                    value={promoterSelect?.title}
                    placeholder={promoterSelect?.title}
                    onChange={handlerPromoterFinalValue}
                    options={promoterOptions}
                    classNamePrefix="select2-selection"
                    isLoading={promoterLoad}
                  />
                </div>
              </FormGroup>
            </Col>
            <Col lg={6}>
              <FormGroup className="mb-3">
                <div className="ajax-selectmt-lg-0 select2-container">
                  <Select
                    onInputChange={handleChainEnters}
                    value={withdrawalRequestSelect}
                    placeholder={withdrawalRequestName}
                    onChange={handlerChainFinalValue}
                    options={supplyChainsOptions}
                    classNamePrefix="select2-selection"
                    isLoading={promoterLoad}
                  />
                </div>
              </FormGroup>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col md="4" lg="6">
              {/* <label htmlFor="selectField">Deduction Type</label> */}
              <AvField
                id="selectField"
                name="deductionType"
                className="form-control"
                value={updateData?.deductionType}
                type="select"
              >
                <option value=""> Select deduction type</option>
                <option value="service"> SERVICE</option>
                <option value="tds">TDS</option>
              </AvField>
            </Col>
            <Col lg={6}>
              {/* <label htmlFor="selectField">Enter name</label> */}
              <AvField
                name="name"
                type="text"
                placeholder="Enter name"
                value={updateData?.name}
                className="mb-3"
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <AvField
                name="phone"
                type="number"
                placeholder="Enter Phone Number"
                value={updateData?.phone}
                className="mb-3"
              />
            </Col>
            <Col lg={6}>
              <AvField
                name="panNumber"
                type="text"
                placeholder="Enter Pan Number"
                className="mb-3"
                value={updateData?.panNumber}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <AvField
                name="withrawalAmount"
                type="number"
                placeholder="Enter Withrawal Amount"
                className="mb-3"
                value={updateData?.withrawalAmount}
              />
            </Col>
            <Col lg={6}>
              <AvField
                name="deductionPercentage"
                type="number"
                placeholder="Enter Deduction Percentage"
                className="mb-3"
                value={updateData?.deductionPercentage}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <AvField
                name="deductionAmount"
                type="number"
                placeholder="Enter Deduction Amount"
                className="mb-3"
                value={updateData?.deductionAmount}
              />
            </Col>
          </Row>

          {/* <div className="d-flex justify-content-between mt-3 align-items-center mx-auto"> */}
          <div className="text-center mt-3 d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-danger ms-2"
              onClick={onCloseClick}
            >
              Cancel
            </button>
            <button type="submit" className="btn btn-success ms-2">
              {updateData ? 'Update' : 'Create'}
            </button>
          </div>
          {/* </div> */}
        </AvForm>
      </ModalBody>
    </Modal>
  )
}

DeductionsModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
  updateData: PropTypes.any,
  setWithdrawalRequestSearch: PropTypes.any,
  setPromoterSearch: PropTypes.any,
}

export default DeductionsModal

const deleteModalStyle = {
  width: 700,
  minWidth: 700,
}
const deleteModalBodyStyle = {
  minHeight: 450,
}
