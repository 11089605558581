import {
  GET_DEDUCTIONS,
  GET_DEDUCTIONS_SUCCESS,
  GET_DEDUCTIONS_FAIL,
  GET_DEDUCTION_DETAILS,
  GET_DEDUCTION_DETAILS_SUCCESS,
  GET_DEDUCTION_DETAILS_FAIL,
  CREATE_DEDUCTION,
  CREATE_DEDUCTION_SUCCESS,
  CREATE_DEDUCTION_FAIL,
  UPDATE_DEDUCTION,
  UPDATE_DEDUCTION_SUCCESS,
  UPDATE_DEDUCTION_FAIL,
  DELETE_DEDUCTION,
  DELETE_DEDUCTION_SUCCESS,
  DELETE_DEDUCTION_FAIL,
  DOWNLOAD_DEDUCTIONS,
  DOWNLOAD_DEDUCTIONS_FAIL,
  DOWNLOAD_DEDUCTIONS_SUCCESS,
} from './actionTypes'

const INIT_STATE = {
  deductions: {
    deductions: [],
    total: '',
    page: '',
  },
  downloadDeductionList: {},
  deductionDetails: {},
  error: {},
  loading: false,
}

const Deduction = (state = INIT_STATE, action) => {
  switch (action.type) {
    // Deduction - This line cannot be edited or removed
    case GET_DEDUCTIONS:
    case GET_DEDUCTION_DETAILS:
    case CREATE_DEDUCTION:
    case UPDATE_DEDUCTION:
    case DELETE_DEDUCTION:
    case DOWNLOAD_DEDUCTIONS:
      return {
        ...state,
        loading: true,
      }

    case DELETE_DEDUCTION_FAIL:
    case GET_DEDUCTIONS_FAIL:
    case GET_DEDUCTION_DETAILS_FAIL:
    case UPDATE_DEDUCTION_FAIL:
    case CREATE_DEDUCTION_FAIL:
    case DOWNLOAD_DEDUCTIONS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_DEDUCTIONS_SUCCESS:
      return {
        ...state,
        deductions: action.payload,
        error: {},
        loading: false,
      }

    case GET_DEDUCTION_DETAILS_SUCCESS:
      return {
        ...state,
        deductionDetails: action.payload,
        error: {},
        loading: false,
      }

    case CREATE_DEDUCTION_SUCCESS:
      return {
        ...state,
        deductions: {
          ...state.deductions,
          deductions: [...state.deductions.deductions, action.payload],
          total: state.deductions.total + 1,
        },
        deductionDetails: action.payload,
        error: {},
        loading: false,
      }

    case UPDATE_DEDUCTION_SUCCESS:
      return {
        ...state,
        deductions: {
          ...state.deductions,
          deductions: state.deductions.deductions.map(deduction =>
            deduction._id === action.payload._id
              ? { ...deduction, ...action.payload }
              : deduction,
          ),
        },
        deductionDetails: action.payload,
        loading: false,
        error: {},
      }

    case DELETE_DEDUCTION_SUCCESS:
      return {
        ...state,
        deductions: {
          ...state.deductions,
          deductions: state.deductions.deductions.filter(
            deduction => deduction._id !== action.payload._id,
          ),
        },
        error: {},
        loading: false,
      }
    case DOWNLOAD_DEDUCTIONS_SUCCESS:
      return {
        ...state,
        downloadDeductionList: action.payload,
        error: {},
        loading: false,
      }
    default:
      return state
  }
}

export default Deduction
