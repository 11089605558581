import {
  GET_COURSE_PROVIDERS,
  GET_COURSE_PROVIDERS_SUCCESS,
  GET_COURSE_PROVIDERS_FAIL,
  GET_COURSE_PROVIDER_DETAILS,
  GET_COURSE_PROVIDER_DETAILS_SUCCESS,
  GET_COURSE_PROVIDER_DETAILS_FAIL,
  CREATE_COURSE_PROVIDER,
  CREATE_COURSE_PROVIDER_SUCCESS,
  CREATE_COURSE_PROVIDER_FAIL,
  UPDATE_COURSE_PROVIDER,
  UPDATE_COURSE_PROVIDER_SUCCESS,
  UPDATE_COURSE_PROVIDER_FAIL,
  DELETE_COURSE_PROVIDER,
  DELETE_COURSE_PROVIDER_SUCCESS,
  DELETE_COURSE_PROVIDER_FAIL,
  GET_COURSE_PROVIDER_PROFILE,
  GET_COURSE_PROVIDER_PROFILE_FAIL,
  UPDATE_COURSE_PROVIDER_PROFILE,
  UPDATE_COURSE_PROVIDER_PROFILE_FAIL,
  GET_COURSE_PROVIDER_PROFILE_SUCCESS,
  UPDATE_COURSE_PROVIDER_PROFILE_SUCCESS,
} from './actionTypes'

const INIT_STATE = {
  courseProviders: {
    courseProviders: [],
    total: '',
    page: '',
  },
  courseProviderDetails: {},
  courseProviderProfile: {},
  error: {},
  loading: false,
}

const CourseProvider = (state = INIT_STATE, action) => {
  switch (action.type) {
    // CourseProvider - This line cannot be edited or removed
    case GET_COURSE_PROVIDERS:
    case GET_COURSE_PROVIDER_DETAILS:
    case CREATE_COURSE_PROVIDER:
    case UPDATE_COURSE_PROVIDER:
    case DELETE_COURSE_PROVIDER:
      return {
        ...state,
        loading: true,
      }

    case DELETE_COURSE_PROVIDER_FAIL:
    case GET_COURSE_PROVIDERS_FAIL:
    case GET_COURSE_PROVIDER_DETAILS_FAIL:
    case UPDATE_COURSE_PROVIDER_FAIL:
    case CREATE_COURSE_PROVIDER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_COURSE_PROVIDERS_SUCCESS:
      return {
        ...state,
        courseProviders: action.payload,
        error: {},
        loading: false,
      }

    case GET_COURSE_PROVIDER_DETAILS_SUCCESS:
      return {
        ...state,
        courseProviderDetails: action.payload,
        error: {},
        loading: false,
      }

    case CREATE_COURSE_PROVIDER_SUCCESS:
      return {
        ...state,
        courseProviders: {
          ...state.courseProviders,
          courseProviders: [
            ...state.courseProviders.courseProviders,
            action.payload,
          ],
          total: state.courseProviders.total + 1,
        },
        courseProviderDetails: action.payload,
        error: {},
        loading: false,
      }

    case UPDATE_COURSE_PROVIDER_SUCCESS:
      return {
        ...state,
        courseProviders: {
          ...state.courseProviders,
          courseProviders: state.courseProviders.courseProviders.map(
            courseprovider =>
              courseprovider._id === action.payload._id
                ? { ...courseprovider, ...action.payload }
                : courseprovider,
          ),
        },
        courseProviderDetails: action.payload,
        loading: false,
        error: {},
      }

    case DELETE_COURSE_PROVIDER_SUCCESS:
      return {
        ...state,
        courseProviders: {
          ...state.courseProviders,
          courseProviders: state.courseProviders.courseProviders.filter(
            courseprovider => courseprovider._id !== action.payload._id,
          ),
        },
        error: {},
        loading: false,
      }

    // CourseProviderProfile - This line cannot be edited or removed
    case GET_COURSE_PROVIDER_PROFILE:
    case UPDATE_COURSE_PROVIDER_PROFILE:
      return {
        ...state,
        loading: true,
      }

    case GET_COURSE_PROVIDER_PROFILE_FAIL:
    case UPDATE_COURSE_PROVIDER_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_COURSE_PROVIDER_PROFILE_SUCCESS:
      return {
        ...state,
        courseProviderProfile: action.payload,
        error: {},
        loading: false,
      }

    case UPDATE_COURSE_PROVIDER_PROFILE_SUCCESS:
      return {
        ...state,
        // courseproviderprofiles: {
        //   ...state.courseproviderprofiles,
        //   courseproviderprofiles: state.courseproviderprofiles.courseproviderprofiles.map((courseproviderprofile) =>
        //     courseproviderprofile._id === action.payload._id
        //       ? { ...courseproviderprofile, ...action.payload }
        //       : courseproviderprofile
        //   ),
        // },
        courseProviderProfile: action.payload,
        loading: false,
        error: {},
      }

    default:
      return state
  }
}

export default CourseProvider
