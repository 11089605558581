/*all collections */
export const GET_COLLECTIONS = 'GET_COLLECTIONS'
export const GET_COLLECTIONS_SUCCESS = 'GET_COLLECTIONS_SUCCESS'
export const GET_COLLECTIONS_FAIL = 'GET_COLLECTIONS_FAIL'

/*all collections details*/
export const GET_COLLECTION_DETAILS = 'GET_COLLECTION_DETAILS'
export const GET_COLLECTION_DETAILS_SUCCESS = 'GET_COLLECTION_DETAILS_SUCCESS'
export const GET_COLLECTION_DETAILS_FAIL = 'GET_COLLECTION_DETAILS_FAIL'

/* create collections */
export const CREATE_COLLECTION = 'CREATE_COLLECTION'
export const CREATE_COLLECTION_SUCCESS = 'CREATE_COLLECTION_SUCCESS'
export const CREATE_COLLECTION_FAIL = 'CREATE_COLLECTION_FAIL'

/* update collections */
export const UPDATE_COLLECTION = 'UPDATE_COLLECTION'
export const UPDATE_COLLECTION_SUCCESS = 'UPDATE_COLLECTION_SUCCESS'
export const UPDATE_COLLECTION_FAIL = 'UPDATE_COLLECTION_FAIL'

/* Delete collections */
export const DELETE_COLLECTION = 'DELETE_COLLECTION'
export const DELETE_COLLECTION_SUCCESS = 'DELETE_COLLECTION_SUCCESS'
export const DELETE_COLLECTION_FAIL = 'DELETE_COLLECTION_FAIL'
