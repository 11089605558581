import { AvField, AvForm } from 'availity-reactstrap-validation'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { CardTitle, Col, Modal, ModalBody, Row } from 'reactstrap'
import { createBlog, updateBlog } from 'store/actions'
import FormEditors from 'pages/Settings/SingleView/Editor'
import { useEffect } from 'react'

const BlogModal = ({ show, onCloseClick, updateData }) => {
  const dispatch = useDispatch()

  const [blogContent, setBlogContent] = useState('')

  const handleValidSubmit = (e, v) => {
    const blog = {
      ...v,
      title: v.title,
      description: v.description,
      content: blogContent,
    }
    if (updateData?._id) {
      dispatch(updateBlog(blog, updateData?._id, onCloseClick))
    } else {
      dispatch(createBlog(blog, onCloseClick))
    }
  }

  useEffect(() => {
    if (updateData?._id) {
      setBlogContent(updateData?.content)
    } else {
      setBlogContent('')
    }
  }, [updateData])

  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      centered={true}
      style={deleteModalStyle}
    >
      <ModalBody style={deleteModalBodyStyle} className="p-4">
        <CardTitle>{updateData?._id ? 'Update' : 'Add New'} Blog</CardTitle>
        <AvForm
          onValidSubmit={(e, v) => {
            handleValidSubmit(e, v)
          }}
        >
          <Row>
            <Col lg={12}>
              <AvField
                name="title"
                type="text"
                placeholder="Enter Title"
                value={updateData?.title}
                className="mb-3"
              />
            </Col>
            <Col lg={12}>
              <AvField
                name="description"
                type="textarea"
                placeholder="Enter Description"
                style={{
                  height: 80,
                }}
                className="mb-3"
                value={updateData?.description}
              />
            </Col>
            <span className="mb-1">Content</span>
            <FormEditors content={blogContent} setContent={setBlogContent} />
          </Row>
          <div className="d-flex justify-content-between mt-3 align-items-center">
            <div className="d-flex">
              <div className="form-check form-switch form-switch-md">
                <AvField
                  name="isPublished"
                  label="Publish"
                  className="form-check-input"
                  type="checkbox"
                  id="isPublishedCheckbox"
                  checked={updateData?.isPublished}
                  value={updateData?.isPublished}
                />
              </div>
              <div className="form-check form-switch form-switch-md">
                <AvField
                  name="isHighlighted"
                  label="Highlighted"
                  className="form-check-input"
                  type="checkbox"
                  id="isHighlightedCheckbox"
                  checked={updateData?.isHighlighted}
                  value={updateData?.isHighlighted}
                />
              </div>
            </div>
            <div className="text-center">
              <button
                type="button"
                className="btn btn-danger ms-2"
                onClick={onCloseClick}
              >
                Cancel
              </button>
              <button type="submit" className="btn btn-success ms-2">
                Save
              </button>
            </div>
          </div>
        </AvForm>
      </ModalBody>
    </Modal>
  )
}

BlogModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
  updateData: PropTypes.any,
}

export default BlogModal

const deleteModalStyle = {
  width: 700,
  minWidth: 700,
}
const deleteModalBodyStyle = {
  minHeight: 500,
}
