/* SupplyChain*/
export const CREATE_SUPPLY_CHAIN = 'CREATE_SUPPLY_CHAIN'
export const CREATE_SUPPLY_CHAIN_SUCCESS = 'CREATE_SUPPLY_CHAIN_SUCCESS'
export const CREATE_SUPPLY_CHAIN_FAIL = 'CREATE_SUPPLY_CHAIN_FAIL'

/* ALL supply chain */
export const GET_ALL_SUPPLY_CHAINS = 'GET_ALL_SUPPLY_CHAINS'
export const GET_ALL_SUPPLY_CHAINS_SUCCESS = 'GET_ALL_SUPPLY_CHAINS_SUCCESS'
export const GET_ALL_SUPPLY_CHAINS_FAIL = 'GET_ALL_SUPPLY_CHAINS_FAIL'

/* Update supply chain*/
export const UPDATE_SUPPLY_CHAIN = 'UPDATE_SUPPLY_CHAIN'
export const UPDATE_SUPPLY_CHAIN_SUCCESS = 'UPDATE_SUPPLY_CHAIN_SUCCESS'
export const UPDATE_SUPPLY_CHAIN_FAIL = 'UPDATE_SUPPLY_CHAIN_FAIL'

/* delete supply chain */
export const DELETE_SUPPLY_CHAIN = 'DELETE_SUPPLY_CHAIN'
export const DELETE_SUPPLY_CHAIN_SUCCESS = 'DELETE_SUPPLY_CHAIN_SUCCESS'
export const DELETE_SUPPLY_CHAIN_FAIL = 'DELETE_SUPPLY_CHAIN_FAIL'

// singleview supply chain
export const GET_SUPPLY_CHAINS_DETAILS = 'GET_SUPPLY_CHAINS_DETAILS'
export const GET_SUPPLY_CHAINS_DETAILS_SUCCESS =
  'GET_SUPPLY_CHAINS_DETAILS_SUCCESS'
export const GET_SUPPLY_CHAINS_DETAILS_FAIL = 'GET_SUPPLY_CHAINS_DETAILS_FAIL'

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/* ALL list chain promoter */
export const GET_ALL_CHAIN_PROMOTERS = 'GET_ALL_CHAIN_PROMOTERS'
export const GET_ALL_CHAIN_PROMOTERS_SUCCESS = 'GET_ALL_CHAIN_PROMOTERS_SUCCESS'
export const GET_ALL_CHAIN_PROMOTERS_FAIL = 'GET_ALL_CHAIN_PROMOTERS_FAIL'

// chain promoter single view
export const GET_CHAIN_PROMOTER_DETAILS = 'GET_CHAIN_PROMOTER_DETAILS'
export const GET_CHAIN_PROMOTER_DETAILS_SUCCESS =
  'GET_CHAIN_PROMOTER_DETAILS_SUCCESS'
export const GET_CHAIN_PROMOTER_DETAILS_FAIL = 'GET_CHAIN_PROMOTER_DETAILS_FAIL'
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// ALL list supply chain transitions
export const GET_ALL_SUPPLY_CHAINS_TRANSACTION =
  'GET_ALL_SUPPLY_CHAINS_TRANSACTION'
export const GET_ALL_SUPPLY_CHAINS_TRANSACTION_SUCCESS =
  'GET_ALL_SUPPLY_CHAINS_TRANSACTION_SUCCESS'
export const GET_ALL_SUPPLY_CHAINS_TRANSACTION_FAIL =
  'GET_ALL_SUPPLY_CHAINS_TRANSACTION_FAIL'
//////////////////////////////////////// a/////////////////////////////////////////////////////////////////////////////////////////////////////////////

// ALL list wallet
export const GET_ALL_WALLETS = 'GET_ALL_WALLETS'
export const GET_ALL_WALLETS_SUCCESS = 'GET_ALL_WALLETS_SUCCESS'
export const GET_ALL_WALLETS_FAIL = 'GET_ALL_WALLETS_FAIL'

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/* create luckyDraws*/
export const CREATE_LUCKY_DRAW = 'CREATE_LUCKY_DRAW'
export const CREATE_LUCKY_DRAW_SUCCESS = 'CREATE_LUCKY_DRAW_SUCCESS'
export const CREATE_LUCKY_DRAW_FAIL = 'CREATE_LUCKY_DRAW_FAIL'

/* ALL luckyDraws */
export const GET_ALL_LUCKY_DRAWS = 'GET_ALL_LUCKY_DRAWS'
export const GET_ALL_LUCKY_DRAWS_SUCCESS = 'GET_ALL_LUCKY_DRAWS_SUCCESS'
export const GET_ALL_LUCKY_DRAWS_FAIL = 'GET_ALL_LUCKY_DRAWS_FAIL'

/* Update luckyDraws*/
export const UPDATE_LUCKY_DRAW = 'UPDATE_LUCKY_DRAW'
export const UPDATE_LUCKY_DRAW_SUCCESS = 'UPDATE_LUCKY_DRAW_SUCCESS'
export const UPDATE_LUCKY_DRAW_FAIL = 'UPDATE_LUCKY_DRAW_FAIL'

/* delete luckyDraws */
export const DELETE_LUCKY_DRAW = 'DELETE_LUCKY_DRAW'
export const DELETE_LUCKY_DRAW_SUCCESS = 'DELETE_LUCKY_DRAW_SUCCESS'
export const DELETE_LUCKY_DRAW_FAIL = 'DELETE_LUCKY_DRAW_FAIL'

// singleview luckyDraws
export const GET_LUCKY_DRAW_DETAILS = 'GET_LUCKY_DRAW_DETAILS'
export const GET_LUCKY_DRAW_DETAILS_SUCCESS = 'GET_LUCKY_DRAW_DETAILS_SUCCESS'
export const GET_LUCKY_DRAW_DETAILS_FAIL = 'GET_LUCKY_DRAW_DETAILS_FAIL'

//promotertransaction
export const GET_PROMOTER_TRANSACTION = 'GET_PROMOTER_TRANSACTION'
export const GET_PROMOTER_TRANSACTION_SUCCESS =
  'GET_PROMOTER_TRANSACTION_SUCCESS'
export const GET_PROMOTER_TRANSACTION_FAIL = 'GET_PROMOTER_TRANSACTION_FAIL'
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/* create withdrawalRequest*/
export const CREATE_WITHDRAWAL_REQUEST = 'CREATE_WITHDRAWAL_REQUEST'
export const CREATE_WITHDRAWAL_REQUEST_SUCCESS =
  'CREATE_WITHDRAWAL_REQUEST_SUCCESS'
export const CREATE_WITHDRAWAL_REQUEST_FAIL = 'CREATE_WITHDRAWAL_REQUEST_FAIL'

/* ALL withdrawalRequest */
export const GET_ALL_WITHDRAWAL_REQUESTS = 'GET_ALL_WITHDRAWAL_REQUESTS'
export const GET_ALL_WITHDRAWAL_REQUESTS_SUCCESS =
  'GET_ALL_WITHDRAWAL_REQUESTS_SUCCESS'
export const GET_ALL_WITHDRAWAL_REQUESTS_FAIL =
  'GET_ALL_WITHDRAWAL_REQUESTS_FAIL'

/* Update withdrawalRequest*/
export const UPDATE_WITHDRAWAL_REQUEST = 'UPDATE_WITHDRAWAL_REQUEST'
export const UPDATE_WITHDRAWAL_REQUEST_SUCCESS =
  'UPDATE_WITHDRAWAL_REQUEST_SUCCESS'
export const UPDATE_WITHDRAWAL_REQUEST_FAIL = 'UPDATE_WITHDRAWAL_REQUEST_FAIL'

//  update status
export const APPROVE_WITHDRAWAL_REQUEST = 'APPROVE_WITHDRAWAL_REQUEST'
export const APPROVE_WITHDRAWAL_REQUEST_SUCCESS =
  'APPROVE_WITHDRAWAL_REQUEST_SUCCESS'
export const APPROVE_WITHDRAWAL_REQUEST_FAIL = 'APPROVE_WITHDRAWAL_REQUEST_FAIL'

//dashboard data
export const GET_PROMOTER_SUMMERY = 'GET_PROMOTER_SUMMERY'
export const GET_PROMOTER_SUMMERY_SUCCESS = 'GET_PROMOTER_SUMMERY_SUCCESS'
export const GET_PROMOTER_SUMMERY_FAIL = 'GET_PROMOTER_SUMMERY_FAIL'

//Initiate Lucky Draw
export const GET_INITIATE_LUCKY_DRAW = 'GET_INITIATE_LUCKY_DRAW'
export const GET_INITIATE_LUCKY_DRAW_SUCCESS = 'GET_INITIATE_LUCKY_DRAW_SUCCESS'
export const GET_INITIATE_LUCKY_DRAW_FAIL = 'GET_INITIATE_LUCKY_DRAW_FAIL'

//supply Chain by Product
export const SUPPLY_CHAIN_BY_PRODUCT = 'SUPPLY_CHAIN_BY_PRODUCT'
export const SUPPLY_CHAIN_BY_PRODUCT_SUCCESS = 'SUPPLY_CHAIN_BY_PRODUCT_SUCCESS'
export const SUPPLY_CHAIN_BY_PRODUCT_FAIL = 'SUPPLY_CHAIN_BY_PRODUCT_FAIL'

//winner approve
export const WINNER_APPROVE = 'WINNER_APPROVE'
export const WINNER_APPROVE_SUCCESS = 'WINNER_APPROVE_SUCCESS'
export const WINNER_APPROVE_FAIL = 'WINNER_APPROVE_FAIL'
//Lucky draw won
export const LUCKY_DRAW_WON = 'LUCKY_DRAW_WON'
export const LUCKY_DRAW_WON_SUCCESS = 'LUCKY_DRAW_WON_SUCCESS'
export const LUCKY_DRAW_WON_FAIL = 'LUCKY_DRAW_WON_FAIL'

export const LUCKY_DRAW_WINNINGS = 'LUCKY_DRAW_WINNINGS'
export const LUCKY_DRAW_WINNINGS_SUCCESS = 'LUCKY_DRAW_WINNINGS_SUCCESS'
export const LUCKY_DRAW_WINNINGS_FAIL = 'LUCKY_DRAW_WINNINGS_FAIL'

// localhost:4000/withdrawal-request/admin/download/all?status=pending
export const DOWNLOAD_WITHDRAWAL_REQUEST = 'DOWNLOAD_WITHDRAWAL_REQUEST'
export const DOWNLOAD_WITHDRAWAL_REQUEST_SUCCESS =
  'DOWNLOAD_WITHDRAWAL_REQUEST_SUCCESS'
export const DOWNLOAD_WITHDRAWAL_REQUEST_FAIL =
  'DOWNLOAD_WITHDRAWAL_REQUEST_FAIL'

// user/generate/order/:order
export const GENERATE_SUPPLY_CHAIN = 'GENERATE_SUPPLY_CHAIN'
export const GENERATE_SUPPLY_CHAIN_SUCCESS = 'GENERATE_SUPPLY_CHAIN_SUCCESS'
export const GENERATE_SUPPLY_CHAIN_FAIL = 'GENERATE_SUPPLY_CHAIN_FAIL'

// visualize/tree
export const VISUALIZE_CHAIN_TREE = 'VISUALIZE_CHAIN_TREE'
export const VISUALIZE_CHAIN_TREE_SUCCESS = 'VISUALIZE_CHAIN_TREE_SUCCESS'
export const VISUALIZE_CHAIN_TREE_FAIL = 'VISUALIZE_CHAIN_TREE_FAIL'

// sync/tree
export const SYNC_CHAIN_TREE = 'SYNC_CHAIN_TREE'
export const SYNC_CHAIN_TREE_SUCCESS = 'SYNC_CHAIN_TREE_SUCCESS'
export const SYNC_CHAIN_TREE_FAIL = 'SYNC_CHAIN_TREE_FAIL'

/* BulkRequests - This line cannot be edited or removed */
export const UPDATE_BULKREQUEST = 'UPDATE_BULKREQUEST'
export const UPDATE_BULKREQUEST_SUCCESS = 'UPDATE_BULKREQUEST_SUCCESS'
export const UPDATE_BULKREQUEST_FAIL = 'UPDATE_BULKREQUEST_FAIL'
