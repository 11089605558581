import { useHistory } from 'react-router'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, CardBody, CardTitle } from 'reactstrap'
import { Col, Container, Row } from 'reactstrap'
import { AvForm } from 'availity-reactstrap-validation'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import MetaTag from 'components/Common/Meta-tag'
import Form from './Form'

//actions
import { createCollection, getStoreList } from 'store/actions'

const CreateCollections = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const { loading } = useSelector(state => ({
    loading: state.Collection.loading,
  }))

  const [logoImagesPreview, setLogoImagesPreview] = useState([])
  const [bannerImagesPreview, setBannerImagesPreview] = useState([])
  const [selectedProductId, setSelectedProductId] = useState([])

  useEffect(() => {
    dispatch(getStoreList())
  }, [])

  function handleValidSubmit(values) {
    const createCollections = {
      // store: store,
      primaryLang: values.primaryLang,
      secondaryLang: values.secondaryLang,
      priority: parseInt(values.priority),
      logoImages: logoImagesPreview,
      bannerImages: bannerImagesPreview,
      products: selectedProductId,
    }
    dispatch(createCollection(createCollections, history))
  }

  const formOption = {
    logoImagesPreview,
    setLogoImagesPreview,
    bannerImagesPreview,
    setBannerImagesPreview,
    selectedProductId,
    setSelectedProductId,
  }

  return (
    <>
      <MetaTag title="Create Collection" />

      <div className="page-content">
        <Breadcrumbs title="Collections" breadcrumbItem="Create Collection" />
        <Container fluid>
          <div className="container-fluid">
            <AvForm
              onValidSubmit={(e, v) => {
                handleValidSubmit(v)
              }}
            >
              <Form formOption={formOption} />

              <Row xl="12">
                <Col xl="12">
                  <Card>
                    <CardBody>
                      <CardTitle className="h4 mb-4"></CardTitle>
                      <Row>
                        <Col>
                          <div className="text-end">
                            <button
                              type="submit"
                              className="col-12 btn btn-success save-customer"
                            >
                              {loading && (
                                <>
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                </>
                              )}
                              Create Collection
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </AvForm>
          </div>
        </Container>
      </div>
    </>
  )
}

export default CreateCollections
