import {
  GET_USERS,
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  GET_RAW_USERS,
  GET_RAW_USERS_SUCCESS,
  GET_RAW_USERS_FAIL,
  GET_USER_DETAILS,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_FAIL,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  GET_USER_CARTS,
  GET_USER_CARTS_SUCCESS,
  GET_USER_CARTS_FAIL,
  GET_USER_CART_DETAILS,
  GET_USER_CART_DETAILS_SUCCESS,
  GET_USER_CART_DETAILS_FAIL,
  DELETE_USER_CART,
  DELETE_USER_CART_SUCCESS,
  DELETE_USER_CART_FAIL,
  GET_USER_FAVOURITE_DETAILS,
  GET_USER_FAVOURITE_DETAILS_SUCCESS,
  GET_USER_FAVOURITE_DETAILS_FAIL,
  GET_USER_RECENT_VIEWS,
  GET_USER_RECENT_VIEWS_SUCCESS,
  GET_USER_RECENT_VIEWS_FAIL,
  DELETE_USER_FAVOURITE,
  DELETE_USER_FAVOURITE_SUCCESS,
  DELETE_USER_FAVOURITE_FAIL,
  GET_USER_ADDRESS,
  GET_USER_ADDRESS_SUCCESS,
  GET_USER_ADDRESS_FAIL,
  ADD_USER_ADDRESS,
  ADD_USER_ADDRESS_SUCCESS,
  ADD_USER_ADDRESS_FAIL,
  DELETE_USER_ADDRESS,
  DELETE_USER_ADDRESS_SUCCESS,
  DELETE_USER_ADDRESS_FAIL,
  ADD_ITEM_TO_USER_CART,
  ADD_ITEM_TO_USER_CART_SUCCESS,
  ADD_ITEM_TO_USER_CART_FAIL,
  ADD_COUPON_TO_USER_CART,
  ADD_COUPON_TO_USER_CART_SUCCESS,
  ADD_COUPON_TO_USER_CART_FAIL,
  GET_PROMOTERS,
  GET_PROMOTERS_SUCCESS,
  GET_PROMOTERS_FAIL,
  GET_PROMOTER_DETAILS,
  GET_PROMOTER_DETAILS_SUCCESS,
  GET_PROMOTER_DETAILS_FAIL,
  CREATE_PROMOTER,
  CREATE_PROMOTER_SUCCESS,
  CREATE_PROMOTER_FAIL,
  UPDATE_PROMOTER,
  UPDATE_PROMOTER_SUCCESS,
  UPDATE_PROMOTER_FAIL,
  DELETE_PROMOTER,
  DELETE_PROMOTER_SUCCESS,
  DELETE_PROMOTER_FAIL,
  CHECK_PROMOTERID,
  CHECK_PROMOTERID_SUCCESS,
  CHECK_PROMOTERID_FAIL,
  GET_PAGE_VIEW_DETAILS,
  GET_PAGE_VIEW_DETAILS_SUCCESS,
  GET_PAGE_VIEW_DETAILS_FAIL,
  GET_EARINIG_DETAILS,
  GET_EARINIG_DETAILS_SUCCESS,
  GET_EARINIG_DETAILS_FAIL,
  GET_PAGE_VIEW,
  GET_PAGE_VIEW_SUCCESS,
  GET_PAGE_VIEW_FAIL,
  GET_EARNING,
  GET_EARNING_SUCCESS,
  GET_EARNING_FAIL,
  GET_ALL_FORMATTED_EARNINGS,
  GET_ALL_FORMATTED_EARNINGS_SUCCESS,
  GET_ALL_FORMATTED_EARNINGS_FAIL,
  CREATE_USER,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAIL,
  CLEAR_USER_DATA,
} from './actionTypes'

//all users
export const getUsers = (page, sort, limit, searchText) => ({
  type: GET_USERS,
  payload: { page, sort, limit, searchText },
})

export const getUsersSuccess = users => ({
  type: GET_USERS_SUCCESS,
  payload: users,
})

export const getUsersFail = error => ({
  type: GET_USERS_FAIL,
  payload: error,
})

//all raw users
export const getRawUsers = searchText => ({
  type: GET_RAW_USERS,
  payload: { searchText },
})

export const getRawUsersSuccess = rawUsers => ({
  type: GET_RAW_USERS_SUCCESS,
  payload: rawUsers,
})

export const getRawUsersFail = error => ({
  type: GET_RAW_USERS_FAIL,
  payload: error,
})

//user details
export const getUserDetails = userId => ({
  type: GET_USER_DETAILS,
  payload: userId,
})

export const getUserDetailsSuccess = userDetails => ({
  type: GET_USER_DETAILS_SUCCESS,
  payload: userDetails,
})

export const getUserDetailsFail = error => ({
  type: GET_USER_DETAILS_FAIL,
  payload: error,
})

//CREATE user
export const createUser = (user, closeModal) => ({
  type: CREATE_USER,
  payload: { user, closeModal },
})

export const createUserSuccess = user => ({
  type: CREATE_USER_SUCCESS,
  payload: user,
})
export const clearUserData = () => ({
  type: CLEAR_USER_DATA,
})

export const createUserFail = error => ({
  type: CREATE_USER_FAIL,
  payload: error,
})
//update user
export const updateUser = (user, userId, history) => ({
  type: UPDATE_USER,
  payload: { user, userId, history },
})

export const updateUserSuccess = user => ({
  type: UPDATE_USER_SUCCESS,
  payload: user,
})

export const updateUserFail = error => ({
  type: UPDATE_USER_FAIL,
  payload: error,
})

//delete user
export const deleteUser = (userId, history) => ({
  type: DELETE_USER,
  payload: { userId, history },
})

export const deleteUserSuccess = (user, id) => ({
  type: DELETE_USER_SUCCESS,
  payload: { user, id },
})

export const deleteUserFail = error => ({
  type: DELETE_USER_FAIL,
  payload: error,
})

//all carts
export const getUserCarts = () => ({
  type: GET_USER_CARTS,
})

export const getUserCartsSuccess = userCarts => ({
  type: GET_USER_CARTS_SUCCESS,
  payload: userCarts,
})

export const getUserCartsFail = error => ({
  type: GET_USER_CARTS_FAIL,
  payload: error,
})

//user details
export const getUserCartDetails = userId => ({
  type: GET_USER_CART_DETAILS,
  payload: userId,
})

export const getUserCartDetailsSuccess = userCartDetails => ({
  type: GET_USER_CART_DETAILS_SUCCESS,
  payload: userCartDetails,
})

export const getUserCartDetailsFail = error => ({
  type: GET_USER_CART_DETAILS_FAIL,
  payload: error,
})

//delete cart
export const deleteUserCart = (userCartId, history) => ({
  type: DELETE_USER_CART,
  userCartId,
  history,
})

export const deleteUserCartSuccess = userCart => ({
  type: DELETE_USER_CART_SUCCESS,
  payload: userCart,
})

export const deleteUserCartFail = error => ({
  type: DELETE_USER_CART_FAIL,
  payload: error,
})

//user favourite details
export const getUserFavouriteDetails = userId => ({
  type: GET_USER_FAVOURITE_DETAILS,
  payload: userId,
})

export const getUserFavouriteDetailsSuccess = userFavouriteDetails => ({
  type: GET_USER_FAVOURITE_DETAILS_SUCCESS,
  payload: userFavouriteDetails,
})

export const getUserFavouriteDetailsFail = error => ({
  type: GET_USER_FAVOURITE_DETAILS_FAIL,
  payload: error,
})

//user recent Views
export const getUserRecentViews = userId => ({
  type: GET_USER_RECENT_VIEWS,
  payload: userId,
})

export const getUserRecentViewsSuccess = userRecentViews => ({
  type: GET_USER_RECENT_VIEWS_SUCCESS,
  payload: userRecentViews,
})

export const getUserRecentViewsFail = error => ({
  type: GET_USER_RECENT_VIEWS_FAIL,
  payload: error,
})

//delete user favourite
export const deleteUserFavourite = (userFavouriteId, history) => ({
  type: DELETE_USER_FAVOURITE,
  userFavouriteId,
  history,
})

export const deleteUserFavouriteSuccess = userFavourite => ({
  type: DELETE_USER_FAVOURITE_SUCCESS,
  payload: userFavourite,
})

export const deleteUserFavouriteFail = error => ({
  type: DELETE_USER_FAVOURITE_FAIL,
  payload: error,
})
//address
export const getUserAddress = userId => ({
  type: GET_USER_ADDRESS,
  payload: userId,
})
export const getUserAddressSuccess = userAddress => ({
  type: GET_USER_ADDRESS_SUCCESS,
  payload: userAddress,
})
export const getUserAddressFail = error => ({
  type: GET_USER_ADDRESS_FAIL,
  payload: error,
})

export const addUserAddress = (address, userId, onCloseClick) => ({
  type: ADD_USER_ADDRESS,
  payload: { address, userId, onCloseClick },
})
export const addUserAddressSuccess = userAddress => ({
  type: ADD_USER_ADDRESS_SUCCESS,
  payload: userAddress,
})
export const addUserAddressFail = error => ({
  type: ADD_USER_ADDRESS_FAIL,
  payload: error,
})

export const deleteUserAddress = (addressId, userId) => ({
  type: DELETE_USER_ADDRESS,
  payload: { addressId, userId },
})
export const deleteUserAddressSuccess = addressId => ({
  type: DELETE_USER_ADDRESS_SUCCESS,
  payload: addressId,
})
export const deleteUserAddressFail = error => ({
  type: DELETE_USER_ADDRESS_FAIL,
  payload: error,
})

//Add Item to User Cart
export const addItemToUserCart = (userId, item) => ({
  type: ADD_ITEM_TO_USER_CART,
  payload: { userId, item },
})

export const addItemToUserCartSuccess = userCart => ({
  type: ADD_ITEM_TO_USER_CART_SUCCESS,
  payload: userCart,
})

export const addItemToUserCartFail = error => ({
  type: ADD_ITEM_TO_USER_CART_FAIL,
  payload: error,
})
//Add COUPON to User Cart
export const addCouponToUserCart = (userId, coupon) => ({
  type: ADD_COUPON_TO_USER_CART,
  payload: { userId, coupon },
})

export const addCouponToUserCartSuccess = userCart => ({
  type: ADD_COUPON_TO_USER_CART_SUCCESS,
  payload: userCart,
})

export const addCouponToUserCartFail = error => ({
  type: ADD_COUPON_TO_USER_CART_FAIL,
  payload: error,
})

// promoter

// get Promoters list

export const getPromoters = (page, searchText, sort) => ({
  type: GET_PROMOTERS,
  payload: { page, searchText, sort },
})

export const getPromotersSuccess = promotersList => ({
  type: GET_PROMOTERS_SUCCESS,
  payload: promotersList,
})

export const getPromotersFail = error => ({
  type: GET_PROMOTERS_FAIL,
  payload: error,
})

// promoter details

export const getPromoterDetails = promoterId => ({
  type: GET_PROMOTER_DETAILS,
  payload: promoterId,
})

export const getPromoterDetailsSuccess = promoterDetails => ({
  type: GET_PROMOTER_DETAILS_SUCCESS,
  payload: promoterDetails,
})

export const getPromoterDetailsFail = error => ({
  type: GET_PROMOTER_DETAILS_FAIL,
  payload: error,
})

// create promoter

export const createPromoters = (promoterData, history) => ({
  type: CREATE_PROMOTER,
  payload: { promoterData, history },
})

export const createPromotersSuccess = promoter => ({
  type: CREATE_PROMOTER_SUCCESS,
  payload: promoter,
})

export const createPromotersFail = error => ({
  type: CREATE_PROMOTER_FAIL,
  payload: error,
})

// update promoter

export const updatePromoter = (promoterData, promoterId, history) => ({
  type: UPDATE_PROMOTER,
  payload: { promoterData, promoterId, history },
})

export const updatePromoterSuccess = promoter => ({
  type: UPDATE_PROMOTER_SUCCESS,
  payload: promoter,
})

export const updatePromoterFail = error => ({
  type: UPDATE_PROMOTER_FAIL,
  payload: error,
})

// delete promoter

export const deletePromoter = (promoterId, history) => ({
  type: DELETE_PROMOTER,
  payload: { promoterId, history },
})

export const deletePromoterSuccess = (user, id) => ({
  type: DELETE_PROMOTER_SUCCESS,
  payload: { user, id },
})

export const deletePromoterFail = error => ({
  type: DELETE_PROMOTER_FAIL,
  payload: error,
})

// PromoterID check

export const checkPromoterId = (ref_id, checkPromoter) => ({
  type: CHECK_PROMOTERID,
  payload: { ref_id, checkPromoter },
})

export const checkPromoterIdSuccess = response => ({
  type: CHECK_PROMOTERID_SUCCESS,
  payload: { response },
})

export const checkPromoterIdFail = error => ({
  type: CHECK_PROMOTERID_FAIL,
  payload: error,
})

// page view Details

export const getPageViewDetails = (pageViewId, page) => ({
  type: GET_PAGE_VIEW_DETAILS,
  payload: { pageViewId, page },
})

export const getPageViewDetailsSuccess = pageViewDetails => ({
  type: GET_PAGE_VIEW_DETAILS_SUCCESS,
  payload: pageViewDetails,
})

export const getPageViewDetailsFail = error => ({
  type: GET_PAGE_VIEW_DETAILS_FAIL,
  payload: error,
})

//  Earnings  details

export const getEarningsDetails = (earningsId, page) => ({
  type: GET_EARINIG_DETAILS,
  payload: { earningsId, page },
})
export const getEarningsDetailSuccess = earningDetails => ({
  type: GET_EARINIG_DETAILS_SUCCESS,
  payload: earningDetails,
})
export const getEarningsDetailsFail = error => ({
  type: GET_EARINIG_DETAILS_FAIL,
  payload: error,
})

// All Page view

export const getPageView = (page, limit, searchText, sortByDate) => ({
  type: GET_PAGE_VIEW,
  payload: { page, limit, searchText, sortByDate },
})

export const getPageViewSuccess = pageView => ({
  type: GET_PAGE_VIEW_SUCCESS,
  payload: pageView,
})

export const getPageViewFail = error => ({
  type: GET_PAGE_VIEW_FAIL,
  payload: error,
})

// All Earnings
export const getEarnings = (page, limit, searchText, sortByDate) => ({
  type: GET_EARNING,
  payload: { page, limit, searchText, sortByDate },
})
export const getEarningsSuccess = earnings => ({
  type: GET_EARNING_SUCCESS,
  payload: earnings,
})
export const getEarningsFail = error => ({
  type: GET_EARNING_FAIL,
  payload: error,
})

// All Formatted Earnings

export const getAllFormattedEarnings = (
  page,
  limit,
  searchText,
  sortByDate,
) => ({
  type: GET_ALL_FORMATTED_EARNINGS,
  payload: { page, limit, searchText, sortByDate },
})

export const getAllFormattedEarningsSuccess = formattedEarnings => ({
  type: GET_ALL_FORMATTED_EARNINGS_SUCCESS,
  payload: formattedEarnings,
})

export const getAllFormattedEarningsFail = error => ({
  type: GET_ALL_FORMATTED_EARNINGS_FAIL,
  payload: error,
})
