import React from 'react'
import PropTypes from 'prop-types'

function Spinner({ className }) {
  return (
    <i
      className={
        'bx bx-loader bx-spin font-size-16 align-middle me-2 ' + className
      }
    ></i>
  )
}

export default Spinner
Spinner.propTypes = {
  className: PropTypes.string,
}
