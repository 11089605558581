import React, { Fragment, useEffect, useState } from 'react'
import { Row, Col, Card, CardBody, Badge, FormGroup } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from 'components/Common/Spinner'

// data-table related plugins
import BootstrapTable from 'react-bootstrap-table-next'

import { debounce, map, range } from 'lodash'
import Select from 'react-select'

import ToolkitProvider from 'react-bootstrap-table2-toolkit'

//actions
import { getPromoters, getWithdrawalLists } from 'store/actions'

import '../../assets/scss/data-tables.scss'
import MyPagination from 'components/Common/MyPagination'
// import { AvField, AvForm } from 'availity-reactstrap-validation'
import moment from 'moment'
import { CurrencySwitch } from 'hooks/Currency'

const WithdrawalList = () => {
  const dispatch = useDispatch()
  const currency = CurrencySwitch()

  const [page, setPage] = useState(1)
  // const [searchText, setSearchText] = useState('')

  const [sort, setSort] = useState('')

  // const role = sessionStorage.getItem('role')

  const { WithdrawalLists, loading, allPromoters, promoterLoad } = useSelector(
    state => ({
      WithdrawalLists: state.WithdrawalList.withdrawalLists,
      loading: state.WithdrawalList.loading,
      allPromoters: state?.Users?.promotersList,
      promoterLoad: state.Users?.promoterLoad,
    }),
  )

  const [promoterSelect, setPromoterSelect] = useState({
    id: '',
    title: 'Search Promoter',
  })
  const [promoterSearch, setPromoterSearch] = useState('')

  const debouncePromoterSearch = debounce(
    value => setPromoterSearch(value),
    600,
  )

  useEffect(() => {
    dispatch(getPromoters(page, promoterSearch))
  }, [dispatch, promoterSearch])

  const handlePromoterEnters = textEntered => {
    debouncePromoterSearch(textEntered)
  }

  function handlerPromoterFinalValue(event) {
    const promoter = {
      ...promoterSelect,
      title: event.label,
      id: event.value,
    }
    setPromoterSelect(promoter)
  }

  const promoterOptions = [
    {
      label: 'All Promoters',
      id: '',
    },
    {
      options: (allPromoters?.total >= 1 ? allPromoters?.promoters : [])?.map(
        (result, index) => ({
          key: index,
          label: result?.name || result?.phone,
          value: result?._id,
        }),
      ),
    },
  ]

  useEffect(() => {
    dispatch(getWithdrawalLists(page, sort, 10, promoterSelect?.id))
  }, [dispatch, page, sort, promoterSelect])

  const totalPages = Math.ceil(WithdrawalLists?.total / 10)
  const pages = range(1, totalPages + 1)

  const handleStatus = status => {
    switch (status) {
      case 'INITIATED':
        return 'primary'
      case 'PENDING':
        return 'info'
      case 'ERRORED':
        return 'danger'
      case 'SUCCESS':
        return 'success'
      case 'FAILED':
        return 'danger'
      default:
        break
    }
  }

  const columns = [
    {
      dataField: 'date',
      text: 'Date',
      sort: true,
    },
    {
      dataField: 'user.name',
      text: 'Name',
    },
    {
      dataField: 'user.phone',
      text: 'Phone',
    },
    {
      dataField: 'amount',
      text: 'Withdrawal Amount',
      sort: true,
    },
    {
      dataField: 'withdrawalRequest.requestId',
      text: 'Request Id',
      sort: true,
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
    },
  ]

  const productData = map(WithdrawalLists?.imaWalletWithdrawals, item => ({
    ...item,
    amount: `${currency} ${item?.amount}`,
    date: <span>{moment(item?.createdAt).format('lll')}</span>,
    status: (
      <Badge className={`badge-soft-${handleStatus(item?.status)}`}>
        {item?.status}
      </Badge>
    ),
  }))

  // Select All Button operation
  // const debounceProductSearch = debounce(value => setSearchText(value), 600)

  const headerTap = [
    {
      value: '',
      title: 'All',
    },

    {
      value: 'INITIATED',
      title: 'INITIATED',
    },
    {
      value: 'PENDING',
      title: 'PENDING ',
    },
    {
      value: 'ERRORED',
      title: 'ERRORED ',
    },
    {
      value: 'SUCCESS',
      title: 'SUCCESS ',
    },
    {
      value: 'FAILED',
      title: 'FAILED ',
    },
  ]

  const listChange = sortValue => {
    setSort(sortValue)
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <ToolkitProvider
                keyField="_id"
                key={'id'}
                columns={columns}
                data={productData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col md="6">
                        <div
                          className="btn-group mt-xl-0"
                          role="group"
                          aria-label="Basic radio toggle button group"
                        >
                          {headerTap?.map((tap, key) => (
                            <Fragment key={key}>
                              <input
                                type="radio"
                                className="btn-check"
                                name="btnRadio"
                                id={`btnRadio${tap?.value}`}
                                autoComplete="off"
                                checked={tap.value === sort ? true : false}
                                onChange={() => listChange(tap?.value)}
                              />
                              <label
                                className="btn btn-success"
                                htmlFor={`btnRadio${tap?.value}`}
                              >
                                {tap?.title}
                              </label>
                            </Fragment>
                          ))}
                        </div>
                      </Col>
                      <Col md="3">
                        <FormGroup className="mb-3 w-100 me-3">
                          <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                            <Select
                              onInputChange={handlePromoterEnters}
                              value={promoterSelect?.title}
                              placeholder={promoterSelect?.title}
                              onChange={handlerPromoterFinalValue}
                              options={promoterOptions}
                              classNamePrefix="select2-selection"
                              isLoading={promoterLoad}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      {/* <Col sm="4" className="d-flex align-items-center">
                        <div className="search-box ms-2 w-100 d-inline-block ">
                          <div className="position-relative w-100">
                            <AvForm>
                              <AvField
                                name="searchText"
                                type="text"
                                value={searchText}
                                placeholder="Search..."
                                onChange={e =>
                                  debounceProductSearch(e.target.value)
                                }
                              />
                            </AvForm>
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>
                      </Col> */}
                    </Row>
                    <div className="w-100 pb-2 d-flex align-items-center mb-3">
                      <div className="d-flex sortWrapper mx-2 me-3 align-items-center ">
                        <p
                          className="mb-0 pb-0 mx-1"
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          Total :
                        </p>
                        <div>{WithdrawalLists?.total}</div>
                      </div>
                    </div>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        {WithdrawalLists?.total !== 0 ? (
                          <>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={'id'}
                                    key={'id'}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    classes={'table align-middle table-nowrap'}
                                    headerWrapperClasses={'thead-light'}
                                    {...toolkitProps.baseProps}
                                    // selectRow={handleSelectTableRow()}
                                    // onTableChange={handleTableChange}
                                    // {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <MyPagination
                              totalPages={pages}
                              page={page}
                              setPage={setPage}
                            />
                          </>
                        ) : (
                          <p className="my-4 mx-3 ">No Withdrawal Lists !</p>
                        )}
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default WithdrawalList
