import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import 'toastr/build/toastr.min.css'

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  FormGroup,
  Label,
} from 'reactstrap'

//redux
import { useSelector, useDispatch } from 'react-redux'

import { withRouter, Link } from 'react-router-dom'

// availity-reactstrap-validation
import { AvForm } from 'availity-reactstrap-validation'

// actions
import { loginOtpUser } from '../../store/actions'
import OtpInput from 'react-otp-input'

// import images
import MetaTag from 'components/Common/Meta-tag'
import { LocalLogo2, profile } from 'assets/images'

const LoginOtp = ({ history }) => {
  const dispatch = useDispatch()

  const { error, user, loading } = useSelector(state => ({
    error: state.Login.error,
    user: state.Login.user,
    loading: state.Login.loading,
  }))

  const [otp, setOtp] = useState('')

  const handleChangeOtp = v => {
    setOtp(v)
  }
  const token = sessionStorage.getItem('token')

  useEffect(() => {
    if (token) {
      history.push('/dashboard')
    }
  }, [token])

  useEffect(() => {
    if (user?.userId && otp?.length === 4) {
      const otpData = {
        otp: otp,
        userId: user?.userId,
      }
      dispatch(loginOtpUser(otpData, history))
    }
  }, [otp?.length, user?.userId, dispatch])

  return (
    <React.Fragment>
      <MetaTag title="Login" />

      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-gray p-4">
                        <h5 className="text-gray">Welcome Back !</h5>
                        <p>Sign in to continue</p>
                      </div>
                    </Col>

                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span
                          className="avatar-title rounded-circle "
                          style={{
                            backgroundColor: '#f8f9fa',
                          }}
                        >
                          <img src={LocalLogo2} alt="logo" height="50" />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <AvForm className="form-horizontal">
                      {error?.message ? (
                        <Alert color="danger">{error.message}</Alert>
                      ) : null}

                      <div className="mb-3">
                        <FormGroup className="verification-2 mb-3 " name="otp">
                          <Label
                            htmlFor="digit1-input"
                            className="visually-hidden"
                          >
                            OTP
                          </Label>
                          <Label>
                            Enter Your OTP{' '}
                            {loading && (
                              <>
                                <i className="bx bx-loader bx-spin font-size-16 align-middle text-info me-2"></i>
                              </>
                            )}
                          </Label>
                          <OtpInput
                            onChange={v => handleChangeOtp(v)}
                            numInputs={4}
                            isInputNum={true}
                            inputStyle={'otp_input'}
                            containerStyle={'otp_container'}
                            shouldAutoFocus={true}
                            focusStyle={'otp_focus'}
                            value={otp}
                          />
                        </FormGroup>
                      </div>

                      <div className="mt-4 text-center">
                        <Link
                          to="#!"
                          className="text-muted d-flex align-items-center justify-content-center w-100"
                        >
                          <i className="mdi mdi-lock me-1" />

                          <p className="font-sm mb-0">
                            change
                            <u
                              className="text-black"
                              style={{ cursor: 'pointer' }}
                            >
                              &nbsp;
                              <Link
                                className=""
                                to={{
                                  pathname: '/login',
                                  state: 'showPhoneNumber',
                                }}
                              >
                                Mobile number{' '}
                              </Link>{' '}
                            </u>
                          </p>
                        </Link>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  © {new Date().getFullYear()} {}. Crafted with{' '}
                  <i className="mdi mdi-heart text-danger" /> by
                  <a target="blank" href="https://osperb.com">
                    {` osperb`}
                  </a>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(LoginOtp)

LoginOtp.propTypes = {
  history: PropTypes.object,
}
