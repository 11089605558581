import React, { Fragment, useEffect, useState } from 'react'
import {
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Badge,
  Button,
  UncontrolledTooltip,
} from 'reactstrap'
import { Link, useHistory, useLocation } from 'react-router-dom'
import SimpleBar from 'simplebar-react'
import { useDispatch, useSelector } from 'react-redux'
import { BiEdit } from 'react-icons/bi'

// data-table related plugins
import BootstrapTable from 'react-bootstrap-table-next'

import { debounce, map, range } from 'lodash'

import ToolkitProvider from 'react-bootstrap-table2-toolkit'
import MyPagination from '../../components/Common/MyPagination'

//actions
import {
  autoUpdateOrderStatus,
  clearAutoStatusUpdate,
  getOrders,
  getShiprocketInvoice,
  getShiprocketLabel,
  getTotalOrderCounts,
} from 'store/orders/actions'

import '../../assets/scss/data-tables.scss'
import moment from 'moment'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import { useSiteVersion } from 'hooks/useSiteVersion'
import { CurrencySwitch } from 'hooks/Currency'
import ShiprocketLabelPdfPopup from './SingleView/ShiprocketLabelPdfPopup'
import { FaFileInvoice, FaList, FaStickyNote } from 'react-icons/fa'
import ShiprocketInvoicePdfPopup from './SingleView/ShiprocketInvoicePdfPopup'
import ReactDrawer from 'react-drawer'
import { RiTableFill } from 'react-icons/ri'
import { NotificationAsync } from 'components/Common/Notification'
import StatusAutoUpdateSuccess from './SingleView/StatusAutoUpdateSuccess'
import OrderEditModal from './OrderEdit/OrderEditModal'
import { useWindowSize } from 'hooks/useWindowSize'

const Orders = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const width = useWindowSize()

  const { isPremium } = useSiteVersion()
  const {
    orders,
    total,
    loading,
    // StoreLoading,
    // stores,
    //new
    orderCounts,
    shiprocketLoad,
    shiprocketLabel,
    shiprocketInvoice,
    shiprocketInvoiceLoad,
    autoUpdateLoad,
    autoUpdateStatus,
  } = useSelector(state => ({
    orders: state?.Orders?.orders,
    total: state?.Orders?.total,
    loading: state?.Orders?.loading,
    // stores: state.Stores.stores?.stores,
    // StoreLoading: state.Stores.loading,
    orderCounts: state?.Orders?.orderCounts,
    shiprocketLoad: state.Orders.shiprocketLoad,
    shiprocketLabel: state.Orders.shiprocketLabel,
    shiprocketInvoiceLoad: state.Orders.shiprocketInvoiceLoad,
    shiprocketInvoice: state.Orders.shiprocketInvoice,
    autoUpdateLoad: state.Orders.autoUpdateLoad,
    autoUpdateStatus: state.Orders.autoUpdateStatus,
  }))

  const isMobile = width?.width <= 425

  // const [viewLoad, setViewLoad] = useState({
  //   status: false,
  //   id: '',
  // })
  const [orderSearchText, setOrderSearchText] = useState('')
  const [showModal, setShowModal] = useState({ status: '', data: '' })

  // const [storeSearchText, setStoreSearchText] = useState("")
  // const [selectedStore, setSelectedStore] = useState("All Store")
  // const [finalStoreId, setFinalStoreId] = useState()

  const openModal = item => {
    setShowModal({ status: true, data: item })
  }
  const HandleCloseModal = () => {
    setShowModal({ status: false, data: '' })
  }

  const [sort, setSort] = useState('All')

  const [finalDateFormatted, setFinalDateFormatted] = useState({
    from: '',
    to: '',
  })

  //pagination
  const [totalItemLimit, setTotalItemLimit] = useState(30)
  const [page, setPage] = useState(1)
  const totalPages = Math.ceil(total / totalItemLimit)
  const pages = range(1, totalPages + 1)

  const handleDateSearch = () => {
    if (
      finalDateFormatted?.from?.length >= 1 &&
      finalDateFormatted?.to?.length >= 1
    ) {
      return finalDateFormatted
    } else {
      return {
        from: '',
        to: '',
      }
    }
  }

  useEffect(() => {
    dispatch(
      getOrders(
        page,
        totalItemLimit,
        orderSearchText,
        sort,
        handleDateSearch(),
      ),
    )
  }, [
    dispatch,
    page,
    orderSearchText,
    totalItemLimit,
    sort,
    '',
    finalDateFormatted,
  ])

  useEffect(() => {
    dispatch(getTotalOrderCounts(orderSearchText, handleDateSearch()))
  }, [dispatch, orderSearchText, finalDateFormatted])

  // useEffect(() => {
  //   dispatch(getStores(1, storeSearchText))
  // }, [dispatch, storeSearchText])

  // useEffect(() => {
  //   if (sort === "all") {
  //     setIsCustomStore(false)
  //   } else if (finalStoreId || sort) {
  //     setIsCustomStore(true)
  //   } else {
  //     setIsCustomStore(false)
  //   }
  // }, [sort, finalStoreId])

  const columns = [
    {
      dataField: 'orderId',
      text: 'Order ID',
      sort: true,
    },
    {
      dataField: 'createdAt',
      text: 'Date',
      sort: true,
    },
    {
      dataField: 'shippingInfo.name',
      text: 'Name',
      sort: true,
    },
    {
      dataField: 'user.phone',
      text: 'Phone No.',
      sort: true,
    },
    {
      dataField: 'totalPrice',
      text: 'Amount',
      sort: true,
    },
    {
      dataField: 'paymentType',
      text: 'Payment Type',
    },
    {
      dataField: 'aggregator',
      text: 'Payment Option',
    },
    {
      dataField: 'orderStatus',
      text: 'Status',
    },
    // {
    //   dataField: 'action',
    //   text: 'Action',
    // },
  ]

  const handleOrderStatus = status => {
    switch (status) {
      case 'Pending':
        return 'dark'
      case 'Processing':
        return 'info'
      case 'Shipped':
        return 'info'
      case 'Delivered':
        return 'success'
      case 'Cancelled':
        return 'warning'
      case 'Returned':
        return 'danger'
      case 'Fulfilled':
        return 'success'
      case 'Partially Fulfilled':
        return 'success'
      default:
        return 'info'
    }
  }

  const handlePaymentOption = paymentOption => {
    switch (paymentOption) {
      case 'phonePe':
        return {
          paymentOptionName: 'PhonePe',
          backgroundColor: 'bg-info',
        }
      case 'ima wallet':
        return {
          paymentOptionName: 'IMA Wallet',
          backgroundColor: 'bg-success',
        }
      default:
        return {
          paymentOptionName: '',
          backgroundColor: 'bg-info',
        }
    }
  }

  const currency = CurrencySwitch()

  // const handleViewClick = item => {
  //   setViewLoad({
  //     status: true,
  //     id: item?._id,
  //   })
  //   dispatch(clearOrders())
  //   setTimeout(() => {
  //     history.push(`/order/${item?._id}`)
  //   }, 500)
  // }
  // const handleEditViewClick = item => {
  //   setViewLoad({
  //     status: true,
  //     id: item?._id,
  //   })
  //   dispatch(clearOrders())
  //   setTimeout(() => {
  //     history.push(`/orders/update/${item?._id}`)
  //   }, 500)
  // }
  const orderData = map(orders, item => ({
    ...item,
    orderId: <Link to={`/order/${item?._id}`}>{item?.orderId}</Link>,
    totalPrice: `${currency} ${item.totalPrice}`,
    createdAt: moment(item.createdAt).format('DD/MM/YYYY - h:mm a'),
    // shopName: item.shop?.shopName,
    paymentType: (
      <>
        {item?.paymentType === 'Cash on Delivery' ? (
          <Badge className="px-2 py-2 badge-soft-info ">POC</Badge>
        ) : (
          <Badge className="px-2 py-2 badge-soft-success ">
            {item?.paymentType}
          </Badge>
        )}
        &nbsp;
        {item?.isPaid ? (
          <Badge className="px-2 py-2 badge-soft-success ">Paid</Badge>
        ) : (
          <Badge className="px-2 py-2 badge-soft-danger">Not Paid</Badge>
        )}
      </>
    ),
    aggregator: (
      <>
        <Badge
          className={`px-2 py-2 ${
            handlePaymentOption(item?.aggregator).backgroundColor
          }`}
        >
          {handlePaymentOption(item?.aggregator).paymentOptionName}
        </Badge>
      </>
    ),
    orderStatus: (
      <Badge
        className={
          'px-2 py-2 badge-soft-' + `${handleOrderStatus(item?.orderStatus)}`
        }
      >
        {' '}
        {item?.orderStatus}{' '}
      </Badge>
    ),
    activeData: (
      <>
        <div className="form-check form-switch form-switch-md mb-3">
          <input
            type="checkbox"
            className="form-check-input"
            id="customSwitchsizemd"
            onChange={() => {
              handleSwitchChange(item?._id)
            }}
            checked={item?.isActive}
          />
          <label className="form-check-label" htmlFor="customSwitchsizemd">
            {item?.isActive ? `Active` : `Inactive`}
          </label>
        </div>
      </>
    ),
    action: (
      <div>
        <Button
          onClick={() => openModal(item)}
          id={`view`}
          type="button"
          color="white"
          className="ms-1 btn"
        >
          <BiEdit />

          {/* {viewLoad.status && viewLoad.id === item?._id ? (
            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
          ) : (
            //   <i
            //   className="bx bx-show-alt me-2"
            //    style={{ color: "green", fontSize: "22px" }}
            //  />
            //  <Link to={`/orders/update/${item?._id}`}>
            <BiEdit />
            // </Link>
          )} */}
        </Button>
      </div>
    ),
  }))

  const debounceOrderSearch = debounce(value => setOrderSearchText(value), 600)

  const handleTableChange = () => {}

  const handleSwitchChange = () => {
    // TODO: API needed for status change
  }

  useEffect(() => {
    const currentLimit = localStorage.getItem('orderLimit')
    if (currentLimit) {
      setTotalItemLimit(currentLimit)
    }
  }, [])

  const handlePageLimit = e => {
    if (e.target.value >= 1) {
      setTotalItemLimit(parseInt(e.target.value))
      localStorage.setItem('orderLimit', e.target.value)
    }
  }

  const listChange = sortValue => {
    setSort(sortValue)

    history.push({
      pathname: location.pathname,
      search: `p=${page}&s=${sortValue}`,
      state: {
        page: page,
        sort: sortValue,
      },
    })
  }

  useEffect(() => {
    if (location?.state && location?.state?.sort) {
      setSort(location?.state?.sort)
    } else {
      // setSort("all")
    }
  }, [location?.state?.sort])

  const tableHeads = [
    {
      title: 'All',
      value: 'All',
      count: orderCounts?.allOrdersCount,
    },
    {
      title: 'Pending',
      value: 'Pending',
      count: orderCounts?.pendingOrdersCount,
    },
    {
      title: 'Processing',
      value: 'Processing',
      count: orderCounts?.processingOrdersCount,
    },
    {
      title: 'Pickup Scheduled',
      value: 'Pickup Scheduled',
      count: orderCounts?.pickupScheduledOrdersCount,
    },
    {
      title: 'Shipped',
      value: 'Shipped',
      count: orderCounts?.shippedOrdersCount,
    },
    {
      title: 'Delivered',
      value: 'Delivered',
      count: orderCounts?.deliveredOrdersCount,
    },
    {
      title: 'Cancelled',
      value: 'Cancelled',
      count: orderCounts?.cancelledOrdersCount,
    },
    {
      title: 'Returned',
      value: 'Returned',
      count: orderCounts?.returnedOrdersCount,
    },
    {
      title: 'Course',
      value: 'course',
      count: orderCounts?.courseOrdersCount,
    },
  ]

  //shiprocket
  const [selectedItemIds, setSelectedItemIds] = useState([])

  //label
  const [isLabelGenerated, setIsLabelGenerated] = useState(false)
  const [selectedShipmentLabelIds, setSelectedShipmentLabelIds] = useState([])
  const handleDownloadShippingLabel = () => {
    const shipmentIdArray = {
      shipmentIdArray: selectedShipmentLabelIds,
    }

    if (selectedShipmentLabelIds?.length >= 1) {
      dispatch(getShiprocketLabel(shipmentIdArray))
      setIsLabelGenerated(true)
    }
  }

  const [isOpenShiprocketLabel, setIsOpenShiprocketLabel] = useState({
    status: false,
    id: '',
  })

  const handleOpenShiprocketLabelPdf = id => {
    setIsOpenShiprocketLabel({ status: true, id: id })
  }

  useEffect(() => {
    if (shiprocketLabel?.label_url) {
      handleOpenShiprocketLabelPdf()
    }
  }, [shiprocketLabel])

  const handleCloseLabelPopup = () => {
    setIsOpenShiprocketLabel({ ...isOpenShiprocketLabel, status: false })
    setIsLabelGenerated(false)
    // setSelectedShipmentLabelIds([])
    // setSelectedItemIds([])
  }
  //invoice
  const [isInvoiceGenerated, setIsInvoiceGenerated] = useState(false)
  const [selectedShipmentInvoiceIds, setSelectedShipmentInvoiceIds] = useState(
    [],
  )
  const handleDownloadShippingInvoice = () => {
    const shipmentIdArray = {
      orderIdArray: selectedShipmentInvoiceIds,
    }

    if (selectedShipmentInvoiceIds?.length >= 1) {
      dispatch(getShiprocketInvoice(shipmentIdArray))
      setIsInvoiceGenerated(true)
    }
  }

  const [isOpenShiprocketInvoice, setIsOpenShiprocketInvoice] = useState({
    status: false,
    id: '',
  })

  const handleOpenShiprocketInvoicePdf = id => {
    setIsOpenShiprocketInvoice({ status: true, id: id })
  }

  useEffect(() => {
    if (shiprocketInvoice?.invoice_url) {
      handleOpenShiprocketInvoicePdf()
    }
  }, [shiprocketInvoice])

  const handleCloseInvoicePopup = () => {
    setIsOpenShiprocketInvoice({ ...isOpenShiprocketInvoice, status: false })
    setIsInvoiceGenerated(false)
    // setSelectedShipmentInvoiceIds([])
    // setSelectedItemIds([])
  }

  console.log('selectedItemIds', selectedItemIds)
  console.log('selectedShipmentInvoiceIds', selectedShipmentInvoiceIds)

  // select row
  const handleSelectTableRow = () => {
    return {
      mode: 'checkbox',
      onSelect: (row, isSelect) => {
        if (isSelect) {
          setSelectedItemIds([...selectedItemIds, row?._id])
          const currentItem = orderData?.filter(i => i._id === row._id)[0]

          //label
          if (
            selectedShipmentLabelIds?.includes(
              currentItem?.shiprocketOrderDetails?.shipment_id,
            )
          ) {
            setSelectedShipmentLabelIds(
              selectedShipmentLabelIds?.filter(
                id => id !== currentItem?.shiprocketOrderDetails?.shipment_id,
              ),
            )
          } else {
            setSelectedShipmentLabelIds([
              ...selectedShipmentLabelIds,
              currentItem?.shiprocketOrderDetails?.shipment_id,
            ])
          }

          //invoice
          if (
            selectedShipmentInvoiceIds?.includes(
              currentItem?.shiprocketOrderDetails?.order_id,
            )
          ) {
            setSelectedShipmentInvoiceIds(
              selectedShipmentInvoiceIds?.filter(
                id => id !== currentItem?.shiprocketOrderDetails?.order_id,
              ),
            )
          } else {
            setSelectedShipmentInvoiceIds([
              ...selectedShipmentInvoiceIds,
              currentItem?.shiprocketOrderDetails?.order_id,
            ])
          }
        } else {
          setSelectedItemIds(selectedItemIds?.filter(id => id !== row._id))
          const currentItem = orderData?.filter(i => i._id === row._id)[0]

          //label
          setSelectedShipmentLabelIds(
            selectedShipmentLabelIds?.filter(
              id => id !== currentItem?.shiprocketOrderDetails?.shipment_id,
            ),
          )
          //invoice
          setSelectedShipmentInvoiceIds(
            selectedShipmentInvoiceIds?.filter(
              id => id !== currentItem?.shiprocketOrderDetails?.order_id,
            ),
          )
        }
      },
      onSelectAll: (isSelect, rows) => {
        if (isSelect === true) {
          //label
          const selectedShipmentLabelIds = rows.map(
            row => row?.shiprocketOrderDetails?.shipment_id,
          )
          setSelectedShipmentLabelIds(selectedShipmentLabelIds)
          //invoice
          const selectedShipmentInvoiceIds = rows.map(
            row => row?.shiprocketOrderDetails?.order_id,
          )
          setSelectedShipmentInvoiceIds(selectedShipmentInvoiceIds)

          const selectedItemIds = rows?.map(i => i._id)
          setSelectedItemIds(selectedItemIds)
        } else {
          setSelectedShipmentLabelIds([])
          setSelectedItemIds([])
          setSelectedShipmentInvoiceIds([])
        }
      },
      selected: selectedItemIds,
    }
  }

  const [layout, setLayout] = useState('table')
  const [open, setOpen] = useState(false)
  const [position, setPosition] = useState('right')

  const onDrawerClose = () => {
    setOpen(false)
  }
  const toggleTopDrawer = () => {
    setPosition('right')
    setOpen(!open)
    setLayout('list')
    localStorage.setItem('layout', 'list')
  }

  useEffect(() => {
    const newLayout = localStorage.getItem('layout')
    if (newLayout && newLayout !== undefined) {
      setLayout(newLayout || 'table')
    }
  }, [])

  const [isOpened, setIsOpened] = useState(false)

  const headerOptions = (
    <div
      className={`headerOptionWrap d-flex flex-wrap align-items-center justify-content-between`}
    >
      <div className="headerOptionSubWrap d-flex align-items-center flex-wrap">
        <div className="search-box ms-2 d-inline-block mb-3">
          <div className="position-relative">
            <AvForm>
              <AvField
                name="searchText"
                placeholder="Search"
                type="text"
                onChange={e => debounceOrderSearch(e.target.value)}
              />
            </AvForm>
            <i className="bx bx-search-alt search-icon" />
          </div>
        </div>

        <AvForm className="form-horizontal mx-2 mb-3 d-flex align-items-center">
          <div className="mx-1">
            <span className="">
              Show
              <i className="fas fa-sort-numeric-down-alt mx-1"></i>
            </span>
          </div>
          <div className="" style={{ width: '50px' }}>
            <AvField
              name="pageLimit"
              className="form-control"
              value={totalItemLimit}
              onChange={e => handlePageLimit(e)}
              type="text"
              required
            />
          </div>
        </AvForm>

        <div className="d-flex sortWrapper mx-2 me-3 mb-3 align-items-center ">
          <p className="mb-0 pb-0 mx-1" style={{ whiteSpace: 'nowrap' }}>
            Sort By Date :
          </p>
          <AvForm>
            <div className="d-flex date_wrapper">
              <div className="d-flex align-items-start position-relative">
                <AvField
                  name="dateRange"
                  type="date"
                  validate={{
                    dateRange: {
                      start: { value: -5, units: 'years' },
                      end: { value: 5, units: 'years' },
                    },
                  }}
                  onChange={e =>
                    setFinalDateFormatted({
                      ...finalDateFormatted,
                      from: e.target.value,
                    })
                  }
                  value={finalDateFormatted?.from}
                />
                <button
                  onClick={() =>
                    setFinalDateFormatted({
                      ...finalDateFormatted,
                      from: '',
                    })
                  }
                  type="button"
                  className="btn btn-white editable-cancel date_close btn-sm"
                >
                  <i className="mdi mdi-close text-danger "></i>
                </button>
              </div>
              <div className="d-flex align-items-start position-relative">
                <AvField
                  name="dateRange"
                  className="mx-1"
                  type="date"
                  validate={{
                    dateRange: {
                      start: { value: -5, units: 'years' },
                      end: { value: 5, units: 'years' },
                    },
                  }}
                  onChange={e =>
                    setFinalDateFormatted({
                      ...finalDateFormatted,
                      to: e.target.value,
                    })
                  }
                  value={finalDateFormatted?.to}
                />
                <button
                  onClick={() =>
                    setFinalDateFormatted({
                      ...finalDateFormatted,
                      to: '',
                    })
                  }
                  type="button"
                  className="btn btn-white editable-cancel date_close btn-sm"
                >
                  <i className="mdi mdi-close text-danger "></i>
                </button>
              </div>
            </div>
          </AvForm>
        </div>

        {(sort === 'Processing' || sort === 'Pickup Scheduled') && (
          <>
            {selectedShipmentLabelIds?.length >= 1 && (
              <Button
                className="w-md btn btn-warning me-2 shippingBtn ms-2 mb-3"
                onClick={handleDownloadShippingLabel}
              >
                <FaStickyNote className="me-2" />
                Shipment Label ({selectedShipmentLabelIds?.length})
                {shiprocketLoad && (
                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                )}
              </Button>
            )}
            {selectedShipmentInvoiceIds?.length >= 1 && (
              <Button
                className="w-md btn btn-warning shippingBtn me-0 ms-2 mb-3"
                onClick={handleDownloadShippingInvoice}
              >
                <FaFileInvoice className="me-2" />
                Order Invoice ({selectedShipmentInvoiceIds?.length})
                {shiprocketInvoiceLoad && (
                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                )}
              </Button>
            )}
          </>
        )}

        {(sort === 'Shipped' ||
          sort === 'Processing' ||
          sort === 'Pickup Scheduled') && (
          <button
            onClick={() => dispatch(autoUpdateOrderStatus(sort))}
            className="btn btn-outline-success ms-2 statusBtn mb-3"
          >
            Auto Update Status
            {autoUpdateLoad && (
              <>
                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
              </>
            )}
          </button>
        )}

        {/* <Link
          to="/orders/new"
          className="w-md btn newOrderBtn btn-light ms-2 d-block mb-3"
        >
          + New Order
        </Link> */}
        <Link
          to="/orders/new"
          className="w-md btn newOrderBtn btn-light ms-2 d-block mb-3"
        >
          + New Order
        </Link>
      </div>
    </div>
  )

  useEffect(() => {
    if (autoUpdateStatus?.length >= 1) {
      setIsOpened(true)
      dispatch(getTotalOrderCounts(orderSearchText, handleDateSearch()))
      dispatch(
        getOrders(
          page,
          totalItemLimit,
          orderSearchText,
          sort,
          handleDateSearch(),
        ),
      )
    } else {
      setIsOpened(false)
    }
  }, [autoUpdateStatus])

  const handleCloseStatusPop = () => {
    setIsOpened(false)
    dispatch(clearAutoStatusUpdate())
  }

  return (
    <React.Fragment>
      {autoUpdateLoad && (
        <NotificationAsync
          timeOut={20000}
          title={'Order Status Update'}
          message={'The status of all shipping orders is updating...'}
        />
      )}

      {isOpened && autoUpdateStatus?.length && (
        <StatusAutoUpdateSuccess
          show={isOpened}
          onCloseClick={handleCloseStatusPop}
          data={autoUpdateStatus}
        />
      )}
      {isLabelGenerated && (
        <ShiprocketLabelPdfPopup
          show={isOpenShiprocketLabel?.status}
          onCloseClick={handleCloseLabelPopup}
          shipmentIds={selectedShipmentLabelIds}
        />
      )}
      {isInvoiceGenerated && (
        <ShiprocketInvoicePdfPopup
          show={isOpenShiprocketInvoice?.status}
          onCloseClick={handleCloseInvoicePopup}
          shipmentIds={selectedShipmentInvoiceIds}
        />
      )}
      <OrderEditModal
        onCloseClick={HandleCloseModal}
        show={showModal?.status}
        showModal={showModal}
      />
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <ToolkitProvider
                keyField="_id"
                columns={columns}
                data={orderData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      {isPremium && (
                        <>
                          <Col className="col-12 d-flex align-items-start justify-content-end">
                            {!isMobile && (
                              <div>
                                <UncontrolledTooltip
                                  placement="bottom"
                                  target={'table-view-option'}
                                >
                                  Table view
                                </UncontrolledTooltip>
                                <Button
                                  color={
                                    layout === 'table' ? 'warning' : 'light'
                                  }
                                  className="btn-rounded ms-4 "
                                  id="table-view-option"
                                  onClick={() => {
                                    setLayout('table')
                                    localStorage.setItem('layout', 'table')
                                  }}
                                >
                                  <RiTableFill />
                                </Button>
                              </div>
                            )}
                            <div>
                              <UncontrolledTooltip
                                placement="bottom"
                                target={'list-view-option'}
                              >
                                List view
                              </UncontrolledTooltip>
                              <Button
                                color={layout === 'list' ? 'warning' : 'light'}
                                className="btn-rounded ms-2 position-relative"
                                id="list-view-option"
                                onClick={toggleTopDrawer}
                              >
                                <FaList />
                                {selectedItemIds?.length ? (
                                  <span
                                    className="btn-rounded bg-danger font-size-10 position-absolute"
                                    style={{
                                      top: -6,
                                      right: -6,
                                      padding: 1,
                                      height: 20,
                                      width: 20,
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    {selectedItemIds?.length}
                                  </span>
                                ) : (
                                  ''
                                )}
                              </Button>
                            </div>
                          </Col>
                          <Col
                            className={`mb-${
                              layout === 'table' ? '2' : '0'
                            } col-12`}
                          >
                            <div
                              // className="btn-group mb-2 mt-xl-0"
                              className={`${
                                isMobile
                                  ? 'btn btn-md d-flex flex-wrap'
                                  : 'btn-group'
                              } mt-xl-0`}
                              role="group"
                              aria-label="Basic radio toggle button group"
                            >
                              {map(tableHeads, (head, key) => (
                                <Fragment key={key}>
                                  <input
                                    type="radio"
                                    className="btn-check"
                                    name="btnradio"
                                    id={head.value + key}
                                    autoComplete="off"
                                    checked={sort === head.value}
                                    onChange={() => listChange(head?.value)}
                                  />
                                  <label
                                    // className="btn btn-success d-flex align-items-center w-100"
                                    className={`${
                                      isMobile
                                        ? 'justify-content-center me-2 btn btn-success d-flex align-items-center'
                                        : 'btn btn-success d-flex align-items-center w-100'
                                    } `}
                                    htmlFor={head.value + key}
                                    style={{
                                      whiteSpace: 'nowrap',
                                    }}
                                  >
                                    {head?.title}

                                    <Badge
                                      className={`font-size-12 ms-2 ${
                                        sort === head.value
                                          ? 'badge-soft-warning'
                                          : 'badge-soft-light'
                                      }`}
                                      pill
                                    >
                                      {head?.count}
                                    </Badge>
                                  </label>
                                </Fragment>
                              ))}
                            </div>
                          </Col>
                        </>
                      )}
                      {layout === 'table' && (
                        <div className="order-option-table">
                          {headerOptions}
                        </div>
                      )}
                    </Row>
                    {layout === 'table' && <hr />}
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        {orders?.length ? (
                          <Row>
                            <p>Total Orders :&nbsp;{total ? total : 0} </p>

                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                  // keyField={"id"}
                                  key={'_id'}
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  // defaultSorted={defaultSorted}
                                  selectRow={handleSelectTableRow()}
                                  classes={
                                    'table align-middle table-nowrap custom-padding table-striped'
                                  }
                                  headerWrapperClasses={'thead-light'}
                                  {...toolkitProps.baseProps}
                                  onTableChange={handleTableChange}
                                  // rowClasses={"table-info"}
                                />
                              </div>
                            </Col>
                          </Row>
                        ) : (
                          <p className="my-2 mx-3 ">No Orders!</p>
                        )}
                        <MyPagination
                          totalPages={pages}
                          page={page}
                          setPage={setPage}
                        />
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <ReactDrawer open={open} position={position} onClose={onDrawerClose}>
        <SimpleBar style={{ height: '900px', boxShadow: 'none' }}>
          <div data-simplebar className="h-100">
            <div className="rightbar-title px-3 py-4">
              <Link
                to="#"
                onClick={onDrawerClose}
                className="right-bar-toggle float-end"
              >
                <i className="mdi mdi-close noti-icon" />
              </Link>
              <p className="m-0">More Options</p>
            </div>
            <hr className="my-0" />
            <div className="order-option-sidebar">{headerOptions}</div>
          </div>
        </SimpleBar>
      </ReactDrawer>
    </React.Fragment>
  )
}

export default Orders

{
  /* <Card>
            <CardBody>
              <Col className="d-flex">
                <div className="col-3 me-5">
                  <Label>Search From</Label>
                  <Input
                    type="date"
                    onChange={e => moment(e.target.value).format("DD/MM/YYYY")}
                  />
                </div>
                <div className="col-3">
                  <Label>Search To</Label>
                  <Input
                    type="date"
                    onChange={e => moment(e.target.value).format("DD/MM/YYYY")}
                  />
                </div>
              </Col>
            </CardBody>
          </Card> */
}
