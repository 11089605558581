import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Row, Col, Card, CardBody, Media, Spinner } from 'reactstrap'

import MetaTag from 'components/Common/Meta-tag'
import { map } from 'lodash'
import { Link } from 'react-router-dom'
import { orderImg, productImg, users } from 'assets/images'
import { getPromoterSummery, getPromoterTransactionGraph } from 'store/actions'
import { CurrencySwitch } from 'hooks/Currency'
import LineApexChart from './Chart'
import SplineArea from './Chart2'
import { useWindowSize } from 'hooks/useWindowSize'

const PromotersDashboard = () => {
  const dispatch = useDispatch()
  const currency = CurrencySwitch()
  const width = useWindowSize()

  const { dashboardData, loading, dashboardGraph } = useSelector(state => ({
    dashboardData: state.SupplyChain.dashboardData,
    dashboardGraph: state?.Promoter?.promoterTransactionGraphGrowthReward,
    loading: state.SupplyChain.loading,
  }))

  useEffect(() => {
    dispatch(getPromoterSummery())
  }, [dispatch])

  useEffect(() => {
    dispatch(getPromoterTransactionGraph('growthrewards'))
  }, [dispatch])

  const TopCards = [
    {
      iconClass: productImg,
      highLightList: [
        {
          title: 'Growth Reward ',
          description: `${currency} ${dashboardData?.totalGrowthRewardAmount} `,
        },
        {
          title: 'Lead Bonus',
          description: `${currency} ${dashboardData?.totalLeadBonusAmount}`,
        },
        {
          title: 'Withdrawal',
          description: `${currency} ${dashboardData?.totalWithdrawalAmount}`,
        },
      ],
      highLightList2: [
        {
          title: 'Today GR',
          description: `${currency} ${dashboardData?.todayGrowthRewardAmount} `,
        },
        {
          title: 'Today LB',
          description: `${currency} ${dashboardData?.todayLeadBonusAmount} `,
        },
        {
          title: 'Today',
          description: `${currency} ${dashboardData?.todayWithdrawalAmount} `,
        },
      ],

      bgColor: 'bg-green-peach',
      subList: false,
      list: [],
      Link: '#',
      col: 'col-12  col-xs-6 col-sm-6 col-md-12 col-lg-5 col-xl-5',
    },
    {
      iconClass: orderImg,
      highLightList: [
        {
          title: 'Promoters',
          description: dashboardData?.totalPromotersCount || 0,
        },
        {
          title: 'Today',
          description: dashboardData?.todayPromotersCount || 0,
        },
      ],
      bgColor: 'bg-green-sea',
      subList: true,
      list: [
        {
          sbadges: 'Chain Promoters',
          sbadgesRate: dashboardData?.totalChainPromotersCount,
          sbadgesClass: 'success',
        },
        {
          sbadges: 'Today CP',
          sbadgesRate: dashboardData?.todayChainPromotersCount,
          sbadgesClass: 'success',
        },
      ],
      Link: '#',
      col: 'col-12 col-sm-12 col-xs-12 col-md-12 col-lg-3 col-xl-3',
    },
    {
      iconClass: users,
      highLightList: [
        {
          title: 'Extra',
          description: null,
        },
      ],

      bgColor: 'bg-white',
      subList: true,
      list: [
        {
          sbadges: 'Total Transactions',
          sbadgesRate: `${dashboardData?.totalPromoterTransactionsCount} `,
          sbadgesClass: 'warning',
        },
        {
          sbadges: 'Today Transactions',
          sbadgesRate: `${dashboardData?.todayPromoterTransactionsCount} `,
          sbadgesClass: 'warning',
        },
        {
          sbadges: 'Today Views',
          sbadgesRate: dashboardData?.todayPageViewsCount,
          sbadgesPercentage: null,
          sbadgesClass: 'success',
          sbadgesPercentageColor: 'success',
        },
        {
          sbadges: 'Today Requests',
          sbadgesRate: dashboardData?.todayWithdrawalRequestsCount,
          sbadgesClass: 'success',
        },
        {
          sbadges: (
            <>
              <Link
                to={{
                  pathname: '/supply-chain-products',
                  state: {
                    isSupplyChain: true,
                  },
                }}
              >
                Supply Chain Products
              </Link>
            </>
          ),
          sbadgesRate: null,
          sbadgesClass: 'success',
        },
      ],
      Link: '/users',
      col: 'col-12 col-sm-12 col-xs-12 col-md-12 col-lg-4 col-xl-4',
    },
  ]

  const reports = [
    {
      title: 'Promoters',
      iconClass: 'fas fa-layer-group',
      description: dashboardData?.totalPromotersCount,
      wbadges: '',
      wbadgesRate: '',
      Link: '/promoters',
      col: { md: 3 },
    },
    // {
    //   title: "Page Views",
    //   iconClass: "fas fa-layer-group",
    //   description: dashboardData?.totalPageViewsCount,
    //   wbadges: "",
    //   wbadgesRate: "",
    //   Link: "/all-page-view",
    //   col: { md: 3 },
    // },
    {
      title: 'Supply Chains',
      iconClass: 'bx bxs-collection',
      description: dashboardData?.totalSupplyChainsCount,
      wbadges: '',
      wbadgesRate: '',
      Link: '/supply-chain',
      col: { md: 3 },
    },
    {
      title: 'Chain Promoters',
      iconClass: 'bx bxs-extension',
      description: dashboardData?.totalChainPromotersCount,
      wbadges: '',
      wbadgesRate: '',
      Link: '/chain-promoter',
      col: { md: 3 },
    },
    {
      title: 'Transactions',
      iconClass: 'fas fa-layer-group',
      description: dashboardData?.totalPromoterTransactionsCount,
      wbadges: '',
      wbadgesRate: '',
      Link: '/promoter-transactions',
      col: { md: 3 },
    },
    {
      title: 'lucky Draws',
      iconClass: 'fas fa-ticket-alt',
      description: dashboardData?.totalLuckyDrawsCount,
      wbadges: '',
      wbadgesRate: '',
      Link: '/lucky-draw',
      col: { md: 3 },
    },
    {
      title: 'Requests',
      iconClass: 'fas fa-ticket-alt',
      description: dashboardData?.totalWithdrawalRequestsCount || 0,
      wbadges: '',
      wbadgesRate: '',
      Link: '/withdrawal-requests',
      col: { md: 3 },
    },
    {
      title: 'Deductions',
      iconClass: 'fas fa-layer-group',
      description: dashboardData?.totalDeductionsCount || 0,
      wbadges: '',
      wbadgesRate: '',
      Link: '/deductions',
      col: { md: 3 },
    },
    {
      title: 'IMA Wallet Withdrawals',
      iconClass: 'bx bxs-collection',
      description: dashboardData?.totalImaWalletWithdrawalsCount || 0,
      wbadges: '',
      wbadgesRate: '',
      Link: '/Wallet-Withdrawal-List',
      col: { md: 3 },
    },
    {
      title: 'Idealsply Wallet Withdrawals',
      iconClass: 'bx bxs-collection',
      description: dashboardData?.totalIdealsplyWalletWithdrawalsCount || 0,
      wbadges: '',
      wbadgesRate: '',
      Link: '/idealsply-Wallet-Withdrawal-List',
      col: { md: 3 },
    },
  ]

  // ['supplyChain']

  const handleReport = () => {
    return reports
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTag title=" Promoters Dashboard" />
        <Container fluid>
          {loading ? (
            <Spinner color="secondary" className="d-block m-auto" />
          ) : (
            <>
              <p>Promoter & SupplyChain</p>
              <hr />
              <Row>
                {map(TopCards, (item, key) => (
                  <Col className={item.col} key={key}>
                    <Card className={'mega-stats-wid ' + item.bgColor}>
                      <Link to={item.Link}>
                        <CardBody
                          style={{
                            height: width?.width <= 425 ? 'auto' : '200px',
                          }}
                          className={
                            'd-flex flex-column justify-content-between ' +
                            item.subList
                              ? 'align-items-start'
                              : 'align-items-center'
                          }
                        >
                          <div
                            className={`d-flex flex-wrap align-items-center justify-content-center mb-0 ${
                              item?.subList === false && 'flex-column'
                            }`}
                          >
                            {item?.highLightList?.length >= 1 && (
                              <div className="d-flex mb-2">
                                <Row className="d-flex justify-content-center">
                                  {item?.highLightList?.map(
                                    (topList, index) => (
                                      <Col
                                        key={index}
                                        className={`mx-3 ${
                                          item?.subList === false &&
                                          'mt-2 text-center'
                                        }`}
                                      >
                                        <h6
                                          className="mb-2 "
                                          style={{ whiteSpace: 'nowrap' }}
                                        >
                                          <span className="mb-2 text_md_black">
                                            {topList?.title}
                                          </span>
                                        </h6>
                                        <h4
                                          style={{ whiteSpace: 'nowrap' }}
                                          className="text_deals_green text-center"
                                        >
                                          {topList?.description}
                                        </h4>
                                      </Col>
                                    ),
                                  )}
                                </Row>
                              </div>
                            )}
                            {item?.highLightList2?.length >= 1 && (
                              <div className="d-flex mt-3">
                                <Row className="d-flex justify-content-center">
                                  {item?.highLightList2?.map(
                                    (topList, index) => (
                                      <Col
                                        key={index}
                                        className={`mx-3 ${
                                          item?.subList === false &&
                                          'mt-2 text-center'
                                        }`}
                                      >
                                        <h6
                                          className="mb-2 "
                                          style={{ whiteSpace: 'nowrap' }}
                                        >
                                          <span className="mb-2 text_md_black">
                                            {topList?.title}
                                          </span>
                                        </h6>
                                        <h4
                                          style={{ whiteSpace: 'nowrap' }}
                                          className="text_deals_green text-center"
                                        >
                                          {topList?.description}
                                        </h4>
                                      </Col>
                                    ),
                                  )}
                                </Row>
                              </div>
                            )}
                          </div>

                          {map(item?.list, (subItem, subKey) => (
                            <span
                              className="d-flex align-items-center mb-2 text_md_black"
                              key={subKey}
                            >
                              <i className="bx bx-right-arrow-alt font-size-16 text_deals_yellow mx-2"></i>{' '}
                              {subItem.sbadges}{' '}
                              <p
                                className={
                                  'mb-0 mx-2 text-white fw-medium rounded-pill badge bg-' +
                                  subItem?.sbadgesClass
                                }
                                style={{ fontSize: '14px' }}
                              >
                                {subItem?.sbadgesRate}
                              </p>
                              <p
                                className={
                                  'mb-0 mx-2 fw-medium rounded-pill badge ' +
                                  `text-${subItem?.sbadgesPercentageColor}`
                                }
                                style={{ fontSize: '14px' }}
                              >
                                {subItem?.sbadgesPercentage}{' '}
                                {/* {subItem?.sbadgesPercentageColor
                                  ? subItem?.sbadgesPercentageColor ===
                                    'success'
                                    ? '👍'
                                    : '👎'
                                  : ''} */}
                              </p>
                            </span>
                          ))}
                        </CardBody>
                      </Link>
                    </Card>
                  </Col>
                ))}
              </Row>
              <Row>
                {handleReport()?.map((report, key) => (
                  <Col md={report.col.md} key={'_col_' + key}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <Link to={report.Link}>
                          <Media>
                            <Media body>
                              <p
                                className="text-muted fw-medium d-flex align-items-center"
                                style={{ whiteSpace: 'nowrap' }}
                              >
                                <i
                                  className={`font-size-16 text-success ${report.iconClass}`}
                                ></i>
                                <span className="mx-2">{report.title}</span>
                              </p>
                              <h4 className="mb-0  ">{report.description}</h4>
                            </Media>

                            <Media body className="text-center">
                              <div className="flex-grow-1">
                                <p
                                  className={
                                    'text-white fw-medium rounded-pill badge bg-' +
                                    report?.sbadgesClass
                                  }
                                >
                                  {report.wbadges}
                                </p>
                                <h4 className="mb-0">{report.wbadgesRate}</h4>
                              </div>
                            </Media>
                          </Media>
                        </Link>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
              <Row>
                <Col md={6} lg={6}>
                  <Card>
                    <CardBody>
                      <LineApexChart GraphData={dashboardGraph} />
                    </CardBody>
                  </Card>
                </Col>
                <Col md={6} lg={6}>
                  <Card>
                    <CardBody>
                      <SplineArea stateValue={dashboardData} />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PromotersDashboard
