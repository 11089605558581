import {
  GET_BANNERS,
  GET_BANNERS_FAIL,
  GET_BANNERS_SUCCESS,
  GET_BANNER_DETAILS,
  GET_BANNER_DETAILS_SUCCESS,
  GET_BANNER_DETAILS_FAIL,
  CREATE_BANNER_SUCCESS,
  CREATE_BANNER_FAIL,
  UPDATE_BANNER_SUCCESS,
  UPDATE_BANNER,
  UPDATE_BANNER_FAIL,
  DELETE_BANNER_SUCCESS,
  DELETE_BANNER_FAIL,
  CREATE_BANNER,
} from './actionTypes'

const INIT_STATE = {
  banners: [],
  bannerDetails: {},
  error: {},
  loading: false,
}

const Banner = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BANNERS:
    case GET_BANNER_DETAILS:
    case UPDATE_BANNER:
    case CREATE_BANNER:
      return {
        ...state,
        loading: true,
      }
    case GET_BANNERS_SUCCESS:
      return {
        ...state,
        banners: action.payload,
        error: {},
        loading: false,
      }

    case GET_BANNERS_FAIL:
    case UPDATE_BANNER_FAIL:
    case DELETE_BANNER_FAIL:
    case CREATE_BANNER_FAIL:
    case GET_BANNER_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_BANNER_DETAILS_SUCCESS:
      return {
        ...state,
        bannerDetails: action.payload,
        error: {},
        loading: false,
      }

    case CREATE_BANNER_SUCCESS:
      return {
        ...state,
        banners: [...state.banners, action.payload],
        error: {},
        loading: false,
      }

    case UPDATE_BANNER_SUCCESS:
      return {
        ...state,
        banners: {
          banners: state?.banners?.banners?.map(banner =>
            banner._id.toString() === action.payload._id.toString()
              ? { banner, ...action.payload }
              : banner,
          ),
        },
        error: {},
        loading: false,
      }

    case DELETE_BANNER_SUCCESS:
      return {
        ...state,
        banners: {
          banners: state.banners?.banners?.filter(
            banner => banner._id !== action.payload._id,
          ),
        },
        error: {},
        loading: false,
      }

    default:
      return state
  }
}

export default Banner
