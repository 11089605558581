import {
  GET_ORDERS,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_FAIL,
  GET_ORDER_DETAILS,
  GET_ORDER_DETAILS_SUCCESS,
  GET_ORDER_DETAILS_FAIL,
  GET_USER_ORDER_DETAILS,
  GET_USER_ORDER_DETAILS_SUCCESS,
  GET_USER_ORDER_DETAILS_FAIL,
  UPDATE_ORDER_ITEM,
  UPDATE_ORDER_ITEM_SUCCESS,
  UPDATE_ORDER_ITEM_FAIL,
  GET_STARRED_ORDERS,
  GET_STARRED_ORDERS_SUCCESS,
  GET_STARRED_ORDERS_FAIL,
  GET_CANCELLED_ORDERS,
  GET_CANCELLED_ORDERS_SUCCESS,
  GET_CANCELLED_ORDERS_FAIL,
  GET_RETURNED_ORDERS,
  GET_RETURNED_ORDERS_SUCCESS,
  GET_RETURNED_ORDERS_FAIL,
  UPDATE_ORDER,
  UPDATE_ORDER_FAIL,
  UPDATE_ORDER_SUCCESS,
  UPDATE_PAYMENT,
  UPDATE_PAYMENT_SUCCESS,
  UPDATE_PAYMENT_FAIL,
  UPDATE_ORDER_AWB_NUMBER,
  UPDATE_ORDER_AWB_NUMBER_SUCCESS,
  UPDATE_ORDER_AWB_NUMBER_FAIL,
  CREATE_SHIPROCKET_ORDER,
  CREATE_SHIPROCKET_ORDER_SUCCESS,
  CREATE_SHIPROCKET_ORDER_FAIL,
  CREATE_USER_ORDER_FAIL,
  CREATE_USER_ORDER,
  CREATE_USER_ORDER_SUCCESS,
  CLEAR_ORDER,
  WHATSAPP_ORDER_ONLINE_MSG,
  WHATSAPP_ORDER_ONLINE_MSG_SUCCESS,
  WHATSAPP_ORDER_ONLINE_MSG_FAIL,
  GET_TOTAL_ORDER_COUNTS,
  GET_TOTAL_ORDER_COUNTS_FAIL,
  GET_TOTAL_ORDER_COUNTS_SUCCESS,
  GET_SHIPROCKET_LABEL,
  GET_SHIPROCKET_LABEL_FAIL,
  GET_SHIPROCKET_LABEL_SUCCESS,
  GET_SHIPROCKET_INVOICE,
  GET_SHIPROCKET_INVOICE_FAIL,
  GET_SHIPROCKET_INVOICE_SUCCESS,
  ORDER_AUTO_STATUS_UPDATE,
  ORDER_AUTO_STATUS_UPDATE_SUCCESS,
  ORDER_AUTO_STATUS_UPDATE_FAIL,
  CLEAR_AUTO_STATUS_UPDATE,
  CREATE_ORDER_EDIT_SUCCESS,
  CREATE_ORDER_EDIT_FAIL,
} from './actionTypes'

const INIT_STATE = {
  orders: {
    orders: [],
    total: '',
    page: '',
  },
  orderDetails: {
    orderItems: [],
  },
  userOrderDetails: {},
  starredOrders: [],
  cancelledOrders: [],
  returnedOrders: [],
  orderVarients: {},
  userCart: {},
  error: {},
  loading: false,
  util: {},
  msgLoad: false,
  orderCounts: {},
  shiprocketLabel: {},
  shiprocketLoad: false,
  shiprocketInvoice: {},
  shiprocketInvoiceLoad: false,
  autoUpdateStatus: '',
  autoUpdateLoad: false,
  orderEdit: {},
}

const Orders = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ORDERS:
    case GET_ORDER_DETAILS:
    case GET_USER_ORDER_DETAILS:
    case UPDATE_ORDER_ITEM:
    case GET_STARRED_ORDERS:
    case UPDATE_ORDER:
    case GET_CANCELLED_ORDERS:
    case GET_RETURNED_ORDERS:
    case UPDATE_PAYMENT:
    case UPDATE_ORDER_AWB_NUMBER:
    case CREATE_SHIPROCKET_ORDER:
    case CREATE_USER_ORDER:
    case GET_TOTAL_ORDER_COUNTS:
      return {
        ...state,
        loading: true,
      }
    case GET_SHIPROCKET_LABEL:
      return {
        ...state,
        shiprocketLoad: true,
      }
    case GET_SHIPROCKET_INVOICE:
      return {
        ...state,
        shiprocketInvoiceLoad: true,
      }

    //fail
    case GET_RETURNED_ORDERS_FAIL:
    case GET_ORDERS_FAIL:
    case GET_ORDER_DETAILS_FAIL:
    case UPDATE_ORDER_ITEM_FAIL:
    case GET_USER_ORDER_DETAILS_FAIL:
    case UPDATE_ORDER_AWB_NUMBER_FAIL:
    case CREATE_SHIPROCKET_ORDER_FAIL:
    case UPDATE_ORDER_FAIL:
    case GET_STARRED_ORDERS_FAIL:
    case GET_CANCELLED_ORDERS_FAIL:
    case UPDATE_PAYMENT_FAIL:
    case CREATE_USER_ORDER_FAIL:
    case GET_TOTAL_ORDER_COUNTS_FAIL:
    case GET_SHIPROCKET_LABEL_FAIL:
    case GET_SHIPROCKET_INVOICE_FAIL:
    case CREATE_ORDER_EDIT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        shiprocketLoad: false,
        shiprocketInvoiceLoad: false,
      }

    case GET_TOTAL_ORDER_COUNTS_SUCCESS:
      return {
        ...state,
        orderCounts: action.payload,
        loading: false,
        error: {},
      }
    case GET_ORDERS_SUCCESS:
      return {
        ...state,
        orders: action.payload.orders,
        total: action.payload.total,
        loading: false,
        error: {},
      }

    case GET_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        orderDetails: action.payload,
        orderVarients: action.payload.orderVarients,
        error: {},
        loading: false,
      }

    //USER Order Details
    case GET_USER_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        userOrderDetails: action.payload,
        error: {},
        loading: false,
      }

    //Update
    case UPDATE_ORDER_ITEM_SUCCESS:
      return {
        ...state,
        orderDetails: action.payload,
        error: {},
        loading: false,
      }

    case UPDATE_ORDER_SUCCESS:
      return {
        ...state,
        orderDetails: action.payload,
        error: {},
        loading: false,
      }

    //awb
    case UPDATE_ORDER_AWB_NUMBER_SUCCESS:
      return {
        ...state,
        orderDetails: action.payload,
        error: {},
        loading: false,
      }
    case CREATE_SHIPROCKET_ORDER_SUCCESS:
      return {
        ...state,
        orderDetails: action.payload,
        error: {},
        loading: false,
      }

    case UPDATE_PAYMENT_SUCCESS:
      return {
        ...state,
        orderDetails: action.payload,
        error: {},
        loading: false,
      }

    case GET_STARRED_ORDERS_SUCCESS:
      return {
        ...state,
        starredOrders: action.payload,
        error: {},
        loading: false,
      }

    case GET_CANCELLED_ORDERS_SUCCESS:
      return {
        ...state,
        cancelledOrders: action.payload,
        error: {},
        loading: false,
      }

    case GET_RETURNED_ORDERS_SUCCESS:
      return {
        ...state,
        returnedOrders: action.payload,
        error: {},
        loading: false,
      }

    case CREATE_USER_ORDER_SUCCESS:
      return {
        ...state,
        orders: {
          orders: [...state.orders.orders, action.payload],
        },
        error: {},
        loading: false,
      }

    case CLEAR_ORDER:
      return {
        ...state,
        userOrderDetails: {},
        orderDetails: {},
        loading: false,
        error: {},
      }

    case WHATSAPP_ORDER_ONLINE_MSG:
      return {
        ...state,
        msgLoad: true,
        error: {},
      }
    case WHATSAPP_ORDER_ONLINE_MSG_SUCCESS:
      return {
        ...state,
        msgLoad: false,
        util: action.payload,
      }
    case WHATSAPP_ORDER_ONLINE_MSG_FAIL:
      return {
        ...state,
        msgLoad: false,
        error: action.payload,
      }

    case GET_SHIPROCKET_LABEL_SUCCESS:
      return {
        ...state,
        shiprocketLabel: action.payload,
        shiprocketLoad: false,
        error: {},
      }
    case GET_SHIPROCKET_INVOICE_SUCCESS:
      return {
        ...state,
        shiprocketInvoice: action.payload,
        shiprocketInvoiceLoad: false,
        error: {},
      }

    case ORDER_AUTO_STATUS_UPDATE:
      return {
        ...state,
        autoUpdateLoad: true,
      }
    case ORDER_AUTO_STATUS_UPDATE_SUCCESS:
      return {
        ...state,
        autoUpdateLoad: false,
        autoUpdateStatus: action.payload,
      }
    case ORDER_AUTO_STATUS_UPDATE_FAIL:
      return {
        ...state,
        autoUpdateLoad: false,
        error: action.payload,
      }
    case CLEAR_AUTO_STATUS_UPDATE:
      return {
        ...state,
        autoUpdateLoad: false,
        autoUpdateStatus: '',
      }
    case CREATE_ORDER_EDIT_SUCCESS:
      return {
        ...state,
        orderDetails: action.payload,
        error: {},
        loading: false,
      }
    default:
      return state
  }
}

export default Orders
