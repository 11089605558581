import { AvField } from 'availity-reactstrap-validation'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Label, Row } from 'reactstrap'
import { checkPromoterId } from 'store/actions'
import PropTypes from 'prop-types'
import Switch from 'react-switch'

function PromoterForm({ updateData, isUpdate, isImaUser, setIsImaUser }) {
  const dispatch = useDispatch()
  const role = sessionStorage.getItem('role')

  const [checkPromoter, setCheckPromoter] = useState()
  const { promoterIdCheck } = useSelector(state => ({
    promoterIdCheck: state?.Users?.promoterIdCheck?.response,
  }))

  const handleInputChange = event => {
    event.target.value = event.target.value.toUpperCase()
  }

  function isInvalidIFSC(ifsc_Code) {
    let regex = new RegExp(/^[A-Z]{4}0[A-Z0-9]{6}$/)

    if (ifsc_Code == null) {
      return 'Enter a valid IFSC code'
    }

    if (regex.test(ifsc_Code) == true) {
      return true
    } else {
      return 'Enter a valid IFSC code'
    }
  }
  console.log(updateData)
  useEffect(() => {
    setIsImaUser(updateData?.isImaUser)
  }, [updateData])
  return (
    <>
      <Row>
        <Col className="col-12 d-flex flex-wrap">
          <div className="col-6 mb-4 px-3">
            <AvField
              label={
                <>
                  Name<sup>*</sup>
                </>
              }
              name="name"
              required
              type="text"
              value={updateData?.name ?? ''}
            />
          </div>
          <div className="col-6 mb-4 px-3">
            <AvField
              label={
                <>
                  Phone<sup>*</sup>
                </>
              }
              name="phone"
              maxLength="10"
              required
              type="phone"
              value={updateData?.phone ?? ''}
            />
          </div>
          {isUpdate ? (
            <div className="col-12 mb-4 px-3">
              <AvField
                name="email"
                // required
                readOnly={updateData?.email ? true : false}
                label={
                  <>
                    Email
                    {/* <sup>*</sup> */}
                  </>
                }
                type="email"
                value={updateData?.email ?? ''}
              />
            </div>
          ) : (
            <div className="col-6 mb-4 px-3">
              <AvField
                name="email"
                // required
                label={
                  <>
                    Email
                    {/* <sup>*</sup> */}
                  </>
                }
                type="email"
                value={updateData?.email ?? ''}
              />
            </div>
          )}

          {isUpdate ? (
            ''
          ) : (
            <div className="col-6 mb-4 px-3">
              <AvField
                name="password"
                required
                value={updateData?.password ?? ''}
                label={
                  <>
                    Password<sup>*</sup>
                  </>
                }
                type="password"
              />
            </div>
          )}

          <div className="col-12 mb-4 px-3">
            <AvField
              name="address"
              label="Address"
              value={updateData?.address ?? ''}
              type="textarea"
            />
          </div>

          <div className="col-6 mb-4 px-3">
            <AvField
              name="ref_id"
              required
              value={updateData?.ref_id ?? ''}
              readOnly={updateData?.ref_id ? true : false}
              label={
                <>
                  Promoter Id<sup>*</sup>
                </>
              }
              type="text"
              onChange={e => {
                setCheckPromoter(e.target.value)
                dispatch(checkPromoterId(e.target.value))
              }}
            />

            {checkPromoter && (
              <>
                {promoterIdCheck === true ? (
                  <h6 className="pt-1" style={{ color: 'green' }}>
                    {' '}
                    Available
                  </h6>
                ) : (
                  <h6 className="pt-1" style={{ color: 'red' }}>
                    Not Available{' '}
                  </h6>
                )}
              </>
            )}
          </div>
          <div className="col-6 align-items-center d-flex mb-3 mt-3">
            <Label check for="checkbox">
              {' '}
              Ima User
            </Label>
            <Switch
              uncheckedIcon={<OffSymbol />}
              checkedIcon={<OnSymbol />}
              className="me-1 mb-sm-8 mx-3"
              onColor="#626ed4"
              onChange={v => {
                setIsImaUser(v)
              }}
              checked={isImaUser}
            />
          </div>
          {role === 'admin' && (
            <>
              {/* <div className="col-4 mb-4 px-3">
                <AvField
                  required
                  name="promoterPercentage"
                  value={updateData?.promoterPercentage ?? ''}
                  type="number"
                  label={
                    <>
                      Promoter Percentage<sup>*</sup>
                    </>
                  }
                />
              </div> */}
              {/* <div className="col-4 mb-4 px-3">
                <AvField
                  required
                  name="walletBalance"
                  value={updateData?.walletBalance ?? ''}
                  label={<>Wallet Balance</>}
                  type="number"
                />
              </div> */}
            </>
          )}
        </Col>
      </Row>
      <Row className="mb-4 px-3">
        <div>
          <p className="text-muted">Account Details :</p>
        </div>
        <Row>
          <Col md={6} lg="6">
            <AvField
              name="accountDetails.accountNumber"
              value={updateData?.accountDetails?.accountNumber ?? ''}
              label={'Account Number'}
              type="text"
            />
          </Col>
          <Col md={6} lg="6">
            <AvField
              name="accountDetails.ifsc"
              value={updateData?.accountDetails?.ifsc || ''}
              label={'IFSC Code'}
              type="text"
              // validate={{
              //   custom: isInvalidIFSC,
              // }}
              className={isInvalidIFSC ? 'is-valid' : ''}
              onInput={handleInputChange}
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col md={6} lg="6">
            <AvField
              name="accountDetails.panNumber"
              value={updateData?.accountDetails?.panNumber ?? ''}
              label={'Pan Number'}
              type="text"
            />
          </Col>
          <Col md={6} lg="6">
            <AvField
              name="accountDetails.benificiaryName"
              value={updateData?.accountDetails?.benificiaryName ?? ''}
              label={'Benificiary Name'}
              type="text"
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col md={6} lg="6">
            <AvField
              name="accountDetails.bankName"
              value={updateData?.accountDetails?.bankName ?? ''}
              label={'Bank Name'}
              type="text"
            />
          </Col>
          <Col md={6} lg="6">
            <AvField
              name="accountDetails.branchName"
              value={updateData?.accountDetails?.branchName ?? ''}
              label={'Branch Name'}
              type="text"
            />
          </Col>
        </Row>
      </Row>
    </>
  )
}

PromoterForm.propTypes = {
  updateData: PropTypes.object,
  isUpdate: PropTypes.bool,
  isImaUser: PropTypes.any,
  setIsImaUser: PropTypes.any,
}
export default PromoterForm
export const OffSymbol = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        fontSize: 12,
        color: '#fff',
        paddingRight: 2,
      }}
    >
      {' '}
      No
    </div>
  )
}

export const OnSymbol = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        fontSize: 12,
        color: '#fff',
        paddingRight: 2,
      }}
    >
      {' '}
      Yes
    </div>
  )
}
