import React, { useState, useEffect, Fragment } from 'react'
import { Col, Dropdown, DropdownMenu, DropdownToggle, Row } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import SimpleBar from 'simplebar-react'

//i18n
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { getNotifications } from 'store/actions'
import moment from 'moment'

import notification from 'assets/images/other/notification.png'

const NotificationDropdown = () => {
  const dispatch = useDispatch()
  const [menu, setMenu] = useState(false)
  const [all, setAll] = useState(false)

  //notification
  const { notifications, user } = useSelector(state => ({
    notifications: state.Notification.notifications.notifications,
    user: state.Login.user,
  }))

  const role = sessionStorage.getItem('role') || user?.role

  useEffect(() => {
    if (role !== 'courseProvider') {
      dispatch(getNotifications(1, 10, '', ''))
    }
  }, [dispatch])

  const parent = notifications?.slice(0, 3)

  const getAll = () => {
    if (all) {
      return notifications?.slice(0, 10)
    } else {
      return parent
    }
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon "
          tag="button"
          id="page-header-notifications-dropdown"
          disabled={role === 'courseProvider'}
        >
          <i className="bx bx-bell bx-tada" />
          <span className="badge bg-danger rounded-pill">
            {getAll()?.length}
          </span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg p-0 dropdown-menu-end">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {'Notifications'} </h6>
              </Col>
              <div className="col-auto">
                <a href="#!" className="small" onClick={() => setAll(!all)}>
                  {' '}
                  {all ? 'Show Less' : 'Show All'}
                </a>
              </div>
            </Row>
          </div>

          <SimpleBar style={{ height: all ? '400px' : '230px' }}>
            {getAll()?.map((item, key) => (
              <Fragment key={key}>
                <a
                  href={item?.link}
                  target="_blank"
                  rel="noreferrer"
                  className="text-reset notification-item"
                >
                  <div className="media" style={{ padding: '7px' }}>
                    <div className="media-body d-flex align-items-start">
                      <img
                        src={item?.images?.[0]?.url || notification}
                        alt="notification"
                        className="avatar-sm rounded me-2"
                        style={{ borderRadius: '14px' }}
                      />
                      <div className="me-2">
                        <h6 className="mt-0 mb-1">{item?.title}</h6>
                        <div className="font-size-12 text-muted">
                          <p className="mb-1">{item?.description}</p>
                          <a href={item?.link} target="_blank" rel="noreferrer">
                            {item?.link}
                          </a>
                          <p>
                            <i className="mdi mdi-clock-outline" />{' '}
                            {moment(item?.createdAt).fromNow() == '8 days ago'
                              ? moment(item?.createdAt).format(
                                  'h:mm a | MMM/DD/YYYY',
                                )
                              : moment(item?.updatedAt).fromNow()}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </Fragment>
            ))}
          </SimpleBar>
          <div className="p-2 border-top d-grid">
            <Link
              className="btn btn-sm btn-link font-size-14 btn-block text-center"
              to="/notifications"
            >
              <i className="mdi mdi-arrow-right-circle me-1"></i>{' '}
              {all ? 'Show Less' : 'Show All'}
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default withTranslation()(NotificationDropdown)
