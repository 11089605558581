import React, { useState } from 'react'
import { Row, Col, Spinner } from 'reactstrap'
import { useSelector } from 'react-redux'

// data-table related plugins
import BootstrapTable from 'react-bootstrap-table-next'

import { map, range } from 'lodash'

import ToolkitProvider from 'react-bootstrap-table2-toolkit'
import MyPagination from '../../../components/Common/MyPagination'

import '../../../assets/scss/data-tables.scss'
import { LanguageSwitch } from 'hooks/LanguageSwitch'
import { Link } from 'react-router-dom'
import { CurrencySwitch } from 'hooks/Currency'
import { defaultImg } from 'assets/images'

const CartDataTable = () => {
  const [page, setPage] = useState(1)

  const { userCartDetails, loading } = useSelector(state => ({
    userCartDetails: state.Users.userCartDetails,
    loading: state.Users.loading,
  }))

  const total = userCartDetails?.cartItems?.length

  const currency = CurrencySwitch()

  //pagination
  const totalPages = Math.ceil(total / 10)
  const pages = range(1, totalPages + 1)

  const PreColumns = [
    {
      dataField: 'imageData',
      text: 'Image',
    },
    {
      dataField: 'productName',
      text: 'Name',
    },
    {
      dataField: 'product.secondaryLang.name',
      text: 'Secondary Name',
    },
    {
      dataField: 'price',
      text: 'Price',
      sort: true,
    },
    {
      dataField: 'quantity',
      text: 'Qty',
      sort: true,
    },
    {
      dataField: 'action',
      text: 'Total',
    },
  ]

  const columns = LanguageSwitch()?.isMult
    ? PreColumns
    : PreColumns?.filter(i => i.text !== 'Secondary Name')

  const userCartData = map(userCartDetails?.cartItems, item => ({
    ...item,
    imageData: (
      <Link to={`/product/${item?.product?._id}`}>
        <img
          className="rounded-circle header-profile-user"
          style={{ width: '70px', height: '70px' }}
          src={
            item?.product?.images ? item?.product?.images[0]?.url : defaultImg
          }
        />
      </Link>
    ),
    productName: (
      <Link to={`/product/${item?.product?._id}`}>
        <span>
          {item?.product.primaryLang.name} ({item?.varientValue}{' '}
          {item?.varientUnit})
        </span>
      </Link>
    ),
    price: (
      <span>
        {currency} {item?.price}{' '}
      </span>
    ),
    action: (
      <span>
        {currency} {item?.price * item?.quantity}{' '}
      </span>
    ),
  }))

  const handleTableChange = () => {}

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <ToolkitProvider
            keyField="_id"
            columns={columns}
            data={userCartData}
            search
          >
            {toolkitProps => (
              <React.Fragment>
                {loading ? (
                  <Spinner color="secondary" className="d-block m-auto" />
                ) : (
                  <>
                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          <BootstrapTable
                            // keyField={"id"}
                            key={'id'}
                            responsive
                            bordered={false}
                            striped={false}
                            // defaultSorted={defaultSorted}
                            // selectRow={selectRow}
                            classes={'table align-middle table-nowrap'}
                            headerWrapperClasses={'thead-light'}
                            {...toolkitProps.baseProps}
                            onTableChange={handleTableChange}
                          />
                        </div>
                      </Col>
                    </Row>
                    <MyPagination
                      totalPages={pages}
                      page={page}
                      setPage={setPage}
                    />
                  </>
                )}
              </React.Fragment>
            )}
          </ToolkitProvider>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default CartDataTable
