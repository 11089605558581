import React, { Fragment, useEffect } from 'react'
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Spinner,
  UncontrolledTooltip,
} from 'reactstrap'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// data-table related plugins
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from 'react-bootstrap-table2-paginator'
import { map } from 'lodash'

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'

//actions
import {
  getDeletedProducts,
  hardDeleteProduct,
  restoreProduct,
} from 'store/products/actions'

import '../../../assets/scss/data-tables.scss'
import { defaultImg } from 'assets/images'

const DeletedProducts = () => {
  const dispatch = useDispatch()

  const { deletedProducts, loading } = useSelector(state => ({
    deletedProducts: state.Products.deletedProducts,
    loading: state.Products.loading,
  }))

  const handleHardDelete = id => {
    dispatch(hardDeleteProduct(id, history))
  }

  const handleRestore = id => {
    dispatch(restoreProduct(id, history))
  }

  useEffect(() => {
    dispatch(getDeletedProducts())
  }, [dispatch])

  const columns = [
    {
      dataField: 'name',
      text: 'Product Name',
      sort: true,
    },
    {
      dataField: 'imageData',
      text: 'Image',
      sort: true,
    },
    {
      dataField: 'category.name',
      text: 'Category',
      sort: true,
    },
    {
      dataField: 'colorData',
      text: 'Color',
    },
    {
      dataField: 'activeData',
      text: 'Status',
      sort: true,
    },
    {
      dataField: 'action',
      text: 'Action',
    },
  ]

  const productData = map(deletedProducts, (item, index) => ({
    ...item,
    imageData: (
      <>
        <img
          src={
            item?.images && item?.images[0] ? item?.images[0]?.url : defaultImg
          }
          width="50px"
        />
      </>
    ),
    colorData: (
      <Row
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
        }}
      >
        <Col
          md="2"
          className={'avatar-title rounded-circle bg-soft'}
          style={{
            backgroundColor: `${item?.color?.value}`,
            width: '30px',
            height: '30px',
            display: item?.color?.value == 'Not Specified' ? 'none' : '',
          }}
        ></Col>
        <Col>{item?.color?.name}</Col>
      </Row>
    ),
    activeData: (
      <>
        {/* <Switch  onChange={() => handleSwitchChange(item?._id)} checked={item?.isActive} /> */}
        <div className="form-check form-switch form-switch-md mb-3">
          <input
            type="checkbox"
            className="form-check-input"
            id="customSwitchsizemd"
            onChange={() => {
              handleSwitchChange(item?._id)
            }}
            checked={item?.isActive}
          />
          <label className="form-check-label" htmlFor="customSwitchsizemd">
            {item?.isActive ? `Active` : `Inactive`}
          </label>
        </div>
      </>
    ),
    action: (
      <div>
        <Link to={`/product/update/${item?._id}`}>
          <Button
            id={`view${index}`}
            type="button"
            color="white"
            className="ms-1 btn"
          >
            <i
              className="bx bx-show-alt me-2"
              style={{ color: 'navy', fontSize: '22px' }}
            />
          </Button>
        </Link>
        <Button
          id={`restore${index}`}
          type="button"
          color="white"
          className="ms-1 btn"
          onClick={() => {
            handleRestore(item._id)
          }}
        >
          <i
            className="bx bx-revision me-2"
            style={{ color: 'green', fontSize: '20px' }}
          />
        </Button>
        <Button
          id={`hardDelete${index}`}
          type="button"
          color="white"
          className="ms-1 btn"
          onClick={() => {
            handleHardDelete(item._id)
          }}
        >
          <i
            className="bx bx-error-alt me-2"
            style={{ color: 'red', fontSize: '20px' }}
          />
        </Button>

        <UncontrolledTooltip placement="bottom" target={`view${index}`}>
          View Details
        </UncontrolledTooltip>
        <UncontrolledTooltip placement="bottom" target={`restore${index}`}>
          Restore
        </UncontrolledTooltip>
        <UncontrolledTooltip placement="bottom" target={`hardDelete${index}`}>
          Hard Delete
        </UncontrolledTooltip>
      </div>
    ),
  }))

  const pageOptions = {
    sizePerPage: 10,
    totalSize: deletedZestonProducts?.length,
    custom: true,
  }

  const { SearchBar } = Search

  const handleTableChange = () => {}

  const handleSwitchChange = () => {
    // TODO: API needed for status change
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <PaginationProvider
                pagination={paginationFactory(pageOptions)}
                keyField="_id"
                columns={columns}
                data={productData}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="_id"
                    columns={columns}
                    data={productData}
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-2">
                          {/* <Col md="4">
                            <div className="search-box me-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <form
                                  className="app-search d-lg-block"
                                  onChange={e => handleSearch(e)}
                                >
                                  <div className="position-relative">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Search..."
                                      defaultValue={searchText}
                                    />
                                    <span className="bx bx-search-alt" />
                                  </div>
                                </form>
                              </div>
                            </div>
                          </Col> */}
                          <Col sm="4">
                            <div className="search-box ms-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <SearchBar {...toolkitProps.searchProps} />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </Col>
                          <Col sm="8">
                            <div className="text-sm-end">
                              <Link to="/zestonProduct/create/new">
                                <Button
                                  type="button"
                                  color="success"
                                  className="btn-rounded  mb-2 me-2"
                                >
                                  <i className="mdi mdi-plus me-1" />
                                  Create Product
                                </Button>
                              </Link>
                            </div>
                          </Col>
                        </Row>
                        {loading ? (
                          <Spinner
                            color="secondary"
                            className="d-block m-auto"
                          />
                        ) : (
                          <>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={'id'}
                                    key={'id'}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    // defaultSorted={defaultSorted}
                                    // selectRow={selectRow}
                                    classes={'table align-middle table-nowrap'}
                                    headerWrapperClasses={'thead-light'}
                                    {...toolkitProps.baseProps}
                                    onTableChange={handleTableChange}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </>
                        )}
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default DeletedProducts
