import {
  CREATE_SUPPLY_CHAIN,
  CREATE_SUPPLY_CHAIN_SUCCESS,
  CREATE_SUPPLY_CHAIN_FAIL,
  GET_ALL_SUPPLY_CHAINS,
  GET_ALL_SUPPLY_CHAINS_SUCCESS,
  GET_ALL_SUPPLY_CHAINS_FAIL,
  UPDATE_SUPPLY_CHAIN,
  UPDATE_SUPPLY_CHAIN_SUCCESS,
  UPDATE_SUPPLY_CHAIN_FAIL,
  DELETE_SUPPLY_CHAIN,
  DELETE_SUPPLY_CHAIN_SUCCESS,
  DELETE_SUPPLY_CHAIN_FAIL,
  GET_SUPPLY_CHAINS_DETAILS,
  GET_SUPPLY_CHAINS_DETAILS_SUCCESS,
  GET_SUPPLY_CHAINS_DETAILS_FAIL,
  GET_ALL_CHAIN_PROMOTERS,
  GET_ALL_CHAIN_PROMOTERS_SUCCESS,
  GET_ALL_CHAIN_PROMOTERS_FAIL,
  GET_CHAIN_PROMOTER_DETAILS,
  GET_CHAIN_PROMOTER_DETAILS_SUCCESS,
  GET_CHAIN_PROMOTER_DETAILS_FAIL,
  GET_ALL_SUPPLY_CHAINS_TRANSACTION,
  GET_ALL_SUPPLY_CHAINS_TRANSACTION_SUCCESS,
  GET_ALL_SUPPLY_CHAINS_TRANSACTION_FAIL,
  GET_ALL_WALLETS,
  GET_ALL_WALLETS_SUCCESS,
  GET_ALL_WALLETS_FAIL,
  CREATE_LUCKY_DRAW_FAIL,
  CREATE_LUCKY_DRAW_SUCCESS,
  CREATE_LUCKY_DRAW,
  GET_ALL_LUCKY_DRAWS,
  GET_ALL_LUCKY_DRAWS_SUCCESS,
  GET_ALL_LUCKY_DRAWS_FAIL,
  UPDATE_LUCKY_DRAW,
  UPDATE_LUCKY_DRAW_SUCCESS,
  UPDATE_LUCKY_DRAW_FAIL,
  DELETE_LUCKY_DRAW,
  DELETE_LUCKY_DRAW_SUCCESS,
  DELETE_LUCKY_DRAW_FAIL,
  GET_LUCKY_DRAW_DETAILS,
  GET_LUCKY_DRAW_DETAILS_SUCCESS,
  GET_LUCKY_DRAW_DETAILS_FAIL,
  GET_PROMOTER_TRANSACTION,
  GET_PROMOTER_TRANSACTION_SUCCESS,
  GET_PROMOTER_TRANSACTION_FAIL,
  CREATE_WITHDRAWAL_REQUEST,
  CREATE_WITHDRAWAL_REQUEST_SUCCESS,
  CREATE_WITHDRAWAL_REQUEST_FAIL,
  GET_ALL_WITHDRAWAL_REQUESTS,
  GET_ALL_WITHDRAWAL_REQUESTS_SUCCESS,
  GET_ALL_WITHDRAWAL_REQUESTS_FAIL,
  UPDATE_WITHDRAWAL_REQUEST,
  UPDATE_WITHDRAWAL_REQUEST_SUCCESS,
  UPDATE_WITHDRAWAL_REQUEST_FAIL,
  GET_PROMOTER_SUMMERY,
  GET_PROMOTER_SUMMERY_SUCCESS,
  GET_PROMOTER_SUMMERY_FAIL,
  APPROVE_WITHDRAWAL_REQUEST,
  APPROVE_WITHDRAWAL_REQUEST_SUCCESS,
  APPROVE_WITHDRAWAL_REQUEST_FAIL,
  GET_INITIATE_LUCKY_DRAW,
  GET_INITIATE_LUCKY_DRAW_SUCCESS,
  GET_INITIATE_LUCKY_DRAW_FAIL,
  WINNER_APPROVE,
  WINNER_APPROVE_SUCCESS,
  WINNER_APPROVE_FAIL,
  LUCKY_DRAW_WON,
  LUCKY_DRAW_WON_SUCCESS,
  LUCKY_DRAW_WON_FAIL,
  LUCKY_DRAW_WINNINGS,
  LUCKY_DRAW_WINNINGS_SUCCESS,
  LUCKY_DRAW_WINNINGS_FAIL,
  DOWNLOAD_WITHDRAWAL_REQUEST,
  DOWNLOAD_WITHDRAWAL_REQUEST_SUCCESS,
  DOWNLOAD_WITHDRAWAL_REQUEST_FAIL,
  SUPPLY_CHAIN_BY_PRODUCT,
  SUPPLY_CHAIN_BY_PRODUCT_SUCCESS,
  SUPPLY_CHAIN_BY_PRODUCT_FAIL,
  GENERATE_SUPPLY_CHAIN,
  GENERATE_SUPPLY_CHAIN_SUCCESS,
  GENERATE_SUPPLY_CHAIN_FAIL,
  SYNC_CHAIN_TREE,
  SYNC_CHAIN_TREE_SUCCESS,
  SYNC_CHAIN_TREE_FAIL,
  VISUALIZE_CHAIN_TREE,
  VISUALIZE_CHAIN_TREE_SUCCESS,
  VISUALIZE_CHAIN_TREE_FAIL,
} from './actionTypes'

// create campaign
export const createSupplyChain = (supplyChain, history) => ({
  type: CREATE_SUPPLY_CHAIN,
  payload: { supplyChain, history },
})

export const createSupplyChainSuccess = supplyChain => ({
  type: CREATE_SUPPLY_CHAIN_SUCCESS,
  payload: supplyChain,
})

export const createSupplyChainFail = error => ({
  type: CREATE_SUPPLY_CHAIN_FAIL,
  payload: error,
})

// all CAMPAIGNS
export const getAllSupplyChains = (page, limit, searchText, sort) => ({
  type: GET_ALL_SUPPLY_CHAINS,
  payload: { page, limit, searchText, sort },
})

export const getAllSupplyChainsSuccess = supplyChain => ({
  type: GET_ALL_SUPPLY_CHAINS_SUCCESS,
  payload: supplyChain,
})

export const getAllSupplyChainsFail = error => ({
  type: GET_ALL_SUPPLY_CHAINS_FAIL,
  payload: error,
})

// update campaign
export const updateSupplyChain = (supplyChain, supplyChainId, history) => ({
  type: UPDATE_SUPPLY_CHAIN,
  payload: { supplyChain, supplyChainId, history },
})

export const updateSupplyChainSuccess = data => ({
  type: UPDATE_SUPPLY_CHAIN_SUCCESS,
  payload: data,
})

export const updateSupplyChainFail = error => ({
  type: UPDATE_SUPPLY_CHAIN_FAIL,
  payload: error,
})

// delete campaigns
export const deleteSupplyChain = supplyChainId => ({
  type: DELETE_SUPPLY_CHAIN,
  supplyChainId,
})

export const deleteSupplyChainSuccess = supplyChainId => ({
  type: DELETE_SUPPLY_CHAIN_SUCCESS,
  payload: supplyChainId,
})

export const deleteSupplyChainFail = error => ({
  type: DELETE_SUPPLY_CHAIN_FAIL,
  payload: error,
})
// single view
export const getSupplyChainDetails = supplyChainId => ({
  type: GET_SUPPLY_CHAINS_DETAILS,
  payload: supplyChainId,
})

export const getSupplyChainDetailsSuccess = supplyChainDetails => ({
  type: GET_SUPPLY_CHAINS_DETAILS_SUCCESS,
  payload: supplyChainDetails,
})

export const getSupplyChainDetailsFail = error => ({
  type: GET_SUPPLY_CHAINS_DETAILS_FAIL,
  payload: error,
})

// ........................................................................................................
//  ALL list chain promoter
export const getAllChainPromoters = credentials => ({
  type: GET_ALL_CHAIN_PROMOTERS,
  payload: credentials,
})

export const getAllChainPromotersSuccess = chainPromoter => ({
  type: GET_ALL_CHAIN_PROMOTERS_SUCCESS,
  payload: chainPromoter,
})

export const getAllChainPromotersFail = error => ({
  type: GET_ALL_CHAIN_PROMOTERS_FAIL,
  payload: error,
})

// chain promoter single view
export const getChainPromoterDetails = chainPromoterId => ({
  type: GET_CHAIN_PROMOTER_DETAILS,
  payload: chainPromoterId,
})

export const getChainPromoterDetailsSuccess = chainPromotersDetails => ({
  type: GET_CHAIN_PROMOTER_DETAILS_SUCCESS,
  payload: chainPromotersDetails,
})

export const getChainPromoterDetailsFail = error => ({
  type: GET_CHAIN_PROMOTER_DETAILS_FAIL,
  payload: error,
})
// .............................................................................
//  ALL list Supply chain transactions
export const getAllSupplyChainTransactions = credentials => ({
  type: GET_ALL_SUPPLY_CHAINS_TRANSACTION,
  payload: credentials,
})

export const getAllSupplyChainTransactionsSuccess =
  supplyChainTransactions => ({
    type: GET_ALL_SUPPLY_CHAINS_TRANSACTION_SUCCESS,
    payload: supplyChainTransactions,
  })

export const getAllSupplyChainTransactionsFail = error => ({
  type: GET_ALL_SUPPLY_CHAINS_TRANSACTION_FAIL,
  payload: error,
})
//............................................................
//  ALL list  wallet
export const getAllWallets = (page, sort, limit, searchText) => ({
  type: GET_ALL_WALLETS,
  payload: { page, sort, limit, searchText },
})

export const getAllWalletsSuccess = wallets => ({
  type: GET_ALL_WALLETS_SUCCESS,
  payload: wallets,
})

export const getAllWalletsFail = error => ({
  type: GET_ALL_WALLETS_FAIL,
  payload: error,
})
// ......................................................................

// create LuckyDraws
export const createLuckyDraw = (luckyDraw, onCloseClick) => ({
  type: CREATE_LUCKY_DRAW,
  payload: { luckyDraw, onCloseClick },
})

export const createLuckyDrawSuccess = luckyDraw => ({
  type: CREATE_LUCKY_DRAW_SUCCESS,
  payload: luckyDraw,
})

export const createLuckyDrawFail = error => ({
  type: CREATE_LUCKY_DRAW_FAIL,
  payload: error,
})

// all luckyDraws
export const getAllLuckyDraws = (
  page,
  sort,
  limit,
  searchText,
  supplyChainId,
) => ({
  type: GET_ALL_LUCKY_DRAWS,
  payload: { page, sort, limit, searchText, supplyChainId },
})

export const getAllLuckyDrawsSuccess = luckyDraw => ({
  type: GET_ALL_LUCKY_DRAWS_SUCCESS,
  payload: luckyDraw,
})

export const getAllLuckyDrawsFail = error => ({
  type: GET_ALL_LUCKY_DRAWS_FAIL,
  payload: error,
})
export const updateLuckyDraw = (
  luckyDraw,
  luckyDrawId,
  onCloseClick,
  history,
) => ({
  type: UPDATE_LUCKY_DRAW,
  payload: { luckyDraw, luckyDrawId, onCloseClick, history },
})
// update luckyDraws

export const updateLuckyDrawSuccess = data => ({
  type: UPDATE_LUCKY_DRAW_SUCCESS,
  payload: data,
})

export const updateLuckyDrawFail = error => ({
  type: UPDATE_LUCKY_DRAW_FAIL,
  payload: error,
})

// delete luckyDraws
export const deleteLuckyDraw = luckyDrawId => ({
  type: DELETE_LUCKY_DRAW,
  luckyDrawId,
})

export const deleteLuckyDrawSuccess = luckyDraw => ({
  type: DELETE_LUCKY_DRAW_SUCCESS,
  payload: luckyDraw,
})

export const deleteLuckyDrawFail = error => ({
  type: DELETE_LUCKY_DRAW_FAIL,
  payload: error,
})
// single view luckyDraws
export const getLuckyDrawDetails = luckyDrawId => ({
  type: GET_LUCKY_DRAW_DETAILS,
  payload: luckyDrawId,
})

export const getLuckyDrawDetailsSuccess = luckyDrawDetails => ({
  type: GET_LUCKY_DRAW_DETAILS_SUCCESS,
  payload: luckyDrawDetails,
})

export const getLuckyDrawDetailsFail = error => ({
  type: GET_LUCKY_DRAW_DETAILS_FAIL,
  payload: error,
})

// promoter transaction
export const getPromoterTransaction = promoterId => ({
  type: GET_PROMOTER_TRANSACTION,
  payload: promoterId,
})

export const getPromoterTransactionSuccess = promoterDetails => ({
  type: GET_PROMOTER_TRANSACTION_SUCCESS,
  payload: promoterDetails,
})

export const getPromoterTransactionFail = error => ({
  type: GET_PROMOTER_TRANSACTION_FAIL,
  payload: error,
})
//..................................................................................................................
// create withdrawalRequest
export const createWithdrawalRequest = (withdrawalRequest, onCloseClick) => ({
  type: CREATE_WITHDRAWAL_REQUEST,
  payload: { withdrawalRequest, onCloseClick },
})

export const createWithdrawalRequestSuccess = withdrawalRequest => ({
  type: CREATE_WITHDRAWAL_REQUEST_SUCCESS,
  payload: withdrawalRequest,
})

export const createWithdrawalRequestFail = error => ({
  type: CREATE_WITHDRAWAL_REQUEST_FAIL,
  payload: error,
})

// all withdrawalRequest
export const getAllWithdrawalRequests = credentials => ({
  type: GET_ALL_WITHDRAWAL_REQUESTS,
  payload: credentials,
})

export const getAllWithdrawalRequestsSuccess = withdrawalRequest => ({
  type: GET_ALL_WITHDRAWAL_REQUESTS_SUCCESS,
  payload: withdrawalRequest,
})

export const getAllWithdrawalRequestsFail = error => ({
  type: GET_ALL_WITHDRAWAL_REQUESTS_FAIL,
  payload: error,
})

//UPDATE
export const updateWithdrawalRequest = (
  withdrawalRequest,
  withdrawalRequestId,
  onCloseClick,
) => ({
  type: UPDATE_WITHDRAWAL_REQUEST,
  payload: { withdrawalRequest, withdrawalRequestId, onCloseClick },
})

export const updateWithdrawalRequestSuccess = data => ({
  type: UPDATE_WITHDRAWAL_REQUEST_SUCCESS,
  payload: data,
})

export const updateWithdrawalRequestFail = error => ({
  type: UPDATE_WITHDRAWAL_REQUEST_FAIL,
  payload: error,
})
//STATUS update
export const ApproveWithdrawalRequest = transactionId => ({
  type: APPROVE_WITHDRAWAL_REQUEST,
  payload: { transactionId },
})

export const ApproveWithdrawalRequestSuccess = data => ({
  type: APPROVE_WITHDRAWAL_REQUEST_SUCCESS,
  payload: data,
})

export const ApproveWithdrawalRequestFail = error => ({
  type: APPROVE_WITHDRAWAL_REQUEST_FAIL,
  payload: error,
})

// promoter dashboard
export const getPromoterSummery = () => ({
  type: GET_PROMOTER_SUMMERY,
})

export const getPromoterSummerySuccess = dashboardDetails => ({
  type: GET_PROMOTER_SUMMERY_SUCCESS,
  payload: dashboardDetails,
})

export const getPromoterSummeryFail = error => ({
  type: GET_PROMOTER_SUMMERY_FAIL,
  payload: error,
})

//Initiate Lucky Draw
export const InitiateLuckyDraw = (luckyDrawId, openModal) => ({
  type: GET_INITIATE_LUCKY_DRAW,
  payload: { luckyDrawId, openModal },
})

export const InitiateLuckyDrawSuccess = data => ({
  type: GET_INITIATE_LUCKY_DRAW_SUCCESS,
  payload: data,
})

export const InitiateLuckyDrawFail = error => ({
  type: GET_INITIATE_LUCKY_DRAW_FAIL,
  payload: error,
})

//SUPPLY_CHAIN_BY_PRODUCT
export const supplyChainByProduct = productId => ({
  type: SUPPLY_CHAIN_BY_PRODUCT,
  payload: { productId },
})

export const supplyChainByProductSuccess = data => ({
  type: SUPPLY_CHAIN_BY_PRODUCT_SUCCESS,
  payload: data,
})

export const supplyChainByProductFail = error => ({
  type: SUPPLY_CHAIN_BY_PRODUCT_FAIL,
  payload: error,
})

//winner approve
export const WinnerApprove = (winner, winnerId) => ({
  type: WINNER_APPROVE,
  payload: { winner, winnerId },
})

export const WinnerApproveSuccess = winner => ({
  type: WINNER_APPROVE_SUCCESS,
  payload: winner,
})

export const WinnerApproveFail = error => ({
  type: WINNER_APPROVE_FAIL,
  payload: error,
})
//Lucky draw won
export const LuckyDrawWon = promoterId => ({
  type: LUCKY_DRAW_WON,
  payload: promoterId,
})

export const LuckyDrawWonSuccess = data => ({
  type: LUCKY_DRAW_WON_SUCCESS,
  payload: data,
})

export const LuckyDrawWonFail = error => ({
  type: LUCKY_DRAW_WON_FAIL,
  payload: error,
})

//Lucky draw LUCKY_DRAW_WINNINGS FOR PROMOTER
export const LuckyDrawWinnings = () => ({
  type: LUCKY_DRAW_WINNINGS,
})

export const LuckyDrawWinningsSuccess = data => ({
  type: LUCKY_DRAW_WINNINGS_SUCCESS,
  payload: data,
})

export const LuckyDrawWinningsFail = error => ({
  type: LUCKY_DRAW_WINNINGS_FAIL,
  payload: error,
})

//Lucky draw DOWNLOAD_WITHDRAWAL_REQUEST FOR PROMOTER
export const downloadWithdrawalRequest = status => ({
  type: DOWNLOAD_WITHDRAWAL_REQUEST,
  payload: { status },
})

export const downloadWithdrawalRequestSuccess = data => ({
  type: DOWNLOAD_WITHDRAWAL_REQUEST_SUCCESS,
  payload: data,
})

export const downloadWithdrawalRequestFail = error => ({
  type: DOWNLOAD_WITHDRAWAL_REQUEST_FAIL,
  payload: error,
})

//GENERATE_SUPPLY_CHAIN
export const generateSupplyChain = orderId => ({
  type: GENERATE_SUPPLY_CHAIN,
  payload: { orderId },
})

export const generateSupplyChainSuccess = data => ({
  type: GENERATE_SUPPLY_CHAIN_SUCCESS,
  payload: data,
})

export const generateSupplyChainFail = error => ({
  type: GENERATE_SUPPLY_CHAIN_FAIL,
  payload: error,
})

//SYNC_CHAIN_TREE
export const syncChainTree = (supplChainId, chainPromoterId) => ({
  type: SYNC_CHAIN_TREE,
  payload: { supplChainId, chainPromoterId },
})

export const syncChainTreeSuccess = data => ({
  type: SYNC_CHAIN_TREE_SUCCESS,
  payload: data,
})

export const syncChainTreeFail = error => ({
  type: SYNC_CHAIN_TREE_FAIL,
  payload: error,
})

//VISUALIZE_CHAIN_TREE
export const visualizeChainTree = (supplChainId, chainPromoterId) => ({
  type: VISUALIZE_CHAIN_TREE,
  payload: { supplChainId, chainPromoterId },
})

export const visualizeChainTreeSuccess = data => ({
  type: VISUALIZE_CHAIN_TREE_SUCCESS,
  payload: data,
})

export const visualizeChainTreeFail = error => ({
  type: VISUALIZE_CHAIN_TREE_FAIL,
  payload: error,
})

import {
  UPDATE_BULKREQUEST,
  UPDATE_BULKREQUEST_SUCCESS,
  UPDATE_BULKREQUEST_FAIL,
} from './actionTypes'

// BulkRequest - This line cannot be edited or removed
export const updateBulkRequest = (uploadData, onCloseClick) => ({
  type: UPDATE_BULKREQUEST,
  payload: { uploadData, onCloseClick },
})

export const updateBulkRequestSuccess = bulkrequest => ({
  type: UPDATE_BULKREQUEST_SUCCESS,
  payload: bulkrequest,
})

export const updateBulkRequestFail = error => ({
  type: UPDATE_BULKREQUEST_FAIL,
  payload: error,
})
