import React from 'react'
import { Container } from 'reactstrap'

import MetaTag from 'components/Common/Meta-tag'
import ChainPromoters from './DatatableTables'
import BreadcrumbPremium from 'components/Common/Breadcrumb2'

function ChainPromoter() {
  const role = sessionStorage.getItem('role')

  return (
    <>
      <MetaTag title={'All ChainPromoter '} />

      <div className="page-content">
        <BreadcrumbPremium
          root={[
            '/promoter-dashboard',
            `${role === 'promoter' ? 'source' : '/chain-promoter'}`,
          ]}
        />
        <Container fluid>
          <div className="container-fluid">
            <ChainPromoters
              isPromoterDashboard={role === 'promoter' ? true : false}
            />
          </div>
        </Container>
      </div>
    </>
  )
}

export default ChainPromoter
