import { useCopyText } from 'hooks/useCopyText'
// import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'
import { Badge, Card, CardBody } from 'reactstrap'

function ProviderDetail() {
  const { copyText } = useCopyText()

  const { orderDetails } = useSelector(state => ({
    orderDetails: state.Orders.orderDetails,
  }))

  const handleCopy = text => {
    copyText(text)
  }

  return (
    <Card>
      <CardBody>
        <div className="mb-1 d-flex align-items-start justify-content-between">
          {/* <p className="mb-1">User Account</p> */}

          <Badge className={'font-size-12 badge-soft-info'} color="" pill>
            Course Provider
          </Badge>
        </div>
        <h6 className="mb-2 mx-1">{orderDetails?.courseProvider?.name}</h6>
        <p className="mb-1 d-flex">
          {orderDetails?.courseProvider?.phone && (
            <span className="pe-2 d-flex align-items-center">
              <i className="bx bx-phone-call text-success font-size-16 mx-1"></i>
              {orderDetails?.courseProvider?.phone}
            </span>
          )}
          {orderDetails?.courseProvider?.email && (
            <span className="d-flex align-items-center">
              <i className="bx bx-mail-send text-primary font-size-16 mx-1"></i>
              {orderDetails?.courseProvider?.email}
            </span>
          )}
        </p>
        <p className="py-1 mx-1 text-primary m-0">
          {'Copy Course Link'}{' '}
          <i
            onClick={() => handleCopy(orderDetails?.courseLink)}
            className="bx bx-copy mx-2 "
            style={{ cursor: 'copy' }}
            title="Copy course link"
          ></i>
        </p>
        {/* <p className="mb-0 mx-1 d-flex align-items-center text-warning">
          Joined on&nbsp;
          {moment(orderDetails?.courseProvider?.createdAt).format('ll')}
        </p> */}
      </CardBody>
    </Card>
  )
}

export default ProviderDetail
