// page view

export const PROMOTER_DASHBOARD_PAGE_VIEW_DETAILS =
  'PROMOTER_DASHBOARD_PAGE_VIEW_DETAILS'
export const PROMOTER_DASHBOARD_PAGE_VIEW_DETAILS_SUCCESS =
  'PROMOTER_DASHBOARD_PAGE_VIEW_DETAILS_SUCCESS'
export const PROMOTER_DASHBOARD_PAGE_VIEW_DETAILS_FAIL =
  'PROMOTER_DASHBOARD_PAGE_VIEW_DETAILS_FAIL'

// earnings

export const GET_PROMOTER_DASHBOARD_EARNINGS_DETAILS =
  'GET_PROMOTER_DASHBOARD_EARNINGS_DETAILS'
export const GET_PROMOTER_DASHBOARD_EARNINGS_DETAILS_SUCCESS =
  'GET_PROMOTER_DASHBOARD_EARNINGS_DETAILS_SUCCESS'
export const GET_PROMOTER_DASHBOARD_EARNINGS_DETAILS_FAIL =
  'GET_PROMOTER_DASHBOARD_EARNINGS_DETAILS_FAIL'

// product list

export const GET_PROMOTER_PRODUCT = 'GET_PROMOTER_PRODUCT'
export const GET_PROMOTER_PRODUCT_SUCCESS = 'GET_PROMOTER_PRODUCT_SUCCESS'
export const GET_PROMOTER_PRODUCT_FAIL = 'GET_PROMOTER_PRODUCT_FAIL'

// product details

export const GET_PROMOTER_PRODUCT_DETAILS = 'GET_PROMOTER_PRODUCT_DETAILS'
export const GET_PROMOTER_PRODUCT_DETAILS_SUCCESS =
  'GET_PROMOTER_PRODUCT_DETAILS_SUCCESS'
export const GET_PROMOTER_PRODUCT_DETAILS_FAIL =
  'GET_PROMOTER_PRODUCT_DETAILS_FAIL'

// promoter dashboard profile

export const GET_PROMOTER_PROFILE_DETAILS = 'GET_PROMOTER_PROFILE_DETAILS'
export const GET_PROMOTER_PROFILE_DETAILS_SUCCESS =
  'GET_PROMOTER_PROFILE_DETAILS_SUCCESS'
export const GET_PROMOTER_PROFILE_DETAILS_FAIL =
  'GET_PROMOTER_PROFILE_DETAILS_FAIL'

export const GET_PROMOTER_PROFILE_UPDATE = 'GET_PROMOTER_PROFILE_UPDATE'
export const GET_PROMOTER_PROFILE_UPDATE_SUCCESS =
  'GET_PROMOTER_PROFILE_UPDATE_SUCCESS'
export const GET_PROMOTER_PROFILE_UPDATE_FAIL =
  'GET_PROMOTER_PROFILE_UPDATE_FAIL'

export const GET_PROMOTER_TRANSACTION_GRAPH = 'GET_PROMOTER_TRANSACTION_GRAPH'
export const GET_PROMOTER_TRANSACTION_GRAPH_SUCCESS =
  'GET_PROMOTER_TRANSACTION_GRAPH_SUCCESS'
export const GET_PROMOTER_TRANSACTION_GRAPH_FAIL =
  'GET_PROMOTER_TRANSACTION_GRAPH_FAIL'
