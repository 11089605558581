import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { CardTitle, Modal, ModalBody } from 'reactstrap'

const NotificationViewModal = ({ show, onCloseClick, updateData }) => {
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="p-4">
        <CardTitle> {updateData?.title}</CardTitle>
        <img
          src={updateData?.images[0]?.url}
          style={{
            width: '300px',
            height: '300px',
            borderRadius: 14,
          }}
          alt="notification"
          className="mb-3"
        />
        <p>{updateData?.description}</p>
        <a href={updateData?.link} target="_blank" rel="noreferrer">
          {updateData?.link}
        </a>
        <p>
          <i className="mdi mdi-clock-outline" />{' '}
          {moment(updateData?.createdAt).fromNow() == '8 days ago'
            ? moment(updateData?.createdAt).format('h:mm a | MMM/DD/YYYY')
            : moment(updateData?.updatedAt).fromNow()}
        </p>
      </ModalBody>
    </Modal>
  )
}

NotificationViewModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
  updateData: PropTypes.any,
}

export default NotificationViewModal
