import React from 'react'
import { Container } from 'reactstrap'

import DatatableTables from './DatatableTables'
import MetaTag from 'components/Common/Meta-tag'
import BreadcrumbPremium from 'components/Common/Breadcrumb2'

function Promoters() {
  return (
    <>
      <MetaTag title={'All Promoters'} />

      <div className="page-content">
        <BreadcrumbPremium root={['/promoter-dashboard', '/promoters']} />
        <Container fluid>
          <div className="container-fluid">
            <DatatableTables />
          </div>
        </Container>
      </div>
    </>
  )
}

export default Promoters
