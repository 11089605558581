import React, { useEffect, useState } from 'react'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import { Row, Col, Card, CardBody, Button, Container } from 'reactstrap'
import { UncontrolledTooltip } from 'reactstrap'
import { Link, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import MyPagination from 'components/Common/MyPagination'

// data-table related plugins
import BootstrapTable from 'react-bootstrap-table-next'
import { debounce, map, range } from 'lodash'

import ToolkitProvider from 'react-bootstrap-table2-toolkit'

//actions
import {
  changeProductStatus,
  createDuplicateProduct,
} from 'store/products/actions'

import '../../assets/scss/data-tables.scss'

import { defaultImg } from 'assets/images'
import useCloudinary from 'hooks/useCloudinaryImage'
import { getPromoterProduct } from 'store/actions'
import MetaTag from 'components/Common/Meta-tag'
import BreadcrumbPremium from 'components/Common/Breadcrumb2'

const SupplyChainProductTable = () => {
  const dispatch = useDispatch()
  const location = useLocation()

  const isSupplyChainProduct = location?.state?.isSupplyChain

  const { total, promoterProduct } = useSelector(state => ({
    total: state.Products.total,
    promoterProduct: state?.Promoter?.promoterProduct,
  }))

  const products = promoterProduct?.supplyChainProducts

  const [totalItemLimit] = useState(10)
  const [page, setPage] = useState(1)

  const [searchText, setSearchText] = useState('')

  const handleSwitchChange = id => {
    dispatch(changeProductStatus(id, ''))
  }

  const handleCopyProduct = id => {
    dispatch(createDuplicateProduct(id, ''))
  }

  useEffect(() => {
    dispatch(getPromoterProduct())
  }, [dispatch])

  //pagination
  const totalPages = Math.ceil(total / totalItemLimit)
  const pages = range(1, totalPages + 1)

  const columns = [
    {
      dataField: 'sl',
      text: 'sl',
    },
    {
      dataField: 'nameData',
      filterText: 'name',
      text: 'Name',
      sort: true,
    },
    {
      dataField: 'imageData',
      filterText: 'image',
      text: 'Image',
      sort: true,
    },

    {
      dataField: 'price',
      text: 'Price',
      sort: true,
    },
    {
      dataField: 'stock',
      text: 'Stock',
      sort: true,
    },

    // {
    //   dataField: 'activeData',
    //   filterText: 'status',
    //   text: 'Status',
    //   sort: true,
    // },
    {
      dataField: 'action',
      text: 'Actions',
    },
  ]

  const handleColumns = () => {
    if (isSupplyChainProduct) {
      return [
        ...columns?.filter(
          i => i.dataField !== 'itemCode' && i.dataField !== 'categoryData',
        ),
      ]
    } else return columns
  }

  //all
  const allProductData = map(products, (item, index) => ({
    ...item,
    key: item?._id,
    sl: <span>{index + 1}</span>,
    itemCode: <span>{item?.itemCode}</span>,
    stock: (
      <span className={item?.stock <= 0 ? 'text-danger' : ''}>
        {item?.stock}
      </span>
    ),
    price: <span>₹ {item?.offerPrice ? item.offerPrice : item?.price}</span>,
    nameData: (
      <Row>
        {item?.primaryLang?.name.length > 40
          ? item?.primaryLang?.name.substr(0, 40 - 1) + '...'
          : item?.primaryLang?.name}
      </Row>
    ),

    imageData: (
      <>
        <img
          src={
            item?.images && item?.images[0]
              ? useCloudinary(item?.images[0]?.url, 'w_900')
              : defaultImg
          }
          width="50px"
        />
      </>
    ),

    activeData: (
      <>
        <div className="form-check form-switch form-switch-md mb-3">
          <input
            type="checkbox"
            className="form-check-input"
            id="customSwitchsizemd"
            onChange={() => {
              handleSwitchChange(item?._id)
            }}
            checked={item?.isActive}
          />
          <label className="form-check-label" htmlFor="customSwitchsizemd">
            {item?.isActive ? `Active` : `Inactive`}
          </label>
        </div>
      </>
    ),
    action: (
      <div>
        <Link to={`/product/update/${item?._id}`}>
          <Button
            id={`view${index}`}
            type="button"
            color="white"
            className="ms-1 btn"
          >
            <i
              className="bx bx-edit me-2"
              style={{ color: 'blue', fontSize: '22px' }}
            />
          </Button>
        </Link>

        <UncontrolledTooltip placement="bottom" target={`view${index}`}>
          Edit
        </UncontrolledTooltip>

        <Button
          id={`copy${index}`}
          type="button"
          color="white"
          className="ms-1 btn "
          onClick={() => {
            handleCopyProduct(item._id)
          }}
        >
          <i className="bx bx-copy me-2" style={{ fontSize: '20px' }} />
        </Button>

        <UncontrolledTooltip placement="bottom" target={`copy${index}`}>
          Duplicate
        </UncontrolledTooltip>
      </div>
    ),
  }))

  const debounceProductSearch = debounce(value => setSearchText(value), 600)

  return (
    <React.Fragment>
      <MetaTag title="Products" />

      <div className="page-content">
        <BreadcrumbPremium
          root={['/promoter-dashboard', '/supply-chain-products']}
        />

        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <div className="search-box  ms-2 mb-4 d-flex align-items-center justify-content-between">
                      <div className="position-relative w-25">
                        <AvForm>
                          <AvField
                            name="searchText"
                            placeholder="Search"
                            type="text"
                            value={searchText}
                            onChange={e =>
                              debounceProductSearch(e.target.value)
                            }
                          />
                        </AvForm>
                        <i className="bx bx-search-alt search-icon" />
                      </div>
                      <Link to="product/create/new">
                        <Button
                          type="button"
                          color="success"
                          className="btn-rounded  mb-2 me-2"
                        >
                          <i className="mdi mdi-plus me-1" />
                          Create Product
                        </Button>
                      </Link>
                    </div>

                    <div>
                      <ToolkitProvider
                        keyField="_id"
                        columns={handleColumns()}
                        data={allProductData}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <>
                              {products?.length >= 1 ? (
                                <>
                                  <Row>
                                    <p>
                                      Total Products :&nbsp;
                                      {promoterProduct?.total}{' '}
                                    </p>

                                    <Col xl="12">
                                      <div className="table-responsive">
                                        <BootstrapTable
                                          keyField={'id'}
                                          key={'id'}
                                          responsive
                                          bordered={false}
                                          striped={false}
                                          classes={
                                            'table align-middle table-nowrap'
                                          }
                                          headerWrapperClasses={'thead-light'}
                                          {...toolkitProps.baseProps}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                  <MyPagination
                                    totalPages={pages}
                                    page={page}
                                    setPage={setPage}
                                  />
                                </>
                              ) : (
                                <p className="my-4 mx-3 ">No Products !</p>
                              )}
                            </>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default SupplyChainProductTable
