//PROMOTERS

import {
  PROMOTER_DASHBOARD_PAGE_VIEW_DETAILS,
  PROMOTER_DASHBOARD_PAGE_VIEW_DETAILS_SUCCESS,
  PROMOTER_DASHBOARD_PAGE_VIEW_DETAILS_FAIL,
  GET_PROMOTER_DASHBOARD_EARNINGS_DETAILS,
  GET_PROMOTER_DASHBOARD_EARNINGS_DETAILS_SUCCESS,
  GET_PROMOTER_DASHBOARD_EARNINGS_DETAILS_FAIL,
  GET_PROMOTER_PRODUCT,
  GET_PROMOTER_PRODUCT_SUCCESS,
  GET_PROMOTER_PRODUCT_FAIL,
  GET_PROMOTER_PRODUCT_DETAILS,
  GET_PROMOTER_PRODUCT_DETAILS_SUCCESS,
  GET_PROMOTER_PRODUCT_DETAILS_FAIL,
  GET_PROMOTER_PROFILE_DETAILS,
  GET_PROMOTER_PROFILE_DETAILS_SUCCESS,
  GET_PROMOTER_PROFILE_DETAILS_FAIL,
  GET_PROMOTER_PROFILE_UPDATE,
  GET_PROMOTER_PROFILE_UPDATE_SUCCESS,
  GET_PROMOTER_PROFILE_UPDATE_FAIL,
  GET_PROMOTER_TRANSACTION_GRAPH,
  GET_PROMOTER_TRANSACTION_GRAPH_SUCCESS,
  GET_PROMOTER_TRANSACTION_GRAPH_FAIL,
} from './actionTypes'

export const getPromoterDashbordPageview = page => ({
  type: PROMOTER_DASHBOARD_PAGE_VIEW_DETAILS,
  payload: page,
})

export const getPromoterDashboardPageViewSuccess = dashboardPageView => ({
  type: PROMOTER_DASHBOARD_PAGE_VIEW_DETAILS_SUCCESS,
  payload: dashboardPageView,
})

export const getPromoterDashboardPageViewFail = error => ({
  type: PROMOTER_DASHBOARD_PAGE_VIEW_DETAILS_FAIL,
  payload: error,
})

// earnings

export const getPromoterDashboardEarnings = page => ({
  type: GET_PROMOTER_DASHBOARD_EARNINGS_DETAILS,
  payload: page,
})

export const getPromoterDashboardEarningsSuccess = dashboardEarnings => ({
  type: GET_PROMOTER_DASHBOARD_EARNINGS_DETAILS_SUCCESS,
  payload: dashboardEarnings,
})

export const getPromoterDashboardEaningsFail = error => ({
  type: GET_PROMOTER_DASHBOARD_EARNINGS_DETAILS_FAIL,
  payload: error,
})

//  promoter product

export const getPromoterProduct = () => ({
  type: GET_PROMOTER_PRODUCT,
})

export const getPromoterProductSuccess = promoterProduct => ({
  type: GET_PROMOTER_PRODUCT_SUCCESS,
  payload: promoterProduct,
})

export const getPromoterProductFail = error => ({
  type: GET_PROMOTER_PRODUCT_FAIL,
  payload: error,
})

// promoter product details

export const getPromoterProductDetails = productId => ({
  type: GET_PROMOTER_PRODUCT_DETAILS,
  payload: productId,
})

export const getPromoterProductDetailsSuccess = promoterProductDetails => ({
  type: GET_PROMOTER_PRODUCT_DETAILS_SUCCESS,
  payload: promoterProductDetails,
})

export const getPromoterProductDetailsFail = error => ({
  type: GET_PROMOTER_PRODUCT_DETAILS_FAIL,
  payload: error,
})

// promoter profile data

export const getPromoterProfileDetails = () => ({
  type: GET_PROMOTER_PROFILE_DETAILS,
  payload: '',
})

export const getPromoterProfileDetailsSuccess = promoterProfile => ({
  type: GET_PROMOTER_PROFILE_DETAILS_SUCCESS,
  payload: promoterProfile,
})

export const getPromoterProfileDetailsFail = error => ({
  type: GET_PROMOTER_PROFILE_DETAILS_FAIL,
  payload: error,
})

export const updatePromoterProfile = (profile, onCloseClick) => ({
  type: GET_PROMOTER_PROFILE_UPDATE,
  payload: { profile, onCloseClick },
})

export const updatePromoterProfileSuccess = promoterProfile => ({
  type: GET_PROMOTER_PROFILE_UPDATE_SUCCESS,
  payload: promoterProfile,
})

export const updatePromoterProfileFail = error => ({
  type: GET_PROMOTER_PROFILE_UPDATE_FAIL,
  payload: error,
})

export const getPromoterTransactionGraph = sort => ({
  type: GET_PROMOTER_TRANSACTION_GRAPH,
  payload: sort,
})
export const getPromoterTransactionGraphSuccess =
  promoterTransactionGraphGrowthReward => ({
    type: GET_PROMOTER_TRANSACTION_GRAPH_SUCCESS,
    payload: promoterTransactionGraphGrowthReward,
  })
export const getPromoterTransactionGraphFail = error => ({
  type: GET_PROMOTER_TRANSACTION_GRAPH_FAIL,
  payload: error,
})
