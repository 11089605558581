import {
  GET_COLLECTIONS,
  GET_COLLECTIONS_FAIL,
  GET_COLLECTIONS_SUCCESS,
  GET_COLLECTION_DETAILS,
  GET_COLLECTION_DETAILS_SUCCESS,
  GET_COLLECTION_DETAILS_FAIL,
  CREATE_COLLECTION_SUCCESS,
  CREATE_COLLECTION_FAIL,
  UPDATE_COLLECTION,
  UPDATE_COLLECTION_SUCCESS,
  UPDATE_COLLECTION_FAIL,
  DELETE_COLLECTION_SUCCESS,
  DELETE_COLLECTION_FAIL,
  CREATE_COLLECTION,
} from './actionTypes'

const INIT_STATE = {
  collections: [],
  collectionDetails: {},
  error: {},
  loading: false,
}

const Collection = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COLLECTIONS:
    case GET_COLLECTION_DETAILS:
    case UPDATE_COLLECTION:
    case CREATE_COLLECTION:
      return {
        ...state,
        loading: true,
      }
    case GET_COLLECTIONS_SUCCESS:
      return {
        ...state,
        collections: action.payload,
        error: {},
        loading: false,
      }

    case CREATE_COLLECTION_FAIL:
    case DELETE_COLLECTION_FAIL:
    case UPDATE_COLLECTION_FAIL:
    case GET_COLLECTION_DETAILS_FAIL:
    case GET_COLLECTIONS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_COLLECTION_DETAILS_SUCCESS:
      return {
        ...state,
        collectionDetails: action.payload,
        error: {},
        loading: false,
      }

    case CREATE_COLLECTION_SUCCESS:
      return {
        ...state,
        collections: [...state.collections, action.payload],
        loading: false,
        error: {},
      }

    case UPDATE_COLLECTION_SUCCESS:
      return {
        ...state,
        collections: [...state.collections].map(collection =>
          collection._id.toString() === action.payload._id.toString()
            ? { collection, ...action.payload }
            : collection,
        ),
        loading: false,
        error: {},
      }

    case DELETE_COLLECTION_SUCCESS:
      return {
        ...state,
        collections: state.collections.filter(
          collection =>
            collection._id.toString() !== action.payload._id.toString(),
        ),
        loading: false,
        error: {},
      }

    default:
      return state
  }
}

export default Collection
