import {
  CREATE_SUPPLY_CHAIN,
  CREATE_SUPPLY_CHAIN_SUCCESS,
  CREATE_SUPPLY_CHAIN_FAIL,
  GET_ALL_SUPPLY_CHAINS,
  GET_ALL_SUPPLY_CHAINS_SUCCESS,
  GET_ALL_SUPPLY_CHAINS_FAIL,
  UPDATE_SUPPLY_CHAIN,
  UPDATE_SUPPLY_CHAIN_SUCCESS,
  UPDATE_SUPPLY_CHAIN_FAIL,
  DELETE_SUPPLY_CHAIN,
  DELETE_SUPPLY_CHAIN_SUCCESS,
  DELETE_SUPPLY_CHAIN_FAIL,
  GET_SUPPLY_CHAINS_DETAILS,
  GET_SUPPLY_CHAINS_DETAILS_SUCCESS,
  GET_SUPPLY_CHAINS_DETAILS_FAIL,
  GET_ALL_CHAIN_PROMOTERS,
  GET_ALL_CHAIN_PROMOTERS_FAIL,
  GET_ALL_CHAIN_PROMOTERS_SUCCESS,
  GET_CHAIN_PROMOTER_DETAILS,
  GET_CHAIN_PROMOTER_DETAILS_FAIL,
  GET_CHAIN_PROMOTER_DETAILS_SUCCESS,
  GET_ALL_SUPPLY_CHAINS_TRANSACTION,
  GET_ALL_SUPPLY_CHAINS_TRANSACTION_FAIL,
  GET_ALL_SUPPLY_CHAINS_TRANSACTION_SUCCESS,
  GET_ALL_WALLETS,
  GET_ALL_WALLETS_FAIL,
  GET_ALL_WALLETS_SUCCESS,
  CREATE_LUCKY_DRAW,
  GET_ALL_LUCKY_DRAWS,
  UPDATE_LUCKY_DRAW,
  DELETE_LUCKY_DRAW,
  GET_LUCKY_DRAW_DETAILS,
  CREATE_LUCKY_DRAW_FAIL,
  GET_ALL_LUCKY_DRAWS_FAIL,
  UPDATE_LUCKY_DRAW_FAIL,
  DELETE_LUCKY_DRAW_FAIL,
  GET_LUCKY_DRAW_DETAILS_FAIL,
  CREATE_LUCKY_DRAW_SUCCESS,
  GET_ALL_LUCKY_DRAWS_SUCCESS,
  UPDATE_LUCKY_DRAW_SUCCESS,
  DELETE_LUCKY_DRAW_SUCCESS,
  GET_LUCKY_DRAW_DETAILS_SUCCESS,
  GET_PROMOTER_TRANSACTION,
  GET_PROMOTER_TRANSACTION_SUCCESS,
  GET_PROMOTER_TRANSACTION_FAIL,
  CREATE_WITHDRAWAL_REQUEST,
  GET_ALL_WITHDRAWAL_REQUESTS,
  CREATE_WITHDRAWAL_REQUEST_FAIL,
  GET_ALL_WITHDRAWAL_REQUESTS_FAIL,
  CREATE_WITHDRAWAL_REQUEST_SUCCESS,
  GET_ALL_WITHDRAWAL_REQUESTS_SUCCESS,
  UPDATE_WITHDRAWAL_REQUEST,
  UPDATE_WITHDRAWAL_REQUEST_FAIL,
  UPDATE_WITHDRAWAL_REQUEST_SUCCESS,
  APPROVE_WITHDRAWAL_REQUEST,
  APPROVE_WITHDRAWAL_REQUEST_FAIL,
  APPROVE_WITHDRAWAL_REQUEST_SUCCESS,
  GET_PROMOTER_SUMMERY,
  GET_PROMOTER_SUMMERY_SUCCESS,
  GET_PROMOTER_SUMMERY_FAIL,
  GET_INITIATE_LUCKY_DRAW_SUCCESS,
  WINNER_APPROVE_SUCCESS,
  LUCKY_DRAW_WON_SUCCESS,
  LUCKY_DRAW_WON_FAIL,
  WINNER_APPROVE_FAIL,
  GET_INITIATE_LUCKY_DRAW_FAIL,
  GET_INITIATE_LUCKY_DRAW,
  WINNER_APPROVE,
  LUCKY_DRAW_WON,
  LUCKY_DRAW_WINNINGS,
  LUCKY_DRAW_WINNINGS_SUCCESS,
  LUCKY_DRAW_WINNINGS_FAIL,
  DOWNLOAD_WITHDRAWAL_REQUEST,
  DOWNLOAD_WITHDRAWAL_REQUEST_SUCCESS,
  DOWNLOAD_WITHDRAWAL_REQUEST_FAIL,
  SUPPLY_CHAIN_BY_PRODUCT,
  SUPPLY_CHAIN_BY_PRODUCT_FAIL,
  SUPPLY_CHAIN_BY_PRODUCT_SUCCESS,
  GENERATE_SUPPLY_CHAIN,
  GENERATE_SUPPLY_CHAIN_SUCCESS,
  GENERATE_SUPPLY_CHAIN_FAIL,
  SYNC_CHAIN_TREE_SUCCESS,
  VISUALIZE_CHAIN_TREE_SUCCESS,
  SYNC_CHAIN_TREE,
  VISUALIZE_CHAIN_TREE,
  SYNC_CHAIN_TREE_FAIL,
  VISUALIZE_CHAIN_TREE_FAIL,
  UPDATE_BULKREQUEST,
  UPDATE_BULKREQUEST_FAIL,
  UPDATE_BULKREQUEST_SUCCESS,
} from './actionTypes'

const INIT_STATE = {
  bulkRequests: {},
  bulkRequestLoad: false,
  supplyChains: {
    supplychains: [], // this state is from api
    total: '',
    page: '',
  },
  supplyChainsDetails: {},
  chainPromoters: {
    chainPromoters: [],
    total: '',
    page: '',
  },
  chainPromotersDetails: {},
  supplyChainTransactions: {
    supplyChainTransactions: [],
    total: '',
    page: '',
  },
  wallets: {
    wallets: [],
    total: '',
    page: '',
  },
  luckyDraws: {
    luckyDraws: [],
    total: '',
    page: '',
  },
  luckyDrawsDetails: {},

  withDrawalRequests: {
    withdrawalRequests: [],
    total: '',
    page: '',
  },
  withdrawalRequestsDetails: {},

  withDrawalStatusApprove: {
    withdrawalStatusApprove: [],
    total: '',
    page: '',
  },
  withdrawalStatusApproveDetails: {},

  dashboardData: {},
  InitiateLuckyDraw: {},
  winnerApprove: {},
  LuckyDrawWon: {},
  error: {},
  loading: false,
  promoterWithdrawalRequest: {},

  requestForDownload: {},
  downloadLoad: false,

  generateSupplyChainMsg: {},
  generateSupplyChainLoad: false,

  chainTreeLoad: false,
  syncTreeLoad: false,
  treeChainData: {},
}

const SupplyChain = (state = INIT_STATE, action) => {
  switch (action.type) {
    // rqst
    case CREATE_SUPPLY_CHAIN:
    case GET_ALL_SUPPLY_CHAINS:
    case DELETE_SUPPLY_CHAIN:
    case UPDATE_SUPPLY_CHAIN:
    case GET_SUPPLY_CHAINS_DETAILS:
    case GET_ALL_CHAIN_PROMOTERS:
    case GET_CHAIN_PROMOTER_DETAILS:
    case GET_ALL_SUPPLY_CHAINS_TRANSACTION:
    case GET_ALL_WALLETS:
    case CREATE_LUCKY_DRAW:
    case GET_ALL_LUCKY_DRAWS:
    case UPDATE_LUCKY_DRAW:
    case DELETE_LUCKY_DRAW:
    case GET_LUCKY_DRAW_DETAILS:
    case CREATE_WITHDRAWAL_REQUEST:
    case GET_ALL_WITHDRAWAL_REQUESTS:
    case UPDATE_WITHDRAWAL_REQUEST:
    case APPROVE_WITHDRAWAL_REQUEST:
    case GET_INITIATE_LUCKY_DRAW:
    case WINNER_APPROVE:
    case LUCKY_DRAW_WON:
    case SUPPLY_CHAIN_BY_PRODUCT:
      return {
        ...state,
        loading: true,
      }

    case VISUALIZE_CHAIN_TREE:
      return {
        ...state,
        chainTreeLoad: true,
      }

    case SYNC_CHAIN_TREE:
      return {
        ...state,
        syncTreeLoad: true,
      }

    case SYNC_CHAIN_TREE_FAIL:
    case VISUALIZE_CHAIN_TREE_FAIL:
      return {
        ...state,
        syncTreeLoad: false,
        chainTreeLoad: false,
      }
    // fail
    case SUPPLY_CHAIN_BY_PRODUCT_FAIL:
    case CREATE_SUPPLY_CHAIN_FAIL:
    case GET_ALL_SUPPLY_CHAINS_FAIL:
    case UPDATE_SUPPLY_CHAIN_FAIL:
    case DELETE_SUPPLY_CHAIN_FAIL:
    case GET_SUPPLY_CHAINS_DETAILS_FAIL:
    case GET_ALL_CHAIN_PROMOTERS_FAIL:
    case GET_CHAIN_PROMOTER_DETAILS_FAIL:
    case GET_ALL_SUPPLY_CHAINS_TRANSACTION_FAIL:
    case GET_ALL_WALLETS_FAIL:
    case CREATE_LUCKY_DRAW_FAIL:
    case GET_ALL_LUCKY_DRAWS_FAIL:
    case UPDATE_LUCKY_DRAW_FAIL:
    case DELETE_LUCKY_DRAW_FAIL:
    case GET_LUCKY_DRAW_DETAILS_FAIL:
    case CREATE_WITHDRAWAL_REQUEST_FAIL:
    case GET_ALL_WITHDRAWAL_REQUESTS_FAIL:
    case UPDATE_WITHDRAWAL_REQUEST_FAIL:
    case APPROVE_WITHDRAWAL_REQUEST_FAIL:
    case GET_INITIATE_LUCKY_DRAW_FAIL:
    case WINNER_APPROVE_FAIL:
    case LUCKY_DRAW_WON_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    // ..............................
    // success
    // create  SupplyChains
    case CREATE_SUPPLY_CHAIN_SUCCESS:
      return {
        ...state,
        supplyChainsDetails: action.payload,
        error: {},
        loading: false,
      }
    case GET_ALL_SUPPLY_CHAINS_SUCCESS:
      return {
        ...state,
        supplyChains: action.payload,
        error: {},
        loading: false,
      }
    case UPDATE_SUPPLY_CHAIN_SUCCESS:
      console.log(action.payload)
      return {
        ...state,
        error: {},
        loading: false,
        supplyChains: {
          ...state?.supplyChains,
          supplyChains: state?.supplyChains?.supplyChains?.map(list =>
            list?._id === action?.payload?._id
              ? { ...list, isActive: action?.payload?.isActive }
              : list,
          ),
        },
      }
    // delete supply chain
    case DELETE_SUPPLY_CHAIN_SUCCESS:
      console.log(action.payload)
      return {
        ...state,
        supplyChains: {
          ...state.supplyChains,
          supplychains: state.supplyChains?.supplychains?.filter(
            i => i._id !== action.payload,
          ),
        },
        error: {},
        loading: false,
      }
    //singlview supply chain
    case GET_SUPPLY_CHAINS_DETAILS_SUCCESS:
      return {
        ...state,
        supplyChainsDetails: action.payload,
        error: {},
        loading: false,
      }

    /* ALL list chain promoter */
    case GET_ALL_CHAIN_PROMOTERS_SUCCESS:
      return {
        ...state,
        chainPromoters: action.payload,
        error: {},
        loading: false,
      }
    // chain promoter singleview
    case GET_CHAIN_PROMOTER_DETAILS_SUCCESS:
      return {
        ...state,
        chainPromotersDetails: action.payload,
        // productVarients: action.payload,
        error: {},
        loading: false,
      }
    // ........................
    /* ALL list supplyChainTransactions*/
    case GET_ALL_SUPPLY_CHAINS_TRANSACTION_SUCCESS:
      return {
        ...state,
        supplyChainTransactions: action.payload,
        error: {},
        loading: false,
      }
    //..............................
    /* ALL list wallet*/
    case GET_ALL_WALLETS_SUCCESS:
      return {
        ...state,
        wallets: action.payload,
        error: {},
        loading: false,
      }
    //..............................

    // create  luckyDraws
    case CREATE_LUCKY_DRAW_SUCCESS:
      return {
        ...state,
        luckyDrawsDetails: action.payload,
        luckyDraws: {
          ...state.luckyDraws,
          luckyDraws: [action.payload, ...state.luckyDraws.luckyDraws],
        },
        error: {},
        loading: false,
      }
    // ALL luckyDraws
    case GET_ALL_LUCKY_DRAWS_SUCCESS:
      return {
        ...state,
        luckyDraws: action.payload,
        error: {},
        loading: false,
      }
    // update luckyDraws
    case UPDATE_LUCKY_DRAW_SUCCESS:
      return {
        ...state,
        error: {},
        luckyDrawsDetails: {
          ...state?.luckyDrawsDetails,
          ...action.payload,
        },
        luckyDraws: {
          ...state.luckyDraws,
          luckyDraws: state.luckyDraws?.luckyDraws?.map(i =>
            i._id === action.payload._id ? action.payload : i,
          ),
        },
        loading: false,
      }

    // delete luckyDraws
    case DELETE_LUCKY_DRAW_SUCCESS:
      return {
        ...state,
        luckyDraws: {
          ...state.luckyDraws,
          luckyDraws: state.luckyDraws?.luckyDraws?.filter(
            i => i._id !== action.payload._id,
          ),
        },

        error: {},
        loading: false,
      }
    //singlview luckyDraws
    case GET_LUCKY_DRAW_DETAILS_SUCCESS:
      return {
        ...state,
        luckyDrawsDetails: action.payload,
        error: {},
        loading: false,
      }

    case GET_PROMOTER_TRANSACTION:
      return {
        ...state,
        loading: true,
      }

    case GET_PROMOTER_TRANSACTION_SUCCESS:
      return {
        ...state,
        supplyChainTransactions: action.payload,
        error: {},
        loading: false,
      }

    case GET_PROMOTER_TRANSACTION_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    //.......................................................................................................
    // create  withdrawalRequest
    case CREATE_WITHDRAWAL_REQUEST_SUCCESS:
      return {
        ...state,
        withDrawalRequests: {
          ...state.withDrawalRequests,
          withdrawalRequests: [
            action.payload,
            ...state.withDrawalRequests.withdrawalRequests,
          ],
        },

        error: {},
        loading: false,
      }
    case GET_ALL_WITHDRAWAL_REQUESTS_SUCCESS:
      return {
        ...state,
        withDrawalRequests: action.payload,
        error: {},
        loading: false,
      }

    //update withdrawalRequest
    case UPDATE_WITHDRAWAL_REQUEST_SUCCESS:
      return {
        ...state,
        error: {},
        withdrawalRequestsDetails: action.payload,
        withDrawalRequests: {
          ...state.withDrawalRequests,
          withdrawalRequests: state.withDrawalRequests?.withdrawalRequests?.map(
            i => (i._id === action.payload._id ? action.payload : i),
          ),
        },
        loading: false,
      }
    //update withdrawalRequest status
    case APPROVE_WITHDRAWAL_REQUEST_SUCCESS:
      return {
        ...state,
        withdrawalStatusApproveDetails: action.payload,
        withDrawalRequests: {
          ...state.withDrawalRequests,
          withdrawalRequests: state.withDrawalRequests?.withdrawalRequests?.map(
            i => (i._id === action.payload._id ? action.payload : i),
          ),
        },
        error: {},
        loading: false,
      }

    case GET_PROMOTER_SUMMERY:
      return {
        ...state,
        loading: true,
      }

    case GET_PROMOTER_SUMMERY_SUCCESS:
      return {
        ...state,
        dashboardData: action.payload,
        error: {},
        loading: false,
      }

    case GET_PROMOTER_SUMMERY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    //Initiate Lucky Draw
    case GET_INITIATE_LUCKY_DRAW_SUCCESS:
      return {
        ...state,
        InitiateLuckyDraw: action.payload,
        error: {},
        loading: false,
      }

    //SUPPLY_CHAIN_BY_PRODUCT_SUCCESS
    case SUPPLY_CHAIN_BY_PRODUCT_SUCCESS:
      return {
        ...state,
        supplyChainsDetails: action.payload,
        error: {},
        loading: false,
      }

    //winner approve
    case WINNER_APPROVE_SUCCESS:
      return {
        ...state,
        winnerApprove: action.payload,
        luckyDrawsDetails: action.payload,
        error: {},
        loading: false,
      }
    //Lucky draw won
    case LUCKY_DRAW_WON_SUCCESS:
      return {
        ...state,
        LuckyDrawWon: action.payload,
        luckyDraws: action.payload,
        error: {},
        loading: false,
      }

    case LUCKY_DRAW_WINNINGS:
      return {
        ...state,
        error: {},
        loading: true,
      }
    case LUCKY_DRAW_WINNINGS_SUCCESS:
      return {
        ...state,
        luckyDraws: action.payload,
        error: {},
        loading: false,
      }
    case LUCKY_DRAW_WINNINGS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case DOWNLOAD_WITHDRAWAL_REQUEST:
      return {
        ...state,
        error: {},
        downloadLoad: true,
      }
    case DOWNLOAD_WITHDRAWAL_REQUEST_SUCCESS:
      return {
        ...state,
        requestForDownload: action.payload,
        error: {},
        downloadLoad: false,
      }
    case DOWNLOAD_WITHDRAWAL_REQUEST_FAIL:
      return {
        ...state,
        error: action.payload,
        downloadLoad: false,
      }
    case GENERATE_SUPPLY_CHAIN:
      return {
        ...state,
        error: {},
        generateSupplyChainLoad: true,
      }
    case GENERATE_SUPPLY_CHAIN_SUCCESS:
      return {
        ...state,
        generateSupplyChainMsg: action.payload,
        generateSupplyChainLoad: false,
        error: {},
      }
    case GENERATE_SUPPLY_CHAIN_FAIL:
      return {
        ...state,
        error: action.payload,
        generateSupplyChainLoad: false,
      }

    case VISUALIZE_CHAIN_TREE_SUCCESS:
      return {
        ...state,
        treeChainData: action.payload,
        chainTreeLoad: false,
        error: {},
      }

    case SYNC_CHAIN_TREE_SUCCESS:
      return {
        ...state,
        treeChainData: action.payload,
        syncTreeLoad: false,
        error: {},
      }

    // BulkRequest - This line cannot be edited or removed
    case UPDATE_BULKREQUEST:
      return {
        ...state,
        bulkRequestLoad: true,
      }

    case UPDATE_BULKREQUEST_FAIL:
      return {
        ...state,
        error: action.payload,
        bulkRequestLoad: false,
      }

    case UPDATE_BULKREQUEST_SUCCESS:
      return {
        ...state,
        bulkRequests: action.payload,
        bulkRequestLoad: false,
        error: {},
      }

    default:
      return state
  }
}

export default SupplyChain
