import React, { useState } from 'react'
import {
  Row,
  Card,
  CardBody,
  CardFooter,
  UncontrolledTooltip,
} from 'reactstrap'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { CurrencySwitch } from 'hooks/Currency'
import WithdrawalModal from 'pages/WithdrawalRequest/Crud/Modal'
import { useHistory } from 'react-router-dom'

const CardUser = ({ profile, data }) => {
  const currency = CurrencySwitch()
  const history = useHistory()

  const report = [
    {
      value: data?.todayWithdrawalAmount,
      class: 'bg-info text-info',
      id: 'todayWithdrawalAmount',
      placement: 'top',
      notification: (
        <>
          Today Amount&nbsp;{currency}&nbsp;
          {data?.todayWithdrawalAmount}
        </>
      ),
    },
    {
      value: data?.todayWithdrawalRequestsCount,
      id: 'todayWithdrawalRequestsCount',
      class: 'bg-danger text-danger',
      placement: 'bottom',
      notification: (
        <>
          Today Requests&nbsp;
          {data?.todayWithdrawalRequestsCount}
        </>
      ),
    },
    {
      value: data?.totalWithdrawalAmount,
      id: 'totalWithdrawalAmount',
      class: ' bg-warning text-warning ',
      placement: 'bottom',
      notification: (
        <>
          Total Amount&nbsp;{currency}&nbsp;
          {data?.totalWithdrawalAmount}
        </>
      ),
    },
    {
      value: data?.totalWithdrawalRequestsCount,
      id: 'totalWithdrawalRequestsCount',
      class: ' bg-primary text-primary ',
      placement: 'bottom',
      notification: (
        <>
          Total Requests&nbsp;
          {data?.totalWithdrawalRequestsCount}
        </>
      ),
    },
  ]

  const [WithdrawalModalOpen, setWithdrawalModalOpen] = useState({
    status: false,
    data: {},
  })

  return (
    <React.Fragment>
      {WithdrawalModalOpen?.status && (
        <WithdrawalModal
          onCloseClick={() =>
            setWithdrawalModalOpen({ status: false, data: {} })
          }
          show={WithdrawalModalOpen?.status}
          updateData={WithdrawalModalOpen?.data}
          isPromoterDashboard={true}
        />
      )}
      <Card>
        <CardBody className="border-top pb-2">
          <Row>
            <div className="col-sm-4">
              <div>
                <p className="fw-small mb-2">Balance :</p>
                <h4>
                  {currency} {profile?.walletBalance || 0}
                </h4>
              </div>
            </div>
            <div className="col-sm-8 p-0">
              <div className="mt-4 mt-sm-0">
                <p className="fw-medium mb-2">Withdrawal :</p>
                <div className="d-inline-flex flex-wrap align-items-center mt-1">
                  {report?.map((item, key) => (
                    <Link key={key} to="#" className="m-1">
                      <div
                        className="w-fit"
                        style={{ height: '2rem' }}
                        id={item?.id}
                      >
                        <span
                          style={{ borderRadius: 16 }}
                          className={`avatar-title p-2 bg-soft  font-size-18 ${item?.class}`}
                        >
                          {item?.value}
                        </span>
                      </div>
                      <UncontrolledTooltip
                        placement={item?.placement}
                        target={item?.id}
                      >
                        {item?.notification}
                      </UncontrolledTooltip>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </Row>
          <div className="text-muted pt-3">
            <p className="mb-1">
              <i className="mdi mdi-circle-medium align-middle text-primary me-1" />{' '}
              Today Transactions:{' '}
              <span className="text-black">
                {' '}
                {data?.todayPromoterTransactionsCount}
              </span>
            </p>
            <p className="mb-0">
              <i className="mdi mdi-circle-medium align-middle text-primary me-1" />{' '}
              Total Transactions :{' '}
              <span className="text-black">
                {data?.totalPromoterTransactionsCount}
              </span>
            </p>
          </div>
        </CardBody>

        <CardFooter className="bg-transparent border-top">
          <div className="text-center">
            <Link
              to="#"
              onClick={() => history.push('/withdrawal-requests')}
              className="btn btn-outline-info me-2 w-md"
            >
              View All Requests
            </Link>
            <Link
              to="#"
              onClick={() => setWithdrawalModalOpen({ status: true, data: {} })}
              className="btn btn-success me-2 w-md"
            >
              Withdrawal Request
            </Link>
          </div>
        </CardFooter>
      </Card>
    </React.Fragment>
  )
}

export default CardUser
CardUser.propTypes = {
  profile: PropTypes.any,
  data: PropTypes.any,
}
