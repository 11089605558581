import React from 'react'
import { Container } from 'reactstrap'

import MetaTag from 'components/Common/Meta-tag'
import WithdrawalRequests from './DatatableTables'
import BreadcrumbPremium from 'components/Common/Breadcrumb2'

function withdrawalRequests() {
  return (
    <>
      <MetaTag title={'All Withdrawal Requests '} />
      <div className="page-content">
        <BreadcrumbPremium
          root={['/promoter-dashboard', '/withdrawal-requests']}
        />
        <Container fluid>
          <div className="container-fluid">
            <WithdrawalRequests />
          </div>
        </Container>
      </div>
    </>
  )
}

export default withdrawalRequests
