import { AvField, AvForm } from 'availity-reactstrap-validation'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { CardTitle, Col, Modal, ModalBody, Row } from 'reactstrap'
import { createNotification, updateNotification } from 'store/actions'
import UseImageUploader from 'components/imageUploader/useImageUploader'

const NotificationModal = ({ show, onCloseClick, updateData }) => {
  const dispatch = useDispatch()

  const [imagesPreview, setImagesPreview] = useState([])

  const handleValidSubmit = (e, v) => {
    const data = {
      ...v,
      images: imagesPreview,
    }
    if (updateData?._id) {
      dispatch(updateNotification(data, updateData?._id, onCloseClick))
    } else {
      dispatch(createNotification(data, onCloseClick))
    }
  }

  useEffect(() => {
    if (updateData) {
      setImagesPreview(updateData?.images)
    }
  }, [updateData])

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="p-4">
        <CardTitle>{updateData ? 'Edit' : 'Add'} Notification</CardTitle>
        <AvForm
          onValidSubmit={(e, v) => {
            handleValidSubmit(e, v)
          }}
        >
          <Row>
            <div className="col-12 mb-4 px-3">
              <AvField
                name="title"
                label="Title"
                type="text"
                value={updateData?.title || ''}
              />
            </div>
            <div className="col-12 mb-4 px-3">
              <AvField
                name="link"
                label="Link"
                type="text"
                value={updateData?.link || ''}
              />
            </div>
            <div className="col-12 mb-4 px-3">
              <AvField
                name="description"
                label="Description"
                type="textarea"
                value={updateData?.description || ''}
                style={{
                  minHeight: 140,
                }}
              />
            </div>

            <Col md="6">
              <UseImageUploader
                imageSize={4}
                imagesPreview={imagesPreview || []}
                setImagesPreview={setImagesPreview}
                uploadUrl={'/categories'}
              />
            </Col>
          </Row>
          <div className="d-flex justify-content-end align-items-center">
            <div className="text-center mt-3">
              <button
                type="button"
                className="btn btn-danger ms-2"
                onClick={onCloseClick}
              >
                Cancel
              </button>
              <button type="submit" className="btn btn-success ms-2">
                Save
              </button>
            </div>
          </div>
        </AvForm>
      </ModalBody>
    </Modal>
  )
}

NotificationModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
  updateData: PropTypes.any,
}

export default NotificationModal
