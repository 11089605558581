import { call, put, takeEvery } from 'redux-saga/effects'

import { GET_PRODUCT_PERFORMANCE } from './actionTypes'
import {
  getProductPerformanceSuccess,
  getProductPerformanceFail,
} from './actions'
import { get } from 'helpers/api_helper'

function getProductPerformanceAPi({ page, limit, searchText, sortByDate }) {
  return get(
    `/static/performance/product?page=${page ? page : 1}&limit=${
      limit ? limit : 10
    }&search=${searchText}&from=${sortByDate.from}&to=${sortByDate.to}`,
  )
}

function* fetchProductPerformance({ payload }) {
  try {
    const response = yield call(getProductPerformanceAPi, payload)
    yield put(getProductPerformanceSuccess(response))
  } catch (error) {
    yield put(getProductPerformanceFail(error))
  }
}

function* performanceSaga() {
  yield takeEvery(GET_PRODUCT_PERFORMANCE, fetchProductPerformance)
}

export default performanceSaga
