import { AvField, AvForm } from 'availity-reactstrap-validation'
import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Alert, CardSubtitle, Col, Modal, ModalBody, Row } from 'reactstrap'
import { updatePromoterProfile } from 'store/actions'

const PromoterUpdateModal = ({ show, onCloseClick, updateData }) => {
  const dispatch = useDispatch()
  const { loading, errorMsg } = useSelector(state => ({
    loading: state.SupplyChain.loading,
  }))

  function handleValidSubmit(values) {
    dispatch(updatePromoterProfile(values, onCloseClick))
  }

  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      centered={true}
      style={deleteModalStyle}
    >
      <ModalBody style={deleteModalBodyStyle} className="p-4">
        {errorMsg?.length >= 1 && <Alert color="danger">{errorMsg}</Alert>}

        <CardSubtitle className="mb-3">Update Profile</CardSubtitle>
        <AvForm
          onValidSubmit={(e, v) => {
            handleValidSubmit(v)
          }}
        >
          <Row xl="12">
            <div className="col-12 mb-4 px-3">
              <AvField
                label={
                  <>
                    Name<sup>*</sup>
                  </>
                }
                name="name"
                required
                type="text"
                value={updateData?.name || ''}
              />
            </div>
            <div className="col-12 mb-4 px-3">
              <AvField
                name="address"
                label="Address"
                value={updateData?.address || ''}
                type="textarea"
              />
            </div>
            <div>
              <p className="text-muted">Account Details :</p>
            </div>
            <Col md={6} lg="6">
              <AvField
                name="accountDetails.accountNumber"
                value={updateData?.accountDetails?.accountNumber || ''}
                label={'Account Number'}
                type="text"
              />
            </Col>
            <Col md={6} lg="6">
              <AvField
                name="accountDetails.ifsc"
                value={updateData?.accountDetails?.ifsc || ''}
                label={'IFSC Code'}
                type="text"
              />
            </Col>

            <div className="d-flex align-items-center justify-content-end px-3 mt-4">
              <button
                type="submit"
                className="mt-3 btn btn-success save-customer"
              >
                {loading && (
                  <>
                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                  </>
                )}
                Save Profile
              </button>
            </div>
          </Row>
        </AvForm>
      </ModalBody>
    </Modal>
  )
}

PromoterUpdateModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
  updateData: PropTypes.any,
}

export default PromoterUpdateModal

const deleteModalStyle = {
  width: 600,
  minWidth: 600,
}
const deleteModalBodyStyle = {
  minHeight: 100,
}
