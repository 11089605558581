import React from 'react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, CardBody, Button } from 'reactstrap'
import { CardTitle } from 'reactstrap'
import { Col, Row } from 'reactstrap'
import ToolkitProvider from 'react-bootstrap-table2-toolkit'
import BootstrapTable from 'react-bootstrap-table-next'
import { CSVLink } from 'react-csv'
var xlsx = require('xlsx')

// Form Editor
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import 'assets/css/style.css'

//actions
import { getAllProductsForDownload, updateBulkProducts } from 'store/actions'

import { map } from 'lodash'

function MultiProductUpdate() {
  const dispatch = useDispatch()

  const { productsForDownload, updateBulkProductsLoading } = useSelector(
    state => ({
      productsForDownload: state.Products.productsForDownload,
      updateBulkProductsLoading: state.Products.updateBulkProductsLoading,
    }),
  )

  const [uploadedExcelDataAsJson, setUploadedExcelDataAsJson] = useState()

  useEffect(() => {
    dispatch(getAllProductsForDownload())
  }, [dispatch, updateBulkProductsLoading])

  const readUploadFile = e => {
    e.preventDefault()
    if (e.target.files) {
      const reader = new FileReader()
      reader.onload = e => {
        const data = e.target.result
        const workbook = xlsx.read(data, { type: 'array' })
        const sheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[sheetName]
        const json = xlsx.utils.sheet_to_json(worksheet)
        setUploadedExcelDataAsJson(json)
      }
      reader.readAsArrayBuffer(e.target.files[0])
    }
  }

  const columns = [
    {
      dataField: '_id',
      filterText: '_id',
      text: 'ID',
      sort: true,
    },
    {
      dataField: 'itemCode',
      filterText: 'itemCode',
      text: 'Item Code',
      sort: true,
    },
    {
      dataField: 'name',
      filterText: 'name',
      text: 'Name',
      sort: true,
    },
    {
      dataField: 'cost',
      filterText: 'cost',
      text: 'Cost',
      sort: true,
    },
    {
      dataField: 'price',
      filterText: 'price',
      text: 'Price',
      sort: true,
    },
    {
      dataField: 'offerPrice',
      filterText: 'offerPrice',
      text: 'Offer Price',
      sort: true,
    },
    {
      dataField: 'stock',
      filterText: 'stock',
      text: 'Stock',
      sort: true,
    },
  ]

  const allExcelData = map(uploadedExcelDataAsJson, (item, index) => ({
    ...item,
    key: index,
  }))

  const handleUploadExcelData = () => {
    dispatch(updateBulkProducts({ products: uploadedExcelDataAsJson }))
  }

  const headers = [
    { label: '_id', key: '_id' },
    { label: 'itemCode', key: 'itemCode' },
    { label: 'name', key: 'name' },
    { label: 'cost', key: 'cost' },
    { label: 'price', key: 'price' },
    { label: 'offerPrice', key: 'offerPrice' },
    { label: 'stock', key: 'stock' },
  ]

  const excelDownloadProducts = map(productsForDownload?.products, item => ({
    ...item,
    _id: item._id,
    itemCode: item.itemCode,
    name: item?.primaryLang?.name,
    cost: item.cost,
    price: item.price,
    offerPrice: item.offerPrice,
    stock: item.stock,
  }))

  return (
    <Row>
      <Col className="col-12">
        <Card>
          <CardBody>
            <CardTitle className="h4 mb-4 bg_deals_green">
              Multi Product Update
            </CardTitle>
            <div>
              <ToolkitProvider
                keyField="key"
                columns={columns}
                data={allExcelData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col className="d-flex justify-content-space-between">
                        <div className="search-box mb-2 d-inline-block">
                          <div className="position-relative">
                            <div className="mb-3 ">
                              <form>
                                <label className="" htmlFor="upload">
                                  Upload Excel File:
                                </label>
                                <br />
                                <input
                                  type="file"
                                  name="upload"
                                  id="upload"
                                  onChange={readUploadFile}
                                />
                              </form>
                            </div>
                          </div>
                        </div>

                        <Button
                          data-tip
                          data-for="downloadTip"
                          style={{ width: '300px', border: 'none' }}
                          className="w-md btn btn-info m-auto me-0 mt-0 mb-2 d-block bg-white"
                        >
                          {productsForDownload &&
                            productsForDownload?.products &&
                            productsForDownload?.products?.length > 0 && (
                              <CSVLink
                                data={excelDownloadProducts}
                                headers={headers}
                                filename={'products.csv'}
                              >
                                <i
                                  className="bx bx-download mx-1"
                                  style={{
                                    fontSize: '18px',
                                    color: 'blue',
                                  }}
                                />{' '}
                                {`Download ${productsForDownload?.products.length} products`}
                              </CSVLink>
                            )}
                          {/* <ReactTooltip id="downloadTip" place="bottom" effect="solid">
                        Download
                        </ReactTooltip> */}
                        </Button>
                      </Col>
                    </Row>
                    {allExcelData?.length >= 1 ? (
                      <Row>
                        <p>Total Products :&nbsp;{allExcelData?.length} </p>
                        {/* <span onClick={()=>setUploadedExcelDataAsJson({})}>Clear table</span> */}

                        <Col xl="12">
                          <div className="table-responsive">
                            <BootstrapTable
                              keyField={'id'}
                              key={'id'}
                              responsive
                              bordered={false}
                              striped={false}
                              classes={'table align-middle table-nowrap'}
                              headerWrapperClasses={'thead-light'}
                              {...toolkitProps.baseProps}
                            />
                          </div>
                        </Col>
                        <Col>
                          <div className="d-flex align-items-center justify-content-end">
                            <button
                              className="mt-5 w-25 btn btn-success bg-success save-customer"
                              style={{ maxWidth: '150px' }}
                              onClick={handleUploadExcelData}
                            >
                              {updateBulkProductsLoading && (
                                <>
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                </>
                              )}
                              Update Data
                            </button>
                          </div>
                        </Col>
                      </Row>
                    ) : (
                      <></>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default MultiProductUpdate
