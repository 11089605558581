import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

export function Notification({ type, title, message }) {
  toastr.options = {
    positionClass: 'toast-top-right',
    timeOut: 2000,
    extendedTimeOut: 500,
    // closeButton: closeButton,
    // debug: debug,
    // progressBar: progressBar,
    preventDuplicates: 'preventDuplicates',
    newestOnTop: 'newestOnTop',
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 300,
  }

  if (type === 'info') toastr.info(message, title)
  else if (type === 'warning') toastr.warning(message, title)
  else if (type === 'error') toastr.error(message, title)
  else toastr.success(message, title)

  return true
}

export const errorNotification = () => {
  Notification({
    type: 'error',
    message: 'Something Went Wrong',
    title: 'Try Again',
  })
}
export const useSuccessNotification = msg => {
  Notification({
    type: 'success',
    message: msg ? msg : 'Done',
    title: 'Success',
  })
}

export function NotificationAsync({ title, message, timeOut }) {
  toastr.options = {
    positionClass: 'toast-top-right',
    timeOut: timeOut,
    extendedTimeOut: 500,
    closeButton: false,
    progressBar: true,
    preventDuplicates: 'preventDuplicates',
    newestOnTop: 'newestOnTop',
    showMethod: 'fadeIn',
    showDuration: 500,
  }

  toastr.info(message, title)
  return true
}
