import { ApiPut, get } from 'helpers/api_helper'
import { call, put, takeEvery } from 'redux-saga/effects'
import {
  getPromoterDashboardPageViewSuccess,
  getPromoterDashboardPageViewFail,
  getPromoterDashboardEarningsSuccess,
  getPromoterDashboardEaningsFail,
  getPromoterProductSuccess,
  getPromoterProductFail,
  getPromoterProductDetailsFail,
  getPromoterProductDetailsSuccess,
  getPromoterProfileDetailsSuccess,
  getPromoterProfileDetailsFail,
  updatePromoterProfileSuccess,
  updatePromoterProfileFail,
  getPromoterTransactionGraphSuccess,
  getPromoterTransactionGraphFail,
} from './actions'
import {
  PROMOTER_DASHBOARD_PAGE_VIEW_DETAILS,
  GET_PROMOTER_DASHBOARD_EARNINGS_DETAILS,
  GET_PROMOTER_PRODUCT,
  GET_PROMOTER_PRODUCT_DETAILS,
  GET_PROMOTER_PROFILE_DETAILS,
  GET_PROMOTER_PROFILE_UPDATE,
  GET_PROMOTER_TRANSACTION_GRAPH,
} from './actionTypes'

//Promoter Dashboard page view
function promoterDashboardPageViewApi(page) {
  return get(`/page-view/promoter/all?page=${page ? page : 1}`)
}

// Promoter Dashboard earnings
function promoterDashboardEarningsApi(page) {
  return get(`/earning/promoter/all?page=${page ? page : 1}`)
}

// promoter product
function promoterProductApi() {
  return get(`/supplychain/product/all`)
}

//promoter product details
function productDetailsApi(productId) {
  return get(`/product/promoter/single/${productId}`)
}

// promoter profile api
function promoterProfileApi() {
  return get(`/promoter`)
}

function promoterProfileUpdateApi({ profile }) {
  return ApiPut(`/promoter`, profile)
}

function promoterTransactionGraphApi(sort) {
  return get(`/promotertransaction/admin/graph/monthly?sort=${sort}&promoter=`)
}

// page view
function* promoterDashboardPageView({ payload }) {
  try {
    const res = yield call(promoterDashboardPageViewApi, payload)
    yield put(getPromoterDashboardPageViewSuccess(res))
  } catch (error) {
    yield put(getPromoterDashboardPageViewFail(error))
  }
}

// earnings
function* promoterDashboardEarnings({ payload }) {
  try {
    const response = yield call(promoterDashboardEarningsApi, payload)
    yield put(getPromoterDashboardEarningsSuccess(response))
  } catch (error) {
    yield put(getPromoterDashboardEaningsFail(error))
  }
}

// promoter product
function* promoterProduct() {
  try {
    const response = yield call(promoterProductApi)
    yield put(getPromoterProductSuccess(response))
  } catch (error) {
    yield put(getPromoterProductFail(error))
  }
}

// promoter product details
function* fetchPromoterProductDetails({ payload }) {
  try {
    const response = yield call(productDetailsApi, payload)
    yield put(getPromoterProductDetailsSuccess(response))
  } catch (error) {
    yield put(getPromoterProductDetailsFail(error))
  }
}

function* promoterProfile({ payload }) {
  try {
    const response = yield call(promoterProfileApi, payload)
    yield put(getPromoterProfileDetailsSuccess(response))
  } catch (error) {
    yield put(getPromoterProfileDetailsFail(error))
  }
}
function* promoterProfileUpdate({ payload }) {
  try {
    const response = yield call(promoterProfileUpdateApi, payload)
    yield put(updatePromoterProfileSuccess(response))

    if (payload?.onCloseClick) {
      payload?.onCloseClick()
    }
  } catch (error) {
    yield put(updatePromoterProfileFail(error))
  }
}

function* fetchPromoterTransactionsGraph({ payload }) {
  try {
    const response = yield call(promoterTransactionGraphApi, payload)
    yield put(
      getPromoterTransactionGraphSuccess({
        data: response,
        sort: payload,
      }),
    )
  } catch (error) {
    yield put(getPromoterTransactionGraphFail(error))
  }
}

function* promoterSaga() {
  // promoter dashboard
  // page view
  yield takeEvery(
    PROMOTER_DASHBOARD_PAGE_VIEW_DETAILS,
    promoterDashboardPageView,
  )
  // earnings
  yield takeEvery(
    GET_PROMOTER_DASHBOARD_EARNINGS_DETAILS,
    promoterDashboardEarnings,
  )
  // promoter product
  yield takeEvery(GET_PROMOTER_PRODUCT, promoterProduct)

  yield takeEvery(GET_PROMOTER_PROFILE_DETAILS, promoterProfile)
  yield takeEvery(GET_PROMOTER_PROFILE_UPDATE, promoterProfileUpdate)

  // promoter product details
  yield takeEvery(GET_PROMOTER_PRODUCT_DETAILS, fetchPromoterProductDetails)

  // graph
  yield takeEvery(
    GET_PROMOTER_TRANSACTION_GRAPH,
    fetchPromoterTransactionsGraph,
  )
}
export default promoterSaga
