import { call, put, takeEvery } from 'redux-saga/effects'

import {
  GET_WITHDRAWALLISTS,
  GET_WITHDRAWALLIST_DETAILS,
  CREATE_WITHDRAWALLIST,
  UPDATE_WITHDRAWALLIST,
  DELETE_WITHDRAWALLIST,
} from './actionTypes'
import {
  getWithdrawalListsFail,
  getWithdrawalListsSuccess,
  getWithdrawalListDetailsSuccess,
  getWithdrawalListDetailsFail,
  createWithdrawalListFail,
  createWithdrawalListSuccess,
  updateWithdrawalListSuccess,
  updateWithdrawalListFail,
  deleteWithdrawalListSuccess,
  deleteWithdrawalListFail,
} from './actions'

import { get, post, ApiPut, del } from 'helpers/api_helper'
import { Notification } from '../../components/Common/Notification'

// WithdrawalList - This line cannot be edited or removed
function getWithdrawalListsAPi({ page, sort, limit, promoter }) {
  return get(
    `/ima-wallet-withdrawal/admin/all?status=${sort ? sort : ''}&page=${
      page ? page : 1
    }&limit=${limit ? limit : 10}&promoter=${promoter ? promoter : ''}`,
  )
}

const getWithdrawalListDetailsAPi = withdrawallistId => {
  return get(`/withdrawallist/admin/single/${withdrawallistId}`)
}

const createWithdrawalListApi = ({ withdrawallist }) => {
  return post('/withdrawallist/admin/new', withdrawallist)
}

const updateWithdrawalListApi = ({ withdrawallist, withdrawallistId }) => {
  return ApiPut(`/withdrawallist/admin/${withdrawallistId}`, withdrawallist)
}

const deleteWithdrawalListApi = ({ withdrawallistId }) => {
  return del(`/withdrawallist/admin/${withdrawallistId}`)
}

function* fetchWithdrawalLists({ payload }) {
  try {
    const response = yield call(getWithdrawalListsAPi, payload)
    yield put(getWithdrawalListsSuccess(response))
  } catch (error) {
    yield put(getWithdrawalListsFail(error))
  }
}

function* fetchWithdrawalListDetails({ payload: withdrawallistId }) {
  try {
    const response = yield call(getWithdrawalListDetailsAPi, withdrawallistId)
    yield put(getWithdrawalListDetailsSuccess(response))
  } catch (error) {
    yield put(getWithdrawalListDetailsFail(error))
  }
}

function* onCreateWithdrawalList({ payload }) {
  try {
    const response = yield call(createWithdrawalListApi, payload)
    if (response?.message) {
      Notification({
        type: 'error',
        message: response?.message,
        title: '',
      })
    } else if (response) {
      yield put(createWithdrawalListSuccess(response))
      payload?.history?.push('/withdrawallists')
      doneNotification('WithdrawalList Created Successfully!')
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: 'error',
        message: error?.response?.data?.message,
        title: '',
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: 'error',
        message: 'file size is Too Large',
        title: '',
      })
    }
    yield put(createWithdrawalListFail(error))
  }
}

function* onUpdateWithdrawalList({ payload }) {
  try {
    const response = yield call(updateWithdrawalListApi, payload)
    if (response) {
      Notification({
        type: 'success',
        message: 'WithdrawalList Updated Successfully!',
        title: '',
      })
      yield put(updateWithdrawalListSuccess(response))
      if (payload.history) {
        payload.history.push('/withdrawallists')
      }
    }
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: 'error',
        message: error.response?.data?.message,
        title: '',
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: 'error',
        message: 'file size is Too Large',
        title: '',
      })
    }
    yield put(updateWithdrawalListFail(error.response.data))
  }
}

function* onDeleteWithdrawalList({ payload }) {
  try {
    const response = yield call(deleteWithdrawalListApi, payload)
    yield put(deleteWithdrawalListSuccess(response))
    Notification({
      type: 'success',
      message: 'WithdrawalList Deleted Successfully!',
      title: '',
    })
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: 'error',
        message: error?.response?.data?.message,
        title: '',
      })
    }
    yield put(deleteWithdrawalListFail(error?.response?.data))
  }
}

function* withdrawallistSaga() {
  yield takeEvery(GET_WITHDRAWALLISTS, fetchWithdrawalLists)
  yield takeEvery(GET_WITHDRAWALLIST_DETAILS, fetchWithdrawalListDetails)
  yield takeEvery(CREATE_WITHDRAWALLIST, onCreateWithdrawalList)
  yield takeEvery(UPDATE_WITHDRAWALLIST, onUpdateWithdrawalList)
  yield takeEvery(DELETE_WITHDRAWALLIST, onDeleteWithdrawalList)
}

export default withdrawallistSaga

function doneNotification(message) {
  Notification({
    type: 'success',
    message: message,
    title: '',
  })
}
